import { IconButton, styled } from '@mui/material';
import { FC, useEffect } from 'react';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { authService } from '../../../../../../services/auth/auth.service';
import { redirectIfProduction } from '../../../../../../../routes/helper.routes';
import { useTranslation } from 'react-i18next';

export const Logout: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const subSignOut = authService.sub.signOut().subscribe(() => {
      redirectIfProduction({
        fallbackHandler: () => {
          // Reload page to reset all streams values.
          // App will be redirected to the login page after reload.
          window.location.reload();
        },
      });
    });
    return () => {
      subSignOut.unsubscribe();
    };
  }, []);

  const signOut = () => {
    authService.pub.signOut();
    localStorage.clear();
    sessionStorage.clear();
    const cookies = document.cookie.split(';').map((v) => v.trim());
    for (let i = 0, len = cookies.length; i < len; i++) {
      document.cookie = `${cookies[i]}=;expires=${new Date(0).toUTCString()}`;
    }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker?.controller?.postMessage({ type: 'LOG_OUT' });
    }
  };

  return (
    <SWrapper onClick={signOut} size='large' title={t('common.sign_out')}>
      <LogoutIcon />
    </SWrapper>
  );
};

const SWrapper = styled(IconButton)(({ theme }) => ({
  width: '34px',
  height: '34px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));
