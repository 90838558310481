import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { Content } from '../../../../../shared/components/content/content.component';
import {
  ArticleProductionTabDataRes,
  ProductionTabOptionsRes,
} from '../../../services/article.service';
import { ITabState } from '../../../states/tabState.model';
import { ProductionForm } from './components/productionForm.component';
import { IProductionTabState, productionTabState } from './production.state';
import { createValidationSchema } from './validation/production.schema';
import { isDoughService } from '../articleIsDough.service';

const validationSchema = createValidationSchema();

export const ProductionTab: FC = () => {
  const [productionRecipeValidation, setProductionRecipeValidation] = useState<boolean>(false);
  const { defaultValues, productionTabOptions } = useLoaderData() as IProductionTab;
  const options = productionTabOptions as ProductionTabOptionsRes;
  const [isArticleIdExist, setIsArticleIdExist] = useState<boolean>(Boolean(defaultValues?.id));
  const [dictRecipesWithConnectedArticles, setDictRecipesWithConnectedArticles] = useState(
    options?.dictRecipesWithConnectedArticles,
  );
  options.dictRecipesWithConnectedArticles = dictRecipesWithConnectedArticles;
  const { t } = useTranslation();
  const formMethods = useForm<ArticleProductionTabDataRes>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
    context: {
      setLoading: setProductionRecipeValidation,
    },
  });

  const { watch, reset, resetField, trigger, setValue } = formMethods || {};

  useEffect(() => {
    trigger('productionRecipeId');
  }, [watch('id')]);

  const isDough = watch('isDough');
  useEffect(() => {
    isDoughService.pub.isDough(isDough);
  }, [isDough]);

  useEffect(() => {
    productionTabState.pub.initDefaultValues(defaultValues);
    const formStateSub = watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        if (reason.name === 'productionRecipeId') {
          trigger('productionRecipeId');
        }
        const fieldName = reason.name;
        productionTabState.pub.recordData({
          [fieldName]: data[fieldName],
          id: data.id,
        });
      }
    });
    const unsubarticleIdNotExist = productionTabState.sub.articleIdNotExist().subscribe(() => {
      setIsArticleIdExist(false);
      reset({});
    });
    const unsubProductionState = productionTabState.sub
      .state()
      .subscribe(
        ({ action, dataToSave, defaultValues, dictRecipesWithConnectedArticles }: IState) => {
          setIsArticleIdExist(true);
          if (action === 'saveArticleProductionCalculation') {
            const { articleProductionCalculationDependencies } = defaultValues;
            setValue(
              'articleProductionCalculationDependencies.whichArticlesThisUses',
              articleProductionCalculationDependencies.whichArticlesThisUses,
            );
            setValue(
              'articleProductionCalculationDependencies.whichArticlesUseThis',
              articleProductionCalculationDependencies.whichArticlesUseThis,
            );
            setValue(
              'articleProductionCalculationDependencies.circularChain',
              articleProductionCalculationDependencies.circularChain,
            );
          } else if (action === 'list.selectedArticle') {
            setDictRecipesWithConnectedArticles(dictRecipesWithConnectedArticles);
            reset(defaultValues, {
              keepErrors: true,
            });
          } else {
            for (const valuesKey in dataToSave) {
              if (valuesKey && dataToSave) {
                resetField(valuesKey as keyof typeof dataToSave, {
                  defaultValue: dataToSave[valuesKey as keyof typeof dataToSave],
                  keepDirty: false,
                  keepError: true,
                });
              }
            }
          }
        },
      );
    return () => {
      formStateSub.unsubscribe();
      unsubProductionState.unsubscribe();
      unsubarticleIdNotExist.unsubscribe();
    };
  }, []);

  return (
    <>
      {isArticleIdExist ? (
        <ProductionForm
          formMethods={formMethods}
          productionTabOptions={options}
          productionRecipeValidation={productionRecipeValidation}
        />
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};
interface IState extends IProductionTabState {
  defaultValues: ArticleProductionTabDataRes;
}
interface IProductionTab extends ITabState {
  defaultValues: ArticleProductionTabDataRes;
}
