import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper';

type Keys =
  | 'offer'
  | 'date_settings'
  | 'valid_until'
  | 'article'
  | 'article_other'
  | 'empty_offer_grid'
  | 'individual_description'
  | 'lot_quantity'
  | 'error_calculating_price'
  | 'accepted_offers'
  | 'active_customers'
  | 'enter_customer_info'
  | 'creatingCustomer'
  | 'one_time_customer_offer'
  | 'inactive_customers'
  | 'additional_offer'
  | 'open_offers'
  | 'declined_offers'
  | 'reset_to_default'
  | 'choose_default'
  | 'offer_no'
  | 'last_offer'
  | 'select_offer'
  | 'select_offer_title'
  | 'page_break'
  | 'group_header'
  | 'add_page_break'
  | 'add_header'
  | 'create_offer'
  | 'decline_offer'
  | 'accept_offer'
  | 'offer_num'
  | 'save_offer'
  | 'offer_accepted'
  | 'email_message'
  | 'new_position'
  // <-- errors messages
  | 'empty_positions_create_warning';

const t = LocalizationHelper<Keys>('offer');

export const offerDe = t({
  offer: 'Angebot',
  date_settings: 'Datumseinstellungen',
  valid_until: 'Gültig bis',
  article: 'Artikel',
  article_other: 'Artikel',
  empty_offer_grid: 'Klicken Sie auf “+”, um den ersten Artikel hinzuzufügen',
  individual_description: 'Individuelle Bezeichnung',
  lot_quantity: 'Der Wert muss ein Vielfaches der Anzahl der Lots sein ({{quantityPerLot}})',
  error_calculating_price: 'Beim Berechnen des Preises ist ein Fehler aufgetreten.',
  accepted_offers: 'Akzeptierte Angebote',
  active_customers: 'Aktive Kunden',
  inactive_customers: 'Inaktive Kunden',
  additional_offer: 'Weiteres Angebot',
  enter_customer_info: 'Kundeninformationen eingeben',
  creatingCustomer: 'Erstellen Sie einen Kunden',
  one_time_customer_offer: 'Angebot für Einmalkunde',
  open_offers: 'Offene Angebote',
  declined_offers: 'Abgelehnte Angebote',
  reset_to_default: 'Auf Standard zurücksetzen',
  choose_default: 'Vorgabe wählen',
  offer_no: 'Angebot-Nr.',
  last_offer: 'Letztes Angebot',
  select_offer: 'Angebot wählen',
  select_offer_title: 'Bitte wählen Sie die gewünschte Angebot aus der Liste unten:',
  add_page_break: 'Seitenumbruch hinzufügen',
  add_header: 'Kopfzeile hinzufügen',
  page_break: 'Seitenumbruch',
  group_header: 'Kopfzeile gruppe',
  accept_offer: 'Angebot Akzeptierte',
  create_offer: 'Angebot Erstellen',
  decline_offer: 'Angebot Ablehnen',
  empty_positions_create_warning:
    'Das Angebot kann nicht erstellt werden, da das Feld Betrag in allen Positionen des Angebots leer ist.',
  offer_num: 'Angebot-Nr.',
  save_offer: 'Angebot speichern',
  offer_accepted: 'Angebot angenommen',
  email_message:
    'Sehr geehrte Damen und Herren\n \nIn der Beilage erhalten Sie den Angebot {{offerNr}} zur Archivierung.\n \nMit freundlichen Grüssen',
  new_position: 'Neue Position',
});

export const offerEn = t({
  offer: 'Offer',
  date_settings: 'Date settings',
  valid_until: 'Valid until',
  article: 'Article',
  article_other: 'Articles',
  empty_offer_grid: 'Click “+” to add the first item',
  individual_description: 'Individual description',
  lot_quantity: 'The value must be a multiple of the number of lots ({{quantityPerLot}})',
  error_calculating_price: 'An error occurred while calculating the price.',
  accepted_offers: 'Accepted offers',
  active_customers: 'Active customers',
  inactive_customers: 'Inactive customers',
  additional_offer: 'Additional offer',
  open_offers: 'Open offers',
  enter_customer_info: 'Enter customer information',
  creatingCustomer: 'Creating a customer',
  one_time_customer_offer: 'Offer for one-time customer',
  declined_offers: 'Declined offers',
  reset_to_default: 'Reset to default',
  choose_default: 'Choose default',
  offer_no: 'Offer No.',
  last_offer: 'Last Offer',
  select_offer: 'Select offer',
  select_offer_title: 'Please select desired offer from the list below:',
  add_page_break: 'Add page break',
  add_header: 'Add header',
  page_break: 'Page break',
  group_header: 'Group header',
  accept_offer: 'Offer Accepted',
  create_offer: 'Create offer',
  decline_offer: 'Offer Declined',
  empty_positions_create_warning:
    'Offer cannot be created because amount field is empty in all positions of the offer.',
  offer_num: 'Offer No.',
  save_offer: 'Save offer',
  offer_accepted: 'Offer accepted',
  email_message:
    'Dear Ladies and Gentlemen\n \nEnclosed you receive the offer {{offerNr}} for archiving.\n \nBest regards',
  new_position: 'New position',
});

export const offerFr = t({
  offer: 'Offre',
  date_settings: 'Paramètres de date',
  valid_until: 'Valide jusqu’au',
  article: 'Article',
  article_other: 'Articles',
  empty_offer_grid: 'Cliquez sur “+” pour ajouter le premier article',
  individual_description: 'Description individuelle',
  lot_quantity: 'La valeur doit être un multiple du nombre de lots ({{quantityPerLot}})',
  error_calculating_price: "Une erreur s'est produite lors du calcul du prix.",
  accepted_offers: 'Offres acceptées',
  active_customers: 'Clients actifs',
  inactive_customers: 'Clients inactifs',
  additional_offer: 'Offre supplémentaire',
  open_offers: 'Offres ouvertes',
  enter_customer_info: 'Entrer les informations client',
  creatingCustomer: "Création d'un client",
  one_time_customer_offer: 'Offre pour client unique',
  declined_offers: 'Offres refusées',
  reset_to_default: 'Réinitialiser aux valeurs par défaut',
  choose_default: 'Choisir par défaut',
  offer_no: "Numéro d'offre",
  last_offer: 'Dernière offre',
  select_offer: 'Sélectionner une offre',
  select_offer_title: "Veuillez sélectionner l'offre souhaitée dans la liste ci-dessous :",
  add_page_break: 'Ajouter un saut de page',
  add_header: 'Ajouter un en-tête',
  page_break: 'Saut de page',
  group_header: 'En-tête de groupe',
  accept_offer: 'Offre acceptée',
  create_offer: 'Créer une offre',
  decline_offer: "Refuser l'offre",
  empty_positions_create_warning:
    "L'offre n'a pas été créée car le champ quantité est vide dans certains articles de commande.",
  offer_num: "Numéro d'offre",
  save_offer: "Enregistrer l'offre",
  offer_accepted: 'Offre acceptée',
  email_message:
    "Chers Messieurs\n \nClôturé vous recevez le {{offerNr}} de offre pour l'archivage.\n \nCordialement",
  new_position: 'Nouvelle position',
});

export const offerIt = t({
  offer: 'Offerta',
  date_settings: 'Impostazioni data',
  valid_until: 'Valido fino al',
  article: 'Articolo',
  article_other: 'Articoli',
  empty_offer_grid: 'Clicca “+” per aggiungere il primo articolo',
  individual_description: 'Descrizione individuale',
  lot_quantity: 'Il valore deve essere un multiplo del numero di lotti ({{quantityPerLot}})',
  error_calculating_price: 'Si è verificato un errore durante il calcolo del prezzo.',
  accepted_offers: 'Offerte accettate',
  active_customers: 'Clienti attivi',
  inactive_customers: 'Clienti inattivi',
  additional_offer: 'Offerta aggiuntiva',
  open_offers: 'Offerte aperte',
  enter_customer_info: 'Inserisci le informazioni sul cliente',
  creatingCustomer: 'Creazione di un cliente',
  one_time_customer_offer: 'Offerta per cliente occasionale',
  declined_offers: 'Offerte rifiutate',
  reset_to_default: 'Ripristina le impostazioni predefinite',
  choose_default: 'Scegli predefinito',
  offer_no: 'Numero offerta',
  last_offer: 'Ultima offerta',
  select_offer: 'Seleziona offerta',
  select_offer_title: "Seleziona l'offerta desiderata dall'elenco sottostante:",
  add_page_break: 'Aggiungi interruzione di pagina',
  add_header: 'Aggiungi intestazione',
  page_break: 'Interruzione di pagina',
  group_header: 'Intestazione gruppo',
  accept_offer: 'Offerta accettata',
  create_offer: 'Crea offerta',
  decline_offer: 'Rifiuta offerta',
  empty_positions_create_warning:
    "L'offerta non è stata creata perché il campo quantità è vuoto in alcuni articoli d'ordine.",
  offer_num: 'Numero offerta',
  save_offer: 'Salva offerta',
  offer_accepted: 'Offerta accettata',
  email_message:
    "Gentili Signore e Signori\n \nIn allegato ricevete l'offerta {{offerNr}} per l'archiviazione.\n \nCordiali saluti.",
  new_position: 'Nuova posizione',
});
