import { merge, map, Observable } from 'rxjs';

import { Sub, Pub, State } from '../../../../../shared/state/state.abstract';
import { C_Report } from '../../../../../graphql/generatedModel';
import { ProducedArticlesRes, ProductionListRes } from '../../../services/printJobs.service';
import { GridSortModel } from '@mui/x-data-grid-premium';
import { storageHelper } from '../../../../../shared/helpers/storage';
import { dataHelper } from '../../../../../shared/helpers/data/data.helper';

export const reportIdsForVersioning = [
  C_Report.R1_BAKING_LIST_2_COLUMNS,
  C_Report.R34_BAKING_LIST_A4,
  C_Report.R41_BAKING_LIST_A4_DETAILED,
  C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS,
  C_Report.R26_BAKING_LIST_WITH_RECIPE_A4,
  C_Report.R27_BAKING_LIST_WITH_RECIPE_A5,
  C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS,
];

class PubImpl extends Pub<IVersionsWorkerState> {
  init(productionList: ProductionListRes) {
    const versionStatuses = productionList
      .filter(({ reportId }) => reportIdsForVersioning.includes(reportId as C_Report))
      .map(({ id }) => ({
        id,
        statusItem: ReportVersionStatus.enabled,
        isSelected: false,
      }));

    this.emit('init', { versionStatuses });
  }
  search(search: string) {
    this.emit('search', { search });
  }
  sort(sortModel: GridSortModel) {
    this.emit('sort', { sortModel });
    storageHelper.local.setItem('printJobs.producedArticlesSortModel', sortModel);
  }
  updateVersionWorkerState(params: Partial<IVersionsWorkerState>) {
    this.emit('updateVersionWorkerState', params);
  }
}

class SubImpl extends Sub<IVersionsWorkerState> {
  protected actionHandlers(): Observable<IVersionsWorkerState> {
    return merge(this.updateState()).pipe(
      map(({ producedArticles, sortModel, search, ...rest }) => {
        let sortedList = [...(producedArticles || [])];
        producedArticles = dataHelper
          .data(producedArticles as [])
          .sort({
            sortModel,
            callback: (sorted) => (sortedList = sorted as []),
          })
          .search({
            search,
            fields: ['articleNo', 'description'],
          })
          .result() as TProducedArticles;

        const newState = {
          ...rest,
          search,
          producedArticles,
          sortModel,
        };
        this.stream$.next({ ...newState, producedArticles: sortedList, action: 'internalUpdate' });
        return newState;
      }),
    );
  }
  private updateState(): Observable<IVersionsWorkerState> {
    return this.actionListener(['init', 'search', 'sort', 'updateVersionWorkerState']);
  }
}

class VersionsWorkerState extends State<IVersionsWorkerState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export enum ReportVersionStatus {
  enabled = 'enabled',
  loading = 'loading',
  viewed = 'viewed',
}

export const initVersionsData: IVersionsWorkerState = {
  action: undefined,
  versionStatuses: [],
  producedArticles: [],
  recordsToSave: [],
  search: '',
  sortModel: storageHelper.local.getItem('printJobs.producedArticlesSortModel') || [
    { field: 'articleNo', sort: 'asc' },
  ],
};

export const versionsWorkerState = new VersionsWorkerState(initVersionsData);

export interface IVersionsWorkerState {
  action: 'init' | 'internalUpdate' | 'search' | 'sort' | 'updateVersionWorkerState' | undefined;
  versionStatuses: {
    id: string | number;
    statusItem: ReportVersionStatus;
    isSelected: boolean;
  }[];
  search: string;
  sortModel: GridSortModel;
  producedArticles: TProducedArticles;
  recordsToSave: TRecordsToSave;
}

type TRecordsToSave = Array<Omit<ProducedArticlesRes[number], 'articleNo' | 'description'>>;

export type TProducedArticles = Array<
  ProducedArticlesRes[number] & {
    id?: string;
  }
>;
