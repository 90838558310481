import { Observable, Subject } from 'rxjs';
import { IState, Sub } from '../../../../../shared/state/state.abstract';

export function editPrintListTabsSub<T extends IState<T>>() {
  abstract class Common extends Sub<T> {
    protected printIdNotExist$ = new Subject<boolean>();

    printIdNotExist(): Observable<boolean> {
      return this.printIdNotExist$.asObservable();
    }
  }
  return Common;
}
