import { localeFormatterHelper } from '../formatter/localeFormatter.helper.ts';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

class DataMethods {
  private resultData: TypeData = [];
  constructor(data: TypeData) {
    this.resultData = data;
  }
  search({ search, fields }: ISearchOptions): this {
    this.resultData = search
      ? (this.resultData?.reduce((acc, item) => {
          for (const el of fields) {
            let newEl: string;
            if (Array.isArray(el)) {
              newEl = item[el[0]] ? localeFormatterHelper.formatDate(item[el[0]], el[1]) : '';
            } else newEl = item[el] ? String(item[el])?.toLowerCase() : '';
            if (newEl.includes(search?.toLowerCase())) {
              acc.push(item);
              break;
            }
          }
          return acc;
        }, []) as [])
      : this.resultData;
    return this;
  }
  sort({ sortModel, callback }: ISortOptions): this {
    const sortArr: TypeData = [...this.resultData];
    if (sortModel.length) {
      const type = !!sortArr.find((el) => typeof el[sortModel[0]?.field] === 'number');
      sortArr.sort((a, b) => {
        if (type) {
          return (
            (a[sortModel[0]?.field] - b[sortModel[0]?.field]) *
            (sortModel[0]?.sort === 'desc' ? -1 : 1)
          );
        } else {
          return (
            (a[sortModel[0]?.field] || '').localeCompare(b[sortModel[0]?.field] || '') *
            (sortModel[0]?.sort === 'desc' ? -1 : 1)
          );
        }
      });
    }
    callback?.(sortArr);
    this.resultData = sortArr;
    return this;
  }
  result() {
    return this.resultData;
  }
}

class DataHelper {
  data(data: TypeData = []) {
    return new DataMethods(data);
  }
}

export const dataHelper = new DataHelper();

export interface ISearchOptions {
  search: string;
  fields: (string | [string, Intl.DateTimeFormatOptions] | [string])[];
  /* fields is a complex field that specifies which fields to sort by and, if the field is a date, how it should be formatted. Example:
  ['orderNo'], - simple field,
  [['orderDate']] - date field with default formatting,
  [['printedDateTime',{ hour: '2-digit',minute: '2-digit',second: '2-digit' }]] - date field with custom formatting */
}
interface ISortOptions {
  sortModel: GridSortModel;
  callback?: (v: TypeData) => void;
}

export type TypeData = Record<string, any>[];
