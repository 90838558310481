/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import { TPopupDefaultActions } from '../../../../../../../../../shared/components/popup/services/popup.service.ts';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { CopyDefaultOrder } from './copyDefaultOrder.form.tsx';

export class CopyDefaultOrderPopup extends Popup<ICopyDefaultOrderPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;
    this.Component = () => {
      const { t } = useTranslation();
      const [currentCustomerId, setCurrentCustomerId] = useState<string>('');

      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 498 },
        }));
      }, []);

      const formMethods = useForm({
        mode: 'onBlur',
      });

      useEffect(() => {
        stream.state().subscribe(({ action, currentCustomerId }) => {
          if (action === 'open') setCurrentCustomerId(currentCustomerId);
          if (action === 'close') formMethods.reset();
        });
      }, []);

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('customer.copy_default_order')}>
          <FormProvider {...formMethods}>
            <CopyDefaultOrder {...{ currentCustomerId, stream }} />
          </FormProvider>
        </SWrapper>
      );
    };
  }
}

interface ICopyDefaultOrderPopup {
  action: TPopupDefaultActions;
  currentCustomerId: string;
}
