import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

import { Table } from '../../../../../shared/components/table/table.component.tsx';
import { Column } from '../../../../../shared/components/table/components/common.components.tsx';
import { Checkbox } from '../../../../../shared/components/form';
import {
  IDeliveryNotesTabDataRes,
  IPrintJobsDetailsState,
  printJobsDetailsState,
} from '../../states/printJobsDetails.state.ts';
import {
  C_Order_Type,
  C_Pdf_Document_Type,
  C_S3_Bucket,
} from '../../../../../graphql/generatedModel.ts';
import {
  SColumnContainer,
  SIconWrapper,
} from '../production/productionList/productionList.component.tsx';
import { PreviewPopup } from '../../../../../shared/components/previewPopup/preview.popup.tsx';
import {
  GetWAReportRes,
  ListWAReportRes,
  reportsService,
} from '../../../../../shared/services/reports/reports.service.ts';
import { storageHelper } from '../../../../../shared/helpers/storage';
import { map } from 'rxjs';
import { localeFormatterHelper } from '../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler.ts';

const PrintDeliveryNotesTab: FC<IPrintDeliveryNotesTabProps> = ({
  data,
  loading,
  action,
  datePeriod,
}) => {
  const { printDeliveryNotesList, params } = data || {};
  const { t } = useTranslation();
  const columnModel = storageHelper.local.getItem('printJobs.deliveryNotesColumnModel');
  const deliveryNotesPreviewReportPopup = useMemo(() => new PreviewPopup(), []);
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [isMultiselectTurnOn, setIsMultiselectTurnOn] = useState<boolean>(false);

  const handleSelectAllChange = useCallback(() => {
    const newSelectedIds: Set<string> = selectAll
      ? new Set()
      : new Set(printDeliveryNotesList.map((row) => row?.id as string));

    setSelectedIds(newSelectedIds);
    setSelectAll(!selectAll);
  }, [selectAll, printDeliveryNotesList]);

  const handleRowCheckboxChange = useCallback(
    (rowId: string) => {
      const newSelectedIds = new Set(selectedIds);
      if (newSelectedIds.has(rowId)) {
        newSelectedIds.delete(rowId);
      } else {
        newSelectedIds.add(rowId);
      }

      setSelectedIds(newSelectedIds);

      const allIdsSelected = printDeliveryNotesList.every((row) =>
        newSelectedIds.has(row?.id as string),
      );
      setSelectAll(allIdsSelected);
    },
    [selectedIds, printDeliveryNotesList],
  );

  const handleReportPreview = (dataRecordId: string) => {
    deliveryNotesPreviewReportPopup.stream.emit('open', {
      sub: () =>
        reportsService.sub.safeGetWAReport().pipe(
          responseHandler<GetWAReportRes>({
            errorReturnType: {
              url: null,
              bucket: C_S3_Bucket.S3B_MAIN_HISTORY_PRINTOUTS,
              s3Key: null,
            },
          }),
          map((v) => ({ url: v.url })),
        ),
      pub: () =>
        reportsService.pub.getWAReport({
          params: {
            dataRecordId,
            reportType: C_Pdf_Document_Type['PDT1_ORDER'],
          },
        }),
    });
  };
  const handleReportMultiplePreview = (listOfIds: string[]) => {
    deliveryNotesPreviewReportPopup.stream.emit('open', {
      sub: () =>
        reportsService.sub.safeListWAReports().pipe(
          responseHandler<ListWAReportRes>({ errorReturnType: {} }),
          map((v) => ({ url: v.mergedFile?.url })),
        ),
      pub: () =>
        reportsService.pub.listWAReports({
          params: {
            dataRecordId: listOfIds,
            reportType: C_Pdf_Document_Type['PDT1_ORDER'],
            mergeFiles: true,
          },
        }),
    });
  };

  useEffect(() => {
    if (action === 'selectDatePeriod') {
      setSelectedIds(new Set());
      setSelectAll(false);
    }
  }, [action, datePeriod]);

  return (
    <>
      <Box display={'flex'} alignItems='center'>
        <IconButton
          color='primary'
          size='large'
          title={t('common.multiselect')}
          disabled={!printDeliveryNotesList?.length || loading}
          onClick={() => setIsMultiselectTurnOn(!isMultiselectTurnOn)}
        >
          <LibraryAddCheckIcon />
        </IconButton>
        <IconButton
          color='primary'
          size='large'
          title={t('common.preview')}
          disabled={!isMultiselectTurnOn || !selectedIds.size || loading}
          onClick={() => handleReportMultiplePreview([...selectedIds])}
          sx={{ marginLeft: 1 }}
        >
          <RemoveRedEyeIcon />
        </IconButton>
      </Box>
      <Box sx={{ minWidth: '100%', width: '100%' }}>
        <Table
          initialState={{
            columns: columnModel,
          }}
          heightOffset={264}
          loading={loading}
          data={(printDeliveryNotesList as GridValidRowModel[]) || []}
          onChangeColumnModel={(v) =>
            storageHelper.local.setItem(`printJobs.deliveryNotesColumnModel`, v)
          }
          hidingColumnModel={{
            multiSelectFields: 'id',
          }}
          defaultSorting={params?.sortModel}
          onSort={(sortModel) => {
            printJobsDetailsState.pub.sort(sortModel);
          }}
          columnVisibilityModel={{
            id: isMultiselectTurnOn,
          }}
        >
          <Column
            width={75}
            headerAlign='right'
            align='right'
            field='id'
            disableReorder
            sortable={false}
            renderHeader={() => (
              <Box marginLeft={1.5}>
                <Checkbox
                  size='small'
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  disabled={!printDeliveryNotesList?.length || loading}
                />
              </Box>
            )}
            renderCell={({ row }) => (
              <Checkbox
                size='small'
                checked={selectedIds.has(row.id)}
                onChange={() => handleRowCheckboxChange(row.id)}
              />
            )}
          />
          <Column field='orderNo' headerName={t('common.delivery_no')} width={150} />
          <Column
            field='orderDate'
            valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
            headerName={t('common.date')}
            width={100}
          />
          <Column field='customerNo' headerName={t('common.customer_no')} width={120} />
          <Column field='internalOrFullName' headerName={t('common.name')} width={220} />
          <Column
            field='orderType'
            headerName={t('production.order_type')}
            width={130}
            renderCell={(params) => (
              <Typography sx={{ fontSize: 14 }}>
                {params?.value ? t(`enums.${C_Order_Type[params?.value as C_Order_Type]}`) : null}
              </Typography>
            )}
          />
          <Column
            field='customerDeliveryNoteGroup'
            headerName={t('production.delivery_note_group')}
            width={170}
          />
          <Column
            field='isPrinted'
            headerName={t('production.printed')}
            renderCell={(params) => <Checkbox size='small' checked={params.value} readOnly />}
            align='right'
            headerAlign='center'
            width={90}
            sortable={false}
          />
          <Column
            field='printedDateTime'
            headerName={t('production.date_time')}
            valueGetter={({ value }) =>
              value
                ? localeFormatterHelper.formatDate(value, {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  })
                : ''
            }
            width={170}
          />
          <Column
            field='reportPreview'
            headerName=''
            flex={1}
            sortable={false}
            renderCell={({ row }) => (
              <SColumnContainer>
                <SIconWrapper
                  onClick={() => row?.id && handleReportPreview(row.id)}
                  title={t('common.preview')}
                >
                  <RemoveRedEyeIcon />
                </SIconWrapper>
              </SColumnContainer>
            )}
          />
        </Table>
        <deliveryNotesPreviewReportPopup.Component />
      </Box>
    </>
  );
};

export default PrintDeliveryNotesTab;

interface IPrintDeliveryNotesTabProps {
  data: IDeliveryNotesTabDataRes;
  action: IPrintJobsDetailsState['action'];
  datePeriod: IPrintJobsDetailsState['datePeriod'];
  loading: boolean;
}
