import React, { FC, useCallback } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { TNumericFieldProps } from '../../../../../../../../../shared/components/form/fields/numericField.component.tsx';
import { TableNumericField } from '../../../../../../../../../shared/components/table/styles/styleFields.ts';
import { quantityDiscountDictState } from '../quantityDiscountsDict.state.ts';

export const NumericEditDict: FC<INumericEditDict> = ({ editCellParams, numericProps = {} }) => {
  const { value, field, id, api, row } = editCellParams;

  const { setEditCellValue, stopCellEditMode } = api;

  const updateCell = useCallback(
    (v: number | null) => {
      if (typeof Number(v) === 'number' && value !== v) {
        setEditCellValue({ id, field, value: v });
        quantityDiscountDictState.pub.updateCell({ ...row, [field]: v });
      }
    },
    [editCellParams],
  );

  const handleKeyDown = (v: React.KeyboardEvent) => {
    if (v.target instanceof HTMLInputElement && v.code === 'Enter') {
      const value = v.target.value.replace(/,/g, '.');
      updateCell(value ? Number(value) : null);
      stopCellEditMode({ id, field });
    }
  };

  const { ref: _, ...filteredNumericProps } = numericProps || {};

  return (
    <TableNumericField
      onKeyDown={handleKeyDown}
      onBlur={updateCell}
      min={-10000000}
      autoFocus
      precision={2}
      noTrailingZeros
      defaultValue={value}
      {...filteredNumericProps}
    />
  );
};

interface INumericEditDict {
  editCellParams: GridRenderEditCellParams;
  numericProps?: TNumericFieldProps;
}
