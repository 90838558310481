import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { filter, map } from 'rxjs';

import {
  IPrintJobsDetailsState,
  IProductionTabDataRes,
  printJobsDetailsState,
} from '../../states/printJobsDetails.state.ts';
import SideListControls from './sideListContols/sideListControls.component.tsx';
import { ProductionList } from './productionList/productionList.component.tsx';
import { ProductionDetails } from './productionDetails/productionDetails.component.tsx';
import {
  IStatusWorkerState,
  ReportPreviewStatus,
  StatusItem,
  initPreviewData,
  statusWorkerState,
} from '../../states/statusWorker/statusWorker.state.ts';
import { PreviewPopup } from '../../../../../shared/components/previewPopup/preview.popup.tsx';
import {
  initVersionsData,
  IVersionsWorkerState,
  versionsWorkerState,
} from '../../states/versionsWorker/versionsWorker.state.ts';
import { ProductionVersionDetails } from './productionDetails/productionVersionDetails.component.tsx';

const ProdutionTab: FC<IProductionTabProps> = ({ data, datePeriod, listLoading }) => {
  const { countOrders, countOrderPreProduction, selectedRecord } = data || {};
  const [previewStatus, setPreviewStatus] = useState<IStatusWorkerState>(initPreviewData);
  const [versions, setVersionsStatus] = useState<IVersionsWorkerState>(initVersionsData);

  useEffect(() => {
    const previewStatusSub = statusWorkerState.sub.state().subscribe((v) => {
      setPreviewStatus({ ...v, list: [...v.list] });
    });
    const versionsStatusSub = versionsWorkerState.sub.state().subscribe((v) => {
      setVersionsStatus({ ...v });
    });

    return () => {
      previewStatusSub.unsubscribe();
      versionsStatusSub.unsubscribe();
    };
  }, []);

  const previewMergedPopup = useMemo(() => new PreviewPopup(), []);
  const handleOpenMergedModal = () => {
    previewMergedPopup.stream.emit('open', {
      sub: () =>
        statusWorkerState.sub.state().pipe(
          filter(({ action, mergedAllUrl }) => action === 'batchPrint' && !!mergedAllUrl),
          map(({ mergedAllUrl }) => ({ url: mergedAllUrl })),
        ),
      pub: () => statusWorkerState.pub.batchPrint(),
    });
  };

  const previewStatusData: IPreviewStatusData = useMemo(() => {
    const res: IPreviewStatusData = {
      item: undefined,
      isFirst: undefined,
      isLast: undefined,
    };
    if (!selectedRecord?.id || listLoading) {
      return res;
    }
    const enabledItems = previewStatus.list.filter(
      (item) => item.status !== ReportPreviewStatus.disabled,
    );
    const selectedItemInd = enabledItems.findIndex((item) => item.uuid === selectedRecord?.id);
    if (selectedItemInd !== -1) {
      res.item = enabledItems[selectedItemInd];
      res.isFirst = selectedItemInd === 0;
      res.isLast = selectedItemInd === enabledItems.length - 1;
    }
    return res;
  }, [selectedRecord?.id, previewStatus.list, listLoading]);
  const versionStatusData = versions.versionStatuses.some(
    (status) => status.id === selectedRecord?.id && status.isSelected,
  );

  return (
    <Stack
      height='100%'
      direction='row'
      justifyContent='flex-start'
      alignItems='stretch'
      spacing={2}
    >
      <Box sx={{ minWidth: 500, width: 500 }}>
        <SideListControls
          initFilter={data.filter}
          loading={listLoading}
          countOrders={countOrders}
          countOrderPreProduction={countOrderPreProduction}
        />
        <ProductionList
          data={data}
          datePeriod={datePeriod}
          previewListStatus={previewStatus.list}
          previewAllStatus={previewStatus.statusAll}
          versions={versions}
          onOpenMerged={handleOpenMergedModal}
          loading={listLoading}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        {versionStatusData && (
          <ProductionVersionDetails
            versions={versions}
            selectedRecord={selectedRecord}
            date={datePeriod.fromDate}
          />
        )}

        {previewStatusData.item && !versionStatusData && (
          <ProductionDetails
            data={previewStatusData.item}
            hasNext={!previewStatusData.isLast}
            hasPrevious={!previewStatusData.isFirst}
            onNavigate={(navType) => printJobsDetailsState.pub.navigateInProductionList(navType)}
            name={selectedRecord?.name || ''}
          />
        )}
      </Box>
      <previewMergedPopup.Component />
    </Stack>
  );
};
export default ProdutionTab;

interface IPreviewStatusData {
  item: StatusItem | undefined;
  isFirst: boolean | undefined;
  isLast: boolean | undefined;
}
interface IProductionTabProps {
  data: IProductionTabDataRes;
  datePeriod: IPrintJobsDetailsState['datePeriod'];
  listLoading: boolean;
}
