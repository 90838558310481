import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  share,
  switchMap,
  take,
  tap,
  zip,
} from 'rxjs';

import {
  CopyArticleMutation,
  CopyArticleMutationVariables,
  CreateArticleMutation,
  GetArticleAvailabilityTabDataQuery,
  GetArticleAvailabilityTabDataQueryVariables,
  GetArticleCashAssitTabDataQuery as CashAssistTabQuery,
  GetArticleCashAssitTabDataQueryVariables as CashAssistTabVariables,
  GetArticleGeneralTabDataQuery as GeneralTabQuery,
  GetArticleGeneralTabDataQueryVariables as GeneralTabVariables,
  GetArticleLabelDataFromRaQuery,
  GetArticleLabelDataFromRaQueryVariables,
  GetArticleLabelTabDataQuery,
  GetArticleLabelTabDataQueryVariables,
  GetArticleListsTabDataQuery as ListsTabQuery,
  GetArticleListsTabDataQueryVariables as ListsTabVariables,
  GetArticlePostTabDataQuery as PostTabQuery,
  GetArticlePostTabDataQueryVariables as PostTabVariables,
  GetArticleProductionCalculationDependenciesQuery,
  GetArticleProductionCalculationDependenciesQueryVariables as CalculationDependenciesVariables,
  GetArticleProductionTabDataQuery as ProductionTabQuery,
  GetWa_ArticleDoughDataFromRaQuery,
  GetWa_ArticleDoughDataFromRaQueryVariables,
  ListWa_ArticlesQuery as ArticlesQuery,
  ListWa_ArticlesQueryVariables as ArticlesQueryVariables,
  SaveArticleDataMutation,
  SaveArticleDataMutationVariables,
  ValidateWa_ArticleFieldsQuery as ValidateArticleFieldsQuery,
  ValidateWa_ArticleFieldsQueryVariables as ValidateArticleFieldsVariables,
  GetArticleSpecialPricesQueryVariables,
  GetArticleSpecialPricesQuery,
  SaveArticleProductionCalculationMutationVariables,
  SaveArticleProductionCalculationMutation,
} from '../../../graphql/generatedModel.ts';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import { storageHelper } from '../../../shared/helpers/storage';
import {
  companyConfigService,
  TCompanyConfigs,
} from '../../../shared/services/companyConfig/companyConfig.service.ts';
import {
  configsData,
  DictArticlesRes,
  DictCashAssistTemplatesRes,
  DictDiscountGroupsRes,
  DictMarketingGroupsRes,
  DictProductionGroupsRes,
  DictRecipesWithConnectedArticlesRes,
  DictShippingPointsRes,
} from '../../../shared/services/configsData/configsData.service.ts';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import { articleListState, IArticleListState } from '../states/articleList.state.ts';
import {
  copyArticle,
  createArticle,
  getArticleAvailabilityTabData,
  getArticleCashAssitTabData,
  getArticleDoughDataFromRA,
  getArticleGeneralTabData,
  getArticleLabelDataFromRA,
  getArticleLabelTabData,
  getArticleListsTabData,
  getArticlePostTabData,
  getArticleProductionCalculationDependencies,
  getArticleProductionTabData,
  getArticlesList,
  saveArticleData,
  validateArticleFieldsOnServer,
  getArticleSpecialPrices,
  saveArticleProductionCalculation,
} from './gql/article.gql.ts';
import { modeService } from '../../../shared/services/mode/mode.service.ts';
import equal from 'fast-deep-equal/react';

type Action =
  | undefined
  | 'articlesList'
  | 'articleGeneralTabData'
  | 'generalTabOptions'
  | 'articleListsTabData'
  | 'articleFilterData'
  | 'filterArticleLists'
  | 'createArticle'
  | 'articleAvailabilityTabData'
  | 'copyArticle'
  | 'editArticle'
  | 'discountGroups'
  | 'validateArticleFieldsOnServer'
  | 'validateProductionRecipeIdOnServer'
  | 'articleProductionTabData'
  | 'articleLabelTabData'
  | 'articleLabelDataFromRa'
  | 'articleCashAssistTabData'
  | 'articlePostTabData'
  | 'articleDoughDataFromRA'
  | 'getArticleProductionCalculationDependencies'
  | 'getArticleSpecialPrices'
  | 'saveArticleProductionCalculation';

class PubImpl extends Pub<Action> {
  articlesList(params?: ArticlesQueryVariables & { cache: boolean }): void {
    this.emit('articlesList', params);
  }
  articleGeneralTabData(params: GeneralTabVariables): void {
    this.emit('articleGeneralTabData', params);
  }
  articleListsTabData(params: ListsTabVariables): void {
    this.emit('articleListsTabData', params);
  }
  articleCashAssistTabData(params: CashAssistTabVariables): void {
    this.emit('articleCashAssistTabData', params);
  }
  articleProductionTabData(params: GeneralTabVariables): void {
    this.emit('articleProductionTabData', params);
  }
  articlePostTabData(params: PostTabVariables): void {
    this.emit('articlePostTabData', params);
  }
  articleLabelTabData(params: GetArticleLabelTabDataQueryVariables): void {
    this.emit('articleLabelTabData', params);
  }
  articleLabelDataFromRa(params: GetArticleLabelDataFromRaQueryVariables): void {
    this.emit('articleLabelDataFromRa', params);
  }
  generalTabOptions(): void {
    this.emit('generalTabOptions');
  }
  createArticle(params: ArticleGeneralTabDataRes): void {
    this.emit('createArticle', params);
  }
  copyArticle(params: CopyArticleMutationVariables) {
    this.emit('copyArticle', params);
  }
  editArticle(params: SaveArticleDataMutationVariables): void {
    this.emit('editArticle', params);
  }
  saveArticleProductionCalculation(
    params: SaveArticleProductionCalculationMutationVariables,
  ): void {
    this.emit('saveArticleProductionCalculation', params);
  }
  articleAvailabilityTabData(params: GetArticleAvailabilityTabDataQueryVariables): void {
    this.emit('articleAvailabilityTabData', params);
  }
  validateArticleFieldsOnServer(
    params: ValidateArticleFieldsVariables & {
      context: any;
    },
  ): void {
    this.emit('validateArticleFieldsOnServer', params);
  }
  validateProductionRecipeIdOnServer(
    params: ValidateArticleFieldsVariables & { context: any },
  ): void {
    this.emit('validateProductionRecipeIdOnServer', params);
  }
  articleDoughDataFromRA(params: GetWa_ArticleDoughDataFromRaQueryVariables): void {
    this.emit('articleDoughDataFromRA', params);
  }
  getArticleProductionCalculationDependencies(params: CalculationDependenciesVariables): void {
    this.emit('getArticleProductionCalculationDependencies', params);
  }
  getArticleSpecialPrices(params: GetArticleSpecialPricesQueryVariables): void {
    this.emit('getArticleSpecialPrices', params);
  }
}
class SubImpl extends Sub<Action> {
  private _validateArticleNo = () => {
    return this.actionListener('validateArticleFieldsOnServer').pipe(
      distinctUntilChanged((prev, curr) => {
        const prevValue = prev.params.fieldForCheck.articleNo;
        const currValue = curr.params.fieldForCheck.articleNo;
        return prevValue === currValue;
      }),
      debounceTime(700),
      tap(({ params: { context } }) => context.setLoading(true)),
      switchMap(
        ({ params: { context, ...variables } }) =>
          gqlClient(validateArticleFieldsOnServer, variables).pipe(
            tap(() => context.setLoading(false)) as any,
          ) as Observable<ValidateArticleFieldsQuery>,
      ),
      map((v) => v.wawiAssist?.validateWA_ArticleFields as ValidateArticleFieldsRes),
      share(),
    );
  };
  private _validateProductionRecipeId = () => {
    return this.actionListener('validateProductionRecipeIdOnServer').pipe(
      tap(({ params: { context } }) => context.setLoading(true)),
      switchMap(({ params: { context, ...rest } }) => {
        if (rest.fieldForCheck.productionRecipeId) {
          return gqlClient(validateArticleFieldsOnServer, rest).pipe(
            tap(() => {
              context.setLoading(false);
            }) as any,
            map((data: ValidateArticleFieldsQuery) => {
              return data.wawiAssist?.validateWA_ArticleFields as ValidateArticleFieldsRes;
            }),
          ) as Observable<ValidateArticleFieldsQuery>;
        }
        context.setLoading(false);
        return of({ productionRecipeId: true });
      }),
      share(),
    );
  };
  private validateArticleNo$ = this._validateArticleNo();
  private validateProductionRecipeId$ = this._validateProductionRecipeId();

  articleList(): Observable<ArticlesListRes> {
    return this.actionListener('articlesList').pipe(
      switchMap(({ params: { filter, cache } }) => {
        const savedFilter = storageHelper.memory.getItem('article.filter');
        if (cache && equal(filter, savedFilter)) {
          const list = storageHelper.memory.getItem('article.list');
          if (list) {
            return of(list);
          }
        }

        return gqlClient(getArticlesList, { filter }).pipe(
          map((data) => {
            const list = data.wawiAssist?.listWA_Articles;
            storageHelper.memory.setItem('article.filter', filter);
            storageHelper.memory.setItem('article.list', list);
            return list;
          }),
        ) as Observable<ArticlesQuery>;
      }),
    );
  }
  articleGeneralTabData(): Observable<ArticleGeneralTabDataRes> {
    return this.actionListener('articleGeneralTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleGeneralTabData, params) as Observable<GeneralTabQuery>;
      }),
      map((data: GeneralTabQuery) => {
        return data.wawiAssist?.getArticleData as ArticleGeneralTabDataRes;
      }),
    );
  }

  articleListsTabData(): Observable<ArticleListsTabDataRes> {
    return this.actionListener('articleListsTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleListsTabData, params) as Observable<ListsTabQuery>;
      }),
      map((data: ListsTabQuery) => {
        return data.wawiAssist?.getArticleData as ArticleListsTabDataRes;
      }),
    );
  }
  articleCashAssistTabData(): Observable<ArticleCashAssistTabDataRes> {
    return this.actionListener('articleCashAssistTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleCashAssitTabData, params) as Observable<CashAssistTabQuery>;
      }),
      map((data: CashAssistTabQuery) => {
        return data.wawiAssist?.getArticleData as ArticleCashAssistTabDataRes;
      }),
    );
  }
  articleProductionTabData(): Observable<ArticleProductionTabDataRes> {
    return this.actionListener('articleProductionTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleProductionTabData, params) as Observable<ProductionTabQuery>;
      }),
      map((data: ProductionTabQuery) => {
        return data.wawiAssist?.getArticleData as ArticleProductionTabDataRes;
      }),
    );
  }

  getArticleProductionCalculationDependencies(): Observable<GetArticleProductionCalculationDependenciesRes> {
    return this.actionListener('getArticleProductionCalculationDependencies').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleProductionCalculationDependencies, params);
      }),
      map((data: GetArticleProductionCalculationDependenciesQuery) => {
        return data.wawiAssist?.getArticleData
          .articleProductionCalculationDependencies as GetArticleProductionCalculationDependenciesRes;
      }),
    );
  }

  getArticleSpecialPrices(): Observable<GetArticleSpecialPricesRes> {
    return this.actionListener('getArticleSpecialPrices').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getArticleSpecialPrices,
          params,
        ) as Observable<GetArticleSpecialPricesQuery>;
      }),
      map((data) => {
        return data.wawiAssist?.getArticleData?.articleSpecialPrices as GetArticleSpecialPricesRes;
      }),
    );
  }

  articleAvailabilityTabData(): Observable<ArticleAvailabilityTabDataRes> {
    return this.actionListener('articleAvailabilityTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getArticleAvailabilityTabData,
          params,
        ) as Observable<GetArticleAvailabilityTabDataQuery>;
      }),
      map((data: GetArticleAvailabilityTabDataQuery) => {
        const {
          articleAssortimentGroupsCheckedState,
          availabilityInProcurementKindId,
          articleDeliveryPeriodsSettings,
          ...weekDays
        } = data.wawiAssist?.getArticleData || {};
        return {
          articleAssortimentGroupsCheckedState,
          availabilityInProcurementKindId,
          periods: articleDeliveryPeriodsSettings?.articleRegularDeliveryPeriods,
          periodsWithCustomWeekDays:
            articleDeliveryPeriodsSettings?.articleCustomWeekDaysAndDeliveryPeriods?.map(
              ({ id, fromDate, toDate, description, isRepeatYearly, ...weekDays }) => ({
                period: {
                  fromDate,
                  toDate,
                  isRepeatYearly,
                },
                weekDays,
                description,
                id,
              }),
            ),
          vacations: articleDeliveryPeriodsSettings?.articleVacations,
          weekDays,
        } as ArticleAvailabilityTabDataRes;
      }),
    );
  }

  articlePostTabData(): Observable<ArticlePostTabDataRes> {
    return this.actionListener('articlePostTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticlePostTabData, params) as Observable<PostTabQuery>;
      }),
      map((data: PostTabQuery) => {
        return data.wawiAssist?.getArticleData as ArticlePostTabDataRes;
      }),
    );
  }

  articleLabelTabData(): Observable<ArticleLabelTabDataRes> {
    return this.actionListener('articleLabelTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleLabelTabData, params) as Observable<GetArticleLabelTabDataQuery>;
      }),
      map((data: GetArticleLabelTabDataQuery) => {
        const mainData = data.wawiAssist?.getArticleData;
        return {
          ...mainData,
        } as ArticleLabelTabDataRes;
      }),
    );
  }

  articleLabelDataFromRa(): Observable<ArticleLabelDataFromRaRes> {
    return this.actionListener('articleLabelDataFromRa').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getArticleLabelDataFromRA,
          params,
        ) as Observable<GetArticleLabelDataFromRaQuery>;
      }),
      map((data: GetArticleLabelDataFromRaQuery) => {
        return data.wawiAssist?.getWA_ArticleLabelDataFromRA as ArticleLabelDataFromRaRes;
      }),
    );
  }

  articleDoughDataFromRA(): Observable<ArticleDoughDataFromRARes> {
    return this.actionListener('articleDoughDataFromRA').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getArticleDoughDataFromRA,
          params,
        ) as Observable<GetWa_ArticleDoughDataFromRaQuery>;
      }),
      map((data: GetWa_ArticleDoughDataFromRaQuery) => {
        return data.wawiAssist?.getWA_ArticleDoughDataFromRA as ArticleDoughDataFromRARes;
      }),
    );
  }

  generalTabOptions(): Observable<GeneralTabOptionsRes> {
    return this.actionListener('generalTabOptions').pipe(
      switchMap(() => {
        const discountGroups = storageHelper.memory.getItem('configsData.dictDiscountGroups');
        const marketingGroups = storageHelper.memory.getItem('configsData.dictMarketingGroups');
        const productionGroups = storageHelper.memory.getItem('configsData.dictProductionGroups');
        const shippingPoints = storageHelper.memory.getItem('configsData.dictShippingPoints');
        const companyConfig = storageHelper.session.getItem('companyConfig');
        if (
          Array.isArray(discountGroups) &&
          Array.isArray(marketingGroups) &&
          Array.isArray(productionGroups) &&
          Array.isArray(shippingPoints) &&
          companyConfig
        ) {
          return of({
            discountGroups,
            marketingGroups,
            productionGroups,
            shippingPoints,
            isShowShippingPointsInArticle: companyConfig?.isShowShippingPointsInArticle,
          });
        }
        configsData.pub.common([
          'dictDiscountGroups',
          'dictMarketingGroups',
          'dictProductionGroups',
          'dictShippingPoints',
        ]);
        return zip(
          configsData.sub.dictDiscountGroups(),
          configsData.sub.dictProductionGroups(),
          configsData.sub.dictMarketingGroups(),
          configsData.sub.dictShippingPoints(),
          companyConfigService.getConfigs().pipe(take(1)),
        );
      }),
      map((v) => {
        // v is from zip operator (fetched data)
        if (Array.isArray(v)) {
          return {
            discountGroups: v[0] || [],
            productionGroups: v[1] || [],
            marketingGroups: v[2] || [],
            shippingPoints: v[3] || [],
            isShowShippingPointsInArticle: v[4]?.isShowShippingPointsInArticle,
          } as GeneralTabOptionsRes;
        }
        // v is from of operator (cached data)
        return v;
      }),
    );
  }
  createArticle(): Observable<CreateOrCopyArticleRes> {
    return this.actionListener('createArticle').pipe(
      switchMap(({ params: paramsDirty }) => {
        const { id: _id, ...params } = paramsDirty || {};
        return gqlClient(createArticle, { data: params }) as Observable<CreateArticleMutation>;
      }),
      map((res: CreateArticleMutation) => {
        const data = res.wawiAssist?.createWA_Article;
        this.switchToEditModeAndAddArticle(data as CreateOrCopyArticleRes);
        return data as CreateOrCopyArticleRes;
      }),
    );
  }

  copyArticle(): Observable<CreateOrCopyArticleRes> {
    return this.actionListener('copyArticle').pipe(
      switchMap(({ params }) => {
        const { originalId, data } = params as CopyArticleMutationVariables;

        return gqlClient(copyArticle, {
          originalId,
          data,
        }) as Observable<CopyArticleMutation>;
      }),
      map((res: CopyArticleMutation) => {
        const data = res.wawiAssist?.copyWA_Article;
        this.switchToEditModeAndAddArticle(data as CreateOrCopyArticleRes);
        return data as CreateOrCopyArticleRes;
      }),
    );
  }

  editArticle(): Observable<SaveArticleDataRes> {
    return this.actionListener('editArticle').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveArticleData, params) as Observable<SaveArticleDataMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.saveArticleData as SaveArticleDataRes;
      }),
    );
  }

  saveArticleProductionCalculation(): Observable<SaveArticleProductionCalculationRes> {
    return this.actionListener('saveArticleProductionCalculation').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          saveArticleProductionCalculation,
          params,
        ) as Observable<SaveArticleProductionCalculationMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.saveArticleData as SaveArticleProductionCalculationRes;
      }),
    );
  }

  validateArticleFieldsOnServer(): Observable<ValidateArticleFieldsRes> {
    return this.validateArticleNo$ as Observable<ValidateArticleFieldsRes>;
  }
  validateProductionRecipeIdOnServer(): Observable<ValidateArticleFieldsRes> {
    return this.validateProductionRecipeId$ as Observable<ValidateArticleFieldsRes>;
  }

  private switchToEditModeAndAddArticle(data: CreateOrCopyArticleRes) {
    modeService.pub.mode('edit');
    modeService.sub
      .mode()
      .pipe(
        filter((v) => v === 'edit'),
        take(1),
      )
      .subscribe((v) => {
        if (v === 'edit') articleListState.pub.addArticleToList(data);
      });
  }
}

class ArticleService extends Service<Action> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const articleService = new ArticleService();

export type ArticlesListRes = NonNullable<
  NonNullable<ArticlesQuery['wawiAssist']>['listWA_Articles']
>;

export type ArticleGeneralTabDataRes = NonNullable<
  NonNullable<GeneralTabQuery['wawiAssist']>['getArticleData']
>;

export type ArticleProductionTabDataRes = NonNullable<
  NonNullable<ProductionTabQuery>['wawiAssist']
>['getArticleData'];

export type ArticleCashAssistTabDataRes = NonNullable<
  NonNullable<CashAssistTabQuery['wawiAssist']>['getArticleData'] & { id: string }
>;

export type ArticlePostTabDataRes = NonNullable<
  NonNullable<PostTabQuery['wawiAssist']>['getArticleData']
>;

export type ArticleLabelTabDataRes = NonNullable<
  NonNullable<GetArticleLabelTabDataQuery['wawiAssist']>['getArticleData']
> & {
  id?: string;
};

export type ArticleLabelDataFromRaRes = NonNullable<
  NonNullable<GetArticleLabelDataFromRaQuery['wawiAssist']>['getWA_ArticleLabelDataFromRA']
>;

export type ArticleListsTabDataRes = NonNullable<
  NonNullable<ListsTabQuery['wawiAssist']>['getArticleData']
>;

export type ArticleDoughDataFromRARes = NonNullable<
  NonNullable<GetWa_ArticleDoughDataFromRaQuery['wawiAssist']>['getWA_ArticleDoughDataFromRA']
>;

export type ArticleAvailabilityTabDataFromServer = NonNullable<
  NonNullable<GetArticleAvailabilityTabDataQuery['wawiAssist']>['getArticleData']
>;

export enum WeekDays {
  isDeliveredMon = 'isDeliveredMon',
  isDeliveredTue = 'isDeliveredTue',
  isDeliveredWed = 'isDeliveredWed',
  isDeliveredThu = 'isDeliveredThu',
  isDeliveredFri = 'isDeliveredFri',
  isDeliveredSat = 'isDeliveredSat',
  isDeliveredSun = 'isDeliveredSun',
}

export type WeekDaysFlags = {
  [key in WeekDays]: boolean;
};

export type PeriodsWithCustomWeekDaysType =
  ArticleAvailabilityTabDataFromServer['articleDeliveryPeriodsSettings']['articleCustomWeekDaysAndDeliveryPeriods'][number];

export type PeriodWithCustomWeekDays = Pick<PeriodsWithCustomWeekDaysType, 'id' | 'description'> & {
  period: {
    fromDate: PeriodsWithCustomWeekDaysType['fromDate'];
    toDate: PeriodsWithCustomWeekDaysType['toDate'];
    isRepeatYearly: PeriodsWithCustomWeekDaysType['isRepeatYearly'];
  };
  weekDays: WeekDaysFlags;
};

export type PeriodConditions = {
  periods: ArticleAvailabilityTabDataFromServer['articleDeliveryPeriodsSettings']['articleRegularDeliveryPeriods'];
  periodsWithCustomWeekDays: PeriodWithCustomWeekDays[];
  vacations: ArticleAvailabilityTabDataFromServer['articleDeliveryPeriodsSettings']['articleVacations'];
  weekDays: WeekDaysFlags;
};

export type ArticleAvailabilityTabDataRes = Pick<
  ArticleAvailabilityTabDataFromServer,
  'articleAssortimentGroupsCheckedState' | 'availabilityInProcurementKindId'
> &
  PeriodConditions & { id?: string };

export type ValidateArticleFieldsRes = NonNullable<
  NonNullable<ValidateArticleFieldsQuery['wawiAssist']>['validateWA_ArticleFields']
>;
export type SaveArticleDataRes = NonNullable<
  NonNullable<NonNullable<SaveArticleDataMutation['wawiAssist']>['saveArticleData']>
>;
export type SaveArticleProductionCalculationRes = NonNullable<
  NonNullable<SaveArticleProductionCalculationMutation['wawiAssist']>['saveArticleData']
>;
export type GetArticleSpecialPricesRes = NonNullable<
  NonNullable<GetArticleSpecialPricesQuery['wawiAssist']>['getArticleData']
>['articleSpecialPrices'];

export interface GeneralTabOptionsRes {
  discountGroups: DictDiscountGroupsRes;
  productionGroups: DictProductionGroupsRes;
  marketingGroups: DictMarketingGroupsRes;
  shippingPoints: DictShippingPointsRes;
  isShowShippingPointsInArticle: TCompanyConfigs['isShowShippingPointsInArticle'];
}

export interface CashAssistTabOptionsRes {
  productionGroups: DictProductionGroupsRes;
  cashAssistTemplates: DictCashAssistTemplatesRes;
}

export interface ProductionTabOptionsRes {
  dictArticles: DictArticlesRes;
  dictRecipesWithConnectedArticles: DictRecipesWithConnectedArticlesRes;
}

export type CreateOrCopyArticleRes = NonNullable<
  NonNullable<CreateArticleMutation['wawiAssist']>['createWA_Article']
>;
export interface ICopyArticleMutation {
  articleId: NonNullable<IArticleListState['selectedArticle']>['id'];
  generalTab: ArticleGeneralTabDataRes;
}
export type GetArticleProductionCalculationDependenciesRes = NonNullable<
  NonNullable<
    NonNullable<GetArticleProductionCalculationDependenciesQuery['wawiAssist']>['getArticleData']
  >['articleProductionCalculationDependencies']
>;
