import { gql } from 'graphql-request';

/* QUERIES start */
export const getListPotentialAndCreatedOffers = gql`
  query listWA_PotentialAndCreatedOffers($filter: WA_OffersGridFilter!) {
    wawiAssist {
      listWA_PotentialAndCreatedOffers(filter: $filter) {
        customerId
        offerForDate
        offerNo
        customerNr
        internalOrFullName
        draftOfferId
        offerId
        isAccepted
        isDeclined
        offerTotal
      }
    }
  }
`;

export const getCustomerInfo = gql`
  query GetCustomerInfo($getCustomerDataId: ID!) {
    wawiAssist {
      getCustomerData(id: $getCustomerDataId) {
        phoneBusiness
        phoneBusiness2
        phoneMobile
        phonePrivate
        fullName
        fax
        email
        deliveryAddress
        customerNo
        informationTip
        discountKindId
        isDeliverySplitting
        deliverySplittingPartsCount
        transportSectorId
        transportSector {
          isPriceRateFixed
          priceRateFixed
        }
      }
    }
  }
`;
export const getOfferData = gql`
  query GetOfferData($selectionProps: WA_OfferDataInputs!) {
    wawiAssist {
      getWA_OfferData(selectionProps: $selectionProps) {
        positions {
          virtualPositionId
          articleId
          articleNo
          description
          quantity
          price
          total
          discount
          vatCode
          indTextDeliveryNote
          indTextProduction
          quantityPerLot
          saleUnit
        }
        linkText
        note
        offerForDate
        offerSourceInfo {
          offerNo
          offerForDate
          offerTotal
        }
        validUntilDate
        datetimeOfLastEmail
      }
    }
  }
`;

/* MUTATIONS start */

export const createDraftOffer = gql`
  mutation CreateWA_DraftOffer($draftOfferProps: WA_DraftOfferInputs!) {
    wawiAssist {
      createWA_DraftOffer(draftOfferProps: $draftOfferProps)
    }
  }
`;

export const createOffer = gql`
  mutation CreateWA_Offer($offerProps: WA_CreateOfferInputs!) {
    wawiAssist {
      createWA_Offer(offerProps: $offerProps) {
        offerId
        offerNo
      }
    }
  }
`;

export const saveOffer = gql`
  mutation SaveWA_Offer($offerProps: WA_SaveOfferInputs!) {
    wawiAssist {
      saveWA_Offer(offerProps: $offerProps) {
        customerId
        offerForDate
        offerNo
        customerNr
        internalOrFullName
        draftOfferId
        offerId
        isAccepted
        isDeclined
        offerTotal
      }
    }
  }
`;

export const acceptOffer = gql`
  mutation AcceptWA_Offer($acceptWaOfferId: ID!) {
    wawiAssist {
      acceptWA_Offer(id: $acceptWaOfferId) {
        customerId
        offerForDate
        offerNo
        customerNr
        internalOrFullName
        draftOfferId
        offerId
        isAccepted
        isDeclined
        offerTotal
      }
    }
  }
`;

export const declineOffer = gql`
  mutation DeclineWA_Offer($declineWaOfferId: ID!) {
    wawiAssist {
      declineWA_Offer(id: $declineWaOfferId) {
        customerId
        offerForDate
        offerNo
        customerNr
        internalOrFullName
        draftOfferId
        offerId
        isAccepted
        isDeclined
        offerTotal
      }
    }
  }
`;
/* QUERIES end */
/* MUTATIONS start */
export const deleteDraftOffer = gql`
  mutation DeleteDraftOffer($deleteWaDraftOfferId: ID!) {
    wawiAssist {
      deleteWA_DraftOffer(id: $deleteWaDraftOfferId)
    }
  }
`;
/* MUTATIONS end */
