import equal from 'fast-deep-equal/react';
import {
  BehaviorSubject,
  filter,
  finalize,
  map,
  merge,
  Observable,
  of,
  share,
  switchMap,
  take,
  tap,
  zip,
} from 'rxjs';
import {
  C_Save_Operation_Status,
  SaveArticleDataMutationVariables,
} from '../../../../../graphql/generatedModel';
import { storageHelper } from '../../../../../shared/helpers/storage';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';
import {
  configsData,
  DictRecipesWithConnectedArticlesRes,
} from '../../../../../shared/services/configsData/configsData.service';
import { Pub, State } from '../../../../../shared/state/state.abstract';
import {
  defaultArticleProductionTabData,
  sortDictRecipesWithConnectedArticles,
} from '../../../loaders/productionTab.resolver';
import {
  ArticleDoughDataFromRARes,
  ArticleProductionTabDataRes,
  articleService,
  ProductionTabOptionsRes,
  SaveArticleDataRes,
  SaveArticleProductionCalculationRes,
} from '../../../services/article.service';
import { articleListState } from '../../../states/articleList.state';
import { ITabState } from '../../../states/tabState.model';
import { artcileTabsSub } from '../articleCommonTabs.sub';
import { articleTabLoadingService } from '../articleTababLoading.service';

class PubImpl extends Pub<IProductionTabState> {
  initDefaultValues(defaultValues: IProductionTabState['defaultValues']) {
    this.emit('initDefaultValues', { defaultValues });
  }
  recordData(dataToSave: IProductionTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  save() {
    this.emit('save', {});
  }
  initArticleProductionCalculation(
    articleProductionCalculation: IProductionTabState['articleProductionCalculation'],
  ) {
    this.emit('initArticleProductionCalculation', { articleProductionCalculation });
  }
  selectArticleProdCalcPos(
    selectedArticleProdCalcPos: IProductionTabState['selectedArticleProdCalcPos'],
  ) {
    this.emit('selectArticleProdCalcPos', { selectedArticleProdCalcPos });
  }
  updateArticleProdCalcPos(
    selectedArticleProdCalcPos: IProductionTabState['selectedArticleProdCalcPos'],
  ) {
    this.emit('updateArticleProdCalcPos', { selectedArticleProdCalcPos });
  }
  deleteArticleProdCalcPos() {
    this.emit('deleteArticleProdCalcPos', {});
  }
  saveArticleProductionCalculation() {
    this.emit('saveArticleProductionCalculation', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
}
class SubImpl extends artcileTabsSub<IProductionTabState>() {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  private lastAction: IProductionTabState['action'];
  protected actionHandlers(): Observable<IProductionTabState> {
    return merge(
      this.selectedArticle(),
      this.updateState(),
      this.updateArticleProdCalcPos(),
      this.deleteSpecialPricesPos(),
      this.saveArticleProductionCalculation(),
      this.save(),
    ).pipe(
      tap((state) => {
        if (state.action !== 'innerUpdate') {
          this.lastAction = state.action;
        }
        this.stream$.next({ ...state, action: 'innerUpdate' });
        this.loading$.next(false);
      }),
    );
  }
  private save(): Observable<IProductionTabState> {
    return this.actionListener('save').pipe(
      switchMap((state) => {
        const { dataToSave } = state;
        if (!dataToSave.productionRecipeId) {
          return of(state);
        }
        articleService.pub.articleDoughDataFromRA({
          recipeId: dataToSave.productionRecipeId,
        });

        return articleService.sub.articleDoughDataFromRA().pipe(
          responseHandler<ArticleDoughDataFromRARes>({ errorReturnType: {} }),
          map(({ id, intermediateWeight }: ArticleDoughDataFromRARes) => {
            const isIntermediateWeight = intermediateWeight && intermediateWeight > 0;
            state.dataToSave = {
              ...dataToSave,
              articleDoughDataFromRA: {
                id,
                intermediateWeight: intermediateWeight || 0,
              },
              ...(!!isIntermediateWeight && {
                weightOfLiterOfDough: intermediateWeight,
              }),
            };
            this.stream$.next({
              ...state,
              action: 'innerUpdate',
            });
            return state;
          }),
        );
      }),
      tap(({ dataToSave }) => {
        const { articleDoughDataFromRA: _, ...dataToSaveWithoutDough } = dataToSave;
        articleService.sub
          .editArticle()
          .pipe(
            responseHandler<SaveArticleDataRes | undefined>({
              success: () => 'article.article_saved',
              customErrorHandler: () => 'article.article_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              articleListState.pub.updateArticle(updatedGridItem!);
            }
            storageHelper.memory.removeItem('configsData.articlesForOrderPositionList');
          });
        articleService.pub.editArticle({
          dataToSave: dataToSaveWithoutDough,
        } as SaveArticleDataMutationVariables);
      }),
      finalize(() => productionTabState.pub.clearStream()),
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  private selectedArticle(): Observable<IProductionTabState> {
    return articleListState.sub.state().pipe(
      filter(
        ({ action }) =>
          action === 'selectArticle' || action === 'articleList' || action === 'filter',
      ),
      filter(({ selectedArticle }) => {
        if (!selectedArticle || !selectedArticle?.id) {
          this.articleIdNotExist$.next(true);
        }
        return typeof selectedArticle?.id === 'string';
      }),
      switchMap(({ selectedArticle }) => {
        articleTabLoadingService.pub.loading(true);
        const id = selectedArticle!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedArticle';
        this.lastAction = 'list.selectedArticle';
        const details = zip(
          articleService.sub.articleProductionTabData(),
          configsData.sub.dictRecipesWithConnectedArticles(),
        ).pipe(
          responseHandler<ProductionAndRecipesArticlesRes>({
            errorReturnType: [defaultArticleProductionTabData, []],
            customErrorHandler: () => 'article.tab_not_loaded',
          }),
          take(1),
          map(([data, dictRecipesWithConnectedArticles]) => {
            state.defaultValues = data;
            state.articleProductionCalculation = structuredClone(data.articleProductionCalculation);
            state.selectedArticleProdCalcPos = null;
            state.defaultValues.id = id;
            state.dictRecipesWithConnectedArticles = sortDictRecipesWithConnectedArticles(
              dictRecipesWithConnectedArticles,
            );
            articleTabLoadingService.pub.loading(false);
            return state;
          }),
        );
        articleService.pub.articleProductionTabData({ id });
        configsData.pub.common(['dictRecipesWithConnectedArticles']);
        return details;
      }),
    );
  }

  private updateArticleProdCalcPos(): Observable<IProductionTabState> {
    return this.actionListener('updateArticleProdCalcPos').pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        const foundedIdx = updatedState.articleProductionCalculation.findIndex(
          (item) => item.id === updatedState.selectedArticleProdCalcPos?.id,
        );
        if (updatedState.selectedArticleProdCalcPos) {
          if (foundedIdx !== -1 && updatedState.selectedArticleProdCalcPos) {
            updatedState.articleProductionCalculation.splice(
              foundedIdx,
              1,
              updatedState.selectedArticleProdCalcPos,
            );
          } else {
            updatedState.articleProductionCalculation.push(updatedState.selectedArticleProdCalcPos);
          }
        }
        return updatedState;
      }),
    );
  }

  private deleteSpecialPricesPos(): Observable<IProductionTabState> {
    return this.actionListener('deleteArticleProdCalcPos').pipe(
      map((state) => {
        const updatedState = state;
        let selectedPos: IProductionTabState['selectedArticleProdCalcPos'] = null;
        updatedState.articleProductionCalculation =
          updatedState.articleProductionCalculation?.filter((pos, i, arr) => {
            if (pos?.id === state.selectedArticleProdCalcPos?.id) {
              const nextPos = arr?.[i + 1];
              const prevPos = arr?.[i - 1];
              if (i === 0 && arr!.length > 1) {
                selectedPos = nextPos;
              }
              if (i !== 0) {
                selectedPos = nextPos || prevPos || null;
              }
              return false;
            } else return true;
          });
        updatedState.selectedArticleProdCalcPos = selectedPos;
        return updatedState;
      }),
    );
  }

  private saveArticleProductionCalculation(): Observable<IProductionTabState> {
    return this.actionListener('saveArticleProductionCalculation').pipe(
      filter(({ defaultValues, articleProductionCalculation }) => {
        return !equal(defaultValues.articleProductionCalculation, articleProductionCalculation);
      }),
      tap((state) => {
        const {
          articleProductionCalculation,
          defaultValues: { id },
        } = state;
        const preparedDataForSave = articleProductionCalculation.map((item) => {
          const { addedArticleId, multiplier } = item;
          return { addedArticleId, multiplier };
        });
        articleService.pub.saveArticleProductionCalculation({
          dataToSave: { id, articleProductionCalculation: preparedDataForSave },
        });
      }),
      switchMap(() => {
        return articleService.sub.saveArticleProductionCalculation().pipe(
          responseHandler<SaveArticleProductionCalculationRes>({
            success: () => 'article.article_saved',
            errorReturnType: {
              status: C_Save_Operation_Status.SOS0_DATA_UNCHANGED,
              updatedArticleProductionCalculationDependencies: null,
            },
            customErrorHandler: () => 'common.error_chnages_not_saved',
          }),
          take(1),
          filter((response) => response.status === C_Save_Operation_Status.SOS1_DATA_CHANGED),
          map(({ updatedArticleProductionCalculationDependencies }) => {
            const updatedState = structuredClone(this.stream$.getValue());
            if (this.lastAction !== 'list.selectedArticle') {
              updatedState.defaultValues.articleProductionCalculation = structuredClone(
                updatedState.articleProductionCalculation,
              );
              updatedState.defaultValues.articleProductionCalculationDependencies =
                updatedArticleProductionCalculationDependencies;
            }
            return updatedState;
          }),
        );
      }),
    );
  }

  private updateState(): Observable<IProductionTabState> {
    return this.actionListener(['selectArticleProdCalcPos']);
  }
}

class ProductionTabState extends State<IProductionTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const initProductionTabState: IProductionTabState = {
  action: undefined,
  recipeId: '',
  defaultValues: {},
  dataToSave: {},
  dictRecipesWithConnectedArticles: [],
  articleProductionCalculation: [],
  selectedArticleProdCalcPos: null,
};

export const productionTabState = new ProductionTabState(initProductionTabState);

export interface IProductionTabState extends Pick<ITabState, 'defaultValues'> {
  action:
    | undefined
    | 'list.selectedArticle'
    | 'initArticleProductionCalculation'
    | 'selectArticleProdCalcPos'
    | 'updateArticleProdCalcPos'
    | 'deleteArticleProdCalcPos'
    | 'saveArticleProductionCalculation'
    | 'initDefaultValues'
    | 'recordData'
    | 'save'
    | 'innerUpdate';
  recipeId: '';
  dataToSave: ArticleProductionTabDataRes | Record<string, any>;
  dictRecipesWithConnectedArticles: ProductionTabOptionsRes['dictRecipesWithConnectedArticles'];
  articleProductionCalculation: ArticleProductionTabDataRes['articleProductionCalculation'];
  selectedArticleProdCalcPos:
    | ArticleProductionTabDataRes['articleProductionCalculation'][number]
    | null;
}

type ProductionAndRecipesArticlesRes = [
  ArticleProductionTabDataRes,
  DictRecipesWithConnectedArticlesRes,
];
