import { Tab, Tabs } from '@mui/material';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useParams } from 'react-router-dom';

import { ContentLoading } from '../../../../shared/components/loading/loading.component.tsx';
import { transitionService } from '../../../../shared/components/loading/service/transition.service.ts';
import { TabPanel } from '../../../../shared/components/tabs';
import { TCompanyConfigs } from '../../../../shared/services/companyConfig/companyConfig.service.ts';
import { navigateService } from '../../../../shared/services/navigate/navigate.service.ts';
import { CompanyTabs } from '../../company/states/tabState.model.ts';
import { configurationTabLoadingService } from '../configurationTabLoading.service.ts';

import { ArticleTab } from './tabs/article.tab.tsx';
import { CustomerTab, TCompanyConfigsForCustomer } from './tabs/customer/customer.tab.tsx';
import { FinancialAccountingTab } from './tabs/financialAccounting.tab.tsx';
import { InvoiceTab } from './tabs/invoice/invoice.tab.tsx';
import { ProductionTab } from './tabs/production/production.tab.tsx';
import { VatTab } from './tabs/vat.tab.tsx';
import { OrderTab } from './tabs/order/order.tab.tsx';
import { BankAccountsTab } from './tabs/bankAccounts/bankAccounts.tab.tsx';

import { ListTaxesPeriodsAndRatesQueryRes } from '../services/configuration.service.ts';
import { TabContentContainer } from '../../../../shared/components/tabs/tabsContainer.component.tsx';
import { C_Country } from '../../../../graphql/generatedModel.ts';
import { DictBanksRes } from '../../../../shared/services/configsData/configsData.service.ts';
import { PostTab } from './tabs/post/post.tab.tsx';

enum ConfigurationDetailsTab {
  INVOICE = 'invoice',
  FINANCIAL_ACCOUNTING = 'financial-accounting',
  PRODUCTION = 'production',
  CUSTOMER = 'customer',
  ARTICLE = 'article',
  POST = 'post',
  VAT = 'vat',
  ORDER = 'order',
  BANK_ACCOUNTS = 'bank-accounts',
}

const ConfigurationDetails: FC = () => {
  const { tab } = useParams() as { tab: CompanyTabs };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const initTabData = useLoaderData() as IInitTabData;

  useEffect(() => {
    const unsubLoading = configurationTabLoadingService.sub.loading().subscribe(setLoading);
    return () => {
      unsubLoading.unsubscribe();
    };
  }, []);

  const handleActiveTab: THandleActiveTab = (_, tab) => {
    if (!loading) {
      transitionService.pub.off();
      configurationTabLoadingService.pub.loading(true);
      navigateService.pub.navigateTo(`/settings/configuration/${tab}`);
    }
  };

  return (
    <>
      <Tabs
        scrollButtons={false}
        sx={{ mb: 3, borderBottom: 1, borderColor: 'divider' }}
        variant='scrollable'
        value={tab}
        onChange={handleActiveTab}
      >
        <Tab label={t('settings.customer_tab')} value={ConfigurationDetailsTab.CUSTOMER} />
        <Tab label={t('settings.article_tab')} value={ConfigurationDetailsTab.ARTICLE} />
        <Tab label={t('settings.order_tab')} value={ConfigurationDetailsTab.ORDER} />
        <Tab label={t('settings.production_tab')} value={ConfigurationDetailsTab.PRODUCTION} />
        <Tab label={t('settings.invoice_tab')} value={ConfigurationDetailsTab.INVOICE} />
        {initTabData?.countryId === C_Country.CO1_SWITZERLAND && (
          <Tab
            label={t('settings.bank_accounts_tab')}
            value={ConfigurationDetailsTab.BANK_ACCOUNTS}
          />
        )}
        <Tab label={t('settings.vat_tab')} value={ConfigurationDetailsTab.VAT} />
        <Tab
          label={t('settings.financial_accounting_tab')}
          value={ConfigurationDetailsTab.FINANCIAL_ACCOUNTING}
        />
        <Tab label={t('settings.post_tab')} value={ConfigurationDetailsTab.POST} />
      </Tabs>
      <TabContentContainer>
        <ContentLoading {...{ loading }}>
          <TabPanel value={ConfigurationDetailsTab.INVOICE} activeValue={tab}>
            <InvoiceTab params={initTabData} />
          </TabPanel>
          <TabPanel value={ConfigurationDetailsTab.PRODUCTION} activeValue={tab}>
            <ProductionTab params={initTabData} />
          </TabPanel>
          <TabPanel value={ConfigurationDetailsTab.CUSTOMER} activeValue={tab}>
            <CustomerTab params={initTabData as TCompanyConfigsForCustomer} />
          </TabPanel>
          <TabPanel value={ConfigurationDetailsTab.ARTICLE} activeValue={tab}>
            <ArticleTab params={initTabData} />
          </TabPanel>
          <TabPanel value={ConfigurationDetailsTab.FINANCIAL_ACCOUNTING} activeValue={tab}>
            <FinancialAccountingTab params={initTabData} />
          </TabPanel>
          <TabPanel value={ConfigurationDetailsTab.BANK_ACCOUNTS} activeValue={tab}>
            <BankAccountsTab params={initTabData} />
          </TabPanel>
          <TabPanel value={ConfigurationDetailsTab.POST} activeValue={tab}>
            <PostTab params={initTabData} />
          </TabPanel>
          <TabPanel value={ConfigurationDetailsTab.VAT} activeValue={tab}>
            <VatTab params={initTabData} />
          </TabPanel>
          <TabPanel value={ConfigurationDetailsTab.ORDER} activeValue={tab}>
            <OrderTab params={initTabData} />
          </TabPanel>
        </ContentLoading>
      </TabContentContainer>
    </>
  );
};

export default ConfigurationDetails;

type THandleActiveTab = (evt: SyntheticEvent<Element, Event>, tab: string) => void;

export interface IInitTabData extends TCompanyConfigs {
  listTaxesPeriodsAndRates?: ListTaxesPeriodsAndRatesQueryRes;
  banksDictOptions?: DictBanksRes;
}
