import { LocalizationHelper } from '../helpers/localization/localization.helper.ts';

type Keys =
  | 'settings'
  | 'search'
  | 'sort'
  | 'clear'
  | 'avatar'
  | 'no_data'
  | 'no_data_in_global_search'
  | 'name'
  | 'number'
  | 'vat'
  | 'note'
  | 'configure'
  | 'filter'
  | 'successfully_saved'
  | 'confirm'
  | 'add'
  | 'remove'
  | 'font_color'
  | 'font_size'
  // errors messages -->
  | 'error_customer_info_not_loaded'
  | 'error_customer_list_not_loaded'
  | 'error_orders_not_loaded'
  // <-- errors messages
  | 'cancel'
  | 'history'
  | 'current_entry'
  | 'deleted_entries'
  | 'date'
  | 'more_functions'
  | 'date_time'
  | 'operation'
  | 'username'
  | 'field'
  | 'article_no'
  | 'delivery_no'
  | 'brutto'
  | 'net'
  | 'user'
  | 'assist'
  | 'cash'
  | 'wawi'
  | 'rezept'
  | 'smart'
  | 'picking'
  | 'restaurant'
  | 'manager'
  | 'run'
  | 'my_resto'
  | 'console'
  | 'grouping'
  | 'old_value'
  | 'new_value'
  | 'content'
  | 'lot_quantity'
  | 'not_empty'
  | 'only_numbers'
  | 'valid_emails'
  | 'loading'
  | 'search_failed'
  | 'search_result_for'
  | 'value_between'
  | 'value_less'
  | 'value_greater'
  | 'description'
  | 'activated'
  | 'cash_register_id'
  | 'cashassist'
  | 'receipt_no'
  | 'delivery_method'
  | 'cashassist_user'
  | 'permitted'
  | 'forbidden'
  | 'address'
  | 'enter_delivery_address'
  | 'print'
  | 'email'
  | 'empty'
  | 'export'
  | 'delivery_note'
  | 'invoice'
  | 'preview'
  | 'successfully'
  | 'multiselect'
  | 'server_error'
  | 'error_chnages_not_saved'
  | 'proceed_anyway'
  | 'warning'
  | 'current_month'
  | 'save'
  | 'last_month'
  | 'date_range'
  | 'customer'
  | 'customer_no'
  | 'customers_group'
  | 'customers_list'
  | 'one_time_customers'
  | 'open_emails'
  | 'sending_emails'
  | 'sent_emails'
  | 'show_deleted'
  | 'from'
  | 'to'
  | 'ok'
  | 'pdf_history'
  | 'pdfs_versions'
  | 'created'
  | 'invalid_data_warning'
  | 'report'
  | 'sending'
  | 'send'
  | 'email_sent_successfully'
  | 'email_sent_successfully_other'
  | 'problem_while_sending_email'
  | 'problem_while_sending_email_other'
  | 'order'
  | 'offer'
  | 'recipient'
  | 'regarding'
  | 'message'
  | 'from_date'
  | 'to_date'
  | 'yearly'
  | 'rules_is_not_set'
  | 'regional_datetime_entered_day_of_week'
  | 'in_period'
  | 'repeat_yearly'
  | 'in_periods'
  | 'vacation'
  | 'not_delivered'
  | 'change'
  | 'fri'
  | 'mon'
  | 'sat'
  | 'sun'
  | 'thu'
  | 'tue'
  | 'wed'
  | 'facilities'
  | 'number_short'
  | 'offline'
  | 'internet_restored'
  | 'new_version_available'
  | 'update'
  | 'session_expired'
  | 'sign_out'
  | 'signed_out_refresh'
  | 'refresh'
  | 'reload_page'
  | 'monday'
  | 'revert_changes'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | 'replace'
  | 'weekAgo'
  | 'twoWeeksAgo'
  | 'threeWeeksAgo'
  | 'changes_you_made_were_not_saved'
  | 'all_lists'
  | 'default_settings'
  | 'additional'
  | 'enter_valid_email'
  | 'days'
  | 'update_browser_heading'
  | 'update_browser_message'
  | 'link_current_browser_versions'
  | 'max'
  | 'min'
  | 'category'
  | 'successfully_deleted'
  | 'close'
  | 'apply'
  | 'all'
  | 'shown'
  | 'show_all'
  | 'amount'
  | 'discount'
  | 'delete'
  | 'do_you_want_unbind_and_delete'
  | 'emails'
  | 'mailing_message'
  | 'emails_sent_successfully'
  | 'emails_failed_to_send'
  | 'error_while_sending_emails'
  | 'previous_record'
  | 'next_record'
  | 'quantity'
  | 'yes'
  | 'no'
  | 'new_record'
  | 'report_is_generating'
  | 'discard_your_changes'
  | 'discard'
  | 'difference'
  | 'list_are_not_configured'
  | 'click_button_above_to_configure'
  | 'invoice_dates_range'
  | 'track'
  | 'sender_address'
  | 'receiver_address'
  | 'subject'
  | 'diagnostic_information'
  | 'delivery_information_is_not_yet_available'
  | 'data_processing'
  | 'version'
  | 'price'
  | 'total';

const t = LocalizationHelper<Keys>('common');

export const commonDe = t({
  settings: 'Einstellungen',
  add: 'Hinzufügen',
  remove: 'Löschen',
  all: 'Gesamt',
  name: 'Name',
  number: 'Nummer',
  search: 'Suchen',
  sort: 'Sorte',
  clear: 'Klar',
  no_data: 'Keine Daten',
  vat: 'MwSt',
  avatar: 'Avatar',
  shown: 'Gezeigt',
  show_all: 'Zeige alles',
  search_failed: 'Suche fehlgeschlagen',
  search_result_for: 'Suchergebnis für',
  note: 'Bemerkung',
  configure: 'Konfigurieren',
  filter: 'Filter',
  confirm: 'Bestätigen',
  font_color: 'Schriftfarbe',
  font_size: 'Schriftgröße',
  cancel: 'Abbrechen',
  history: 'Verlauf',
  brutto: 'Brutto',
  net: 'Netto',
  user: 'Benutzer',
  grouping: 'Gruppierung',
  current_entry: 'Aktueller Eintrag',
  deleted_entries: 'Gelöschte Einträge',
  date: 'Datum',
  rules_is_not_set: 'Es sind keine Regeln festgelegt',
  regional_datetime_entered_day_of_week: 'Jeden {{days}}',
  in_period: 'in der Zeit',
  repeat_yearly: 'Wiederhole jährlich',
  in_periods: 'in der Zeit {{periods}}',
  vacation: 'Urlaub',
  not_delivered: 'Nicht verfügbar',
  from_date: 'Von Datum',
  to_date: 'Bis Datum',
  yearly: 'Jährlich',
  change: 'Veränderung',
  assist: 'Assist',
  cash: 'Cash',
  wawi: 'Wawi',
  rezept: 'Rezept',
  smart: 'Smart',
  picking: 'Picking',
  restaurant: 'Restaurant',
  manager: 'Manager',
  run: 'Run',
  my_resto: 'MyResto',
  console: 'Console',
  fri: 'Fr',
  mon: 'Mo',
  sat: 'Sa',
  sun: 'So',
  thu: 'Do',
  tue: 'Di',
  wed: 'Mi',
  date_time: 'Datum/Zeit',
  operation: 'Operation',
  username: 'Benutzername',
  field: 'Field',
  successfully_saved: 'Erfolgreich gespeichert',
  successfully_deleted: 'Erfolgreich gelöscht',
  more_functions: 'Mehr Funktion',
  article_no: 'Artikel-Nr.',
  delivery_no: 'Lieferschein-Nr.',
  old_value: 'Alter Wert',
  new_value: 'Neuer Wert',
  content: 'Inhalt',
  lot_quantity: 'Der Wert muss ein Vielfaches der Anzahl der Lots sein',
  not_empty: 'Eingabe erforderlich',
  only_numbers: 'Sollte nur Zahlen enthalten',
  valid_emails: 'Bitte gültige EMail(s) eingeben',
  value_between: 'Der Wert muss zwischen liegen {{min}} und {{max}}',
  value_less: 'Der Wert muss kleiner als {{max}} sein',
  value_greater: 'Der Wert muss größer sein als {{min}}',
  loading: 'Wird geladen...',
  description: 'Bezeichnung',
  activated: 'Aktiviert',
  permitted: 'Erlaubt',
  forbidden: 'Verboten',
  address: 'Adresse',
  enter_delivery_address: 'Lieferadresse eingeben',
  empty: 'Leer',
  export: 'Exportieren',
  print: 'Drucken',
  email: 'Email',
  revert_changes: 'Änderungen rückgängig machen',
  delivery_note: 'Lieferschein',
  invoice: 'Rechnung',
  preview: 'Vorschau',
  successfully: 'Erfolgreich',
  server_error: 'Serverfehler',
  multiselect: 'Mehrfachauswahl',
  proceed_anyway: 'Fahre dennoch fort',
  cash_register_id: 'Kasse ID',
  cashassist: 'CashAssist',
  delivery_method: 'Lieferart',
  receipt_no: 'Beleg-Nr',
  cashassist_user: 'CashAssist Benutzer',
  warning: 'Warnung',
  current_month: 'Aktueller Monat',
  last_month: 'Letzter Monat',
  date_range: 'Datumsbereich',
  customer: 'Kunde',
  customer_no: 'Kunden-Nr.',
  customers_group: 'Kundengruppe',
  customers_list: 'Kundenliste',
  one_time_customers: 'Einmalkunden',
  open_emails: 'Offene Emails',
  sending_emails: 'Emails senden in Bearbeitung',
  sent_emails: 'Versendete Emails',
  show_deleted: 'Gelöschte anzeigen',
  from: 'Von',
  to: 'Bis',
  pdf_history: 'PDF Verlauf',
  pdfs_versions: 'PDFs Verlauf',
  created: 'Erstellt',
  report: 'Report',
  ok: 'Ok',
  invalid_data_warning:
    'Die Daten waren ungültig. Ihre Änderungen gehen verloren. Möchten Sie fortfahren?',
  sending: 'Senden...',
  send: 'Senden',
  email_sent_successfully: 'E-Mails wurde erfolgreich Versendet',
  email_sent_successfully_other: 'E-Mails wurden erfolgreich gesendet',
  problem_while_sending_email: 'Problem beim Senden der E-Mail',
  problem_while_sending_email_other: 'Problem beim Senden von E-Mails',
  order: 'Bestellung',
  offer: 'Angebot',
  recipient: 'Empfänger',
  regarding: 'Betreff',
  message: 'Meldung',
  facilities: 'Anlagen',
  number_short: 'Nr.',
  offline: 'Du bist offline',
  internet_restored: 'Internetverbindung wiederhergestellt',
  new_version_available: 'Neue Version ist verfügbar',
  update: 'Aktualisieren',
  session_expired: 'Sitzung abgelaufen',
  sign_out: 'Abmelden',
  signed_out_refresh:
    'Sie sind abgemeldet. Aktualisieren Sie die Seite, um mit der Arbeit fortzufahren.',
  refresh: 'Aktualisieren',
  reload_page: 'Seite neuladen',
  monday: 'Montag',
  tuesday: 'Dienstag',
  wednesday: 'Mittwoch',
  thursday: 'Donnerstag',
  friday: 'Freitag',
  saturday: 'Samstag',
  save: 'Speichern',
  sunday: 'Sonntag',
  replace: 'Ersetzen',
  weekAgo: 'Vor einer Woche',
  twoWeeksAgo: 'Vor zwei Wochen',
  threeWeeksAgo: 'Vor drei Wochen',
  no_data_in_global_search: 'Ihre Suche „{{searchString}}“ hat keine Dokumente ergeben',
  error_customer_info_not_loaded: 'Kundeninformationen wurden nicht geladen!',
  error_customer_list_not_loaded: 'Kundenliste wurde nicht geladen!',
  error_orders_not_loaded: 'Bestellung wurde nicht geladen!',
  error_chnages_not_saved: 'Fehler. Ihre Änderungen wurden nicht gespeichert.',
  changes_you_made_were_not_saved:
    'Die von Ihnen vorgenommenen Änderungen wurden nicht gespeichert. Möchten Sie fortfahren?',
  all_lists: 'Alle Listen',
  default_settings: 'Standardeinstellung',
  additional: 'Zusätzlich',
  days: 'Tage',
  enter_valid_email: 'Geben Sie eine gültige Email Adresse ein',
  update_browser_heading: 'Aktualisieren Sie Ihren Browser',
  update_browser_message:
    'Leider verwenden Sie einen veralteten Browser. <br /> Bitte aktualisieren Sie Ihren Browser, um Ihr Erlebnis und Ihre Sicherheit zu verbessern.',
  link_current_browser_versions: 'Links zu aktuellen Browserversionen:',
  max: 'Max',
  min: 'Min',
  category: 'Kategorie',
  close: 'Schließen',
  apply: 'Anwenden',
  amount: 'Betrag',
  discount: 'Rabatt',
  delete: 'Löschen',
  emails: 'Emails',
  do_you_want_unbind_and_delete:
    '{{dictionary}} kann nicht gelöscht werden. Es wird verwendet für die folgenden {{lists}} (siehe Details). Möchten Sie diese von der {{lists}} entfernen und die löschen?',
  mailing_message:
    'Der E-Mail Versand wird in einem seperaten Prozess nun durchgeführt.\n \nNach Ende des Prozesses erhalten Sie eine Meldung.',
  emails_sent_successfully: 'E-Mails wurden erfolgreich versendet: {{successCount}}',
  emails_failed_to_send: 'E-Mails konnten nicht gesendet werden: {{failureCount}}',
  error_while_sending_emails:
    'Beim Senden von E-Mails ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
  previous_record: 'Bisherigen Datensatz',
  next_record: 'Nächster Datensatz',
  quantity: 'Menge',
  yes: 'Ja',
  no: 'Nein',
  new_record: 'Neuer Eintrag',
  report_is_generating: 'Der Bericht wird gerade erstellt. Bitte versuchen Sie es später erneut',
  discard_your_changes: 'Änderungen verwerfen?',
  discard: 'Verwerfen',
  difference: 'Differenz',
  list_are_not_configured: 'Listen sind nicht konfiguriert',
  click_button_above_to_configure:
    'Klicken Sie auf die <icon /> Schaltfläche oben, um zu konfigurieren',
  track: 'Verfolgen',
  sender_address: 'Absenderadresse',
  receiver_address: 'Empfängeradresse',
  subject: 'Betreff',
  diagnostic_information: 'Diagnoseinformationen',
  delivery_information_is_not_yet_available:
    'Die Lieferinformationen sind noch nicht verfügbar, bitte versuchen Sie es später erneut!',
  invoice_dates_range: 'Rechnung Datum Bereich',
  data_processing: 'Datenverarbeitung...',
  version: 'Version',
  price: 'Preis',
  total: 'Total',
});

export const commonEn = t({
  settings: 'Settings',
  add: 'Add',
  all: 'All',
  remove: 'Remove',
  name: 'Name',
  number: 'Number',
  search: 'Search',
  sort: 'Sort',
  clear: 'Clear',
  no_data: 'No data',
  vat: 'VAT',
  note: 'Note',
  configure: 'Configure',
  filter: 'Filter',
  confirm: 'Confirm',
  font_color: 'Font color',
  font_size: 'Font size',
  cancel: 'Cancel',
  history: 'History',
  current_entry: 'Current entry',
  deleted_entries: 'Deleted entries',
  date: 'Date',
  brutto: 'Brutto',
  avatar: 'Avatar',
  net: 'Net',
  shown: 'Shown',
  show_all: 'Show all',
  user: 'User',
  assist: 'Assist',
  cash: 'Cash',
  wawi: 'Wawi',
  rezept: 'Rezept',
  smart: 'Smart',
  picking: 'Picking',
  restaurant: 'Restaurant',
  manager: 'Manager',
  run: 'Run',
  my_resto: 'MyResto',
  console: 'Console',
  grouping: 'Grouping',
  date_time: 'Date/Time',
  operation: 'Operation',
  revert_changes: 'Revert changes',
  username: 'User name',
  field: 'Field',
  more_functions: 'More functions',
  article_no: 'Article No.',
  delivery_no: 'Order No.',
  old_value: 'Old value',
  new_value: 'New value',
  cash_register_id: 'Cash register ID',
  cashassist: 'CashAssist',
  delivery_method: 'Delivery method',
  receipt_no: 'Receipt No.',
  cashassist_user: 'CashAssist user',
  successfully_saved: 'Successfully saved',
  successfully_deleted: 'Successfully deleted',
  content: 'Content',
  lot_quantity: 'The value must be a multiple of the number of lots',
  not_empty: 'Input required',
  only_numbers: 'Should contain only numbers',
  valid_emails: 'Please enter valid email(s).',
  value_between: 'The value must be between {{min}} and {{max}}',
  value_less: 'The value must be less than {{max}}',
  value_greater: 'The value must be greater than {{min}}',
  no_data_in_global_search: 'Your search - {{searchString}} - did not match any documents',
  loading: 'Loading...',
  description: 'Description',
  activated: 'Activated',
  search_failed: 'Search failed',
  search_result_for: 'Search result for',
  permitted: 'Permitted',
  rules_is_not_set: 'Rules is not set',
  regional_datetime_entered_day_of_week: 'Every {{days}}',
  in_period: 'in period',
  repeat_yearly: 'Repeat yearly',
  in_periods: 'in periods {{periods}}',
  vacation: 'Vacation',
  not_delivered: 'Not delivered',
  from_date: 'From date',
  to_date: 'To date',
  yearly: 'Yearly',
  change: 'Change',
  fri: 'Fr',
  mon: 'Mo',
  sat: 'Sa',
  sun: 'Su',
  thu: 'Th',
  tue: 'Tu',
  wed: 'We',
  forbidden: 'Forbidden',
  address: 'Address',
  enter_delivery_address: 'Enter delivery address',
  empty: 'Empty',
  export: 'Export',
  print: 'Print',
  email: 'Email',
  delivery_note: 'Delivery note',
  invoice: 'Invoice',
  preview: 'Preview',
  successfully: 'Successfully',
  server_error: 'Server error',
  multiselect: 'Multi-selection',
  proceed_anyway: 'Proceed anyway',
  warning: 'Warning',
  current_month: 'Current month',
  last_month: 'Last month',
  date_range: 'Date range',
  customer: 'Customer',
  customer_no: 'Customer No.',
  customers_group: 'Customer group',
  customers_list: 'Customer list',
  one_time_customers: 'One-time customer',
  open_emails: 'Emails not sent',
  sending_emails: 'Emails send in progress',
  sent_emails: 'Sent emails',
  show_deleted: 'Show deleted',
  from: 'From',
  to: 'To',
  pdf_history: 'PDF History',
  pdfs_versions: 'PDFs History',
  created: 'Created',
  report: 'Report',
  ok: 'Ok',
  invalid_data_warning: 'Data was invalid. Your changes will be lost. Do you want to proceed?',
  sending: 'Sending...',
  save: 'Save',
  send: 'Send',
  email_sent_successfully: 'Email was successfully sent',
  email_sent_successfully_other: 'Emails was successfully sent',
  problem_while_sending_email: 'Problem while sending email',
  problem_while_sending_email_other: 'Problem while sending emails',
  order: 'Order',
  offer: 'Offer',
  recipient: 'Recipient',
  regarding: 'Regarding',
  message: 'Message',
  facilities: 'Facilities',
  number_short: 'No.',
  offline: 'You are offline',
  internet_restored: 'Internet connection restored',
  new_version_available: 'New app version is available',
  update: 'Update',
  session_expired: 'Session expired',
  sign_out: 'Sign out',
  signed_out_refresh: 'You are signed out. Refresh the page to continue working.',
  refresh: 'Refresh',
  reload_page: 'Reload the page',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  replace: 'Replace',
  weekAgo: 'Week ago',
  twoWeeksAgo: 'Two weeks ago',
  threeWeeksAgo: 'Three weeks ago',
  error_customer_info_not_loaded: 'Customer information has not been loaded!',
  error_orders_not_loaded: 'Order was not loaded!',
  error_customer_list_not_loaded: 'Customer list was not loaded!',
  error_chnages_not_saved: 'Error. Your changes were not saved.',
  changes_you_made_were_not_saved: 'The changes you made were not saved. Do you want to proceed?',
  all_lists: 'All lists',
  default_settings: 'Default settings',
  additional: 'Additional',
  days: 'Days',
  enter_valid_email: 'Please enter a correct email address',
  update_browser_heading: 'Update your browser',
  update_browser_message:
    'Unfortunately, you are using an outdated browser.<br />Please upgrade your browser to improve your experience and security.',
  link_current_browser_versions: "Links of browser's current versions:",
  max: 'Max',
  min: 'Min',
  category: 'Category',
  close: 'Close',
  apply: 'Apply',
  amount: 'Amount',
  discount: 'Discount',
  delete: 'Delete',
  emails: 'Emails',
  do_you_want_unbind_and_delete:
    '{{dictionary}} can not be deleted. It is used for the following {{lists}}. Do you want to unbind them from the {{lists}} and to delete this {{dictionary}}?',
  mailing_message:
    'The email dispatch is carried out in a separate process.\n \nAfter process is finished you will\n \nreceive a notification.',
  emails_sent_successfully: 'Emails sent successfully: {{successCount}}',
  emails_failed_to_send: 'Emails failed to send: {{failureCount}}',
  error_while_sending_emails: 'An error occurred while sending emails. Please try again later.',
  previous_record: 'Previous record',
  next_record: 'Next record',
  quantity: 'Quantity',
  yes: 'Yes',
  no: 'No',
  new_record: 'New record',
  report_is_generating: 'Report is generating now. Please try again later',
  difference: 'Difference',
  discard_your_changes: 'Discard your changes?',
  discard: 'Discard',
  list_are_not_configured: 'List are not configured',
  click_button_above_to_configure: 'Click the <icon /> button above to configure',
  track: 'Track',
  sender_address: 'Sender’s address',
  receiver_address: 'Receiver’s address',
  subject: 'Subject',
  diagnostic_information: 'Diagnostic information',
  delivery_information_is_not_yet_available:
    'Delivery information is not yet available, please try again later!',
  invoice_dates_range: 'Invoice dates range',
  data_processing: 'Data processing...',
  version: 'Version',
  price: 'Price',
  total: 'Total',
});

export const commonFr = t({
  settings: 'Paramètres',
  add: 'Ajouter',
  all: 'Tout',
  remove: 'Supprimer',
  name: 'Nom',
  number: 'Numéro',
  search: 'Recherche',
  sort: 'Trier',
  clear: 'Effacer',
  no_data: 'Aucune donnée',
  vat: 'TVA',
  note: 'Remarque',
  configure: 'Configurer',
  filter: 'Filtrer',
  confirm: 'Confirmer',
  font_color: 'Couleur de police',
  font_size: 'Taille de police',
  cancel: 'Annuler',
  history: 'Historique',
  current_entry: 'Entrée actuelle',
  deleted_entries: 'Entrées supprimées',
  date: 'Date',
  brutto: 'Brut',
  avatar: 'Avatar',
  net: 'Net',
  shown: 'Affiché',
  show_all: 'Afficher tout',
  user: 'Utilisateur',
  assist: 'Assister',
  cash: 'Espèces',
  wawi: 'Wawi',
  rezept: 'Recette',
  smart: 'Intelligent',
  picking: 'Prélèvement',
  restaurant: 'Restaurant',
  manager: 'Gestionnaire',
  run: 'Exécuter',
  my_resto: 'MonResto',
  console: 'Console',
  grouping: 'Regroupement',
  date_time: 'Date/Heure',
  operation: 'Opération',
  revert_changes: 'Annuler les modifications',
  username: 'Nom d’utilisateur',
  field: 'Champ',
  more_functions: 'Plus de fonctions',
  article_no: 'N° d’article',
  delivery_no: 'N° de commande',
  old_value: 'Ancienne valeur',
  new_value: 'Nouvelle valeur',
  cash_register_id: 'ID de caisse',
  cashassist: 'CashAssist',
  delivery_method: 'Méthode de livraison',
  receipt_no: 'N° de reçu',
  cashassist_user: 'Utilisateur CashAssist',
  successfully_saved: 'Enregistré avec succès',
  successfully_deleted: 'Supprimé avec succès',
  content: 'Contenu',
  lot_quantity: 'La valeur doit être un multiple du nombre de lots',
  not_empty: 'Entrée requise',
  only_numbers: 'Ne doit contenir que des chiffres',
  valid_emails: 'Veuillez entrer des emails valides.',
  value_between: 'La valeur doit être comprise entre {{min}} et {{max}}',
  value_less: 'La valeur doit être inférieure à {{max}}',
  value_greater: 'La valeur doit être supérieure à {{min}}',
  no_data_in_global_search: 'Votre recherche - {{searchString}} - n’a donné aucun résultat',
  loading: 'Chargement...',
  description: 'Description',
  activated: 'Activé',
  search_failed: 'La recherche a échoué',
  search_result_for: 'Résultat de recherche pour',
  permitted: 'Autorisé',
  rules_is_not_set: 'Règles non définies',
  regional_datetime_entered_day_of_week: 'Chaque {{days}}',
  in_period: 'en période',
  repeat_yearly: 'Répéter chaque année',
  in_periods: 'dans les périodes {{periods}}',
  vacation: 'Vacances',
  not_delivered: 'Non livré',
  from_date: 'À partir de la date',
  to_date: 'À la date',
  yearly: 'Annuel',
  change: 'Changer',
  fri: 'Ve',
  mon: 'Lu',
  sat: 'Sa',
  sun: 'Di',
  thu: 'Je',
  tue: 'Ma',
  wed: 'Me',
  forbidden: 'Interdit',
  address: 'Adresse',
  enter_delivery_address: 'Entrez l’adresse de livraison',
  empty: 'Vide',
  export: 'Exporter',
  print: 'Imprimer',
  email: 'Email',
  delivery_note: 'Bon de livraison',
  invoice: 'Facture',
  preview: 'Aperçu',
  successfully: 'Avec succès',
  server_error: 'Erreur serveur',
  multiselect: 'Sélection multiple',
  proceed_anyway: 'Continuer quand même',
  warning: 'Avertissement',
  current_month: 'Mois actuel',
  last_month: 'Le mois dernier',
  date_range: 'Plage de dates',
  customer: 'Client',
  customer_no: 'N° client',
  customers_group: 'Groupe de clients',
  customers_list: 'Liste de clients',
  one_time_customers: 'Client ponctuel',
  open_emails: 'Emails non envoyés',
  sending_emails: 'Envoi des emails en cours',
  sent_emails: 'Emails envoyés',
  show_deleted: 'Afficher supprimés',
  from: 'De',
  to: 'À',
  pdf_history: 'Historique PDF',
  pdfs_versions: 'Historique des PDFs',
  created: 'Créé',
  report: 'Rapport',
  ok: 'Ok',
  invalid_data_warning:
    'Les données sont invalides. Vos modifications seront perdues. Voulez-vous continuer?',
  sending: 'Envoi...',
  save: 'Enregistrer',
  send: 'Envoyer',
  email_sent_successfully: 'Email envoyé avec succès',
  email_sent_successfully_other: 'Emails envoyés avec succès',
  problem_while_sending_email: 'Problème lors de l’envoi de l’email',
  problem_while_sending_email_other: 'Problème lors de l’envoi des emails',
  order: 'Commande',
  offer: 'Offres',
  recipient: 'Destinataire',
  regarding: 'Concernant',
  message: 'Message',
  facilities: 'Installations',
  number_short: 'N°',
  offline: 'Vous êtes hors ligne',
  internet_restored: 'Connexion internet rétablie',
  new_version_available: 'Nouvelle version de l’application disponible',
  update: 'Mettre à jour',
  session_expired: 'Session expirée',
  sign_out: 'Se déconnecter',
  signed_out_refresh: 'Vous êtes déconnecté. Actualisez la page pour continuer à travailler.',
  refresh: 'Rafraîchir',
  reload_page: 'Recharger la page',
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  saturday: 'Samedi',
  sunday: 'Dimanche',
  replace: 'Remplacer',
  weekAgo: 'Il y a une semaine',
  twoWeeksAgo: 'Il y a deux semaines',
  threeWeeksAgo: 'Il y a trois semaines',
  error_customer_info_not_loaded: 'Les informations du client n’ont pas été chargées!',
  error_orders_not_loaded: 'La commande n’a pas été chargée!',
  error_customer_list_not_loaded: 'La liste des clients n’a pas été chargée!',
  error_chnages_not_saved: 'Erreur. Vos modifications n’ont pas été enregistrées.',
  changes_you_made_were_not_saved:
    'Les modifications que vous avez faites n’ont pas été enregistrées. Voulez-vous continuer?',
  all_lists: 'Toutes les listes',
  default_settings: 'Paramètres par défaut',
  additional: 'Supplémentaire',
  days: 'Jours',
  enter_valid_email: 'Veuillez entrer une adresse email correcte',
  update_browser_heading: 'Mettez à jour votre navigateur',
  update_browser_message:
    'Malheureusement, vous utilisez un navigateur obsolète.<br />Veuillez mettre à jour votre navigateur pour améliorer votre expérience et votre sécurité.',
  link_current_browser_versions: 'Liens vers les versions actuelles des navigateurs:',
  max: 'Max',
  min: 'Min',
  category: 'Catégorie',
  close: 'Fermer',
  apply: 'Appliquer',
  amount: 'Montant',
  discount: 'Remise',
  delete: 'Supprimer',
  emails: 'Emails',
  do_you_want_unbind_and_delete:
    '{{dictionary}} ne peut pas être supprimé. Il est utilisé pour les {{lists}} suivants. Voulez-vous les dissocier des {{lists}} et supprimer ce {{dictionary}}?',
  mailing_message:
    'L’envoi des emails se fait dans un processus distinct.\n \nUne fois le processus terminé, vous\n \nrecevrez une notification.',
  emails_sent_successfully: 'Emails envoyés avec succès: {{successCount}}',
  emails_failed_to_send: 'Emails non envoyés: {{failureCount}}',
  error_while_sending_emails:
    'Une erreur s’est produite lors de l’envoi des emails. Veuillez réessayer plus tard.',
  previous_record: 'Enregistrement précédent',
  next_record: 'Enregistrement suivant',
  quantity: 'Quantité',
  yes: 'Oui',
  no: 'Non',
  new_record: 'Nouvel enregistrement',
  report_is_generating: 'Le rapport est en cours de génération. Veuillez réessayer plus tard',
  difference: 'Différence',
  discard_your_changes: 'Annuler vos modifications?',
  discard: 'Annuler',
  list_are_not_configured: 'La liste n’est pas configurée',
  click_button_above_to_configure: 'Cliquez sur le bouton <icon /> ci-dessus pour configurer',
  track: 'Suivre',
  sender_address: 'Adresse de l’expéditeur',
  receiver_address: 'Adresse du destinataire',
  subject: 'Objet',
  diagnostic_information: 'Informations de diagnostic',
  delivery_information_is_not_yet_available:
    'Les informations de livraison ne sont pas encore disponibles, veuillez réessayer plus tard!',
  invoice_dates_range: 'Plage de dates des factures',
  data_processing: 'Traitement des données...',
  version: 'Version',
  price: 'Prix',
  total: 'Total',
});

export const commonIt = t({
  settings: 'Impostazioni',
  add: 'Aggiungi',
  all: 'Tutto',
  remove: 'Rimuovi',
  name: 'Nome',
  number: 'Numero',
  search: 'Cerca',
  sort: 'Ordina',
  clear: 'Pulisci',
  no_data: 'Nessun dato',
  vat: 'IVA',
  note: 'Nota',
  configure: 'Configura',
  filter: 'Filtro',
  confirm: 'Conferma',
  font_color: 'Colore del carattere',
  font_size: 'Dimensione del carattere',
  cancel: 'Annulla',
  history: 'Cronologia',
  current_entry: 'Voce corrente',
  deleted_entries: 'Voci eliminate',
  date: 'Data',
  brutto: 'Lordo',
  avatar: 'Avatar',
  net: 'Netto',
  shown: 'Mostrato',
  show_all: 'Mostra tutto',
  user: 'Utente',
  assist: 'Assistenza',
  cash: 'Contanti',
  wawi: 'WaWi',
  rezept: 'Ricetta',
  smart: 'Intelligente',
  picking: 'Picking',
  restaurant: 'Ristorante',
  manager: 'Gestore',
  run: 'Esegui',
  my_resto: 'MyResto',
  console: 'Console',
  grouping: 'Raggruppamento',
  date_time: 'Data/Ora',
  operation: 'Operazione',
  revert_changes: 'Annulla modifiche',
  username: 'Nome utente',
  field: 'Campo',
  more_functions: 'Altre funzioni',
  article_no: 'N. Articolo',
  delivery_no: 'N. Ordine',
  old_value: 'Vecchio valore',
  new_value: 'Nuovo valore',
  cash_register_id: 'ID Cassa',
  cashassist: 'CashAssist',
  delivery_method: 'Metodo di consegna',
  receipt_no: 'N. Ricevuta',
  cashassist_user: 'Utente CashAssist',
  successfully_saved: 'Salvato con successo',
  successfully_deleted: 'Eliminato con successo',
  content: 'Contenuto',
  lot_quantity: 'Il valore deve essere un multiplo del numero di lotti',
  not_empty: 'Inserimento richiesto',
  only_numbers: 'Deve contenere solo numeri',
  valid_emails: 'Inserisci email valide.',
  value_between: 'Il valore deve essere compreso tra {{min}} e {{max}}',
  value_less: 'Il valore deve essere inferiore a {{max}}',
  value_greater: 'Il valore deve essere maggiore di {{min}}',
  no_data_in_global_search: 'La tua ricerca - {{searchString}} - non ha prodotto risultati',
  loading: 'Caricamento...',
  description: 'Descrizione',
  activated: 'Attivato',
  search_failed: 'Ricerca fallita',
  search_result_for: 'Risultato della ricerca per',
  permitted: 'Permesso',
  rules_is_not_set: 'Le regole non sono impostate',
  regional_datetime_entered_day_of_week: 'Ogni {{days}}',
  in_period: 'nel periodo',
  repeat_yearly: 'Ripeti ogni anno',
  in_periods: 'nei periodi {{periods}}',
  vacation: 'Vacanza',
  not_delivered: 'Non consegnato',
  from_date: 'Dalla data',
  to_date: 'Alla data',
  yearly: 'Annuale',
  change: 'Modifica',
  fri: 'Ve',
  mon: 'Lu',
  sat: 'Sa',
  sun: 'Do',
  thu: 'Gi',
  tue: 'Ma',
  wed: 'Me',
  forbidden: 'Vietato',
  address: 'Indirizzo',
  enter_delivery_address: "Inserisci l'indirizzo di consegna",
  empty: 'Vuoto',
  export: 'Esporta',
  print: 'Stampa',
  email: 'Email',
  delivery_note: 'Bolla di consegna',
  invoice: 'Fattura',
  preview: 'Anteprima',
  successfully: 'Con successo',
  server_error: 'Errore del server',
  multiselect: 'Selezione multipla',
  proceed_anyway: 'Procedi comunque',
  warning: 'Avviso',
  current_month: 'Mese corrente',
  last_month: 'Mese scorso',
  date_range: 'Intervallo di date',
  customer: 'Cliente',
  customer_no: 'N. Cliente',
  customers_group: 'Gruppo clienti',
  customers_list: 'Elenco clienti',
  one_time_customers: 'Cliente occasionale',
  open_emails: 'Email non inviate',
  sending_emails: 'Invio delle email in corso',
  sent_emails: 'Email inviate',
  show_deleted: 'Mostra eliminati',
  from: 'Da',
  to: 'A',
  pdf_history: 'Storico PDF',
  pdfs_versions: 'Versioni PDF',
  created: 'Creato',
  report: 'Rapporto',
  ok: 'Ok',
  invalid_data_warning: 'Dati non validi. Le tue modifiche verranno perse. Vuoi procedere?',
  sending: 'Invio...',
  save: 'Salva',
  send: 'Invia',
  email_sent_successfully: 'Email inviata con successo',
  email_sent_successfully_other: 'Email inviate con successo',
  problem_while_sending_email: "Problema durante l'invio dell'email",
  problem_while_sending_email_other: "Problema durante l'invio delle email",
  order: 'Ordine',
  offer: 'Offerta',
  recipient: 'Destinatario',
  regarding: 'Riguardo',
  message: 'Messaggio',
  facilities: 'Strutture',
  number_short: 'N.',
  offline: 'Sei offline',
  internet_restored: 'Connessione a Internet ripristinata',
  new_version_available: "Nuova versione dell'app disponibile",
  update: 'Aggiorna',
  session_expired: 'Sessione scaduta',
  sign_out: 'Disconnetti',
  signed_out_refresh: 'Sei stato disconnesso. Aggiorna la pagina per continuare.',
  refresh: 'Aggiorna',
  reload_page: 'Ricarica la pagina',
  monday: 'Lunedì',
  tuesday: 'Martedì',
  wednesday: 'Mercoledì',
  thursday: 'Giovedì',
  friday: 'Venerdì',
  saturday: 'Sabato',
  sunday: 'Domenica',
  replace: 'Sostituisci',
  weekAgo: 'Una settimana fa',
  twoWeeksAgo: 'Due settimane fa',
  threeWeeksAgo: 'Tre settimane fa',
  error_customer_info_not_loaded: 'Le informazioni del cliente non sono state caricate!',
  error_orders_not_loaded: "L'ordine non è stato caricato!",
  error_customer_list_not_loaded: "L'elenco clienti non è stato caricato!",
  error_chnages_not_saved: 'Errore. Le tue modifiche non sono state salvate.',
  changes_you_made_were_not_saved: 'Le modifiche apportate non sono state salvate. Vuoi procedere?',
  all_lists: 'Tutti gli elenchi',
  default_settings: 'Impostazioni predefinite',
  additional: 'Aggiuntivo',
  days: 'Giorni',
  enter_valid_email: 'Inserisci un indirizzo email corretto',
  update_browser_heading: 'Aggiorna il tuo browser',
  update_browser_message:
    "Purtroppo stai utilizzando un browser obsoleto.<br />Aggiorna il tuo browser per migliorare l'esperienza e la sicurezza.",
  link_current_browser_versions: 'Link delle versioni attuali del browser:',
  max: 'Max',
  min: 'Min',
  category: 'Categoria',
  close: 'Chiudi',
  apply: 'Applica',
  amount: 'Importo',
  discount: 'Sconto',
  delete: 'Elimina',
  emails: 'Email',
  do_you_want_unbind_and_delete:
    '{{dictionary}} non può essere eliminato. È utilizzato nei seguenti {{lists}}. Vuoi scollegarli dai {{lists}} e eliminare questo {{dictionary}}?',
  mailing_message:
    "L'invio delle email viene effettuato in un processo separato.\n \nDopo il completamento del processo riceverai\n \nuna notifica.",
  emails_sent_successfully: 'Email inviate con successo: {{successCount}}',
  emails_failed_to_send: 'Email non inviate: {{failureCount}}',
  error_while_sending_emails:
    "Si è verificato un errore durante l'invio delle email. Riprova più tardi.",
  previous_record: 'Record precedente',
  next_record: 'Record successivo',
  quantity: 'Quantità',
  yes: 'Sì',
  no: 'No',
  new_record: 'Nuovo record',
  report_is_generating: 'Il rapporto è in fase di generazione. Riprova più tardi',
  difference: 'Differenza',
  discard_your_changes: 'Scartare le tue modifiche?',
  discard: 'Scarta',
  list_are_not_configured: 'La lista non è configurata',
  click_button_above_to_configure: 'Clicca sul pulsante <icon /> sopra per configurare',
  track: 'Traccia',
  sender_address: 'Indirizzo del mittente',
  receiver_address: 'Indirizzo del destinatario',
  subject: 'Oggetto',
  diagnostic_information: 'Informazioni diagnostiche',
  delivery_information_is_not_yet_available:
    'Le informazioni di consegna non sono ancora disponibili, riprova più tardi!',
  invoice_dates_range: 'Intervallo date fattura',
  data_processing: 'Elaborazione dati...',
  version: 'Versione',
  price: 'Prezzo',
  total: 'Totale',
});
