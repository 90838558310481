import { Observable, map, switchMap } from 'rxjs';
import { Pub, Service, Sub } from '../../../../../services/service.abstract';
import { ListHs_AppsQuery } from '../../../../../../graphql/generatedModel';
import { gqlClient } from '../../../../../../graphql/graphqlRequest';
import { getHsAppsList } from './gql/topBar.gql';
type Actions = 'tilte' | 'hsAppsList';
class PubImpl extends Pub<Actions> {
  title(title: string): void {
    this.emit('tilte', title);
  }
  hsAppsList(): void {
    this.emit('hsAppsList');
  }
}
class SubImpl extends Sub<Actions> {
  title(): Observable<string> {
    return this.actionListener('tilte').pipe(
      map(({ params }) => {
        return params;
      }),
    );
  }

  hsAppsList(): Observable<HsAppsListRes> {
    return this.actionListener('hsAppsList').pipe(
      switchMap(() => {
        return gqlClient(getHsAppsList) as Observable<ListHs_AppsQuery>;
      }),
      map((data) => {
        return data.wawiAssist?.listHS_Apps as HsAppsListRes;
      }),
    );
  }
}
class TopBarService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const topbarService = new TopBarService();

export type HsAppsListRes = NonNullable<NonNullable<ListHs_AppsQuery['wawiAssist']>['listHS_Apps']>;
