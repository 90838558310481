import { ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  AutocompleteProps,
  FilterOptionsState,
  createFilterOptions,
  CircularProgress,
  styled,
} from '@mui/material';

import { TextField } from './textField.component';
import { ListboxComponent, StyledPopper } from '../../virtualization/virtualization.component';

const SelectBase = forwardRef<HTMLDivElement, ISelectBaseProps>(
  (
    { shrink, label, width, disableTextInput, required, error, helperText, options, ...props },
    ref,
  ) => {
    options.forEach((el) => {
      el.label = el.label || '';
    });
    const { t } = useTranslation();
    const { autoFocus, fontSize } = props;

    return (
      <Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            label={label}
            {...(props.name && {
              name: props.name,
            })}
            InputLabelProps={{ shrink: true }}
            error={error}
            helperText={helperText}
            FormHelperTextProps={{
              sx: { marginLeft: 0 },
            }}
            {...(!props.multiple && {
              sx: {
                '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
                  visibility: 'visible',
                },
              },
            })}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {props.loading && <CircularProgress size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              ...(disableTextInput && { readOnly: true }),
            }}
            {...{ autoFocus, required }}
          />
        )}
        fullWidth
        sx={{
          width: '100%',
          maxWidth: (theme) =>
            width || (shrink ? theme.form.fieldShrinkWidth : theme.form.fieldWidth),
          '& .MuiFormHelperText-root': {
            marginLeft: 0,
          },
        }}
        clearText={t('common.clear')}
        noOptionsText={t('common.no_data')}
        renderOption={(props, option) => (
          <SList
            title={option.label}
            style={{ ...(fontSize && { fontSize }) }}
            {...props}
            key={option.id}
          >
            <span>{option.label}</span>
          </SList>
        )}
        ref={ref}
        options={options}
        {...props}
      />
    );
  },
);

export const Select = forwardRef<HTMLDivElement, ISelectProps>((props, ref) => {
  const { fontSize, datasetattribute } = props;
  return (
    <SelectBase
      {...props}
      renderOption={(props, option) =>
        [
          {
            ...props,
            style: {
              ...(fontSize && { fontSize }),
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: 'inline-block',
            },
            title: option.label,
          },
          option,
          datasetattribute,
        ] as ReactNode
      }
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      onChange={(e, v, r) => props.onChange?.(e, v as ISelectOption | null, r)}
      ref={ref}
    />
  );
});
export const SelectMultiple = forwardRef<HTMLDivElement, ISelectMultipleProps>((props, ref) => (
  <SelectBase
    {...props}
    onChange={(e, v, r) => props.onChange?.(e, v as ISelectOption[], r)}
    multiple
    ref={ref}
  />
));
export const SelectFreeSolo = forwardRef<HTMLDivElement, ISelectFreeSoloProps>((props, ref) => {
  const { t } = useTranslation();
  const filter = createFilterOptions<IFreeSoloOption>();

  const onFilterOptions = (
    options: ISelectOption[],
    state: FilterOptionsState<ISelectOption>,
  ): ISelectOption[] => {
    const filtered = filter(options, state);
    const { inputValue } = state;
    const isExisting = options.some((option) => inputValue === option.label);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        label: `${t('common.add')}: "${inputValue}"`,
        id: inputValue,
      });
    }
    return filtered;
  };

  return (
    <SelectBase
      {...props}
      onChange={(e, v, r) => props.onChange?.(e, v as ISelectOption | null, r)}
      getOptionLabel={(option: string | IFreeSoloOption): string =>
        typeof option === 'string' ? option : option.inputValue || option.label
      }
      filterOptions={onFilterOptions}
      renderOption={(props, option) => (
        <SList
          title={option.label}
          {...props}
          key={Object.getOwnPropertyDescriptor(props, 'data-option-index')?.value}
        >
          <span>{option.label}</span>
        </SList>
      )}
      selectOnFocus
      freeSolo
      ref={ref}
    />
  );
});

type TSelectSingle = AutocompleteProps<ISelectOption, false, false, false>;
type TSelectMultiple = AutocompleteProps<ISelectOption, true, false, false>;
type TSelectFreeSolo = AutocompleteProps<ISelectOption, false, true | false, false>;

export interface ISelectOption {
  id: string | number;
  label: string;
  disabled?: boolean;
}
interface ISelectBaseProps
  extends Omit<
    AutocompleteProps<ISelectOption, true | false, true | false, true | false>,
    'renderInput'
  > {
  label?: string;
  shrink?: boolean;
  width?: string;
  disableTextInput?: boolean;
  required?: boolean;
  fontSize?: string;
  error?: boolean;
  helperText?: string;
  datasetattribute?: string;
  name?: string;
}
export interface IFreeSoloOption extends ISelectOption {
  inputValue?: string;
}
export interface ISelectProps extends Omit<ISelectBaseProps, 'value' | 'onChange' | 'multiple'> {
  value?: TSelectSingle['value'];
  onChange?: TSelectSingle['onChange'];
}
export interface ISelectMultipleProps
  extends Omit<ISelectBaseProps, 'value' | 'onChange' | 'multiple'> {
  value?: TSelectMultiple['value'];
  onChange?: TSelectMultiple['onChange'];
}
export interface ISelectFreeSoloProps
  extends Omit<ISelectBaseProps, 'value' | 'onChange' | 'multiple'> {
  value?: TSelectFreeSolo['value'];
  onChange?: TSelectFreeSolo['onChange'];
}

const SList = styled('li')({
  '& > span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
});
