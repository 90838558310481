import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper';

type Keys =
  | 'customer'
  | 'customers'
  | 'create_customer'
  | 'new_customer'
  | 'number'
  | 'customer_active'
  | 'surname'
  | 'general'
  | 'availability'
  | 'standardOrder'
  | 'type_of_address'
  | 'group'
  | 'delivery_note_group'
  | 'address'
  | 'salutation'
  | 'additive'
  | 'internal_name'
  | 'street'
  | 'post_box'
  | 'zip_code'
  | 'city'
  | 'country'
  | 'delivery_address'
  | 'contacts'
  | 'contact_person'
  | 'phone_business'
  | 'phone_private'
  | 'mobile'
  | 'fax'
  | 'refresh_customer'
  | 'recalculate'
  | 'recalculate_orders_for_customers'
  | 'refresh_prices'
  | 'refresh_conditions_rebates_net_etc'
  | 'refresh_just_cashAssist_receipts'
  | 'recalculated_orders_for_customers'
  | 'not_refreshed'
  | 'email_for_invoices'
  | 'website'
  | 'lists'
  | 'customer_groups'
  | 'tourplans'
  | 'print_form'
  | 'print_forms'
  | 'summary_form'
  | 'payment_slip_form'
  | 'copy'
  | 'copy_other'
  | 'copy_customer'
  | 'delivery_note_comments'
  | 'invoice_comments'
  | 'additional_comments'
  | 'e_bill_account_id'
  | 'select_default_setting'
  | 'invalid_data_warning'
  | 'enter_valid_email'
  | 'customer_saved'
  | 'overview'
  | 'availabilitySetting'
  | 'allProductGroups'
  | 'setUpStation'
  | 'setUpStationSat'
  | 'setUpStationSun'
  | 'standardTour'
  | 'drivingPos'
  | 'deliveryTime'
  | 'saturdayTour'
  | 'sundayTour'
  | 'addInTourIfNoDelivery'
  | 'tour'
  | 'cashAssist'
  | 'canBuyOnCreditOnCashAssist'
  | 'takeReductionsAndPricesFrom'
  | 'defaultOrder'
  | 'preset'
  | 'createAutomaticallyFromPresetting'
  | 'editing'
  | 'conditions'
  | 'price_and_discount'
  | 'priceCategories'
  | 'discountKind'
  | 'discountPercent'
  | 'quantityDiscountValue'
  | 'discountType'
  | 'articlePrice'
  | 'accounting'
  | 'debtor'
  | 'customerIsInvoicedWith'
  | 'payment'
  | 'bank'
  | 'paymentDeadline'
  | 'termOfPaymentInDays'
  | 'individual'
  | 'cashDiscount'
  | 'cashDiscountIn'
  | 'debtorAccount'
  | 'slipReference'
  | 'returnLimitIn'
  | 'debit'
  | 'bankName'
  | 'bin'
  | 'accountNo'
  | 'iban'
  | 'bic'
  | 'specialDsicountCashAssist'
  | 'mandatNumber'
  | 'dateOfMandatSignature'
  | 'changesProhibited'
  | 'orderLimit'
  | 'addNewRecord'
  | 'deleteRecord'
  | 'deliverySplittingConfig'
  | 'tourNote'
  | 'partialDelivery'
  | 'numberOfPieces'
  | 'enablePartialDelivery'
  | 'part'
  | 'checkSplitting'
  | 'defaultOrder_saved'
  | 'defaultOrderItems'
  | 'articlesAvailabilityOn'
  | 'individual_payment_conditions'
  | 'bank_abbreviation'
  | 'vat_system'
  | 'invalid_value'
  | 'useDefOrderFromCustomer'
  | 'articlePrices'
  | 'stdPrice'
  | 'noDiscount'
  | 'fromQuantity'
  | 'kind'
  | 'comment'
  | 'individualPrices'
  | 'article'
  | 'shipping_costs_sector'
  | 'offer'
  | 'distribution_limits'
  | 'flexible_orders'
  | 'copy_default_order';

const t = LocalizationHelper<Keys>('customer');

export const customerDe = t({
  customer: 'Kunde',
  customers: 'Kunden',
  create_customer: 'Kunde erstellen',
  copy_customer: 'Kunde kopien',
  new_customer: 'Neukunde',
  surname: 'Nachname',
  number: 'Nummer',
  general: 'Allgemein',
  customer_active: 'Kunde ist aktiv',
  type_of_address: 'Art der Adresse',
  group: 'Gruppe',
  delivery_note_group: 'Lieferscheingruppe',
  address: 'Adresse',
  salutation: 'Anrede',
  additive: 'Zusatz',
  internal_name: 'Interner Name',
  street: 'Strasse',
  post_box: 'Postfach',
  zip_code: 'PLZ/Ort',
  availability: 'Verfügbarkeit',
  city: 'Stadt',
  discountKind: 'Rabattart',
  discountPercent: 'Rabatt %',
  quantityDiscountValue: 'Mengenrabattwert',
  country: 'Land',
  delivery_address: 'Lieferschein Adresse',
  contacts: 'Kontakte',
  contact_person: 'Ansprechperson',
  phone_business: 'Telefon Geschäftlich',
  phone_private: 'Telefon Privat',
  mobile: 'Mobile',
  fax: 'Fax',
  refresh_customer: 'Aktualisieren Kunde',
  recalculate: 'Neuberechnen',
  recalculate_orders_for_customers: 'Neuberechnung von Bestellung für Kunden',
  refresh_prices: 'Preise aktualisieren',
  refresh_conditions_rebates_net_etc: 'Konditionen aktualisieren (Rabatte, Netto usw.)',
  refresh_just_cashAssist_receipts: 'Nur CashAssist-Belege aktualisieren',
  recalculated_orders_for_customers:
    'Neuberechnung der Bestellung für {{customersQuantity}} Kunden',
  not_refreshed: 'Nicht aufgefrischt',
  email_for_invoices: 'Email für Rechnungen',
  website: 'Website',
  lists: 'Listen',
  customer_groups: 'Kundengruppen',
  tourplans: 'Touren',
  print_form: 'Formular',
  print_forms: 'Formulare',
  summary_form: 'Zusammenzug Formular',
  payment_slip_form: 'Einzahlungsschein Formular',
  copy: 'Kopie',
  copy_other: 'Kopien',
  delivery_note_comments: 'Individuelle Bemerkungen auf dem Lieferschein',
  invoice_comments: 'Individuelle Bemarkungen auf der Rechnung',
  additional_comments: 'Zusätzliche Bemerkung',
  e_bill_account_id: 'E-Bill Account ID',
  select_default_setting: 'Kunden Standardeinstellung',
  invalid_data_warning:
    'Sie haben einen Fehler in Ihren Daten. Ihre Änderungen gehen verloren. Möchten Sie fortfahren?',
  enter_valid_email: 'Geben Sie eine gültige Email Adresse ein',
  customer_saved: 'Kunde wurde erfolgreich gespeichert',
  standardOrder: 'Standardreihenfolge',
  overview: 'Überblick',
  availabilitySetting: 'Verfügbarkeit Einstellung',
  allProductGroups: 'Alle Sortimentsgruppen',
  setUpStation: 'Rüstplatz',
  setUpStationSat: 'Rüstplatz Sa',
  setUpStationSun: 'Rüstplatz So',
  standardTour: 'Standard Tour',
  drivingPos: 'Anfahrt Pos.',
  deliveryTime: 'Lieferzeit',
  saturdayTour: 'Sa. Tour',
  sundayTour: 'So. Tour',
  addInTourIfNoDelivery: 'Wenn keine Lieferung aufführen',
  tour: 'Tour',
  cashAssist: 'CashAssist',
  canBuyOnCreditOnCashAssist: 'Kreditkunde CashAssist',
  takeReductionsAndPricesFrom: 'Rabatte und Preise übernehmen von ...',
  defaultOrder: 'Vorgabebestellung',
  preset: 'Voreinstellung',
  createAutomaticallyFromPresetting: 'Fixbestellung (aus Vorgabe)',
  editing: 'Bearbeitung',
  conditions: 'Konditionen',
  price_and_discount: 'Preis und Rabatt',
  priceCategories: 'Preiskategorie',
  discountType: 'Rabatt typ',
  articlePrice: 'Artikel-Preise',
  accounting: 'Abrechnung',
  debtor: 'Debitor-Status',
  customerIsInvoicedWith: 'Dieser Kunde wird fakturiert mit',
  payment: 'Zahlung',
  bank: 'Bank',
  paymentDeadline: 'Zahlungsziel',
  termOfPaymentInDays: 'Zahlungsziel in Tagen',
  individual: 'Individuelle',
  cashDiscount: 'Skonto',
  cashDiscountIn: 'Skonto in %',
  debtorAccount: 'Debitorenkonto',
  slipReference: 'Beleg-Referenz',
  returnLimitIn: 'Retourenbegrenzung in %',
  debit: 'Belastung',
  bankName: 'Bankname',
  bin: 'BLZ',
  accountNo: 'Konto-Nr.',
  iban: 'IBAN',
  bic: 'BIC',
  specialDsicountCashAssist: 'Sonderrabatt CashAssist (%)',
  mandatNumber: 'Mandat Nummer',
  dateOfMandatSignature: 'Datum der Unterschrift des Mandats',
  changesProhibited: 'Änderungen sperren',
  orderLimit: 'Bestellbegrenzung',
  addNewRecord: 'Neuen Eintrag hinzufügen',
  deleteRecord: 'Eintrag löschen',
  deliverySplittingConfig: 'Konfiguration für Bestellaufteilung',
  tourNote: 'Tourbemerkung',
  partialDelivery: 'Teillieferung',
  numberOfPieces: 'Anzahl der Teile',
  enablePartialDelivery: 'Teillieferung aktivieren',
  part: 'Anf.',
  checkSplitting: 'Splitting prüfen',
  defaultOrder_saved: 'Vorgabebestellung wurde erfolgreich gespeichert',
  defaultOrderItems: 'Vorgabebestellung Positionen',
  articlesAvailabilityOn: 'Artikelverfügbarkeit auf',
  individual_payment_conditions: 'Individuelle Zahlungskonditionen',
  bank_abbreviation: 'BESR/VESR Bank',
  vat_system: 'MwSt-System',
  invalid_value: 'Ungültiger Wert',
  useDefOrderFromCustomer: 'Vorgabebestellung von Kunde verwenden',
  articlePrices: 'Artikel-Preise',
  stdPrice: 'Std. Preis',
  noDiscount: 'Nettoartikel',
  fromQuantity: 'Ab Menge',
  kind: 'Art',
  comment: 'Bemerkung',
  individualPrices: 'Individuelle Preise',
  article: 'Artikel',
  shipping_costs_sector: 'Versandkosten Bereich',
  offer: 'Angebot',
  distribution_limits: 'Verteilungslimit',
  flexible_orders: 'Flex Bestellung',
  copy_default_order: 'Standardreihenfolge kopieren',
});

export const customerEn = t({
  customer: 'Customer',
  customers: 'Customers',
  create_customer: 'Create customer',
  copy_customer: 'Copy customer',
  new_customer: 'New customer',
  surname: 'Surname',
  number: 'Number',
  general: 'General',
  customer_active: 'Customer is active',
  type_of_address: 'Type of address',
  group: 'Group',
  delivery_note_group: 'Delivery note group',
  address: 'Address',
  salutation: 'Salutation',
  additive: 'Additive',
  internal_name: 'Internal name',
  street: 'Street',
  post_box: 'Post office box',
  zip_code: 'ZIP',
  city: 'City',
  refresh_customer: 'Refresh customer',
  recalculate: 'Recalculate',
  recalculate_orders_for_customers: 'Recalculate orders for customers',
  refresh_prices: 'Refresh prices',
  refresh_conditions_rebates_net_etc: 'Refresh conditions (rebates, net etc.)',
  refresh_just_cashAssist_receipts: 'Refresh just CashAssist receipts',
  recalculated_orders_for_customers: 'Recalculated orders for {{customersQuantity}} customers',
  not_refreshed: 'Not refreshed',
  availability: 'Availability',
  country: 'Country',
  delivery_address: 'Delivery address',
  contacts: 'Contacts',
  contact_person: 'Contact person',
  phone_business: 'Phone business',
  phone_private: 'Phone private',
  mobile: 'Mobile',
  fax: 'Fax',
  discountKind: 'Discount kind',
  quantityDiscountValue: 'Quantity discount value',
  discountPercent: 'Discount %',
  email_for_invoices: 'Email for invoices',
  website: 'Website',
  lists: 'Lists',
  customer_groups: 'Customer groups',
  tourplans: 'Tourplans',
  print_form: 'Print form',
  print_forms: 'Print forms',
  summary_form: 'Summary form',
  payment_slip_form: 'Payment slip form',
  copy: 'Copy',
  copy_other: 'Copies',
  delivery_note_comments: 'Individual comments on the delivery note',
  invoice_comments: 'Individual comments on the invoice',
  additional_comments: 'Additional comments',
  e_bill_account_id: 'E-Bill Account ID',
  select_default_setting: 'Customer default setting',
  invalid_data_warning:
    'You have an error in your data. Your changes will be lost. Do you want to proceed?',
  enter_valid_email: 'Please enter a correct email address',
  customer_saved: 'Customer was successfully saved',
  standardOrder: 'Standard order',
  overview: 'Overview',
  availabilitySetting: 'Availability setting',
  allProductGroups: 'All assortment groups',
  setUpStation: 'Set-up station',
  setUpStationSat: 'Set-up station Sat',
  setUpStationSun: 'Set-up station Sun',
  standardTour: 'Standard tour',
  drivingPos: 'Driving pos.',
  deliveryTime: 'Delivery time',
  saturdayTour: 'Sat. tour',
  sundayTour: 'Sun. tour',
  addInTourIfNoDelivery: 'Add in tour if no delivery',
  tour: 'Tour',
  cashAssist: 'CashAssist',
  canBuyOnCreditOnCashAssist: 'Credit customer CashAssist',
  takeReductionsAndPricesFrom: 'Take reductions and prices from ...',
  defaultOrder: 'Default order',
  preset: 'Preset',
  createAutomaticallyFromPresetting: 'Create automatically from presetting',
  editing: 'Editing',
  conditions: 'Conditions',
  price_and_discount: 'Price and discount',
  priceCategories: 'Price category',
  discountType: 'Discount type',
  articlePrice: 'Article price',
  accounting: 'Accounting',
  debtor: 'Debtor status',
  customerIsInvoicedWith: 'This customer is invoiced with',
  payment: 'Payment',
  bank: 'Bank',
  paymentDeadline: 'Payment deadline',
  termOfPaymentInDays: 'Payment periods in days',
  individual: 'Individual',
  cashDiscount: 'Cash discount',
  cashDiscountIn: 'Skonto in %',
  debtorAccount: 'Debtor account',
  slipReference: 'Slip reference',
  returnLimitIn: 'Return limit in %',
  debit: 'Debit',
  bankName: 'Bank name',
  bin: 'BIN',
  accountNo: 'Account No.',
  iban: 'IBAN',
  bic: 'BIC',
  specialDsicountCashAssist: 'Credit customer Special discount CashAssist (%)',
  mandatNumber: 'Mandate number',
  dateOfMandatSignature: 'Date of mandate signature',
  changesProhibited: 'Changes prohibited',
  orderLimit: 'Order limit',
  addNewRecord: 'Add new record',
  deleteRecord: 'Delete record',
  deliverySplittingConfig: 'Delivery splitting configuration',
  tourNote: 'Tour note',
  partialDelivery: 'Partial delivery',
  numberOfPieces: 'Number of pieces',
  enablePartialDelivery: 'Enable partial delivery',
  part: 'Part',
  checkSplitting: 'Check splitting',
  defaultOrder_saved: 'Default order was successfully saved',
  defaultOrderItems: 'Default order items',
  articlesAvailabilityOn: 'Articles availability on',
  individual_payment_conditions: 'Individual payment conditions',
  bank_abbreviation: 'BESR/VESR bank',
  vat_system: 'VAT system',
  invalid_value: 'Invalid value',
  useDefOrderFromCustomer: 'Use default order from customer',
  articlePrices: 'Article prices',
  stdPrice: 'Std. price',
  noDiscount: 'No discount',
  fromQuantity: 'From quantity',
  kind: 'Kind',
  comment: 'Comment',
  individualPrices: 'Individual prices',
  article: 'Article',
  shipping_costs_sector: 'Shipping costs sector',
  offer: 'Offer',
  distribution_limits: 'Distribution limits',
  flexible_orders: 'Flexible orders',
  copy_default_order: 'Copy default order',
});

export const customerFr = t({
  customer: 'Client',
  customers: 'Clients',
  create_customer: 'Créer un client',
  copy_customer: 'Copier un client',
  new_customer: 'Nouveau client',
  surname: 'Nom de famille',
  number: 'Numéro',
  general: 'Général',
  customer_active: 'Client actif',
  type_of_address: "Type d'adresse",
  group: 'Groupe',
  delivery_note_group: 'Groupe de bon de livraison',
  address: 'Adresse',
  salutation: 'Civilité',
  additive: 'Complément',
  internal_name: 'Nom interne',
  street: 'Rue',
  post_box: 'Boîte postale',
  zip_code: 'Code postal',
  city: 'Ville',
  availability: 'Disponibilité',
  country: 'Pays',
  delivery_address: 'Adresse de livraison',
  contacts: 'Contacts',
  contact_person: 'Personne de contact',
  phone_business: 'Téléphone professionnel',
  phone_private: 'Téléphone privé',
  mobile: 'Mobile',
  fax: 'Fax',
  refresh_customer: 'Actualiser client',
  recalculate: 'Recalculer',
  recalculate_orders_for_customers: 'Recalculer les commandes des clients',
  refresh_prices: 'Rafraîchir les prix',
  refresh_conditions_rebates_net_etc: "Conditions d'actualisation (rabais, net, etc.)",
  refresh_just_cashAssist_receipts: 'Rafraîchir uniquement les reçus CashAssist',
  recalculated_orders_for_customers: 'Recalcul des commandes pour {{customersQuantity}} clients',
  not_refreshed: 'Non actualisé',
  discountKind: 'Type de remise',
  quantityDiscountValue: 'Valeur de remise par quantité',
  discountPercent: 'Remise %',
  email_for_invoices: 'Email pour les factures',
  website: 'Site web',
  lists: 'Listes',
  customer_groups: 'Groupes de clients',
  tourplans: 'Plans de tournée',
  print_form: "Formulaire d'impression",
  print_forms: "Formulaires d'impression",
  summary_form: 'Formulaire récapitulatif',
  payment_slip_form: 'Formulaire de bulletin de versement',
  copy: 'Copier',
  copy_other: 'Copies',
  delivery_note_comments: 'Commentaires individuels sur le bon de livraison',
  invoice_comments: 'Commentaires individuels sur la facture',
  additional_comments: 'Commentaires supplémentaires',
  e_bill_account_id: 'Identifiant de compte E-Bill',
  select_default_setting: 'Paramètre par défaut du client',
  invalid_data_warning:
    'Erreur dans vos données. Vos modifications seront perdues. Voulez-vous continuer ?',
  enter_valid_email: 'Veuillez entrer une adresse email correcte',
  customer_saved: 'Le client a été enregistré avec succès',
  standardOrder: 'Commande standard',
  overview: "Vue d'ensemble",
  availabilitySetting: 'Paramètre de disponibilité',
  allProductGroups: "Tous les groupes d'assortiment",
  setUpStation: 'Station de configuration',
  setUpStationSat: 'Station de configuration Sam',
  setUpStationSun: 'Station de configuration Dim',
  standardTour: 'Tournée standard',
  drivingPos: 'Position de conduite',
  deliveryTime: 'Heure de livraison',
  saturdayTour: 'Tournée Samedi',
  sundayTour: 'Tournée Dimanche',
  addInTourIfNoDelivery: "Ajouter à la tournée s'il n'y a pas de livraison",
  tour: 'Tournée',
  cashAssist: 'CashAssist',
  canBuyOnCreditOnCashAssist: 'Client crédité sur CashAssist',
  takeReductionsAndPricesFrom: 'Prendre réductions et prix de ...',
  defaultOrder: 'Commande par défaut',
  preset: 'Préréglage',
  createAutomaticallyFromPresetting: 'Créer automatiquement à partir du préréglage',
  editing: 'Édition',
  conditions: 'Conditions',
  price_and_discount: 'Prix et remise',
  priceCategories: 'Catégorie de prix',
  discountType: 'Type de remise',
  articlePrice: "Prix de l'article",
  accounting: 'Comptabilité',
  debtor: 'Statut du débiteur',
  customerIsInvoicedWith: 'Ce client est facturé avec',
  payment: 'Paiement',
  bank: 'Banque',
  paymentDeadline: 'Échéance de paiement',
  termOfPaymentInDays: 'Délais de paiement en jours',
  individual: 'Individuel',
  cashDiscount: 'Escompte',
  cashDiscountIn: 'Escompte en %',
  debtorAccount: 'Compte débiteur',
  slipReference: 'Référence du bulletin',
  returnLimitIn: 'Limite de retour en %',
  debit: 'Débit',
  bankName: 'Nom de la banque',
  bin: 'BIN',
  accountNo: 'N° de compte',
  iban: 'IBAN',
  bic: 'BIC',
  specialDsicountCashAssist: 'Remise spéciale client crédité CashAssist (%)',
  mandatNumber: 'Numéro de mandat',
  dateOfMandatSignature: 'Date de signature du mandat',
  changesProhibited: 'Modifications interdites',
  orderLimit: 'Limite de commande',
  addNewRecord: 'Ajouter un nouvel enregistrement',
  deleteRecord: "Supprimer l'enregistrement",
  deliverySplittingConfig: 'Configuration de la division des livraisons',
  tourNote: 'Note de tournée',
  partialDelivery: 'Livraison partielle',
  numberOfPieces: 'Nombre de pièces',
  enablePartialDelivery: 'Activer la livraison partielle',
  part: 'Partie',
  checkSplitting: 'Vérifier la division',
  defaultOrder_saved: 'Commande par défaut enregistrée avec succès',
  defaultOrderItems: 'Articles de la commande par défaut',
  articlesAvailabilityOn: 'Disponibilité des articles activée',
  individual_payment_conditions: 'Conditions de paiement individuelles',
  bank_abbreviation: 'Banque BESR/VESR',
  vat_system: 'Système de TVA',
  invalid_value: 'Valeur invalide',
  useDefOrderFromCustomer: 'Utiliser la commande par défaut du client',
  articlePrices: 'Prix des articles',
  stdPrice: 'Prix standard',
  noDiscount: 'Pas de remise',
  fromQuantity: 'À partir de la quantité',
  kind: 'Genre',
  comment: 'Commentaire',
  individualPrices: 'Prix individuels',
  article: 'Article',
  shipping_costs_sector: 'Secteur des frais de livraison',
  offer: 'Offre',
  distribution_limits: 'Limites de distribution',
  flexible_orders: 'Commandes flexibles',
  copy_default_order: "Copier l'ordre par défaut",
});

export const customerIt = t({
  customer: 'Cliente',
  customers: 'Clienti',
  create_customer: 'Crea cliente',
  copy_customer: 'Copia cliente',
  new_customer: 'Nuovo cliente',
  surname: 'Cognome',
  number: 'Numero',
  general: 'Generale',
  customer_active: 'Cliente attivo',
  type_of_address: 'Tipo di indirizzo',
  group: 'Gruppo',
  delivery_note_group: 'Gruppo note di consegna',
  address: 'Indirizzo',
  salutation: 'Titolo',
  additive: 'Aggiuntivo',
  internal_name: 'Nome interno',
  street: 'Via',
  post_box: 'Casella postale',
  zip_code: 'CAP',
  city: 'Città',
  refresh_customer: 'Ricarica cliente',
  recalculate: 'Ricalcolo',
  recalculate_orders_for_customers: 'Ricalcolo degli ordini per i clienti',
  refresh_prices: 'Aggiornare i prezzi',
  refresh_conditions_rebates_net_etc: 'Condizioni di aggiornamento (sconti, netto, ecc.)',
  refresh_just_cashAssist_receipts: 'Aggiornare solo le ricevute CashAssist',
  recalculated_orders_for_customers: 'Ordini ricalcolati per {{customersQuantity}} clienti',
  not_refreshed: 'Non aggiornato',
  availability: 'Disponibilità',
  country: 'Paese',
  delivery_address: 'Indirizzo di consegna',
  contacts: 'Contatti',
  contact_person: 'Persona di contatto',
  phone_business: 'Telefono aziendale',
  phone_private: 'Telefono privato',
  mobile: 'Cellulare',
  fax: 'Fax',
  discountKind: 'Tipo di sconto',
  quantityDiscountValue: 'Valore dello sconto quantità',
  discountPercent: 'Sconto %',
  email_for_invoices: 'Email per le fatture',
  website: 'Sito web',
  lists: 'Elenchi',
  customer_groups: 'Gruppi di clienti',
  tourplans: 'Piani di consegna',
  print_form: 'Modulo di stampa',
  print_forms: 'Moduli di stampa',
  summary_form: 'Modulo riepilogativo',
  payment_slip_form: 'Modulo bollettino di pagamento',
  copy: 'Copia',
  copy_other: 'Copie',
  delivery_note_comments: 'Commenti individuali sulla nota di consegna',
  invoice_comments: 'Commenti individuali sulla fattura',
  additional_comments: 'Commenti aggiuntivi',
  e_bill_account_id: 'ID account e-fattura',
  select_default_setting: 'Impostazione predefinita del cliente',
  invalid_data_warning: 'Errore nei dati. Le modifiche verranno perse. Vuoi procedere?',
  enter_valid_email: 'Inserisci un indirizzo email corretto',
  customer_saved: 'Cliente salvato con successo',
  standardOrder: 'Ordine standard',
  overview: 'Panoramica',
  availabilitySetting: 'Impostazione disponibilità',
  allProductGroups: 'Tutti i gruppi di assortimento',
  setUpStation: 'Postazione di preparazione',
  setUpStationSat: 'Postazione di preparazione Sabato',
  setUpStationSun: 'Postazione di preparazione Domenica',
  standardTour: 'Tour standard',
  drivingPos: 'Posizione di guida',
  deliveryTime: 'Orario di consegna',
  saturdayTour: 'Tour sabato',
  sundayTour: 'Tour domenica',
  addInTourIfNoDelivery: 'Aggiungi al tour se non c’è consegna',
  tour: 'Giro',
  cashAssist: 'CashAssist',
  canBuyOnCreditOnCashAssist: 'Cliente a credito CashAssist',
  takeReductionsAndPricesFrom: 'Prendi riduzioni e prezzi da...',
  defaultOrder: 'Ordine predefinito',
  preset: 'Preimpostazione',
  createAutomaticallyFromPresetting: 'Crea automaticamente dalla preimpostazione',
  editing: 'Modifica',
  conditions: 'Condizioni',
  price_and_discount: 'Prezzo e sconto',
  priceCategories: 'Categoria di prezzo',
  discountType: 'Tipo di sconto',
  articlePrice: 'Prezzo articolo',
  accounting: 'Contabilità',
  debtor: 'Stato del debitore',
  customerIsInvoicedWith: 'Questo cliente è fatturato con',
  payment: 'Pagamento',
  bank: 'Banca',
  paymentDeadline: 'Scadenza pagamento',
  termOfPaymentInDays: 'Termini di pagamento in giorni',
  individual: 'Individuale',
  cashDiscount: 'Sconto per pagamento in contanti',
  cashDiscountIn: 'Skonto in %',
  debtorAccount: 'Conto debitore',
  slipReference: 'Riferimento bollettino',
  returnLimitIn: 'Limite di reso in %',
  debit: 'Addebito',
  bankName: 'Nome banca',
  bin: 'BIN',
  accountNo: 'N. conto',
  iban: 'IBAN',
  bic: 'BIC',
  specialDsicountCashAssist: 'Sconto speciale cliente CashAssist (%)',
  mandatNumber: 'Numero mandato',
  dateOfMandatSignature: 'Data della firma del mandato',
  changesProhibited: 'Modifiche vietate',
  orderLimit: 'Limite d’ordine',
  addNewRecord: 'Aggiungi nuovo record',
  deleteRecord: 'Elimina record',
  deliverySplittingConfig: 'Configurazione frazionamento consegna',
  tourNote: 'Nota del tour',
  partialDelivery: 'Consegna parziale',
  numberOfPieces: 'Numero di pezzi',
  enablePartialDelivery: 'Abilita consegna parziale',
  part: 'Parte',
  checkSplitting: 'Verifica frazionamento',
  defaultOrder_saved: 'Ordine predefinito salvato con successo',
  defaultOrderItems: 'Articoli ordine predefinito',
  articlesAvailabilityOn: 'Disponibilità articoli attiva',
  individual_payment_conditions: 'Condizioni di pagamento individuali',
  bank_abbreviation: 'Banca BESR/VESR',
  vat_system: 'Sistema IVA',
  invalid_value: 'Valore non valido',
  useDefOrderFromCustomer: 'Usa l’ordine predefinito del cliente',
  articlePrices: 'Prezzi articoli',
  stdPrice: 'Prezzo standard',
  noDiscount: 'Nessuno sconto',
  fromQuantity: 'Da quantità',
  kind: 'Tipo',
  comment: 'Commento',
  individualPrices: 'Prezzi individuali',
  article: 'Articolo',
  shipping_costs_sector: 'Settore costi di spedizione',
  offer: 'Offerta',
  distribution_limits: 'Limiti di distribuzione',
  flexible_orders: 'Ordini flessibili',
  copy_default_order: 'Copia ordine predefinito',
});
