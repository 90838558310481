import { FC, ReactNode, useEffect } from 'react';
import styled from '@mui/material/styles/styled';
import { Box, Stack } from '@mui/material';
import { topbarService } from '../navigation/components/topBar/services/topBar.service';

export const Page: FC<IPage> = ({ title, withBorder, children }) => {
  useEffect(() => {
    topbarService.pub.title(title);
  }, [title]);
  return (
    <SPage direction='column'>
      <SBox flexGrow={1} sx={{ minHeight: 0 }} {...{ withBorder }}>
        {children}
      </SBox>
    </SPage>
  );
};
const SPage = styled(Stack)(({ theme }) => ({
  height: `calc(100vh - 62px)`,
  padding: theme.page.padding,
  overflow: 'auto',
  flexGrow: 1,
  paddingTop: '16px',
}));
export interface IPage {
  title: string;
  withBorder?: boolean;
  children: ReactNode;
}

const SBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'withBorder',
})<{ withBorder?: boolean }>(({ theme, withBorder }) => ({
  ...(withBorder && {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    padding: '20px 16px 16px',
  }),
}));
