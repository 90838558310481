import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ITabState } from '../../../states/tabState.model';
import { Content } from '../../../../../shared/components/content/content.component';
import { formsTabState, IFormsTabState } from './forms.state';
import { FormsForm } from './components/formsForm.component';
import { CustomerFormsDataRes } from '../../../services/customer.service';

export const FormsTab: FC = () => {
  const { defaultValues } = useLoaderData() as ITabState;
  const { t } = useTranslation();
  const formMethods = useForm<CustomerFormsDataRes>({
    mode: 'onChange',
    defaultValues,
  });

  const { watch, reset, resetField, getValues, getFieldState } = formMethods;

  useEffect(() => {
    const formStateSub = watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        const noteFields: [
          'defaultNoteInOrder',
          'defaultNoteInOrder2',
          'defaultNoteInInvoice',
          'defaultNoteInInvoice2',
        ] = [
          'defaultNoteInOrder',
          'defaultNoteInOrder2',
          'defaultNoteInInvoice',
          'defaultNoteInInvoice2',
        ];
        const isFieldNote = (noteFields as string[]).includes(fieldName);
        if (isFieldNote) {
          // 2 note fields can be dirty because they saved by save button in modal, not on blur
          const dataToSave: IFormsTabState['dataToSave'] = { id: data.id };
          noteFields.forEach((noteField) => {
            const isDirty = getFieldState(noteField).isDirty;
            if (isDirty) {
              dataToSave[noteField] = data[noteField];
            }
          });
          formsTabState.pub.recordData(dataToSave);
        } else {
          // simple form fields
          formsTabState.pub.recordData({
            [fieldName]: data[fieldName],
            id: data.id,
          });
        }
      }
    });
    const unsubState = formsTabState.sub
      .state()
      .subscribe(({ action, defaultValues, dataToSave }) => {
        if (action === 'list.selectedRecord') {
          reset(defaultValues);
        } else {
          Object.keys(dataToSave).forEach((field) => {
            const name = field as keyof typeof dataToSave;
            resetField(name, {
              defaultValue: dataToSave[name],
              keepDirty: false,
            });
          });
        }
      });
    return () => {
      formStateSub.unsubscribe();
      unsubState.unsubscribe();
    };
  }, []);

  return (
    <>
      {Object.keys(getValues()).length ? (
        <FormsForm formMethods={formMethods} />
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};
