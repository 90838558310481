import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  ListProcurementProductsQuery,
  ListProcurementProductsQueryVariables,
  GetProcurementProductDataQueryVariables,
  GetProcurementProductDataQuery,
  SaveProcurementProductDataMutationVariables,
  SaveProcurementProductDataMutation,
  ListProcurementProductStorageChangesQuery,
  ListProcurementProductStorageChangesQueryVariables,
  GetProcurementProductStorageQuantityQueryVariables,
  GetProcurementProductStorageQuantityQuery,
  ChangeProcurementProductStorageQuantityMutationVariables,
  ChangeProcurementProductStorageQuantityMutation,
} from '../../../graphql/generatedModel.ts';

import { map, Observable, switchMap, tap, zip } from 'rxjs';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import {
  procurementProductsList,
  getProcurementProductData,
  getProcurementProductStorageChanges,
  saveProcurementProductData,
  getProcurementProductStorageQuantity,
  changeProcurementProductStorageQuantity,
} from './gql/stockOfFreezer.gql.ts';

type Actions =
  | 'procurementProductsList'
  | 'getProcurementProductData'
  | 'saveProcurementProductData'
  | 'getProcurementProductStorageChanges'
  | 'getProcurementProductDataAndStorageChanges'
  | 'getProcurementProductStorageQuantity'
  | 'changeProcurementProductStorageQuantity'
  | undefined;

class PubImpl extends Pub<Actions> {
  procurementProductsList(params: ListProcurementProductsQueryVariables): void {
    this.emit('procurementProductsList', params);
  }
  getProcurementProductData(params: GetProcurementProductDataQueryVariables): void {
    this.emit('getProcurementProductData', params);
  }
  getProcurementProductStorageChanges(
    params: ListProcurementProductStorageChangesQueryVariables,
  ): void {
    this.emit('getProcurementProductStorageChanges', params);
  }
  getProcurementProductDataAndStorageChanges(
    params: ListProcurementProductStorageChangesQueryVariables,
  ): void {
    this.emit('getProcurementProductDataAndStorageChanges', params);
  }
  getProcurementProductStorageQuantity(
    params: GetProcurementProductStorageQuantityQueryVariables,
  ): void {
    this.emit('getProcurementProductStorageQuantity', params);
  }
  saveProcurementProductData(params: SaveProcurementProductDataMutationVariables): void {
    this.emit('saveProcurementProductData', params);
  }
  changeProcurementProductStorageQuantity(
    params: ChangeProcurementProductStorageQuantityMutationVariables,
  ): void {
    this.emit('changeProcurementProductStorageQuantity', params);
  }
}

class SubImpl extends Sub<Actions> {
  procurementProductsList(): Observable<ListProcurementProductsRes> {
    return this.actionListener('procurementProductsList').pipe(
      switchMap(({ params }) => {
        return gqlClient(procurementProductsList, params);
      }),
      map((data: ListProcurementProductsQuery) => {
        return data.wawiAssist?.listWA_ProcurementProducts as ListProcurementProductsRes;
      }),
    );
  }
  getProcurementProductData(): Observable<GetProcurementProductDataRes> {
    return this.actionListener('getProcurementProductData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getProcurementProductData, params);
      }),
      map((data: GetProcurementProductDataQuery) => {
        return data.wawiAssist?.getProcurementProductData as GetProcurementProductDataRes;
      }),
    );
  }
  getProcurementProductStorageChanges(): Observable<GetProcurementProductStorageChangesRes> {
    return this.actionListener('getProcurementProductStorageChanges').pipe(
      switchMap(({ params }) => {
        return gqlClient(getProcurementProductStorageChanges, params);
      }),
      map((data: ListProcurementProductStorageChangesQuery) => {
        return data.wawiAssist?.listWA_ProcurementProductStorageChanges?.map((values, idx) => ({
          ...values,
          id: String(idx),
        })) as GetProcurementProductStorageChangesRes;
      }),
    );
  }
  getProcurementProductDataAndStorageChanges(): Observable<ProcurementProductDataAndStorageChangesRes> {
    return this.actionListener('getProcurementProductDataAndStorageChanges').pipe(
      tap(({ params }) => {
        stockOfFreezerService.pub.getProcurementProductData({
          id: String(params?.procurementProductId),
        });
      }),
      switchMap(({ params }) => {
        return zip(
          this.getProcurementProductData(),
          gqlClient(getProcurementProductStorageChanges, params),
        ).pipe(
          map(([productInfo, changesInStockData]) => {
            const changesInStorage = (
              changesInStockData?.wawiAssist
                ?.listWA_ProcurementProductStorageChanges as ProcurementProductStorageChangesResWithoutId[]
            )?.map((values, idx) => ({
              ...values,
              id: String(idx),
            })) as GetProcurementProductStorageChangesRes;

            return {
              productInfo,
              changesInStorage,
            };
          }),
        );
      }),
    );
  }
  getProcurementProductStorageQuantity(): Observable<GetProcurementProductStorageQuantityRes> {
    return this.actionListener('getProcurementProductStorageQuantity').pipe(
      switchMap(({ params }) => {
        return gqlClient(getProcurementProductStorageQuantity, params);
      }),
      map((data: GetProcurementProductStorageQuantityQuery) => {
        return data.wawiAssist
          ?.getWA_ProcurementProductStorageQuantity as GetProcurementProductStorageQuantityRes;
      }),
    );
  }
  saveProcurementProductData(): Observable<SaveProcurementProductDataRes> {
    return this.actionListener('saveProcurementProductData').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          saveProcurementProductData,
          params,
        ) as Observable<SaveProcurementProductDataMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.saveProcurementProductData as SaveProcurementProductDataRes;
      }),
    );
  }
  changeProcurementProductStorageQuantity(): Observable<ChangeProcurementProductStorageQuantityRes> {
    return this.actionListener('changeProcurementProductStorageQuantity').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          changeProcurementProductStorageQuantity,
          params,
        ) as Observable<ChangeProcurementProductStorageQuantityMutation>;
      }),
      map((data) => {
        return data.wawiAssist
          ?.changeWA_ProcurementProductStorageQuantity as ChangeProcurementProductStorageQuantityRes;
      }),
    );
  }
}

class StockOfFreezerService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const stockOfFreezerService = new StockOfFreezerService();

export type ListProcurementProductsRes = NonNullable<
  NonNullable<ListProcurementProductsQuery['wawiAssist']>['listWA_ProcurementProducts']
>;

export type GetProcurementProductDataRes = NonNullable<
  NonNullable<GetProcurementProductDataQuery['wawiAssist']>['getProcurementProductData']
>;

type ProcurementProductStorageChangesResWithoutId = NonNullable<
  NonNullable<
    ListProcurementProductStorageChangesQuery['wawiAssist']
  >['listWA_ProcurementProductStorageChanges']
>[number];

type ProcurementProductStorageChangesRes = ProcurementProductStorageChangesResWithoutId & {
  id: string;
};

export type GetProcurementProductStorageChangesRes = ProcurementProductStorageChangesRes[];

export type ProcurementProductDataAndStorageChangesRes = {
  productInfo: GetProcurementProductDataRes;
  changesInStorage: GetProcurementProductStorageChangesRes;
};

export type SaveProcurementProductDataRes = NonNullable<
  NonNullable<SaveProcurementProductDataMutation['wawiAssist']>['saveProcurementProductData']
>;

export type GetProcurementProductStorageQuantityRes = NonNullable<
  NonNullable<
    GetProcurementProductStorageQuantityQuery['wawiAssist']
  >['getWA_ProcurementProductStorageQuantity']
>;

export type ChangeProcurementProductStorageQuantityRes = NonNullable<
  NonNullable<
    ChangeProcurementProductStorageQuantityMutation['wawiAssist']
  >['changeWA_ProcurementProductStorageQuantity']
>;
