import { FC, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { Content } from '../../../../../shared/components/content/content.component';
import { TMode, modeService } from '../../../../../shared/services/mode/mode.service';
import { editPrintListTabLoadingService } from './editPrintListTabLoading.service';
import { ContentLoading } from '../../../../../shared/components/loading/loading.component';
import { GeneralTab, IGeneralTab } from './general/general.tab';
import { GeneralCopyTab } from './general/generalCopy.tab';
import { TabContentContainer } from '../../../../../shared/components/tabs/tabsContainer.component';
import { GeneralCreateTab } from './general/generalCreate.tab';
import {
  EditPrintListGeneralTabRes,
  editPrintListService,
} from '../../../services/editPrintList.service';
import { createValidationSchema } from './general/validation/general.schema';
import {
  extractGeneralTabData,
  fieldsToTransform,
  getDataForRecord,
} from './general/utils/generalTab.utils';
import {
  IGeneralTabState,
  generalTabState,
  defaultCreateModeValues,
} from './general/generalTab.state';

const validationSchema = createValidationSchema();

const EditPrintListDetails: FC = () => {
  const { defaultValues } = useLoaderData() as IGeneralTab;
  const { t } = useTranslation();

  const [isPrintIdExist, setIsPrintIdExist] = useState<boolean>(Boolean(defaultValues?.id));
  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<TMode>('edit');
  const [editModeValues, setEditModeValues] = useState<EditPrintListGeneralTabRes>(defaultValues);

  const formMethods = useForm<EditPrintListGeneralTabRes>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { watch, reset, resetField, setValue, register, unregister, clearErrors } = formMethods;

  useEffect(() => {
    switch (mode) {
      case 'edit':
        clearErrors();
        reset(editModeValues);
        break;
      case 'create':
        reset(defaultCreateModeValues as EditPrintListGeneralTabRes, { keepErrors: true });
        break;
      case 'copy':
        reset({ ...editModeValues, name: null } as EditPrintListGeneralTabRes, {
          keepErrors: true,
        });
        break;
    }
  }, [mode]);

  useEffect(() => {
    const formStateSub = watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        const defaultValues = editPrintListService.sub.initialGeneralTabPrintListData;
        const extractedFields = extractGeneralTabData(
          {
            ...defaultValues,
            ...data,
          } as EditPrintListGeneralTabRes,
          fieldName,
        );
        // Form understand which field added or deleted
        const currentFields = Object.keys(data);
        const fieldsToRemove = currentFields.filter((field) => !(field in extractedFields));
        fieldsToRemove.forEach((field) => unregister(field as keyof EditPrintListGeneralTabRes));

        Object.keys(extractedFields).forEach((field) => {
          const typedField = field as keyof EditPrintListGeneralTabRes;
          if (!currentFields.includes(field)) {
            register(typedField);
            setValue(typedField, extractedFields[typedField], { shouldDirty: false });
          } else {
            setValue(typedField, extractedFields[typedField], { shouldDirty: field === fieldName });
          }
        });
        const dataForRecord = getDataForRecord(
          fieldName as keyof EditPrintListGeneralTabRes,
          extractedFields as EditPrintListGeneralTabRes,
        );
        if (mode === 'edit') {
          setEditModeValues((prevState) => {
            return {
              ...prevState,
              ...dataForRecord,
            };
          });
        }
        generalTabState.pub.recordData({
          id: extractedFields.id,
          ...dataForRecord,
        });
      }
    });

    return () => {
      formStateSub.unsubscribe();
    };
  }, [mode]);

  useEffect(() => {
    const unsubLoading = editPrintListTabLoadingService.sub.loading().subscribe(setLoading);
    const unsubMode = modeService.sub.mode().subscribe(setMode);

    const unsubprintIdNotExist = generalTabState.sub.printIdNotExist().subscribe(() => {
      setIsPrintIdExist(false);
      reset({});
    });
    const unsubGeneralTabState = generalTabState.sub
      .state()
      .subscribe(({ action, defaultValues, dataToSave }: IState) => {
        setIsPrintIdExist(true);
        if (action === 'list.selectedPrintList') {
          setEditModeValues(defaultValues);
          reset(defaultValues);
        } else {
          for (const valuesKey in dataToSave) {
            if (valuesKey && dataToSave) {
              const defaultValues = editPrintListService.sub.initialGeneralTabPrintListData;
              const isLabelKey = fieldsToTransform.includes(valuesKey as keyof typeof dataToSave);
              let newValue = dataToSave[valuesKey as keyof typeof dataToSave];
              if (valuesKey === 'crosstableConfigurationData') {
                newValue =
                  newValue?.map(
                    (
                      setting: EditPrintListGeneralTabRes['crosstableConfigurationData'][number],
                    ) => ({
                      ...setting,
                      customerIndicator: setting.categoryCustomerId
                        ? '0'
                        : setting.categoryCustomerListId
                        ? '1'
                        : '0',
                    }),
                  ) || [];
              }
              if (isLabelKey && Array.isArray(newValue)) {
                newValue = newValue.map((item) => {
                  const label = defaultValues
                    ? (
                        defaultValues[valuesKey as keyof EditPrintListGeneralTabRes] as {
                          id: string;
                          label: string;
                          checked: boolean;
                        }[]
                      )?.find((dvItem: { id: string }) => dvItem.id === item.id)?.label
                    : undefined;
                  return { ...item, label };
                });
              }
              resetField(valuesKey as keyof EditPrintListGeneralTabRes, {
                defaultValue: newValue,
                keepDirty: false,
                keepError: true,
              });
            }
          }
        }
      });

    return () => {
      modeService.pub.mode('edit');

      unsubMode.unsubscribe();
      unsubLoading.unsubscribe();

      unsubGeneralTabState.unsubscribe();
      unsubprintIdNotExist.unsubscribe();
    };
  }, []);

  return (
    <>
      <TabContentContainer>
        {isPrintIdExist || mode === 'create' ? (
          <ContentLoading loading={loading}>
            <FormProvider {...formMethods}>
              {mode === 'edit' && <GeneralTab mode={mode} />}
              {mode === 'create' && <GeneralCreateTab mode={mode} />}
              {mode === 'copy' && <GeneralCopyTab mode={mode} />}
            </FormProvider>
          </ContentLoading>
        ) : (
          <Content
            type='box'
            boxProps={{
              sx: {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                height: '100%',
              },
            }}
            emptyData={{
              value: true,
              title: t('common.no_data'),
            }}
          />
        )}
      </TabContentContainer>
    </>
  );
};

export default EditPrintListDetails;

interface IState extends IGeneralTabState {
  defaultValues: EditPrintListGeneralTabRes;
}
