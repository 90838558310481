import { Pub, State, Sub } from '../../../../../../../../shared/state/state.abstract.ts';
import { delay, map, merge, Observable, of, switchMap, tap } from 'rxjs';
import { GetDictQuantityDiscountQueryRes } from '../../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service.ts';

export const initQuantityDiscountDictState: IQuantityDiscountDictState = {
  action: undefined,
  selectedPos: null,
  quantityDiscountGrid: [],
};
class PubImpl extends Pub<IQuantityDiscountDictState> {
  init(quantityDiscountGrid: IQuantityDiscountDictState['quantityDiscountGrid']) {
    this.emit('init', { quantityDiscountGrid });
  }
  selectPosition(selectedPos: IQuantityDiscountDictState['selectedPos']) {
    this.emit('selectPosition', { selectedPos });
  }
  addPosition() {
    this.emit('addPosition', {});
  }
  deletePosition() {
    this.emit('deletePosition', {});
  }
  updateCell(selectedPos: IQuantityDiscountDictState['selectedPos']) {
    this.emit('updateCell', { selectedPos });
  }
}
class SubImpl extends Sub<IQuantityDiscountDictState> {
  protected actionHandlers(): Observable<IQuantityDiscountDictState> {
    return merge(
      this.simpleCell(),
      this.updateState(),
      this.deletePosition(),
      this.addPosition(),
    ).pipe(
      tap((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
      }),
    );
  }
  private simpleCell(): Observable<IQuantityDiscountDictState> {
    return this.actionListener('updateCell').pipe(
      map((state) => {
        const { selectedPos } = state;
        const updatedPos = structuredClone(selectedPos!);
        state.quantityDiscountGrid = state.quantityDiscountGrid?.map((el) =>
          el?.id === updatedPos?.id ? updatedPos : el,
        );
        state.selectedPos = updatedPos;
        return { ...state };
      }),
    );
  }

  private addPosition(): Observable<IQuantityDiscountDictState> {
    return this.actionListener('addPosition').pipe(
      switchMap((state) => {
        const updatedState = structuredClone(state);
        const newPosition = {
          fromAmount: null,
          discountOnAllGroups: null,
          discountOnGroup1: null,
          discountOnGroup2: null,
          discountOnGroup3: null,
          discountOnGroup4: null,
          discountOnGroup5: null,
          discountOnGroup6: null,
          discountOnGroup7: null,
          discountOnGroup8: null,
          discountOnGroup9: null,
          discountOnGroup10: null,
          discountOnGroup11: null,
          discountOnGroup12: null,
          discountOnGroup13: null,
          discountOnGroup14: null,
          discountOnGroup15: null,
          discountOnGroup16: null,
          discountOnGroup17: null,
          discountOnGroup18: null,
          discountOnGroup19: null,
          discountOnGroup20: null,
          id: `new_${Date.now()}`,
        };
        updatedState.quantityDiscountGrid.push(newPosition);
        updatedState.selectedPos = newPosition;
        return of(updatedState);
      }),
    );
  }

  private deletePosition(): Observable<IQuantityDiscountDictState> {
    return this.actionListener('deletePosition').pipe(
      delay(1),
      map((state) => {
        const updatedState = structuredClone(state);
        let selectedPos: IQuantityDiscountDictState['selectedPos'] = null;
        updatedState.quantityDiscountGrid = updatedState.quantityDiscountGrid?.filter(
          (pos, i, arr) => {
            if (pos?.id === state.selectedPos?.id) {
              const nextPos = arr?.[i + 1];
              const prevPos = arr?.[i - 1];
              if (i === 0 && arr!.length > 1) {
                selectedPos = nextPos;
              }
              if (i !== 0) {
                selectedPos = nextPos || prevPos || null;
              }
              return false;
            } else return true;
          },
        );
        updatedState.selectedPos = selectedPos;
        return updatedState;
      }),
    );
  }

  private updateState(): Observable<IQuantityDiscountDictState> {
    return this.actionListener(['selectPosition']);
  }
}

class DefaultOrderState extends State<IQuantityDiscountDictState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const quantityDiscountDictState = new DefaultOrderState(initQuantityDiscountDictState);
export interface IQuantityDiscountDictState {
  action:
    | 'init'
    | 'selectPosition'
    | 'addPosition'
    | 'deletePosition'
    | 'internalUpdate'
    | 'updateCell'
    | undefined;
  quantityDiscountGrid: TGetDictQuantityDiscountPos[];
  selectedPos: TGetDictQuantityDiscountPos | null;
}

type TGetDictQuantityDiscountPos = GetDictQuantityDiscountQueryRes[number] & { id: string };
