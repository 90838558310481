import { forwardRef, ReactNode } from 'react';
import { FormControlLabel, RadioProps, Radio as MiuRadio } from '@mui/material';

export const Radio = forwardRef<HTMLButtonElement, IRadio>(
  ({ label, value, ...radioProps }, ref) => {
    return <FormControlLabel control={<MiuRadio {...radioProps} />} {...{ label, value, ref }} />;
  },
);

export interface IRadio extends RadioProps {
  label?: ReactNode;
  value: string;
}
