import { Observable, filter, map, merge, switchMap, take, tap, finalize } from 'rxjs';

import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler';
import { Pub, State } from '../../../../../../shared/state/state.abstract';
import { editPrintListState } from '../../../states/editPrintList.state';
import { ITabState } from '../../../states/tabState.model';
import { editPrintListTabLoadingService } from '../editPrintListTabLoading.service';
import {
  CrosstableConfigurationDataRes,
  EditPrintListGeneralTabRes,
  SaveProductionEditPrintListDataRes,
  editPrintListService,
} from '../../../../services/editPrintList.service';
import {
  C_Production_Article_Sorting_Kind,
  C_Save_Operation_Status,
  SaveProductionEditPrintListDataMutationVariables,
} from '../../../../../../graphql/generatedModel';
import { fieldsToTransform } from './utils/generalTab.utils';
import { modeService } from '../../../../../../shared/services/mode/mode.service';
import { editPrintListTabsSub } from '../editPrintListCommonTabs.sub';

export const defaultCreateModeValues: Partial<EditPrintListGeneralTabRes> = {
  id: 'testId',
  name: null,
  isActive: false,
  isAvailableMon: true,
  isAvailableTue: true,
  isAvailableWed: true,
  isAvailableThu: true,
  isAvailableFri: true,
  isAvailableSat: true,
  isAvailableSun: true,
  reportId: null,
  reportGroupId: null,
  isShowDeliverySplitting: false,
  isShowIndividualTexts: true,
  isOnlyPositionsWithIndividualText: false,
  isHighlightArticlesFromProductionCalc: false,
  isSplitDoughBeforePrint: false,
  articlesSortingKindId: C_Production_Article_Sorting_Kind.PASK1_DOUGH_ARTICLE_NO,
  isShowSemiProductsDetails: false,
  isShowProductionDescription: false,
  addDaysForPreProduction: 0,
  isCrosstableEasyMode: false,
  isNeedSignatureOnForwardingList: false,
  isPrintDeliveryNotesForTourplan: false,
};

export const defaultEditPrintListGeneralTabRes: EditPrintListGeneralTabRes = {
  id: '',
  name: null,
  isActive: false,
  isAvailableMon: false,
  isAvailableTue: false,
  isAvailableWed: false,
  isAvailableThu: false,
  isAvailableFri: false,
  isAvailableSat: false,
  isAvailableSun: false,
  reportId: null,
  reportGroupId: null,
  addDaysForPreProduction: null,
  articlesSortingKindId: C_Production_Article_Sorting_Kind.PASK1_DOUGH_ARTICLE_NO,
  customersSortingKindId: null,
  isShowSemiProductsDetails: false,
  isCrosstableEasyMode: false,
  crosstableEasyModeSplitNoFilter: null,
  isNeedSignatureOnForwardingList: false,
  tourplanId: null,
  tourplanDay: null,
  isPrintDeliveryNotesForTourplan: false,
  isShowProductionDescription: false,
  isShowIndividualTexts: false,
  isOnlyPositionsWithIndividualText: false,
  isHighlightArticlesFromProductionCalc: false,
  isSplitDoughBeforePrint: false,
  isShowDeliverySplitting: false,
  crosstableConfigurationData: [],
  tourplanConfigurationData: [],
  orderTypesFilter: [],
  customerListsFilter: [],
  articleListsFilter: [],
};

export const defaultCrosstableConfigurationDataRes: CrosstableConfigurationDataRes = {
  crosstableConfigurationData: [
    {
      categoryId: '',
      categoryName: '',
      categoryCustomerId: null,
      categoryCustomerListId: null,
      categoryFilterDeliveryType: null,
      categoryFilterSplitNo: null,
      categoryFilterCustomer: null,
      categoryCustomFilterDelivery: null,
    },
  ],
};

export const initCrossTableSettings = {
  id: null,
  categoryId: null,
  categoryName: null,
  categoryCustomerId: null,
  categoryCustomerListId: null,
  categoryFilterDeliveryType: null,
  categoryFilterSplitNo: null,
  categoryFilterCustomer: null,
  categoryCustomFilterDelivery: null,
  customerIndicator: '0',
};

export const initTourplansSettings = {
  categoryId: null,
  categoryName: null,
  categoryFilter: [],
};

class PubImpl extends Pub<IGeneralTabState> {
  recordData(dataToSave: IGeneralTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  save() {
    this.emit('save', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends editPrintListTabsSub<IGeneralTabState>() {
  protected actionHandlers(): Observable<IGeneralTabState> {
    return merge(this.selectedPrintList(), this.save());
  }

  private save(): Observable<IGeneralTabState> {
    return this.actionListener('save').pipe(
      tap(({ dataToSave }) => {
        editPrintListService.sub.updateInitialGeneralTabData().pipe(take(1)).subscribe();
        editPrintListService.sub
          .saveEditPrintListData()
          .pipe(
            responseHandler<SaveProductionEditPrintListDataRes>({
              success: () => 'production.edit_print_list_saved',
              customErrorHandler: () => 'production.edit_print_list_not_saved',
              errorReturnType: {
                status: C_Save_Operation_Status.SOS0_DATA_UNCHANGED,
                updatedGridItem: null,
              },
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              editPrintListState.pub.updateEditPrintListItem(updatedGridItem!);
            }
          });
        editPrintListService.pub.updateInitialGeneralTabData(dataToSave);
        editPrintListService.pub.saveEditPrintListData({
          dataToSave: this.transformDataForSave(dataToSave),
        } as SaveProductionEditPrintListDataMutationVariables);
      }),
      finalize(() => generalTabState.pub.clearStream()),
    );
  }

  private selectedPrintList(): Observable<IGeneralTabState> {
    return editPrintListState.sub.state().pipe(
      filter(({ action }) => {
        return (
          action === 'selectEditPrintList' ||
          action === 'editPrintList' ||
          action === 'deleteEditPrintList' ||
          action === 'filter'
        );
      }),
      filter(({ selectedPrintList }) => {
        const state = this.stream$.getValue();
        if (!selectedPrintList || !selectedPrintList?.id) {
          state.defaultValues = {};
          this.printIdNotExist$.next(true);
        }
        return (
          typeof selectedPrintList?.id === 'string' &&
          state.defaultValues.id !== selectedPrintList?.id
        );
      }),
      tap(() => modeService.pub.mode('edit')),
      switchMap(({ selectedPrintList }) => {
        editPrintListTabLoadingService.pub.loading(true);
        const id = selectedPrintList!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedPrintList';
        const details = editPrintListService.sub.editPrintListGeneralData().pipe(
          responseHandler<EditPrintListGeneralTabRes>({
            customErrorHandler: () => 'production.tab_not_loaded',
            errorReturnType: { ...defaultEditPrintListGeneralTabRes },
          }),
          take(1),
          map((data) => {
            state.defaultValues = data;
            editPrintListTabLoadingService.pub.loading(false);
            return state;
          }),
        );
        editPrintListService.pub.editPrintListGeneralData({ id });
        return details;
      }),
    );
  }

  /* UTILITY start */
  private transformDataForSave(
    data: IGeneralTabState['dataToSave'],
  ): Partial<EditPrintListGeneralTabRes> {
    const transformedData = structuredClone(data);
    const transformArray = (arr: { id: string; checked: boolean }[]) =>
      arr.map(({ id, checked }) => ({ id, checked }));

    fieldsToTransform.forEach((field: keyof EditPrintListGeneralTabRes) => {
      if (transformedData[field]) {
        transformedData[field] = transformArray(transformedData[field]);
      }
    });

    if (transformedData?.tourplanConfigurationData) {
      transformedData.tourplanConfigurationData = transformedData.tourplanConfigurationData.map(
        (item: Record<string, any>) => {
          if (item.categoryFilter) {
            item.categoryFilter = transformArray(item.categoryFilter);
          }
          return item;
        },
      );
    }

    return transformedData;
  }
  /* UTILITY end */
}

class GeneralTabState extends State<IGeneralTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const generalTabState = new GeneralTabState({
  action: undefined,
  defaultValues: {},
  dataToSave: {},
});
export interface IGeneralTabState extends Pick<ITabState, 'defaultValues'> {
  action: undefined | 'list.selectedPrintList' | 'recordData' | 'save';
  dataToSave: EditPrintListGeneralTabRes | Record<string, any>;
}
