import { FC, useEffect, useState } from 'react';
import { FormSelect } from '../../../../../../../../../shared/components/form';
import { Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { configsData } from '../../../../../../../../../shared/services/configsData/configsData.service.ts';
import { Content } from '../../../../../../../../../shared/components/content/content.component.tsx';
import { ISelectOption } from '../../../../../../../../../shared/components/form/fields/select.component.tsx';
import { useFormContext } from 'react-hook-form';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import { IPopupWrapper } from '../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { generalTabState } from '../../../../generalTab.state.ts';

export const CopySpecialPrices: FC<ICopySpecialPrices> = ({ currentArticleId, stream }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState<ExtendedSelectOption[]>([]);
  const { watch, handleSubmit } = useFormContext<{ articleId: string }>();

  useEffect(() => {
    const dictArticlesSub = configsData.sub.dictArticles().subscribe((articles) => {
      setArticles(
        articles.reduce<ExtendedSelectOption[]>((acc, item) => {
          if (item.id !== currentArticleId) {
            acc.push({ ...item, label: item?.description ?? '', articleNo: item?.articleNo ?? '' });
          }
          return acc;
        }, []),
      );
      setLoading(false);
    });
    configsData.pub.common(['dictArticles']);
    return () => dictArticlesSub.unsubscribe();
  }, []);

  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        ((option?.articleNo ? `${option?.articleNo} ` : '') + (option?.label || ''))
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) as boolean,
    );

  useBeforeClosePopup({
    stream,
    isDirty: Boolean(watch('articleId')),
  });

  const onSubmit = ({ articleId }: { articleId: string }) => {
    generalTabState.pub.copySpecialPrices(articleId);
    stream.emit('close');
  };

  return (
    <Content type='box' lsx={{ bottom: -5, right: -5, top: -5, left: -5 }} {...{ loading }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormSelect
          label={t('article.articles')}
          width='100%'
          name='articleId'
          options={articles as []}
          datasetattribute='articleOptions'
          {...{ filterOptions }}
        />
        <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
          <Button disabled={!watch('articleId')} variant='contained' type='submit'>
            {t('article.copy')}
          </Button>
        </Stack>
      </form>
    </Content>
  );
};

interface ICopySpecialPrices {
  currentArticleId: string;
  stream: IPopupWrapper['stream'];
}

interface ExtendedSelectOption extends ISelectOption {
  articleNo?: string;
}
