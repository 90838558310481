import { FC } from 'react';
import { Box, styled } from '@mui/material';
import { AppBox } from './appBox.component';
import { IAppInfo } from '../hsAppMenu.component';

export const Content: FC<IContent> = ({ appsInfo }) => {
  return (
    <SWrapper>
      {appsInfo?.map((appInfo) => (
        <AppBox key={appInfo?.url} {...appInfo} />
      ))}
    </SWrapper>
  );
};

const SWrapper = styled(Box)({
  width: '392px',
  padding: '8px',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  gap: '8px',
});

interface IContent {
  appsInfo: IAppInfo[];
}
