import { finalize, merge, Observable, take, tap } from 'rxjs';
import { storageHelper } from '../../../../shared/helpers/storage';
import {
  companyConfigService,
  TCompanyConfigs,
} from '../../../../shared/services/companyConfig/companyConfig.service.ts';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract.ts';

class PubImpl extends Pub<IConfigurationTabState> {
  recordData(dataToSave: IConfigurationTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  saveData() {
    this.emit('saveData', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
}
class SubImpl extends Sub<IConfigurationTabState> {
  protected actionHandlers(): Observable<IConfigurationTabState> {
    return merge(this.saveData());
  }

  private saveData(): Observable<IConfigurationTabState> {
    return this.actionListener('saveData').pipe(
      tap(({ dataToSave }) => {
        companyConfigService
          .saveConfigData(dataToSave)
          .pipe(take(1))
          .subscribe((res) => {
            if (res) {
              const cachedConfigData = storageHelper.session.getItem('companyConfig');
              storageHelper.session.setItem('companyConfig', {
                ...cachedConfigData,
                ...dataToSave,
              });
            }
          });
      }),
      finalize(() => configurationTabState.pub.clearStream()),
    );
  }
}

class ConfigurationTabState extends State<IConfigurationTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const configurationTabState = new ConfigurationTabState({
  action: undefined,
  dataToSave: {},
});

export interface IConfigurationTabState {
  action: undefined | 'recordData' | 'saveData';
  dataToSave: TCompanyConfigs | object;
}
