import { take } from 'rxjs';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import { CustomerFormsDataRes, customerService } from '../services/customer.service';
import { ITabState } from '../states/tabState.model';
import { C_Report } from '../../../graphql/generatedModel';

export const defaultCustomerFormsData: CustomerFormsDataRes = {
  id: '',
  invoicePaymentSlipReportId: C_Report.R18_INVOICE_PAYMENTSLIP,
  invoiceReportId: C_Report.R9_INVOICE_A4,
  invoiceSummaryReportId: C_Report.R17_INVOICE_SUMMARY,
  orderReportId: C_Report.R7_WEEKLY_ORDERING_LIST,
  offerReportId: C_Report.R37_OFFER_A4,
  deliveryNoteCopies: null,
  invoiceCopies: null,
  eBillAccount: null,
  defaultNoteInOrder: null,
  defaultNoteInOrder2: null,
  defaultNoteInInvoice: null,
  defaultNoteInInvoice2: null,
};

export async function resolveDataForFormsTab(data: ITabState): Promise<ITabState> {
  data.defaultValues = data?.customerId
    ? await customerService.globHelpers.streamToPromise(
        customerService.sub
          .customerFormsData()
          .pipe(
            responseHandler<CustomerFormsDataRes>({ errorReturnType: defaultCustomerFormsData }),
            take(1),
          ),
        () => {
          customerService.pub.customerFormsData({ id: data.customerId! });
        },
      )
    : {};

  return data;
}
