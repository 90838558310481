/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  amber,
  blue,
  deepOrange,
  deepPurple,
  green,
  lightBlue,
  lightGreen,
  orange,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { LinkProps } from 'react-router-dom';
import { LinkBehavior } from './components/link/link.component';

export const Theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as Omit<LinkProps, 'to'>,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#2196F3',
      dark: '#1E88E5',
      light: '#42A5F5',
    },
    primaryFocus: {
      main: '#2196f31f',
    },
    primaryHover: {
      main: '#2196F30A',
    },
    secondary: {
      main: '#212121',
      dark: '#000000f2',
      light: '#666666',
    },
    text: {
      primary: '#212121',
      secondary: '#666666',
      disabled: '#9e9e9e',
    },
    action: {
      active: '#707070',
      hover: '#f5f5f5',
      selected: '#ebebeb',
      disabledBackground: '#e0e0e0',
      focus: '#e0e0e0',
      disabled: '#9e9e9e',
    },
    error: {
      main: '#D32F2F',
      dark: '#C62828',
      light: '#EF5350',
    },
    warning: {
      main: '#ED6C02',
      dark: '#E65100',
      light: '#FF9800',
    },
    success: {
      main: '#2E7D32',
      dark: '#1B5E20',
      light: '#4CAF50',
    },
    divider: '#e0e0e0',
    yellow: {
      light: yellow['100'],
      main: yellow['200'],
    },
    teal: {
      light: teal['100'],
      main: teal['200'],
    },
    orange: {
      light: orange['50'],
      main: orange['100'],
      dark: orange['200'],
    },
    darkOrange: {
      light: orange['200'],
      main: orange['300'],
    },
    deepOrange: {
      light: deepOrange['100'],
      main: deepOrange['200'],
    },
    deepPurple: {
      light: deepPurple['100'],
      main: deepPurple['200'],
    },
    lightGreen: {
      light: lightGreen['50'],
      main: lightGreen['100'],
    },
    green: {
      light: green['100'],
      main: green['200'],
    },
    darkGreen: {
      main: green['800'],
    },
    lightRed: {
      main: red['50'],
    },
    red: {
      light: red['100'],
      main: red['200'],
    },
    brightRed: {
      light: red['A100'],
      main: red['A200'],
    },
    blue: {
      light: blue['50'],
      main: blue['100'],
      dark: blue['700'],
    },
    lightBlue: {
      light: lightBlue['100'],
      dark: lightBlue['200'],
      main: blue['700'],
    },
    paleBlue: {
      main: lightBlue['50'],
    },
    black: {
      main: 'rgb(0, 0, 0)',
    },
    lightGrey: {
      main: '#ebebeb',
      dark: '#e6e6e6',
    },
    deepGrey: {
      main: '#515556',
    },
    lightBrown: {
      light: amber['100'],
      main: amber['200'],
    },
  },
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif',
    fontSize: 16,
  },
  nav: {
    width: '56px',
    widthOpen: '300px',
  },
  page: {
    padding: 24,
  },
  form: {
    fieldWidth: 300,
    fieldShrinkWidth: 142,
  },
});

/* Custom variables in theme official docs https://mui.com/material-ui/customization/theming/#custom-variables */
declare module '@mui/material/styles' {
  interface Theme {
    nav: {
      width: string;
      widthOpen: string;
    };
    page: {
      padding: number;
    };
    form: {
      fieldWidth: number;
      fieldShrinkWidth: number;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    nav?: {
      width?: string;
      widthOpen?: string;
    };
    page: {
      padding: number;
    };
    form: {
      fieldWidth: number;
      fieldShrinkWidth: number;
    };
  }
}

declare module '@mui/material/styles/createPalette' {
  export type TypeColors =
    | 'yellow'
    | 'teal'
    | 'orange'
    | 'darkOrange'
    | 'deepOrange'
    | 'deepPurple'
    | 'lightGreen'
    | 'darkGreen'
    | 'green'
    | 'primaryFocus'
    | 'primaryHover'
    | 'lightRed'
    | 'red'
    | 'brightRed'
    | 'black'
    | 'lightGrey'
    | 'deepGrey'
    | 'blue'
    | 'lightBlue'
    | 'paleBlue'
    | 'lightBrown'; /* Add other colors as needed. */
  type PaletteColors<T> = Record<TypeColors, T>;
  interface Palette extends PaletteColors<PaletteColor> {}
  interface PaletteOptions extends PaletteColors<PaletteColorOptions> {}
}
