import { Pub, State, Sub } from '../../../../shared/state/state.abstract';
import { BehaviorSubject, Observable, filter, map, merge, of, share, switchMap, tap } from 'rxjs';
import { addDays, format, subDays } from 'date-fns';
import {
  ProcurementProductDataAndStorageChangesRes,
  GetProcurementProductDataRes,
  GetProcurementProductStorageChangesRes,
  SaveProcurementProductDataRes,
  stockOfFreezerService,
} from '../../services/stockOfFreezer.service';
import { stockOfFreezerListState } from './stockOfFreezerList.state';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';
import {
  C_Save_Operation_Status,
  SaveProcurementProductDataMutationVariables,
} from '../../../../graphql/generatedModel';
import { DictStorageLocationsRes } from '../../../../shared/services/configsData/configsData.service';

export const initStockOfFreezerDetailsState: IStockOfFreezerDetailsState = {
  action: undefined,
  dataToSave: {},
  storageLocationOptions: [],
  stockOfFreezerTitle: '',
  changesInStorage: [],
  isEmptyRightSide: false,
  datePeriod: {
    dateFrom: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
    dateTo: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
  },
  productId: '',
};

class PubImpl extends Pub<IStockOfFreezerDetailsState> {
  init(params: IStockOfFreezerDetailsState) {
    this.emit('init', params);
  }
  recordData(dataToSave: IStockOfFreezerDetailsState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  save() {
    this.emit('save', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
  getProcurementProductStorageChanges(datePeriod: IDatePeriod) {
    this.emit('getProcurementProductStorageChanges', { datePeriod });
  }
}

class SubImpl extends Sub<IStockOfFreezerDetailsState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  private procurementChangesLoading$ = new BehaviorSubject<boolean>(false);
  private shareProcurementChangesLoading$: Observable<boolean> =
    this.procurementChangesLoading$.pipe(share());

  protected actionHandlers(): Observable<IStockOfFreezerDetailsState> {
    return merge(
      this.selectedProduct(),
      this.save(),
      this.getProcurementProductStorageChanges(),
    ).pipe(
      map((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
        return state;
      }),
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  procurementChangesLoading(): Observable<boolean> {
    return this.shareProcurementChangesLoading$;
  }

  private save(): Observable<IStockOfFreezerDetailsState> {
    return this.actionListener('save').pipe(
      tap(({ dataToSave }) => {
        stockOfFreezerService.pub.saveProcurementProductData({
          dataToSave,
        } as SaveProcurementProductDataMutationVariables);
      }),
      switchMap((state) => {
        return stockOfFreezerService.sub.saveProcurementProductData().pipe(
          responseHandler<SaveProcurementProductDataRes>({
            success: () => 'common.successfully_saved',
            customErrorHandler: () => 'common.error_chnages_not_saved',
            errorReturnType: {
              status: C_Save_Operation_Status.SOS0_DATA_UNCHANGED,
            },
          }),
          filter((v) => v !== undefined),
          map(() => {
            const updatedProductFields = state?.dataToSave as Partial<GetProcurementProductDataRes>;
            return {
              ...state,
              selectedProductInfo: {
                ...state.selectedProductInfo,
                ...updatedProductFields,
              },
            } as IStockOfFreezerDetailsState;
          }),
        );
      }),
    );
  }

  private getProcurementProductStorageChanges(): Observable<IStockOfFreezerDetailsState> {
    return this.actionListener('getProcurementProductStorageChanges').pipe(
      tap(({ productId, datePeriod }) => {
        this.procurementChangesLoading$.next(true);
        stockOfFreezerService.pub.getProcurementProductStorageChanges({
          dateFrom: datePeriod?.dateFrom,
          dateTo: datePeriod?.dateTo,
          procurementProductId: productId,
        });
      }),
      switchMap((state) => {
        return stockOfFreezerService.sub.getProcurementProductStorageChanges().pipe(
          responseHandler<GetProcurementProductStorageChangesRes>({
            customErrorHandler: () => {
              this.procurementChangesLoading$.next(false);
              return 'common.server_error';
            },
            errorReturnType: [],
          }),
          map((changesInStorage) => {
            this.procurementChangesLoading$.next(false);
            return {
              ...state,
              changesInStorage,
            };
          }),
        );
      }),
    );
  }

  private selectedProduct(): Observable<IStockOfFreezerDetailsState> {
    return stockOfFreezerListState.sub.state().pipe(
      filter(
        ({ action }) =>
          action === 'productsList' || action === 'selectProduct' || action === 'filter',
      ),
      switchMap(({ selectedProduct }) => {
        if (!selectedProduct) {
          return of({
            ...initStockOfFreezerDetailsState,
            isEmptyRightSide: true,
            action: 'emptyData',
          } as IStockOfFreezerDetailsState);
        }
        this.loading$.next(true);
        const id = selectedProduct!.id;
        const state = this.stream$.getValue();
        const { datePeriod } = state || {};

        state.action = 'list.selectedProduct';
        state.isEmptyRightSide = false;

        const details = stockOfFreezerService.sub.getProcurementProductDataAndStorageChanges().pipe(
          responseHandler<ProcurementProductDataAndStorageChangesRes>({
            customErrorHandler: () => {
              this.loading$.next(false);
              return 'common.server_error';
            },
            errorReturnType: {
              productInfo: { id: 'errorId' },
              changesInStorage: [],
            },
          }),
          map(({ productInfo, changesInStorage }) => {
            const { productNo, description } = productInfo;
            state.selectedProductInfo = productInfo;
            state.stockOfFreezerTitle =
              productNo && description
                ? `${productNo} ${description}`
                : productNo || description || '';
            state.productId = id;
            state.changesInStorage = changesInStorage;

            this.loading$.next(false);
            return state;
          }),
        );

        stockOfFreezerService.pub.getProcurementProductDataAndStorageChanges({
          dateFrom: datePeriod?.dateFrom,
          dateTo: datePeriod?.dateTo,
          procurementProductId: id,
        });
        return details;
      }),
    );
  }
}

class StockOfFreezerDetailsState extends State<IStockOfFreezerDetailsState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const stockOfFreezerDetailsState = new StockOfFreezerDetailsState(
  initStockOfFreezerDetailsState,
);

interface IDatePeriod {
  dateFrom: string;
  dateTo: string;
}

export interface IStockOfFreezerDetailsState {
  action:
    | undefined
    | 'init'
    | 'loader'
    | 'list.selectedProduct'
    | 'recordData'
    | 'save'
    | 'emptyData'
    | 'internalUpdate'
    | 'getProcurementProductStorageChanges';
  dataToSave: GetProcurementProductDataRes | Record<string, any>;
  storageLocationOptions: DictStorageLocationsRes;
  stockOfFreezerTitle: string;
  selectedProductInfo?: GetProcurementProductDataRes;
  changesInStorage: GetProcurementProductStorageChangesRes;
  datePeriod: IDatePeriod;
  productId: string;
  isEmptyRightSide: boolean;
}
