import { Box } from '@mui/material';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { SideControls } from './sideControls/sideControls.component.tsx';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderGrid } from './orderGrid/orderGrid.component.tsx';
import { CustomerInfo } from '../../../common/customerInfo/customerInfo.component.tsx';
import { Content } from '../../../../../shared/components/content/content.component.tsx';
import {
  IOrderDetailsState,
  orderDetailsState,
} from '../../states/orderDetails/orderDetails.state.ts';
import { ICreateOrderLoader } from '../../../loaders/createOrder.loader.ts';
import { debounceTime, filter, Subscription } from 'rxjs';
import { OrderSelectionPopup } from './popups/orderSelection/orderSelection.popup.tsx';
import { ArticlesForOrderPositionListRes } from '../../../../../shared/services/configsData/configsData.service.ts';
import { TaxesByDateRes } from '../../../services/order.service.ts';
import { getDeliveryTime } from '../../../../../shared/helpers/utils/utils.helper.ts';

export const orderSelectionPopup = new OrderSelectionPopup();

// eslint-disable-next-line react-refresh/only-export-components
export let additionOptionsSubCreate: Subscription;

export const OrderDetails: FC<IOrderDetails> = ({
  initData: {
    initState,
    linkTextOptions,
    additionalOptions,
    rowReordering,
    deliveryFunctionActive,
  },
}) => {
  const { transportSector, ...customerInfo } = initState.customerInfo;
  const [orderDetails, setOrderDetails] = useState<TOrderDetails>({
    customerInfo,
    orderData: initState?.orderData[0] || {},
    noData: initState.noData,
    selectedPos: initState.selectedPos,
    dirty: false,
  });
  const [isPriceRateFixed, setIsPriceRateFixed] = useState(!!transportSector?.isPriceRateFixed);
  const [orderGridOptions, setOrderGridOptions] = useState<IOrderGridOptions>({
    productList: additionalOptions.productData?.productList,
    taxes: additionalOptions.taxes,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    orderDetailsState.pub.init({ ...initState, orderData: initState.orderData?.[0] ?? {} });
    if (initState?.orderData?.length > 1) {
      orderSelectionPopup.stream.emit('open', { orders: initState.orderData, saveBackup: true });
    }
    orderDetailsState.pub.initOptions(additionalOptions);
    const subOrderDetailsState = orderDetailsState.sub
      .state()
      .pipe(filter(({ action }) => action !== 'init'))
      .subscribe(
        ({
          customerInfo: { transportSector, ...customerInfoRest },
          orderData,
          noData,
          selectedPos,
          dirty,
        }) => {
          setOrderDetails({
            customerInfo: customerInfoRest,
            orderData,
            noData,
            selectedPos,
            dirty,
          });
          setIsPriceRateFixed(!!transportSector?.isPriceRateFixed);
        },
      );
    const subAdditionalOptions = orderDetailsState.sub.additionalOptions().subscribe((value) => {
      const {
        productData: { productList },
        taxes,
      } = value;
      setOrderGridOptions({ productList, taxes });
    });
    additionOptionsSubCreate = subAdditionalOptions;
    const unsubLoading = orderDetailsState.sub
      .loading()
      .pipe(debounceTime(0.5))
      .subscribe(setLoading);
    return () => {
      subOrderDetailsState.unsubscribe();
      unsubLoading.unsubscribe();
      subAdditionalOptions.unsubscribe();
      window.removeEventListener('beforeunload', pageLeaveHandle.current);
    };
  }, []);

  const pageLeaveHandle = useRef((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  });
  /* Show default browser alert window before leave or reload page if there are unsaved changes */
  useEffect(() => {
    if (orderDetails.dirty) {
      window.addEventListener('beforeunload', pageLeaveHandle.current);
    } else {
      window.removeEventListener('beforeunload', pageLeaveHandle.current);
    }
  }, [orderDetails.dirty]);

  const deliveryTime = useMemo(() => {
    return getDeliveryTime(orderDetails.orderData?.deliveryTime);
  }, [orderDetails.orderData?.deliveryTime]);

  const formMethods = useForm({
    values: {
      specialAddress:
        orderDetails.orderData?.specialAddress || orderDetails.customerInfo?.deliveryAddress,
      referenceOrderNo: orderDetails.orderData?.referenceOrderNo,
      note: orderDetails.orderData.note,
      note2: orderDetails.orderData.note2,
      deliveryTime,
      linkText: orderDetails.orderData.linkText,
    },
  });

  useEffect(() => {
    const formStateSub = formMethods.watch(({ deliveryTime, ...fields }, { name }) => {
      orderDetailsState.pub.updateOrderData({
        ...orderDetails.orderData,
        ...fields,
        ...(name === 'deliveryTime' && { deliveryTime }),
      });
    });

    return () => formStateSub.unsubscribe();
  }, [formMethods.watch, orderDetails.orderData]);
  return (
    <FormProvider {...formMethods}>
      <Content
        type='paper'
        emptyData={{ value: orderDetails.noData }}
        paperProps={{
          variant: 'outlined',
          sx: { flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' },
        }}
        {...{ loading }}
      >
        <CustomerInfo {...orderDetails.customerInfo} />
        <Box sx={{ p: 2, flexGrow: 1 }}>
          <SideControls
            errorText={
              !deliveryTime && typeof orderDetails.orderData?.deliveryTime === 'string'
                ? orderDetails.orderData?.deliveryTime
                : undefined
            }
            {...{ linkTextOptions }}
          />
          <OrderGrid
            data={structuredClone(orderDetails)}
            {...{ orderGridOptions, rowReordering, deliveryFunctionActive, isPriceRateFixed }}
          />
        </Box>
        <orderSelectionPopup.Component />
      </Content>
    </FormProvider>
  );
};

interface IOrderDetails {
  initData: ICreateOrderLoader['initOrderGridData'];
}

export interface IOrderGridOptions {
  productList: ArticlesForOrderPositionListRes;
  taxes: TaxesByDateRes;
}

export type TOrderDetails = Pick<
  IOrderDetailsState,
  'customerInfo' | 'orderData' | 'noData' | 'selectedPos' | 'dirty'
>;
