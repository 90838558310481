import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, FieldsRow, FieldsSection } from '../../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { ITabState } from '../../../../../../states/tabState.model.ts';
import { conditionsTabState, IConditionsTabState } from '../../../conditions.state.ts';
import { Table } from '../../../../../../../../shared/components/table/table.component.tsx';
import { Column } from '../../../../../../../../shared/components/table/components/common.components.tsx';
import { localeFormatterHelper } from '../../../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { Stack, styled } from '@mui/material';
import { storageHelper } from '../../../../../../../../shared/helpers/storage';
import { IndividualPricesPopup } from './popups/individualPrices.popup.tsx';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '../../../../../../../order/common/iconButton/iconButton.component.tsx';
import { delay } from 'rxjs';
import { useFormContext } from 'react-hook-form';

export const IndividualPrices: FC<IIndividualPrices> = ({
  customerSpecialPrices,
  productListOptions,
}) => {
  const { t } = useTranslation();
  const [specialPricesData, setSpecialPricesData] = useState(customerSpecialPrices);
  const [selectPos, setSelectPos] = useState<IConditionsTabState['selectedSpecialPricesPos']>(null);
  const { getValues } = useFormContext();
  const individualPricesPopup = useMemo(
    () =>
      new IndividualPricesPopup({
        productListOptions,
      }),
    [],
  );
  const saveSpecialPricesGrid = useRef((e: MouseEvent) => {
    if (!e.target) return;
    const target = e.target as HTMLElement;
    if (!target.closest('.specialPrices')) {
      conditionsTabState.pub.saveSpecialPricesGrid(getValues('id'));
    }
  });

  useEffect(() => {
    conditionsTabState.pub.initCustomerSpecialPrices(specialPricesData);
    const unsubState = conditionsTabState.sub
      .state()
      .subscribe(({ customerSpecialPrices, selectedSpecialPricesPos }) => {
        setSpecialPricesData(customerSpecialPrices);
        setSelectPos(selectedSpecialPricesPos);
      });
    individualPricesPopup.stream
      .state()
      .pipe(delay(0))
      .subscribe(({ action }) => {
        action === 'open' && document.removeEventListener('click', saveSpecialPricesGrid.current);
        action === 'close' && document.addEventListener('click', saveSpecialPricesGrid.current);
      });
    document.addEventListener('click', saveSpecialPricesGrid.current);
    return () => {
      unsubState.unsubscribe();
      individualPricesPopup.stream.unsubscribe();
      document.removeEventListener('click', saveSpecialPricesGrid.current);
    };
  }, []);

  const buttonList: IButtonList[] = useMemo(
    () => [
      {
        title: t('customer.addNewRecord'),
        icon: <AddIcon />,
        onClick: () =>
          individualPricesPopup.stream.emit('open', {
            values: {
              row: {
                articleNo: null,
                comment: null,
                description: null,
                fromDate: null,
                fromQuantity: null,
                id: `new_${Date.now()}`,
                articleId: '',
                regularPrice: null,
                specialDiscount: null,
                specialIsNoDiscount: false,
                specialPrice: null,
                specialPriceType: null,
                toDate: null,
              },
              field: 'description',
            },
          }),
      },
      {
        title: t('customer.deleteRecord'),
        icon: <RemoveIcon />,
        onClick: () => conditionsTabState.pub.deleteSpecialPricesPos(),
        disabled: !specialPricesData.length || !selectPos,
      },
    ],
    [t, specialPricesData, selectPos],
  );

  return (
    <FieldsSection
      className='specialPrices'
      nomt
      titleBoxSx={{ pb: 1 }}
      title={t('customer.articlePrices')}
    >
      <FieldsRow marginBottom={1} alignItems='center' spacing={0}>
        <Stack direction='row' spacing={2}>
          {buttonList.map(({ icon, ...props }, i) => (
            <IconButton key={i} color='primary' size='large' {...props}>
              {icon}
            </IconButton>
          ))}
        </Stack>
      </FieldsRow>
      <STable
        data={specialPricesData}
        initialState={{
          columns: storageHelper.local.getItem('customer.individualPricesColumnModel'),
        }}
        onChangeColumnModel={(v) =>
          storageHelper.local.setItem('customer.individualPricesColumnModel', v)
        }
        rowSelectionModel={selectPos?.id || undefined}
        onRowClick={(v, e) => {
          conditionsTabState.pub.selectSpecialPricesPos(v.row);
          individualPricesPopup.stream.emit('open', {
            values: { row: v.row, field: (e.target as any).parentElement?.dataset?.field },
          });
        }}
        focusTabProps={{
          enabled: true,
          enabledFields: [
            'fromDate',
            'toDate',
            'description',
            'specialPrice',
            'specialDiscount',
            'specialIsNoDiscount',
            'fromQuantity',
            'comment',
          ],
        }}
        onCellKeyDown={({ row, field }, { code }) => {
          if (code === 'Enter') {
            conditionsTabState.pub.selectSpecialPricesPos(row);
            individualPricesPopup.stream.emit('open', {
              values: { row, field },
            });
          }
        }}
        height={400}
      >
        <Column
          field='fromDate'
          headerName={t('common.from_date')}
          valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
          sortable={false}
          width={105}
        />
        <Column
          field='toDate'
          headerName={t('common.to_date')}
          valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
          sortable={false}
          width={105}
        />
        <Column
          field='description'
          headerName={t('customer.article')}
          valueGetter={({ value, row }) => row.articleNo && `${row.articleNo} ${value}`}
          sortable={false}
          width={250}
        />
        <Column
          field='regularPrice'
          headerName={t('customer.stdPrice')}
          align='right'
          headerAlign='right'
          sortable={false}
          width={100}
          valueGetter={({ value }) =>
            value !== undefined && value !== null
              ? localeFormatterHelper.formatNumber(value, {
                  returnZero: false,
                })
              : value
          }
        />
        <Column
          field='specialPrice'
          headerName={t('common.price')}
          align='right'
          headerAlign='right'
          sortable={false}
          width={100}
          valueGetter={({ value }) =>
            value !== undefined && value !== null
              ? localeFormatterHelper.formatNumber(value, {
                  returnZero: false,
                })
              : value
          }
        />
        <Column
          field='specialDiscount'
          headerName={t('common.discount')}
          align='right'
          headerAlign='right'
          sortable={false}
          width={90}
          valueGetter={({ value }) =>
            value !== undefined && value !== null
              ? `${localeFormatterHelper.formatNumber(value, {
                  precision: 2,
                  noTrailingZeros: true,
                })}%`
              : value
          }
        />
        <Column
          field='specialIsNoDiscount'
          headerName={t('customer.noDiscount')}
          sortable={false}
          align='center'
          headerAlign='center'
          renderCell={({ value }) => (
            <SStack data-field='specialIsNoDiscount'>
              <Checkbox disabled size='small' checked={value} />
            </SStack>
          )}
          width={110}
        />
        <Column
          field='fromQuantity'
          headerName={t('customer.fromQuantity')}
          align='right'
          headerAlign='right'
          sortable={false}
          valueGetter={({ value }) =>
            value
              ? localeFormatterHelper.formatNumber(value, {
                  precision: 3,
                  noTrailingZeros: true,
                })
              : ''
          }
          width={125}
        />
        <Column
          field='specialPriceType'
          headerName={t('customer.kind')}
          sortable={false}
          valueGetter={({ value }) => value && t(`enums.${value}`)}
          width={200}
        />
        <Column field='comment' headerName={t('customer.comment')} sortable={false} width={300} />
      </STable>
      <individualPricesPopup.Component />
    </FieldsSection>
  );
};

const STable = styled(Table)({
  '& .MuiDataGrid-cell': {
    padding: 0,
  },
  '& .MuiDataGrid-cellContent': {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'inherit',
  },
});

const SStack = styled(Stack)({
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  '& > label': {
    margin: 0,
    cursor: 'pointer!important',
  },
  '& > label > span': {
    opacity: 0.6,
    color: 'inherit!important',
  },
  '& .Mui-checked': {
    color: '#1976d2!important',
    opacity: 1,
  },
});

export interface IIndividualPrices {
  customerSpecialPrices: ITabState['customerSpecialPrices'];
  productListOptions: ITabState['conditionsTabOptions']['productList'];
}

interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}
