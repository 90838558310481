/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { TableSelect } from '../../../../../../shared/components/table/styles/styleFields.ts';
import { IOrderGrid } from '../../../../create/components/orderDetails/orderGrid/orderGrid.component.tsx';
import { ISelectOption } from '../../../../../../shared/components/form/fields/select.component.tsx';
import {
  IOrderDetailsState as IOrderDetailsStateCreate,
  orderDetailsState as orderDetailsStateCreate,
} from '../../../../create/states/orderDetails/orderDetails.state.ts';
import {
  IOrderDetailsState as IOrderDetailsStateEdit,
  orderDetailsState as orderDetailsStateEdit,
} from '../../../../edit/states/orderDetails/orderDetails.state.ts';
import { ProductConfirmationPopup } from '../../../popups/productConfirmation.popup.tsx';
import { clearRowFromVirtualKeys } from '../splitting/additionalFunctions.tsx';
import { ProductMessagePopup } from '../../../popups/productMessage.popup.tsx';
import { useEnterKey } from '../../hooks/useEnterKey.ts';
import { BehaviorSubject, debounceTime } from 'rxjs';

export const ProductEditCell: FC<IProductEditCell> = ({ params, options, type }) => {
  const { row, api, id, field } = params;
  const cellStream$ = useMemo(
    () => new BehaviorSubject<{ type?: 'pressKey'; changed?: boolean }>({}),
    [],
  );

  const productMessagePopup = useMemo(() => new ProductMessagePopup(), []);
  const productConfirmationPopup = useMemo(() => new ProductConfirmationPopup(), []);
  const { handleEnterKey } = useEnterKey({ type, api });
  const { setEditCellValue, stopCellEditMode, getSortedRows, startCellEditMode } = api;
  const [inputValue, setInputValue] = useState(
    (options.find((el) => el?.articleNo === row.articleNo) && row.description) || undefined,
  );
  const [open, setOpen] = useState(true);
  const defaultValue = useMemo(() => {
    return options.find((el) => el?.id === row.articleId);
  }, []);
  const updateCell = useCallback(
    (value: IProductEditCell['options'][number]) => {
      setEditCellValue({ id, field, value: value?.label });
      const clearedRow = clearRowFromVirtualKeys(row);
      (type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit).pub.updateCell(
        {
          ...clearedRow,
          articleId: value?.id,
          articleNo: value?.articleNo,
          description: value?.label,
          vatCode: value?.vatCode!,
          quantityPerLot: value?.quantityPerLot,
          saleUnit: value?.saleUnit,
          weight: value?.weight,
          __reorder__: `(${value?.articleNo}) ${value?.label}`,
        },
        'descriptionCell',
      );
      cellStream$.value.type === 'pressKey' && handleEnterKey(field, id);
      stopCellEditMode({ id, field });
    },
    [row],
  );

  const checkProductListed = useCallback(
    (productListed: ProductListedType<typeof type>, value: IProductEditCell['options'][number]) => {
      if (productListed) {
        productConfirmationPopup.stream.emit('open', {
          values: { productListed, value },
        });
      } else {
        updateCell(value);
      }
    },
    [updateCell],
  );

  useEffect(() => {
    productConfirmationPopup.stream
      .state()
      .subscribe(({ action, values: { value, productListed } }) => {
        if (action !== 'close') {
          setOpen(false);
          if (action === 'editAmount') {
            const timer = setTimeout(() => {
              startCellEditMode({ id: productListed?.id!, field: 'quantity' });
              (type === 'create'
                ? orderDetailsStateCreate
                : orderDetailsStateEdit
              ).pub.selectPosition(productListed);
              clearTimeout(timer);
            }, 0);
            stopCellEditMode({ id, field });
          } else if (action === 'addArticle') {
            updateCell(value);
          } else if (action === 'cancel') {
            cellStream$.value.type === 'pressKey' && handleEnterKey(field, id);
            stopCellEditMode({ id, field });
          }
        }
      });
    productMessagePopup.stream.state().subscribe(({ action, values: { value, productListed } }) => {
      setOpen(false);
      if (action === 'ok') {
        checkProductListed(productListed, value);
      }
    });
    const cellStreamSub = cellStream$
      .pipe(
        debounceTime(20), // waiting to the answer of handleChange
      )
      .subscribe(({ type, changed }) => {
        if (type === 'pressKey' && !changed) {
          handleEnterKey(field, id);
        }
      });
    return () => {
      productConfirmationPopup.stream.unsubscribe();
      productMessagePopup.stream.unsubscribe();
      cellStreamSub.unsubscribe();
    };
  }, []);

  const handleChange = (_: SyntheticEvent, v: ISelectOption | null) => {
    const value = v as IProductEditCell['options'][number];
    cellStream$.next({ ...cellStream$.getValue(), changed: true });
    const productListed = getSortedRows().find((el) => el.articleId === v?.id) as ProductListedType<
      typeof type
    >;
    if (value?.warningMsgOnSelect) {
      productMessagePopup.stream.emit('open', { values: { productListed, value } });
    } else {
      checkProductListed(productListed, value);
    }
  };
  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        (option.articleNo?.toLowerCase().includes(inputValue?.toLowerCase()) ||
          option.label?.toLowerCase().includes(inputValue?.toLowerCase())) as boolean,
    );
  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.target instanceof HTMLInputElement && e.code === 'Enter') {
      cellStream$.next({ type: 'pressKey' });
    }
  };

  return (
    <>
      <TableSelect
        autoFocus
        datasetattribute='productEditSelect'
        openOnFocus
        onKeyDown={handleKeyDown}
        size='small'
        width='100%'
        fontSize='14px'
        autoHighlight={true}
        filterOptions={filterOptions}
        inputValue={inputValue}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === 'input') setInputValue(newInputValue);
        }}
        defaultValue={defaultValue as ISelectOption}
        options={options as []}
        disableClearable
        onChange={handleChange}
        {...{ open }}
      />
      <productConfirmationPopup.Component />
      <productMessagePopup.Component />
    </>
  );
};

type ProductListedType<T extends 'create' | 'edit'> = T extends 'create'
  ? IOrderDetailsStateCreate['selectedPos']
  : IOrderDetailsStateEdit['selectedPos'];

export interface IProductEditCell {
  params: GridRenderEditCellParams;
  options: IOrderGrid['orderGridOptions']['productList'];
  type: 'create' | 'edit';
}

interface ExtendedSelectOption extends ISelectOption {
  articleNo?: string;
}
