import { Table } from '../../../../../../../shared/components/table/table.component.tsx';
import { Column } from '../../../../../../../shared/components/table/components/common.components.tsx';
import React, { FC, useEffect, useState } from 'react';
import { localeFormatterHelper } from '../../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { NewOrderDataExtendedRes } from '../../../../../services/createOrder.service.ts';
import { IOrderDetailsState } from '../../../../states/orderDetails/orderDetails.state.ts';
import { IPopupWrapper } from '../../../../../../../shared/components/popup/components/popup.component.tsx';

export const Content: FC<IContent> = ({ tableData, fullTableData, stream }) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<TTableData>({});
  useEffect(() => {
    if (tableData.length) setSelectedRow(tableData?.[0]);
  }, [tableData]);
  return (
    <>
      <Typography variant='subtitle1' color={(theme) => theme.palette.grey.A700}>
        {t('order.select_order_title')}
      </Typography>
      <Box marginTop={3}>
        <Table
          rowSelectionModel={selectedRow?.orderNo ?? undefined}
          height={200}
          data={tableData as []}
          onRowClick={(data) => setSelectedRow(data.row)}
          replacedId='orderNo'
        >
          <Column field='orderNo' headerName={t('common.delivery_no')} flex={1} />
          <Column
            field='orderDate'
            renderCell={({ value }) => (
              <React.Fragment children={localeFormatterHelper.formatDate(value)} />
            )}
            headerName={t('common.date')}
            flex={1}
          />
          <Column field='orderTotal' headerName={t('common.total')} flex={1} />
        </Table>
      </Box>
      <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
        <Button
          variant='contained'
          onClick={() => {
            const confirmedOrder =
              fullTableData?.find(
                (item) => item?.orderSourceInfo?.orderNo === selectedRow?.orderNo,
              ) || {};
            stream.emit('confirm', { confirmedOrder });
            stream.emit('close');
          }}
        >
          {t('common.confirm')}
        </Button>
      </Stack>
    </>
  );
};

export interface IContent {
  tableData: TTableData[];
  fullTableData: NewOrderDataExtendedRes;
  stream: IPopupWrapper['stream'];
}

type TTableData = IOrderDetailsState['orderData']['orderSourceInfo'];
