import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
/* Icons */
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import ExpandIcon from '@mui/icons-material/Expand';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RemoveIcon from '@mui/icons-material/Remove';

import EventIcon from '@mui/icons-material/Event';
import FlipIcon from '@mui/icons-material/Flip';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { Box, Button, Stack, styled } from '@mui/material';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { delay } from 'rxjs';
import { WeekPicker } from '../../../../../../../shared/components/datePicker/weekPicker.component.tsx';
import { FieldsRow, FieldsSection } from '../../../../../../../shared/components/form';
import { Column } from '../../../../../../../shared/components/table/components/common.components.tsx';
import { Table } from '../../../../../../../shared/components/table/table.component.tsx';
import { ToggleActionButton } from '../../../../../../../shared/components/toggleActionButton/toggleActionButton.tsx';
import { storageHelper } from '../../../../../../../shared/helpers/storage';
import { formatValue } from '../../../../../../../shared/helpers/utils/utils.helper.ts';
import { DictArticlesRes } from '../../../../../../../shared/services/configsData/configsData.service.ts';
import { IconButton } from '../../../../../../order/common/iconButton/iconButton.component.tsx';
import { customerTabLoadingService } from '../../../customerTabLoading.service.ts';
import {
  defaultOrderState,
  IDefaultOrderState,
  ISplittingFormData,
} from '../../defaultOrder.state.ts';
import { attachMinMaxToQuantity } from './gridItems/cells/additionalFunctions.ts';
import { ChangeInOrderCell } from './gridItems/cells/changeInOrder.cell.tsx';
import { MaxQuantityEditCell } from './gridItems/cells/maxQuantityEdit.cell.tsx';
import { MinQuantityEditCell } from './gridItems/cells/minQuantityEdit.cell.tsx';
import { ProductCell } from './gridItems/cells/product/product.cell.tsx';
import { ProductEditCell } from './gridItems/cells/product/productEdit.cell.tsx';
import { QuantityEditCell } from './gridItems/cells/quantityEdit.cell.tsx';
import { viewCells } from './gridItems/views/viewCells.ts';
import { viewRows } from './gridItems/views/viewRows.ts';
import { DeliverySplittingPopup } from './popups/deliverySplitting/deliverySplitting.popup.tsx';
import { PersonCopyIcon } from '../../../../../../../svg/custom.svg.tsx';
import { CopyDefaultOrderPopup } from './popups/copyDefaultOrder/copyDefaultOrder.popup.tsx';

export const DefaultOrderGrid: FC<IDefaultOrderGrid> = ({ data, productList, formMethods }) => {
  const { t } = useTranslation();
  const deliverySplittingPopup = useMemo(() => new DeliverySplittingPopup(), []);
  const copyDefaultOrderPopup = useMemo(() => new CopyDefaultOrderPopup(), []);
  const { defaultOrderGridData, selectedPos, fromDate } = data;
  const [lockGrid, setLockGrid] = useState(true);
  const [loadingGrid, setLoadingGrid] = useState(false);
  const [visibleLimit, setVisibleLimit] = useState(false);
  const [cellsLoading, setCellsLoading] = useState<string[]>([]);
  const saveDefaultOrderGrid = useRef((e: MouseEvent) => {
    if (!e.target) return;
    const target = e.target as HTMLElement;
    if (!target.closest('.defaultOrder') && !target.closest('[datatype=productEditSelect]')) {
      defaultOrderState.pub.saveDefaultOrderGrid();
    }
  });

  const buttonList: IButtonList[] = useMemo(
    () => [
      {
        title: t('customer.addNewRecord'),
        icon: <AddIcon />,
        onClick: () => defaultOrderState.pub.addPosition(),
        disabled: lockGrid,
      },
      {
        title: t('customer.deleteRecord'),
        icon: <RemoveIcon />,
        onClick: () => defaultOrderState.pub.deletePosition(),
        disabled: lockGrid,
      },
      {
        title: t('customer.orderLimit'),
        icon: <ExpandIcon />,
        active: visibleLimit,
        onClick: () => {
          const timer = setTimeout(() => {
            setVisibleLimit((prevState) => !prevState);
            clearTimeout(timer); // fixes the problem if the cell is in edit mode
          }, 0);
        },
      },
      {
        title: t('customer.deliverySplittingConfig'),
        icon: <FlipIcon />,
        disabled: lockGrid || !defaultOrderGridData.length,
        onClick: () => {
          deliverySplittingPopup.stream.emit('open', {
            tableData: structuredClone(defaultOrderGridData.filter((el) => el.articleId)),
          });
          document.removeEventListener('click', saveDefaultOrderGrid.current);
        },
      },
      {
        title: t('customer.copy_default_order'),
        icon: <PersonCopyIcon />,
        onClick: () =>
          copyDefaultOrderPopup.stream.emit('open', {
            currentCustomerId: data?.customerId as string,
          }),
      },
    ],
    [t, visibleLimit, lockGrid, defaultOrderGridData],
  );

  useEffect(() => {
    const customerTabLoadingSub = customerTabLoadingService.sub.loading().subscribe((loading) => {
      if (!loading) setLockGrid(true);
    });
    const loadingMainGridSub = defaultOrderState.sub.loadingMainGrid().subscribe(setLoadingGrid);
    const cellsLoadingSub = defaultOrderState.sub.cellsLoading().subscribe(setCellsLoading);
    deliverySplittingPopup.stream
      .state()
      .pipe(delay(0))
      .subscribe(({ action }) => {
        if (action === 'close') {
          document.addEventListener('click', saveDefaultOrderGrid.current);
        }
      });
    document.addEventListener('click', saveDefaultOrderGrid.current);
    return () => {
      customerTabLoadingSub.unsubscribe();
      loadingMainGridSub.unsubscribe();
      cellsLoadingSub.unsubscribe();
      deliverySplittingPopup.stream.unsubscribe();
      document.removeEventListener('click', saveDefaultOrderGrid.current);
    };
  }, []);

  return (
    <Box marginTop={3} className='defaultOrder'>
      <FieldsSection title={t('customer.defaultOrderItems')} titleBoxSx={{ pt: 0 }} nomt>
        <FieldsRow marginBottom={1} justifyContent='space-between' alignItems='center' spacing={0}>
          <Stack direction='row' spacing={2}>
            {buttonList.map(({ icon, ...props }, i) => (
              <IconButton key={i} color='primary' size='large' {...props}>
                {icon}
              </IconButton>
            ))}
            <ToggleActionButton
              color='primary'
              size='large'
              icon={<EventIcon />}
              title={t('customer.articlesAvailabilityOn')}
            >
              <Box p={1}>
                <WeekPicker
                  value={fromDate}
                  onBlur={([fromDate]) => {
                    if (fromDate) defaultOrderState.pub.fromDate(fromDate);
                  }}
                  width={320}
                />
              </Box>
            </ToggleActionButton>
          </Stack>
          <Button
            variant='outlined'
            onClick={() => setLockGrid((prevState) => !prevState)}
            startIcon={lockGrid ? <LockIcon /> : <LockOpenIcon />}
          >
            {t('customer.editing')}
          </Button>
        </FieldsRow>
      </FieldsSection>

      <STable
        initialState={{
          columns: storageHelper.local.getItem('defaultOrder.mainGridColumnModel'),
        }}
        loading={loadingGrid}
        data={defaultOrderGridData}
        heightOffset={374}
        isCellEditable={() => !lockGrid}
        emptyTableIndication={{
          icon: <LibraryAddIcon />,
          text: t('order.empty_order_grid'),
        }}
        onChangeColumnModel={(v) =>
          storageHelper.local.setItem('defaultOrder.mainGridColumnModel', v)
        }
        hidingColumnModel={{
          generatedModelFn: attachMinMaxToQuantity,
        }}
        onRowClick={(e) => {
          if (selectedPos?.id !== e.id) defaultOrderState.pub.selectPosition(e.row);
        }}
        rowSelectionModel={selectedPos?.id || undefined}
        onCellEditStop={(v, e) => {
          if (v.reason === 'enterKeyDown') {
            e.defaultMuiPrevented = true;
          }
        }}
        columnVisibilityModel={{
          group_limit: visibleLimit,
        }}
        rowReordering={!lockGrid}
        onChangeOrderPosition={(v) => {
          defaultOrderState.pub.updateGridData(v as []);
        }}
        onRowsCountChange={(rows, prevRows, apiRef) => {
          if (rows.length > prevRows.length && !lockGrid) {
            const newRow = rows?.[rows.length - 1];
            if (newRow.__reorder__ === t('order.new_position')) {
              apiRef?.current.startCellEditMode({ id: newRow.id as string, field: 'description' });
            }
          }
        }}
        {...(!lockGrid && {
          focusTabProps: { enabled: true, enabledFields: ['canChangeInOrders'] },
        })}
        {...{ viewCells, viewRows }}
      >
        <Column sortable={false} headerName={t('common.number')} field='articleNo' width={100} />
        <Column
          sortable={false}
          headerName={t('common.description')}
          width={350}
          field='description'
          renderCell={(params) => <ProductCell {...{ params, loading: cellsLoading }} />}
          renderEditCell={(params) => (
            <ProductEditCell {...{ params, productOptions: productList }} />
          )}
          editable
        />
        <Column
          sortable={false}
          headerName={t('common.mon')}
          field='mondaySettings.quantity'
          valueGetter={({ row }) => row.mondaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <QuantityEditCell {...{ params }} />}
          width={90}
          headerAlign='right'
          align='right'
          editable
        />
        <Column
          sortable={false}
          field='limit.mondaySettings.quantityMin'
          disableReorder={true}
          valueGetter={({ row }) => row.mondaySettings.quantityMin}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MinQuantityEditCell {...{ params }} />}
          headerName={t('common.min')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          field='limit.mondaySettings.quantityMax'
          disableReorder={true}
          valueGetter={({ row }) => row.mondaySettings.quantityMax}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MaxQuantityEditCell {...{ params }} />}
          headerName={t('common.max')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          headerName={t('common.tue')}
          field='tuesdaySettings.quantity'
          valueGetter={({ row }) => row.tuesdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <QuantityEditCell {...{ params }} />}
          width={90}
          headerAlign='right'
          align='right'
          editable
        />
        <Column
          sortable={false}
          field='limit.tuesdaySettings.quantityMin'
          disableReorder={true}
          valueGetter={({ row }) => row.tuesdaySettings.quantityMin}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MinQuantityEditCell {...{ params }} />}
          headerName={t('common.min')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          field='limit.tuesdaySettings.quantityMax'
          disableReorder={true}
          valueGetter={({ row }) => row.tuesdaySettings.quantityMax}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MaxQuantityEditCell {...{ params }} />}
          headerName={t('common.max')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          headerName={t('common.wed')}
          field='wednesdaySettings.quantity'
          valueGetter={({ row }) => row.wednesdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <QuantityEditCell {...{ params }} />}
          width={90}
          headerAlign='right'
          align='right'
          editable
        />
        <Column
          sortable={false}
          field='limit.wednesdaySettings.quantityMin'
          disableReorder={true}
          valueGetter={({ row }) => row.wednesdaySettings.quantityMin}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MinQuantityEditCell {...{ params }} />}
          headerName={t('common.min')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          field='limit.wednesdaySettings.quantityMax'
          disableReorder={true}
          valueGetter={({ row }) => row.wednesdaySettings.quantityMax}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MaxQuantityEditCell {...{ params }} />}
          headerName={t('common.max')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          headerName={t('common.thu')}
          renderEditCell={(params) => <QuantityEditCell {...{ params }} />}
          field='thursdaySettings.quantity'
          valueGetter={({ row }) => row.thursdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
          editable
        />
        <Column
          sortable={false}
          field='limit.thursdaySettings.quantityMin'
          disableReorder={true}
          valueGetter={({ row }) => row.thursdaySettings.quantityMin}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MinQuantityEditCell {...{ params }} />}
          headerName={t('common.min')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          field='limit.thursdaySettings.quantityMax'
          disableReorder={true}
          valueGetter={({ row }) => row.thursdaySettings.quantityMax}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MaxQuantityEditCell {...{ params }} />}
          headerName={t('common.max')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          headerName={t('common.fri')}
          renderEditCell={(params) => <QuantityEditCell {...{ params }} />}
          field='fridaySettings.quantity'
          valueGetter={({ row }) => row.fridaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
          editable
        />
        <Column
          sortable={false}
          field='limit.fridaySettings.quantityMin'
          disableReorder={true}
          valueGetter={({ row }) => row.fridaySettings.quantityMin}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MinQuantityEditCell {...{ params }} />}
          headerName={t('common.min')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          field='limit.fridaySettings.quantityMax'
          disableReorder={true}
          valueGetter={({ row }) => row.fridaySettings.quantityMax}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MaxQuantityEditCell {...{ params }} />}
          headerName={t('common.max')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          headerName={t('common.sat')}
          renderEditCell={(params) => <QuantityEditCell {...{ params }} />}
          field='saturdaySettings.quantity'
          valueGetter={({ row }) => row.saturdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
          editable
        />
        <Column
          sortable={false}
          field='limit.saturdaySettings.quantityMin'
          disableReorder={true}
          valueGetter={({ row }) => row.saturdaySettings.quantityMin}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MinQuantityEditCell {...{ params }} />}
          headerName={t('common.min')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          field='limit.saturdaySettings.quantityMax'
          disableReorder={true}
          valueGetter={({ row }) => row.saturdaySettings.quantityMax}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MaxQuantityEditCell {...{ params }} />}
          headerName={t('common.max')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          headerName={t('common.sun')}
          renderEditCell={(params) => <QuantityEditCell {...{ params }} />}
          field='sundaySettings.quantity'
          valueGetter={({ row }) => row.sundaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
          editable
        />
        <Column
          sortable={false}
          field='limit.sundaySettings.quantityMin'
          disableReorder={true}
          valueGetter={({ row }) => row.sundaySettings.quantityMin}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MinQuantityEditCell {...{ params }} />}
          headerName={t('common.min')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          field='limit.sundaySettings.quantityMax'
          disableReorder={true}
          valueGetter={({ row }) => row.sundaySettings.quantityMax}
          valueFormatter={({ value }) => formatValue(value)}
          renderEditCell={(params) => <MaxQuantityEditCell {...{ params }} />}
          headerName={t('common.max')}
          align='right'
          headerAlign='right'
          width={80}
          editable
        />
        <Column
          sortable={false}
          headerName={t('customer.changesProhibited')}
          field='canChangeInOrders'
          width={170}
          headerAlign='center'
          align='center'
          renderCell={(params) => <ChangeInOrderCell {...{ params, disabled: lockGrid }} />}
        />
      </STable>
      <copyDefaultOrderPopup.Component />
      <FormProvider {...formMethods}>
        <deliverySplittingPopup.Component />
      </FormProvider>
    </Box>
  );
};

export interface IDefaultOrderGrid {
  data: Omit<IDefaultOrderState, 'formData' | 'splittingFormData'>;
  productList: DictArticlesRes;
  formMethods: UseFormReturn<ISplittingFormData>;
}
interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}

const STable = styled(Table)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders [data-field^="limit"]': {
    backgroundColor: theme.palette.grey['200'],
  },
}));
