import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { INewOfferDataExtendedRes } from '../../../../services/offer.service.ts';
import { Table } from '../../../../../../shared/components/table/table.component.tsx';
import { Column } from '../../../../../../shared/components/table/components/common.components.tsx';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { IPopupWrapper } from '../../../../../../shared/components/popup/components/popup.component.tsx';
import { IOfferDetailsState } from '../../../../states/offerDetails/offerDetails.state.ts';

export const Content: FC<IContent> = ({ tableData, fullTableData, stream }) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<TTableData>({});
  useEffect(() => {
    if (tableData.length) setSelectedRow(tableData?.[0]);
  }, [tableData]);
  return (
    <>
      <Typography variant='subtitle1' color={(theme) => theme.palette.grey.A700}>
        {t('offer.select_offer_title')}
      </Typography>
      <Box marginTop={3}>
        <Table
          rowSelectionModel={selectedRow?.offerNo ?? undefined}
          height={200}
          data={tableData as []}
          onRowClick={(data) => setSelectedRow(data.row)}
          replacedId='offerNo'
        >
          <Column field='offerNo' headerName={t('common.delivery_no')} flex={1} />
          <Column
            field='offerForDate'
            renderCell={({ value }) => (
              <React.Fragment children={localeFormatterHelper.formatDate(value)} />
            )}
            headerName={t('common.date')}
            flex={1}
          />
          <Column field='offerTotal' headerName={t('common.total')} flex={1} />
        </Table>
      </Box>
      <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
        <Button
          variant='contained'
          onClick={() => {
            const confirmedOffer =
              fullTableData?.find(
                (item) => item?.offerSourceInfo?.offerNo === selectedRow?.offerNo,
              ) || {};
            stream.emit('confirm', { confirmedOffer });
            stream.emit('close');
          }}
        >
          {t('common.confirm')}
        </Button>
      </Stack>
    </>
  );
};

export interface IContent {
  tableData: TTableData[];
  fullTableData: INewOfferDataExtendedRes;
  stream: IPopupWrapper['stream'];
}

type TTableData = IOfferDetailsState['offerData']['offerSourceInfo'];
