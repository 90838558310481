import React, { FC, useCallback } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

import { TableNumericField } from '../../../../../../shared/components/table/styles/styleFields.ts';
import {
  IVersionsWorkerState,
  versionsWorkerState,
} from '../../../states/versionsWorker/versionsWorker.state.ts';

export const ProducedQuantityEditCell: FC<INumberEditCell> = ({
  params,
  versions,
  onChangeAvailability,
}) => {
  const { value, field, id, api, row } = params;
  const { setEditCellValue, stopCellEditMode, startCellEditMode, scrollToIndexes } = api;

  const updateCell = useCallback(
    (v: number | null) => {
      if (typeof Number(v) === 'number' && value !== v) {
        setEditCellValue({ id, field, value: v });
        const path = field.split('.');
        row[path[0]] = v;
        const updatedProducedArticle = (
          producedArticlesArr: IVersionsWorkerState['producedArticles' | 'recordsToSave'],
        ) =>
          producedArticlesArr.map((article) =>
            article.recordId === row.recordId ? { ...article, [path[0]]: v } : article,
          );

        versionsWorkerState.pub.updateVersionWorkerState({
          ...versions,
          producedArticles: updatedProducedArticle(versions.producedArticles),
          recordsToSave: updatedProducedArticle(versions.recordsToSave),
        });
        onChangeAvailability(false);
      }
    },
    [params],
  );
  const handleKeyDown = (v: React.KeyboardEvent) => {
    if (v.target instanceof HTMLInputElement && v.code === 'Enter') {
      const value = v.target.value.replace(/,/g, '.');
      updateCell(value ? Number(value) : null);
      const sortedRowIds = api.getSortedRowIds();
      const currentRowIndex = sortedRowIds.indexOf(id);
      const nextRowId = sortedRowIds[currentRowIndex + 1];
      if (nextRowId) {
        const timer = setTimeout(() => {
          startCellEditMode({ id: nextRowId, field });
          clearTimeout(timer);
        }, 0);
        scrollToIndexes({ rowIndex: Number(nextRowId) });
      } else {
        stopCellEditMode({ id, field });
      }
    }
  };
  return (
    <TableNumericField
      returnZero
      autoFocus
      onKeyDown={handleKeyDown}
      onBlur={updateCell}
      disableDecrease
      precision={3}
      noTrailingZeros
      defaultValue={value}
    />
  );
};

interface INumberEditCell {
  params: GridRenderCellParams;
  versions: IVersionsWorkerState;
  onChangeAvailability: (isAvailable: boolean) => void;
}
