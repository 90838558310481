import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { BehaviorSubject, map, merge, Observable, share, switchMap, tap } from 'rxjs';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract.ts';
import { ListProcurementProductsQueryVariables } from '../../../../graphql/generatedModel.ts';
import {
  ListProcurementProductsRes,
  stockOfFreezerService,
} from '../../services/stockOfFreezer.service.ts';
import { dataHelper } from '../../../../shared/helpers/data/data.helper.ts';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';
import { storageHelper } from '../../../../shared/helpers/storage';

export const initStockOfFreezerListState: IStockOfFreezerListState = {
  action: undefined,
  filter: {
    isActive: true,
    procurementProductKindId: null,
    storageLocationId: null,
  },
  search: '',
  sortModel: [{ field: 'productNo', sort: 'asc' }],
  selectedProduct: undefined,
  productsList: [],
  allProductsListLength: 0,
};

class PubImpl extends Pub<IStockOfFreezerListState> {
  init(params: Partial<IStockOfFreezerListState>) {
    this.emit('init', params);
  }
  selectCustomer(selectedProduct?: IStockOfFreezerListState['selectedProduct']) {
    this.emit('selectProduct', {
      selectedProduct: selectedProduct || this.stream$.getValue().selectedProduct,
    });
  }
  sort(sortModel: IStockOfFreezerListState['sortModel']) {
    this.emit('sort', { sortModel });
  }
  search(search: string) {
    this.emit('search', { search });
  }
  filter(filter: IStockOfFreezerListState['filter']) {
    this.emit('filter', { filter });
  }
  productsList() {
    this.emit('productsList', {});
  }
  updateProduct(product: ListProcurementProductsRes[number]) {
    this.emit('updateProduct', { params: product });
  }
}

class SubImpl extends Sub<IStockOfFreezerListState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());

  protected actionHandlers(): Observable<IStockOfFreezerListState> {
    return merge(this.productsList(), this.updateState(), this.updateProduct()).pipe(
      map(({ productsList, allProductsListLength, ...rest }) => {
        let sortedProductsList = [...productsList];
        let selectedProduct = rest.selectedProduct;
        allProductsListLength = productsList.length;
        productsList = dataHelper
          .data(productsList as [])
          .sort({
            sortModel: rest.sortModel,
            callback: (data) => {
              sortedProductsList = data as [];
            },
          })
          .search({ search: rest.search, fields: ['productNo', 'description'] })
          .result() as IStockOfFreezerListState['productsList'];
        // Check if selectedProduct is in new list. Otherwise, select the first one from the list.
        if (rest.action === 'productsList' || rest.action === 'filter') {
          const foundSelectedRecord =
            productsList.find((item) => item?.id === selectedProduct?.id) || productsList[0];
          selectedProduct = foundSelectedRecord || null;
          rest.action = 'selectProduct';
          storageHelper.session.setItem('stockOfFreezer.selectedProduct', selectedProduct);
        }
        const updatedState = {
          ...rest,
          productsList,
          selectedProduct,
          allProductsListLength,
        };
        this.stream$.next({
          ...updatedState,
          productsList: sortedProductsList,
          action: 'internalUpdate',
        });
        return updatedState;
      }),
      tap(({ action }) => {
        if (action !== 'sort' && action !== 'search') this.loading$.next(false);
      }),
    );
  }
  loading(): Observable<boolean> {
    return this.shareLoading$;
  }
  /* PRIVATE METHODS start */
  private productsList(): Observable<IStockOfFreezerListState> {
    return this.actionListener(['productsList', 'filter']).pipe(
      tap(({ filter }) => {
        this.loading$.next(true);
        stockOfFreezerService.pub.procurementProductsList({ filter });
      }),
      switchMap((state) => {
        return stockOfFreezerService.sub.procurementProductsList().pipe(
          responseHandler<ListProcurementProductsRes>({ errorReturnType: [] }),
          map((productsList) => ({ ...state, productsList })),
        );
      }),
    );
  }

  private updateState(): Observable<IStockOfFreezerListState> {
    return this.actionListener(['search', 'sort', 'selectProduct']);
  }

  private updateProduct(): Observable<IStockOfFreezerListState> {
    return this.actionListener('updateProduct').pipe(
      map((state) => {
        const { params } = state;
        if (params) {
          const itemIdx = state.productsList.findIndex((item) => item.id === params.id);
          if (itemIdx !== -1) {
            const temp = [...state.productsList];
            temp.splice(itemIdx, 1, params);
            state.productsList = temp;
          }
        }
        return state;
      }),
    );
  }
  /* PRIVATE METHODS end */
}

class StockOfFreezerState extends State<IStockOfFreezerListState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const stockOfFreezerListState = new StockOfFreezerState(initStockOfFreezerListState);

export interface IStockOfFreezerListState {
  action:
    | 'init'
    | 'search'
    | 'sort'
    | 'productsList'
    | 'selectProduct'
    | 'filter'
    | 'loader'
    | 'internalUpdate'
    | 'updateProduct'
    | undefined;
  productsList: ListProcurementProductsRes;
  search: string;
  filter: ListProcurementProductsQueryVariables['filter'];
  sortModel: GridSortModel;
  selectedProduct: ListProcurementProductsRes[number] | undefined;
  allProductsListLength: number;
  params?: any;
}
