import { Pub, State, Sub } from '../../../shared/state/state.abstract.ts';
import {
  C_Offers_Filter_Mode,
  Scalars,
  Wa_OffersGridFilter,
} from '../../../graphql/generatedModel.ts';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import {
  BehaviorSubject,
  map,
  merge,
  Observable,
  share,
  switchMap,
  tap,
  delay,
  of,
  filter,
} from 'rxjs';
import { dataHelper, ISearchOptions } from '../../../shared/helpers/data/data.helper.ts';
import { format } from 'date-fns';
import { ListPotentialAndCreatedOffersRes, offerService } from '../services/offer.service.ts';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import { storageHelper } from '../../../shared/helpers/storage';
import { ISubmitData } from '../components/customerList/popups/oneTimeCustomer/components/content.tsx';
import {
  CreateOneTimeCustomerRes,
  SaveOneTimeCustomerRes,
  createOrderService,
} from '../../order/services/createOrder.service.ts';
import { snackbarService } from '../../../shared/components/snackbar/service/snackbar.service.ts';
import i18n from 'i18next';

export const initCustomerListState: ICustomerListState = {
  action: undefined,
  filter: {
    date: undefined,
    dateTo: undefined,
    filterBy: C_Offers_Filter_Mode.ACTIVE_CUSTOMERS,
  },
  search: '',
  sortModel: [{ field: 'customerNr', sort: 'asc' }],
  selectedCustomer: undefined,
  customerList: [],
  customerListLength: 0,
  isCreatedOffersFilter: false,
};

const searchFieldsForPotential: ISearchOptions['fields'] = ['customerNr', 'internalOrFullName'];
const searchFieldsForCreated: ISearchOptions['fields'] = [
  'customerNumberAndName',
  ['offerForDate'],
  'offerNo',
  'offerTotal',
];

class PubImpl extends Pub<ICustomerListState> {
  init(params: Partial<ICustomerListState>) {
    this.emit('init', params);
  }
  search(search: string) {
    this.emit('search', { search });
  }
  sort(sortModel: ICustomerListState['sortModel']) {
    this.emit('sort', { sortModel });
    storageHelper.local.setItem('offer.customerList.sortModel', sortModel);
  }
  selectCustomer(selectedCustomer: ICustomerListState['selectedCustomer']) {
    this.emit('selectCustomer', { selectedCustomer });
    storageHelper.session.setItem('offer.selectedCustomer', selectedCustomer);
  }

  customerList() {
    this.emit('customerList', {});
  }

  filter(filter: ICustomerListState['filter']) {
    this.emit('filter', { filter });
  }

  oneTimeCustomer(params: ISubmitData) {
    const { customerProfileId, id, isRegularUse, orderIsPaid: _, address: __, ...data } = params;
    const action = id ? 'saveOneTimeCustomer' : 'createOneTimeCustomer';
    if (!id) {
      createOrderService.pub.createOneTimeCustomer({ customerProfileId, data });
    } else {
      const dataToSave = {
        ...data,
        id,
        isOneTimeUse: !isRegularUse,
      };
      createOrderService.pub.saveOneTimeCustomerData({ dataToSave });
    }
    snackbarService.pub.show({
      content: i18n.t('offer.creatingCustomer'),
      type: 'loading',
      id: 'oneTimeCustomer',
      noAutoHide: true,
    });
    this.emit(action, {});
  }

  updateDraftStatus(args: { id: Scalars['ID']; draftOfferId: Scalars['ID'] | null }) {
    let { customerList, selectedCustomer } = structuredClone(this.stream$.getValue());
    let newId: string;
    let updatedCustomer = selectedCustomer;
    customerList = customerList.map((item) => {
      if (item?.id === args.id) {
        newId = `${item?.customerId || ''}${args.draftOfferId || ''}${item?.offerId || ''}`;
        updatedCustomer = { ...item, id: newId, draftOfferId: args.draftOfferId };
        return updatedCustomer;
      }
      return item;
    });
    if (selectedCustomer?.id === args.id) {
      selectedCustomer = updatedCustomer;
      storageHelper.session.setItem('offer.selectedCustomer', selectedCustomer);
    }
    this.emit('updateDraftStatus', { customerList, selectedCustomer });
  }

  reSelectCustomer() {
    const streamV = this.stream$.getValue();
    const { customerList, selectedCustomer } = streamV;
    let newSelectedCustomer = selectedCustomer;
    const newCustomerList = [...customerList];
    if (customerList.length > 0) {
      if (selectedCustomer) {
        const selectedInd = customerList.findIndex((v) => v.offerId === selectedCustomer?.offerId);
        newSelectedCustomer = customerList[selectedInd + 1] || customerList[selectedInd - 1];
        newCustomerList.splice(selectedInd, 1);
      }
    } else {
      newSelectedCustomer = undefined;
    }
    this.emit('selectCustomer', {
      selectedCustomer: newSelectedCustomer,
      customerList: newCustomerList,
    });
  }

  updateSelectedCustomerInfo(values: TUpdatedValuesOfSelectedCustomer, runBySave?: boolean) {
    const currState = structuredClone(this.stream$.getValue());
    const { selectedCustomer, filter } = currState;
    if (
      (filter.filterBy === C_Offers_Filter_Mode['OPEN_OFFERS'] ||
        filter.filterBy === C_Offers_Filter_Mode['ACCEPTED_OFFERS']) &&
      !runBySave
    ) {
      this.reSelectCustomer();
    } else {
      const selectedId = selectedCustomer?.id;
      let { customerList } = currState;

      customerList = customerList.map((customer: ICustomerListState['customerList'][number]) => {
        if (customer?.id === selectedId) {
          return { ...customer, ...values };
        }
        return customer;
      });

      const updatedSelectedCustomer = customerList.find((customer) => customer?.id === selectedId);

      this.emit('updateSelectedCustomerInfo', {
        customerList,
        selectedCustomer: updatedSelectedCustomer,
      });
    }
  }
}
class SubImpl extends Sub<ICustomerListState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  protected actionHandlers(): Observable<ICustomerListState> {
    return merge(
      this.initState(),
      this.customerList(),
      this.updateState(),
      this.oneTimeCustomer(),
    ).pipe(
      map(({ customerList, search, sortModel, customerListLength, ...rest }) => {
        customerListLength = customerList.length;

        const { sortedAndSearchedList, sortedList } = this.sortAndSearchList({
          customerList,
          sortModel,
          search,
          isCreatedOffersFilter: rest?.isCreatedOffersFilter,
        });

        const { action, selectedCustomer } = this.getSelectedRecord({
          action: rest.action,
          customerList: sortedAndSearchedList,
          selectedCustomer: rest.selectedCustomer,
        });

        const updatedState = {
          ...rest,
          action,
          selectedCustomer,
          customerList: sortedAndSearchedList,
          search,
          sortModel,
          customerListLength,
        };

        this.stream$.next({
          ...updatedState,
          customerList: sortedList,
          action: 'internalUpdate',
        });
        return updatedState;
      }),
      tap(({ action }) => {
        if (action !== 'sort' && action !== 'search') this.loading$.next(false);
      }),
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  sharedValues(): Observable<ISharedValues> {
    // if you need current values of this state in other places
    const values = this.stream$.getValue();
    return of({
      date: values?.selectedCustomer?.offerForDate || values.filter.date,
      customer: values?.selectedCustomer,
      lastCustomerInTable: values?.customerList?.length === 1,
    });
  }

  isCreatedOffersFilter(filter: ICustomerListState['filter']): boolean {
    const filterBy = filter?.filterBy;

    return (
      filterBy === C_Offers_Filter_Mode.OPEN_OFFERS ||
      filterBy === C_Offers_Filter_Mode.ACCEPTED_OFFERS ||
      filterBy === C_Offers_Filter_Mode.DECLINED_OFFERS
    );
  }

  private customerList(): Observable<ICustomerListState> {
    return this.actionListener(['customerList', 'filter']).pipe(
      tap(({ filter }) => {
        this.loading$.next(true);
        typeof filter.date === 'object' && (filter.date = format(filter.date, 'yyyy-MM-dd'));
        offerService.pub.getListPotentialAndCreatedOffers({ filter });
      }),
      switchMap((state) => {
        return offerService.sub.getListPotentialAndCreatedOffers().pipe(
          responseHandler<ICustomerListState['customerList']>({
            errorReturnType: [],
          }),
          map((customerList) => {
            const isCreatedOffersFilter = this.isCreatedOffersFilter(state.filter);
            const newSortModel = this.getSortModel({
              prevSortModel: state?.sortModel,
              isCreatedOffersFilter,
            });
            return { ...state, customerList, isCreatedOffersFilter, sortModel: newSortModel };
          }),
        );
      }),
    );
  }

  private oneTimeCustomer(): Observable<ICustomerListState> {
    return this.actionListener(['createOneTimeCustomer', 'saveOneTimeCustomer']).pipe(
      switchMap((state) => {
        const method =
          state.action === 'createOneTimeCustomer'
            ? 'createOneTimeCustomer'
            : 'saveOneTimeCustomerData';
        return createOrderService.sub[method]().pipe(
          responseHandler<CreateOneTimeCustomerRes | SaveOneTimeCustomerRes | null>({
            success: () => 'order.customerCreated',
            errorReturnType: null,
          }),
          tap(() => snackbarService.pub.hide('oneTimeCustomer')),
          filter((data) => !!data),
          map((v) => {
            const data = v as CreateOneTimeCustomerRes | SaveOneTimeCustomerRes;
            const updatedState = structuredClone(state);
            updatedState.action = 'selectCustomer';
            const foundSameCustomer = state.customerList.find(({ id }) => id === data.customerId);

            if (!foundSameCustomer) {
              const selectedCustomer: CustomerList[number] = {
                ...structuredClone(initCustomer),
                customerId: data.customerId,
                customerNr: data.customerNo,
                id: data.customerId,
                internalOrFullName: data.internalOrFullName,
                customerNumberAndName: `${data.customerNo} ${data.internalOrFullName}`,
              };
              updatedState.customerList = [...state.customerList, selectedCustomer];
              updatedState.selectedCustomer = selectedCustomer;
              return updatedState;
            }

            updatedState.selectedCustomer = foundSameCustomer;
            return updatedState;
          }),
        );
      }),
    );
  }

  private updateState(): Observable<ICustomerListState> {
    return this.actionListener([
      'search',
      'sort',
      'selectCustomer',
      'updateDraftStatus',
      'updateSelectedCustomerInfo',
    ]);
  }
  private initState(): Observable<ICustomerListState> {
    // emits the first value for correct operation of the pairwise method
    return this.actionListener('init').pipe(delay(0));
  }

  private sortAndSearchList(args: TSortAndSearchListArgs): ISortAndSearchListRes {
    const { customerList, sortModel, search, isCreatedOffersFilter } = args;
    let sortedList = structuredClone(customerList);
    const searchFields = isCreatedOffersFilter ? searchFieldsForCreated : searchFieldsForPotential;

    const sortedAndSearchedList = dataHelper
      .data(structuredClone(customerList) as [])
      .sort({
        sortModel,
        callback: (data) => {
          sortedList = data as [];
        },
      })
      .search({ search, fields: searchFields })
      .result() as ICustomerListState['customerList'];

    return { sortedAndSearchedList, sortedList };
  }

  private getSortModel(args: IGetSortModelArgs): ICustomerListState['sortModel'] {
    const { prevSortModel, isCreatedOffersFilter } = args;
    const sortField = prevSortModel?.[0]?.field;
    if (isCreatedOffersFilter) {
      const notAvaliableField = !searchFieldsForCreated.includes(sortField);
      if (notAvaliableField) {
        const defaultSortModel = [{ field: 'offerNo', sort: 'asc' }] as GridSortModel;
        storageHelper.local.setItem('offer.customerList.sortModel', defaultSortModel);
        return defaultSortModel;
      }
      return prevSortModel;
    }
    const notAvaliableField = !searchFieldsForPotential.includes(sortField);
    if (notAvaliableField) {
      const defaultSortModel = [{ field: 'customerNr', sort: 'asc' }] as GridSortModel;
      storageHelper.local.setItem('offer.customerList.sortModel', defaultSortModel);
      return defaultSortModel;
    }
    return prevSortModel;
  }

  private getSelectedRecord(args: IGetSelectedRecordArgs): IGetSelectedRecordRes {
    const { action, customerList, selectedCustomer } = args;
    const doNothing = !(action === 'customerList' || action === 'filter');
    if (doNothing) {
      return {
        action,
        selectedCustomer,
      };
    }
    const foundSelectedRecord =
      customerList.find((item) => item?.id === selectedCustomer?.id) || customerList[0];
    const newSelectedCustomer = foundSelectedRecord || null;
    storageHelper.session.setItem('offer.selectedCustomer', selectedCustomer);
    return { action: 'selectCustomer', selectedCustomer: newSelectedCustomer };
  }
}

class CustomerListState extends State<ICustomerListState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const customerListState = new CustomerListState(initCustomerListState);

export interface ICustomerListState {
  action:
    | 'init'
    | 'search'
    | 'sort'
    | 'selectCustomer'
    | 'customerList'
    | 'saveOneTimeCustomer'
    | 'createOneTimeCustomer'
    | 'loader'
    | 'filter'
    | 'updateDraftStatus'
    | 'internalUpdate'
    | 'updateDraftStatus'
    | 'updateSelectedCustomerInfo'
    | undefined;
  filter: Wa_OffersGridFilter;
  search: string;
  customerListLength: number;
  sortModel: GridSortModel;
  selectedCustomer: CustomerList[number] | undefined;
  customerList: CustomerList;
  isCreatedOffersFilter: boolean;
}

const initCustomer: CustomerList[number] = {
  customerId: '',
  customerNr: '',
  customerNumberAndName: '',
  draftOfferId: '0',
  id: '',
  internalOrFullName: '',
  isAccepted: null,
  isDeclined: null,
  offerForDate: null,
  offerId: '0',
  offerNo: null,
  offerTotal: null,
};

type IGetSelectedRecordArgs = Pick<
  ICustomerListState,
  'action' | 'customerList' | 'selectedCustomer'
>;
type IGetSelectedRecordRes = Pick<ICustomerListState, 'action' | 'selectedCustomer'>;

interface IGetSortModelArgs {
  prevSortModel: ICustomerListState['sortModel'];
  isCreatedOffersFilter: boolean;
}

type TSortAndSearchListArgs = Pick<
  ICustomerListState,
  'customerList' | 'sortModel' | 'search' | 'isCreatedOffersFilter'
>;
interface ISortAndSearchListRes {
  sortedList: ICustomerListState['customerList'];
  sortedAndSearchedList: ICustomerListState['customerList'];
}

export interface ISharedValues {
  date: Scalars['Date'];
  customer: ICustomerListState['selectedCustomer'];
  lastCustomerInTable: boolean;
}

export type CustomerList = ListPotentialAndCreatedOffersRes;

type TUpdatedValuesOfSelectedCustomer = Partial<Omit<CustomerList[number], 'id'>>;
