/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../../../../shared/components/popup/services/popup.service';
import { IOrderDetailsState } from '../../../../states/orderDetails/orderDetails.state.ts';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';
import {
  FieldsRow,
  FormDatePicker,
  FormNumericField,
  FormSelect,
  FormTextField,
  FormTimePicker,
} from '../../../../../../../shared/components/form';
import { ICustomer } from '../../../../../loaders/editOrder.loader';
import { useBeforeClosePopup } from '../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import equal from 'fast-deep-equal/react';

export class MoreControlsPopup extends Popup<IMoreControlsPopup> {
  Component: FC<TPopupComponent>;
  isCashAssist: boolean;
  customerList: ICustomer[];
  isEditable: boolean;
  constructor(params: { isCashAssist: boolean; customerList: ICustomer[]; isEditable: boolean }) {
    super();
    const stream = this.innerStream;
    this.isCashAssist = params.isCashAssist;
    this.customerList = params.customerList;
    this.isEditable = params.isEditable;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const { t } = useTranslation();
      const [initValues, setInitValues] = useState<IMoreControlsPopup['values']>({});
      const { resetField, getValues } = useFormContext();

      useEffect(() => {
        stream
          .actionListener('open')
          .subscribe(({ values }) => setInitValues(structuredClone(values)));
      }, []);

      const getDirtyStatus = (): boolean => {
        const {
          orderDate,
          customerId,
          caCashRegisterNo,
          caReceiptNo,
          caDeliveryTime,
          caDeliveryMethod,
          caUserName,
        } = getValues();
        return !equal(initValues, {
          orderDate,
          customerId,
          caCashRegisterNo,
          caReceiptNo,
          caDeliveryTime,
          caDeliveryMethod,
          caUserName,
        });
      };

      useBeforeClosePopup({
        stream,
        isDirty: getDirtyStatus(),
        handlers: () => {
          resetField('orderDate', { defaultValue: initValues.orderDate });
          resetField('customerId', { defaultValue: initValues.customerId });
          resetField('caCashRegisterNo', { defaultValue: initValues.caCashRegisterNo });
          resetField('caReceiptNo', { defaultValue: initValues.caReceiptNo });
          resetField('caDeliveryTime', { defaultValue: initValues.caDeliveryTime });
          resetField('caDeliveryMethod', { defaultValue: initValues.caDeliveryMethod });
          resetField('caUserName', { defaultValue: initValues.caUserName });
        },
        deps: [
          initValues.orderDate,
          initValues.customerId,
          initValues.caCashRegisterNo,
          initValues.caReceiptNo,
          initValues.caDeliveryTime,
          initValues.caDeliveryMethod,
          initValues.caUserName,
        ],
      });

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('order.more_settings')}
          PaperProps={{
            sx: {
              maxWidth: this.isCashAssist ? '548px' : '348px',
              '> div': {
                paddingTop: '0',
              },
            },
          }}
          fullWidth
        >
          <Typography variant='h5' my={2}>
            {t('order.order_settings')}
          </Typography>
          <Stack spacing={3}>
            <FormDatePicker
              name='orderDate'
              navigation={false}
              fieldProps={{ label: t('common.date'), disabled: !this.isEditable }}
              width={300}
            />
            <FormSelect
              name='customerId'
              options={this.customerList as []}
              label={t('common.customer')}
              width='300px'
              disableClearable
              disabled={!this.isEditable}
            />
          </Stack>

          {this.isCashAssist && (
            <>
              <Typography variant='h5' mt={5} mb={2}>
                {t('common.cashassist')}
              </Typography>
              <Stack spacing={3}>
                <FieldsRow>
                  <FormNumericField
                    name='caCashRegisterNo'
                    label={t('common.cash_register_id')}
                    width='242px'
                    noTrailingZeros
                    disabled={true}
                  />
                  <FormNumericField
                    name='caReceiptNo'
                    label={t('common.receipt_no')}
                    width='242px'
                    noTrailingZeros
                    disabled={true}
                  />
                </FieldsRow>
                <FieldsRow>
                  <FormTimePicker
                    name='caDeliveryTime'
                    width={242}
                    label={t('order.delivery_time')}
                    fieldProps={{ disabled: true }}
                  />
                  <FormTextField
                    name='caDeliveryMethod'
                    width='242px'
                    label={t('common.delivery_method')}
                    disabled={true}
                  />
                </FieldsRow>
                <FormTextField
                  name='caUserName'
                  width='500px'
                  label={t('common.cashassist_user')}
                  disabled={true}
                />
              </Stack>
            </>
          )}
          <Stack mt={6} direction='row' justifyContent='flex-end' spacing={2}>
            <Button
              onClick={() => stream.emit('close')}
              variant='contained'
              disabled={!this.isEditable}
            >
              {t('common.save')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

interface IMoreControlsPopup {
  action: TPopupDefaultActions;
  values: {
    orderDate?: IOrderDetailsState['orderData']['orderDate'];
    customerId?: IOrderDetailsState['orderData']['customerId'];
    caCashRegisterNo?: IOrderDetailsState['orderData']['caInfo']['cashRegisterNo'];
    caReceiptNo?: IOrderDetailsState['orderData']['caInfo']['receiptNo'];
    caDeliveryTime?: IOrderDetailsState['orderData']['caInfo']['deliveryTime'];
    caDeliveryMethod?: IOrderDetailsState['orderData']['caInfo']['deliveryMethod'];
    caUserName?: IOrderDetailsState['orderData']['caInfo']['userName'];
  };
}
