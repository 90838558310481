/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useLayoutEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { take } from 'rxjs';

import {
  CreateOrCopyPrintListRes,
  editPrintListService,
} from '../../../../services/editPrintList.service.ts';
import { GeneralForm } from './components/generalForm.component.tsx';
import { IGeneralTab } from './general.tab.tsx';
import { modeService } from '../../../../../../shared/services/mode/mode.service.ts';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler.ts';
import { editPrintListState } from '../../../states/editPrintList.state.ts';
import { Wa_CreateProductionPrintListInput } from '../../../../../../graphql/generatedModel.ts';
import { editPrintListTabLoadingService } from '../editPrintListTabLoading.service.ts';
import { storageHelper } from '../../../../../../shared/helpers/storage';

export const GeneralCreateTab: FC<Pick<IGeneralTab, 'mode'>> = ({ mode }) => {
  const { t } = useTranslation();
  const { getValues, handleSubmit } = useFormContext();

  const onSubmitForm = () => {
    editPrintListTabLoadingService.pub.loading(true);
    const defaultValues = editPrintListService.sub.initialGeneralTabPrintListData;
    const {
      id,
      reportGroupId,
      orderTypesFilter,
      customerListsFilter,
      articleListsFilter,
      tourplanConfigurationData,
      crosstableConfigurationData,
      ...dataForCreate
    } = {
      ...defaultValues,
      ...getValues(),
    };
    editPrintListService.pub.createPrintList({
      data: dataForCreate as Wa_CreateProductionPrintListInput,
    });
  };

  const onCancelCreateMode = () => {
    editPrintListState.pub.selectEditPrintList(
      storageHelper.session.getItem('editPrintList.selectedPrintList'),
    );
    modeService.pub.mode('edit');
  };

  useLayoutEffect(() => {
    editPrintListState.pub.unselectEditPrintList();

    const createEditPrintListSub = editPrintListService.sub
      .createPrintList()
      .pipe(
        take(1),
        responseHandler<CreateOrCopyPrintListRes>({
          success: () => 'production.edit_print_list_saved',
          customErrorHandler: () => {
            editPrintListTabLoadingService.pub.loading(false);
            return 'production.edit_print_list_not_saved';
          },
          errorReturnType: {
            id: 'errorId',
            name: '',
          },
        }),
      )
      .subscribe(() => editPrintListTabLoadingService.pub.loading(false));

    return () => createEditPrintListSub.unsubscribe();
  }, []);

  return (
    <>
      <GeneralForm mode={mode} />
      <Box mt={2} display='flex' gap={2}>
        <Button variant='outlined' onClick={onCancelCreateMode}>
          {t('common.cancel')}
        </Button>
        <Button variant='contained' onClick={handleSubmit(onSubmitForm)} type='submit'>
          {t('production.create_print_list')}
        </Button>
      </Box>
    </>
  );
};
