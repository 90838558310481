import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Content } from '../../../../../shared/components/content/content.component';
import { TabPanel } from '../../../../../shared/components/tabs';
import { createValidationSchema } from './validation/general.schema.ts';
import { GeneralTab } from './general.tab.tsx';
import { GeneralCreateTab, ICreateCustomerValues } from './generalCreate.tab.tsx';
import { GeneralCopyTab } from './generalCopy.tab.tsx';
import { CustomerGeneralDataRes } from '../../../services/customer.service.ts';
import { CustomerTabs, ITabState } from '../../../states/tabState.model.ts';
import { generalTabState, defaultCreateModeValues } from './generalTab.state.ts';
import { TMode } from '../../../../../shared/services/mode/mode.service.ts';

const validationSchema = createValidationSchema();

export const GeneralModeContainer: FC<IGeneralModeContainerProps> = ({ mode, tab }) => {
  const { defaultValues, generalTabOptions } = useLoaderData() as ITabState;
  const { t } = useTranslation();

  const [isCustomerIdExist, setIsCustomerIdExist] = useState<boolean>(Boolean(defaultValues?.id));
  const [editModeValues, setEditModeValues] = useState<CustomerGeneralDataRes>(defaultValues);
  const [loading, setLoading] = useState(false);

  const prevCustomerNoRef = useRef<string | null>(defaultValues?.customerNo || '');
  const prevValidationResRef = useRef<boolean>(true);

  const formMethods = useForm<CustomerGeneralDataRes, IGeneralFormContext>({
    mode: 'onChange',
    defaultValues,
    context: {
      prevCustomerNoRef,
      prevValidationResRef,
      setLoading,
    },
    resolver: yupResolver(validationSchema),
  });

  const { watch, reset, resetField, clearErrors } = formMethods;

  useEffect(() => {
    switch (mode) {
      case 'edit':
        clearErrors();
        reset(editModeValues);
        prevCustomerNoRef.current = editModeValues?.customerNo || null;
        break;
      case 'create':
        reset(
          {
            ...defaultCreateModeValues,
            customerProfileId: generalTabOptions.customerDefaultProfiles?.[0]?.id || null,
          } as ICreateCustomerValues,
          { keepErrors: true },
        );
        prevCustomerNoRef.current = defaultCreateModeValues.customerNo;
        break;
      case 'copy':
        reset({ ...editModeValues, name: null, customerNo: null } as CustomerGeneralDataRes, {
          keepErrors: true,
        });
        prevCustomerNoRef.current = null;
        break;
    }
  }, [mode]);

  useEffect(() => {
    const formStateSub = watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        if (mode === 'edit') {
          setEditModeValues((prevState) => ({
            ...prevState,
            ...{
              [fieldName]: data[fieldName],
            },
          }));
          generalTabState.pub.recordData({
            [fieldName]: data[fieldName],
            id: data.id,
          });
        }
      }
    });

    return () => {
      formStateSub.unsubscribe();
    };
  }, [mode]);

  useEffect(() => {
    const unsubcustomerIdNotExist = generalTabState.sub.customerIdNotExist().subscribe(() => {
      setIsCustomerIdExist(false);
      reset({});
    });
    const unsubGeneralTabState = generalTabState.sub
      .state()
      .subscribe(({ action, dataToSave, defaultValues }) => {
        setIsCustomerIdExist(true);
        if (action === 'list.selectedRecord') {
          setEditModeValues(defaultValues);
          reset(defaultValues);
        } else {
          const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
          resetField(name, {
            defaultValue: dataToSave[name],
            keepDirty: false,
            keepError: true,
          });
        }
      });
    return () => {
      unsubGeneralTabState.unsubscribe();
      unsubcustomerIdNotExist.unsubscribe();
    };
  }, []);

  return isCustomerIdExist || mode === 'create' ? (
    <FormProvider {...formMethods}>
      {mode === 'edit' && (
        <TabPanel value={CustomerTabs.general} activeValue={tab}>
          <GeneralTab mode={mode} loading={loading} />
        </TabPanel>
      )}
      {mode === 'create' && (
        <TabPanel value={CustomerTabs.general} activeValue={tab}>
          <GeneralCreateTab mode={mode} loading={loading} />
        </TabPanel>
      )}
      {mode === 'copy' && (
        <TabPanel value={CustomerTabs.general} activeValue={tab}>
          <GeneralCopyTab mode={mode} loading={loading} />
        </TabPanel>
      )}
    </FormProvider>
  ) : (
    <Content
      type='paper'
      paperProps={{
        variant: 'elevation',
        sx: {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          height: '100%',
        },
      }}
      emptyData={{
        value: true,
        title: t('common.no_data'),
      }}
    />
  );
};

interface IGeneralModeContainerProps {
  mode: TMode;
  tab: CustomerTabs;
}

interface IGeneralFormContext {
  prevCustomerNoRef: MutableRefObject<string | null>;
  prevValidationResRef: MutableRefObject<boolean>;
  setLoading: (v: boolean) => void;
}
