import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Content } from '../../../../../shared/components/content/content.component';
import { TMode } from '../../../../../shared/services/mode/mode.service';
import { ArticleTabs, ITabState } from '../../../states/tabState.model';
import { createValidationSchema } from './validation/general.schema';
import { GeneralTab } from './general.tab';
import { GeneralCreateTab } from './generalCreate.tab';
import { GeneralCopyTab } from './generalCopy.tab';
import { ArticleGeneralTabDataRes } from '../../../services/article.service.ts';
import { TabPanel } from '../../../../../shared/components/tabs/tabPanel.component.tsx';
import { generalTabState, defaultCreateModeValues } from './generalTab.state.ts';

const validationSchema = createValidationSchema();

export const GeneralModeContainer: FC<IGeneralModeContainerProps> = ({ mode, tab, isDough }) => {
  const { defaultValues, articleSpecialPrices: initSpecialPrice } = useLoaderData() as ITabState;
  const { t } = useTranslation();

  const [isArticleIdExist, setIsArticleIdExist] = useState<boolean>(Boolean(defaultValues?.id));
  const [editModeValues, setEditModeValues] = useState<ArticleGeneralTabDataRes>(defaultValues);
  const [specialPrices, setSpecialPrices] =
    useState<ITabState['articleSpecialPrices']>(initSpecialPrice);
  const [loading, setLoading] = useState(false);

  const prevArticleNoRef = useRef<string | null | undefined>(defaultValues?.articleNo || null);
  const prevValidationResRef = useRef<boolean>(true);

  const formMethods = useForm<ArticleGeneralTabDataRes, IGeneralFormContext>({
    mode: 'onChange',
    defaultValues,
    context: { prevArticleNoRef, prevValidationResRef, setLoading },
    resolver: yupResolver(validationSchema),
  });
  const { watch, reset, resetField, clearErrors } = formMethods;

  useEffect(() => {
    switch (mode) {
      case 'edit':
        clearErrors();
        reset(editModeValues);
        prevArticleNoRef.current = editModeValues?.articleNo || null;
        break;
      case 'create':
        reset(defaultCreateModeValues, { keepErrors: true });
        prevArticleNoRef.current = defaultCreateModeValues.articleNo;
        break;
      case 'copy':
        reset({ ...editModeValues, description: null, articleNo: null }, { keepErrors: true });
        prevArticleNoRef.current = null;
        break;
    }
  }, [mode]);

  useEffect(() => {
    const formStateSub = watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        const isNotePopup = fieldName === 'isNotifyOnOrderStage' || fieldName === 'note';
        if (isNotePopup) {
          // fields from notePopup
          const noteData = {
            note: data?.note,
            isNotifyOnOrderStage: data?.isNotifyOnOrderStage,
          };
          if (mode === 'edit') {
            setEditModeValues((prevState) => ({
              ...prevState,
              ...noteData,
            }));
          }

          generalTabState.pub.recordData({
            ...noteData,
            id: data.id,
          });
        } else {
          // simple form fields
          if (mode === 'edit') {
            setEditModeValues((prevState) => ({
              ...prevState,
              ...{
                [fieldName]: data[fieldName],
              },
            }));
          }

          generalTabState.pub.recordData({
            [fieldName]: data[fieldName],
            id: data.id,
          });
        }
      }
    });

    return () => {
      formStateSub.unsubscribe();
    };
  }, [mode]);

  useEffect(() => {
    const unsubarticleIdNotExist = generalTabState.sub.articleIdNotExist().subscribe(() => {
      setIsArticleIdExist(false);
      reset({});
    });

    const unsubGeneralTabState = generalTabState.sub
      .state()
      .subscribe(({ action, defaultValues, dataToSave, articleSpecialPrices }) => {
        setIsArticleIdExist(true);
        setSpecialPrices(articleSpecialPrices);
        if (action === 'list.selectedArticle') {
          setEditModeValues(defaultValues);
          reset(defaultValues);
        } else {
          const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
          resetField(name, {
            defaultValue: dataToSave[name],
            keepDirty: false,
            keepError: true,
          });
        }
      });

    return () => {
      unsubGeneralTabState.unsubscribe();
      unsubarticleIdNotExist.unsubscribe();
    };
  }, []);

  return isArticleIdExist || mode === 'create' ? (
    <FormProvider {...formMethods}>
      {mode === 'edit' && (
        <TabPanel value={ArticleTabs.general} activeValue={tab}>
          <GeneralTab
            loading={loading}
            mode={mode}
            isDough={isDough}
            specialPricesAfterCreateOrCopyMode={specialPrices}
          />
        </TabPanel>
      )}
      {mode === 'create' && (
        <TabPanel value={ArticleTabs.general} activeValue={tab}>
          <GeneralCreateTab mode={mode} loading={loading} />
        </TabPanel>
      )}
      {mode === 'copy' && (
        <TabPanel value={ArticleTabs.general} activeValue={tab}>
          <GeneralCopyTab
            mode={mode}
            isDough={isDough}
            loading={loading}
            initSpecialPrices={specialPrices}
          />
        </TabPanel>
      )}
    </FormProvider>
  ) : (
    <Content
      type='paper'
      paperProps={{
        variant: 'elevation',
        sx: {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          height: '100%',
        },
      }}
      emptyData={{
        value: true,
        title: t('common.no_data'),
      }}
    />
  );
};

interface IGeneralModeContainerProps {
  mode: TMode;
  tab: ArticleTabs;
  isDough: boolean;
}

interface IGeneralFormContext {
  prevArticleNoRef: MutableRefObject<string | null | undefined>;
  prevValidationResRef: MutableRefObject<boolean>;
  setLoading: (v: boolean) => void;
}
