import { UseFormReturn } from 'react-hook-form';
import { filter, map, merge, Observable, switchMap, take, tap } from 'rxjs';
import {
  C_Save_Operation_Status,
  SaveArticleDataMutationVariables,
} from '../../../../../graphql/generatedModel';
import { storageHelper } from '../../../../../shared/helpers/storage';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';
import { Pub, State } from '../../../../../shared/state/state.abstract';
import {
  ArticleAvailabilityTabDataRes,
  articleService,
  SaveArticleDataRes,
} from '../../../services/article.service';
import { articleListState } from '../../../states/articleList.state';
import { ITabState, tabStateModel } from '../../../states/tabState.model';
import { artcileTabsSub } from '../articleCommonTabs.sub';
import { articleTabLoadingService } from '../articleTababLoading.service';

class PubImpl extends Pub<IAvailabilityTabState> {
  form(form: IAvailabilityTabState['form']) {
    this.emit('form', { form });
  }
  save() {
    this.emit('save', {});
  }
}
class SubImpl extends artcileTabsSub<IAvailabilityTabState>() {
  protected actionHandlers(): Observable<IAvailabilityTabState> {
    return merge(this.selectedArticle(), this.save()).pipe(
      filter(({ action }) => action !== 'form'),
      filter(({ action }) => action !== 'save'),
    );
  }
  private save(): Observable<IAvailabilityTabState> {
    return this.actionListener('save').pipe(tap(() => this._save()));
  }
  private selectedArticle(): Observable<IAvailabilityTabState> {
    return articleListState.sub.state().pipe(
      filter(
        ({ action }) =>
          action === 'selectArticle' || action === 'articleList' || action === 'filter',
      ),
      filter(({ selectedArticle }) => {
        if (!selectedArticle || !selectedArticle?.id) {
          this.articleIdNotExist$.next(true);
        }
        return typeof selectedArticle?.id === 'string';
      }),
      switchMap(({ selectedArticle }) => {
        articleTabLoadingService.pub.loading(true);
        const id = selectedArticle!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedArticle';
        return this.details(state, id);
      }),
    );
  }

  private _save() {
    const { form } = this.stream$.getValue();
    if (form) {
      const {
        reset,
        formState: { isValid, dirtyFields },
      } = form;
      const isDirty = Object.keys(dirtyFields).length;
      if (isDirty && isValid) {
        articleService.sub
          .editArticle()
          .pipe(
            responseHandler<SaveArticleDataRes | undefined>({
              success: () => 'article.article_saved',
              customErrorHandler: () => 'article.article_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              articleListState.pub.updateArticle(updatedGridItem!);
            }
            storageHelper.memory.removeItem('configsData.articlesForOrderPositionList');
          });
        const {
          availabilityInProcurementKindId,
          articleAssortimentGroupsCheckedState,
          id,
          periods,
          periodsWithCustomWeekDays,
          vacations,
          weekDays,
        } = { ...form.getValues() };
        const isDeliveryPeriodsChanged =
          dirtyFields?.weekDays ||
          dirtyFields?.periods ||
          dirtyFields?.periodsWithCustomWeekDays ||
          dirtyFields?.vacations;
        // checking which fields will be sent to mutation
        const dataToSave = {
          id,
          ...(dirtyFields?.articleAssortimentGroupsCheckedState && {
            articleAssortimentGroupsCheckedState: articleAssortimentGroupsCheckedState?.map(
              ({ id, checked, label }) => ({
                id,
                checked,
                name: label,
              }),
            ),
          }),
          ...(dirtyFields?.availabilityInProcurementKindId && {
            availabilityInProcurementKindId,
          }),
          ...(isDeliveryPeriodsChanged && {
            articleDeliveryPeriodsSettings: {
              articleRegularDeliveryPeriods: periods?.map(({ id: _, ...v }) => v),
              articleCustomWeekDaysAndDeliveryPeriods: periodsWithCustomWeekDays.map(
                ({ period, weekDays, description }) => ({
                  description,
                  fromDate: period?.fromDate,
                  toDate: period?.toDate,
                  isRepeatYearly: period?.isRepeatYearly,
                  ...weekDays,
                }),
              ),
              articleVacations: vacations?.map(({ id: _, ...v }) => v),
            },
            ...weekDays,
          }),
        };
        articleService.pub.editArticle({ dataToSave } as SaveArticleDataMutationVariables);
        reset(form.getValues());
      }
    }
  }
  private details(state: IAvailabilityTabState, id: string): Observable<IAvailabilityTabState> {
    const details = articleService.sub.articleAvailabilityTabData().pipe(
      responseHandler<Partial<ArticleAvailabilityTabDataRes>>({ errorReturnType: {} }),
      map((data) => {
        state.defaultValues = data;
        state.defaultValues.id = id;
        articleTabLoadingService.pub.loading(false);
        return state;
      }),
    );
    articleService.pub.articleAvailabilityTabData({ id });

    return details;
  }
}

class AvailabilityTabState extends State<IAvailabilityTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const availabilityTabState = new AvailabilityTabState({
  ...tabStateModel,
  action: undefined,
  articleId: '',
  form: undefined,
});

export interface IAvailabilityTabState extends Pick<ITabState, 'defaultValues' | 'articleId'> {
  action: undefined | 'list.selectedArticle' | 'form' | 'save';
  form: undefined | UseFormReturn<ArticleAvailabilityTabDataRes>;
}
