import {
  Box,
  Fade,
  IconButton,
  Popover as PopoverMUI,
  PopoverProps,
  SxProps,
  Theme as ThemeMUI,
  useTheme,
} from '@mui/material';
import { FC, MouseEvent, ReactNode, useState } from 'react';

export const Popover: FC<IPopover> = ({
  icon,
  content,
  minWidth = 392,
  popoverProps,
  mainSx,
  iconSx,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const activeColor = theme.palette.action.hover;
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={mainSx}>
      <IconButton
        sx={{
          backgroundColor: open ? activeColor : 'inherit',
          ...iconSx,
        }}
        onClick={handleClick}
      >
        {icon}
      </IconButton>
      <PopoverMUI
        anchorEl={anchorEl}
        open={open}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          marginTop: 1,
        }}
        {...popoverProps}
      >
        <Box minWidth={`${minWidth}px`}>{content}</Box>
      </PopoverMUI>
    </Box>
  );
};

interface IPopover {
  icon: ReactNode;
  content: ReactNode;
  minWidth?: number;
  mainSx?: SxProps<ThemeMUI>;
  iconSx?: SxProps<ThemeMUI>;
  popoverProps?: Partial<PopoverProps>;
}
