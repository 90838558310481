import React, { FC, useCallback } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { orderDetailsState as orderDetailsStateCreate } from '../../../create/states/orderDetails/orderDetails.state.ts';
import { orderDetailsState as orderDetailsStateEdit } from '../../../edit/states/orderDetails/orderDetails.state.ts';
import { TableNumericField } from '../../../../../shared/components/table/styles/styleFields.ts';
import { clearRowFromVirtualKeys } from './splitting/additionalFunctions.tsx';

export const DiscountEditCell: FC<TDiscountEditCell> = ({ api, type, ...params }) => {
  const { setEditCellValue, stopCellEditMode } = api;
  const { value, field, id, row } = params;
  const numberValue = Number.parseFloat(value) || 0;

  const updateCell = useCallback(
    (v: number | null) => {
      setEditCellValue({ id, field, value: v });
      if (numberValue !== v) {
        const clearedRow = clearRowFromVirtualKeys(row);
        (type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit).pub.updateCell(
          { ...clearedRow, discount: v },
          'discountCell',
        );
      }
    },
    [params, numberValue],
  );
  const handleKeyDown = (v: React.KeyboardEvent) => {
    if (v.target instanceof HTMLInputElement && v.code === 'Enter') {
      const value = v.target.value.replace(/,/g, '.');
      updateCell(Number(value));
      stopCellEditMode({ id, field });
    }
  };

  return (
    <TableNumericField
      onBlur={updateCell}
      onKeyDown={handleKeyDown}
      max={100}
      min={0}
      autoFocus
      noTrailingZeros
      value={numberValue}
    />
  );
};

type TDiscountEditCell = GridRenderEditCellParams & { type: 'create' | 'edit' };
