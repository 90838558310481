import { FC, useEffect, useMemo, useRef } from 'react';
import { appState } from '../services/app/app.state';
import { snackbarService } from '../components/snackbar/service/snackbar.service';
import { useTranslation } from 'react-i18next';
import { TPopupDefaultActions } from '../components/popup/services/popup.service';
import { Popup, TPopupComponent } from '../components/popup/popup.abstract';
import { Button, Stack, Typography } from '@mui/material';

export const useGlobalState = () => {
  const { t } = useTranslation();
  const isSnackBarUpdateDisplayed = useRef(false);

  useEffect(() => {
    const offlineHandler = () => appState.pub.networkStatus('offline');
    const onlineHandler = () => appState.pub.networkStatus('online');
    window.addEventListener('offline', offlineHandler);
    window.addEventListener('online', onlineHandler);

    const subAppState = appState.sub
      .state()
      .subscribe(({ action, networkStatus, handleAppVersionUpdate }) => {
        switch (action) {
          case 'networkStatus': {
            snackbarService.pub.hide(networkStatus === 'offline' ? 'online' : 'offline');
            snackbarService.pub.show({
              id: networkStatus,
              type: networkStatus === 'offline' ? 'warning' : 'success',
              content:
                networkStatus === 'offline' ? t('common.offline') : t('common.internet_restored'),
              noAutoHide: networkStatus === 'offline',
            });
            break;
          }
          case 'logOutFromOtherTab': {
            loggedOutPopup.stream.emit('open');
            break;
          }
          case 'newAppVersionAvailable': {
            !isSnackBarUpdateDisplayed.current &&
              snackbarService.pub.show({
                content: <UpdateMessage onUpdate={handleAppVersionUpdate} />,
                type: 'info',
                noAutoHide: true,
              });
            isSnackBarUpdateDisplayed.current = true;
          }
        }
      });

    return () => {
      subAppState.unsubscribe();
    };
  }, []);
};

const UpdateMessage: FC<{ onUpdate?: () => void }> = ({ onUpdate }) => {
  const { t } = useTranslation();

  return (
    <>
      <span>{t('common.new_version_available')}.</span>
      <br />
      <span onClick={() => onUpdate?.()} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
        {t('common.update')}
      </span>
    </>
  );
};

class LoggedOutPopup extends Popup<{ action: TPopupDefaultActions }> {
  Component: FC<TPopupComponent>;

  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const { t } = useTranslation();

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('common.session_expired')}
          closeBtn={false}
          closureOverlay={false}
        >
          <Typography>{t('common.signed_out_refresh')}</Typography>
          <Stack justifyContent='center' alignItems='center' mt={2}>
            <Button variant='contained' onClick={() => window.location.reload()}>
              {t('common.reload_page')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

export const loggedOutPopup = new LoggedOutPopup();
