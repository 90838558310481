import { C_Report } from '../../../../../../graphql/generatedModel';
import {
  CommonPubArgsForReportByVersion,
  CommonPubArgsForReportByVersionDifference,
  productionReportFromVersionService,
} from '../../../../services/productionReportFromVersion.service';
import {
  CommonPubArgs,
  productionWinReportService,
} from '../../../../services/productionWinReport.service';

export const getProductionWinReportService = (reportId: C_Report, args: CommonPubArgs) => {
  switch (reportId) {
    case C_Report.R1_BAKING_LIST_2_COLUMNS:
      return () => productionWinReportService.pub.r1(args);
    case C_Report.R11_CROSSTABLE_REPORT_A4_LANDSCAPE:
      return () => productionWinReportService.pub.r11(args);
    case C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS:
      return () => productionWinReportService.pub.r14(args);
    case C_Report.R15_FORWARDING_LIST_CUSTOMER_ARTICLES:
      return () => productionWinReportService.pub.r15(args);
    case C_Report.R20_FORWARDING_LIST_ARTICLE_CUSTOMERS:
      return () => productionWinReportService.pub.r20(args);
    case C_Report.R25_BAKING_LIST_2_COLUMNS_COMBINED:
      return () => productionWinReportService.pub.r25(args);
    case C_Report.R26_BAKING_LIST_WITH_RECIPE_A4:
      return () => productionWinReportService.pub.r26(args);
    case C_Report.R27_BAKING_LIST_WITH_RECIPE_A5:
      return () => productionWinReportService.pub.r27(args);
    case C_Report.R23_DEBITOR_JOURNAL_A4_LANDSCAPE:
      return () => productionWinReportService.pub.r32(args);
    case C_Report.R32_TOURLIST:
      return () => productionWinReportService.pub.r32(args);
    case C_Report.R34_BAKING_LIST_A4:
      return () => productionWinReportService.pub.r34(args);
    case C_Report.R41_BAKING_LIST_A4_DETAILED:
      return () => productionWinReportService.pub.r41(args);
    case C_Report.R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE:
      return () => productionWinReportService.pub.r42(args);
    case C_Report.R51_DELIVERY_NOTES_IN_PRODUCTION:
      return () => productionWinReportService.pub.r51(args);
    case C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS:
      return () => productionWinReportService.pub.r57(args);
    case C_Report.R65_CROSSTABLE_REPORT_A4_PORTRAIT:
      return () => productionWinReportService.pub.r65(args);
    case C_Report.R66_BAKING_LIST_FOR_CAKES_AND_PIES:
      return () => productionWinReportService.pub.r66(args);
  }
};

export const getProductionReportFromVersion = (
  reportId: C_Report,
  args: CommonPubArgsForReportByVersion,
) => {
  switch (reportId) {
    case C_Report.R1_BAKING_LIST_2_COLUMNS:
      return productionReportFromVersionService.pub.r1FromVersion(args);
    case C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS:
      return productionReportFromVersionService.pub.r14FromVersion(args);
    case C_Report.R26_BAKING_LIST_WITH_RECIPE_A4:
      return productionReportFromVersionService.pub.r26FromVersion(args);
    case C_Report.R27_BAKING_LIST_WITH_RECIPE_A5:
      return productionReportFromVersionService.pub.r27FromVersion(args);
    case C_Report.R34_BAKING_LIST_A4:
      return productionReportFromVersionService.pub.r34FromVersion(args);
    case C_Report.R41_BAKING_LIST_A4_DETAILED:
      return productionReportFromVersionService.pub.r41FromVersion(args);
    case C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS:
      return productionReportFromVersionService.pub.r57FromVersion(args);
  }
};

export const getProductionReportFromVersionDifference = (
  reportId: C_Report,
  args: CommonPubArgsForReportByVersionDifference,
) => {
  switch (reportId) {
    case C_Report.R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE:
      return productionReportFromVersionService.pub.r58VersionsDifferenceWithRecipe(args);
    case C_Report.R68_FORWARDING_LIST_ARTICLE_CUSTOMERS_VERSIONS_DIFFERENCE:
      return productionReportFromVersionService.pub.r68VersionsDifference(args);
  }
};
