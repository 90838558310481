import { FC, useMemo } from 'react';
/* Icons */
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { useTranslation } from 'react-i18next';
import { SStack, SValue } from '../product/product.cell.tsx';

import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import styled from '@mui/material/styles/styled';

export const QuantityCell: FC<IQuantityCell> = ({ params, loading }) => {
  const { t } = useTranslation();

  const {
    value,
    row: { minQuantity, maxQuantity, quantityPerLot, virtualPositionId },
  } = params;

  const isNotValidRange: string = useMemo(() => {
    const validation =
      (minQuantity && minQuantity > Number(value)) || (maxQuantity && Number(value) > maxQuantity);
    return validation ? t('order.value_between', { minQuantity, maxQuantity }) : '';
  }, [params.row, t]);

  const isNotValidLot: string = useMemo(() => {
    const validation =
      typeof quantityPerLot === 'number' && Number(value || null) % quantityPerLot !== 0;
    return validation ? t('order.lot_quantity', { quantityPerLot }) : '';
  }, [params.row, t]);

  return (
    <SStack>
      {!virtualPositionId ? (
        <>
          <div>
            {(loading.includes(`${params?.id}_${params.field}`) && (
              <Box sx={{ display: 'flex', p: '3.5px' }}>
                <CircularProgress size={16} thickness={5} />
              </Box>
            )) ||
              (Boolean(isNotValidRange || isNotValidLot) && (
                <STooltip
                  title={
                    <>
                      {isNotValidRange}
                      {Boolean(isNotValidRange) && <br />}
                      {isNotValidLot}
                    </>
                  }
                  arrow
                >
                  <SIconButton
                    tabIndex={-1}
                    onClick={(e) => e.stopPropagation()}
                    color='default'
                    size='small'
                  >
                    <HelpOutlineIcon />
                  </SIconButton>
                </STooltip>
              ))}
          </div>
          <SValue>
            {localeFormatterHelper.formatNumber(value || '', {
              noTrailingZeros: true,
              returnZero: false,
              precision: 3,
            })}
          </SValue>
        </>
      ) : undefined}
    </SStack>
  );
};

const SIconButton = styled(IconButton)(() => ({
  padding: 3,
  svg: {
    fontSize: 18,
  },
}));

const STooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

interface IQuantityCell {
  params: GridRenderCellParams;
  loading: string[];
}
