import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormCheckbox } from '../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { configurationTabState } from '../../../states/configuration.state.ts';
import { TCompanyConfigs } from '../../../../../../shared/services/companyConfig/companyConfig.service.ts';
import {
  ICheckListData,
  OrderTypesAccountedInProductionReportsPopup,
} from './popups/orderTypesAccountedInProductionReports/orderTypesAccountedInProductionReports.popup.tsx';

export const ProductionTab: FC<IProductionTab> = ({ params }) => {
  const { t } = useTranslation();
  const orderTypesAccountedInProductionReportsPopup = useMemo(
    () => new OrderTypesAccountedInProductionReportsPopup(),
    [],
  );
  const formMethods = useForm({ defaultValues: params, mode: 'onChange' });
  const {
    resetField,
    formState: { dirtyFields },
  } = formMethods;

  useEffect(() => {
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        const recordData =
          fieldName === 'orderTypesFilterInProductionReportsCheckedState'
            ? (data[fieldName] as ICheckListData[]).map(({ label, ...rest }) => rest)
            : data[fieldName];
        configurationTabState.pub.recordData({ [fieldName]: recordData });
      }
    });
    const configurationTabStateSub = configurationTabState.sub
      .state()
      .pipe()
      .subscribe(({ dataToSave }) => {
        const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
        if (name !== 'orderTypesFilterInProductionReportsCheckedState') {
          resetField(name, {
            defaultValue: dataToSave[name],
            keepDirty: false,
          });
        }
      });

    return () => {
      formStateSub.unsubscribe();
      configurationTabStateSub.unsubscribe();
      orderTypesAccountedInProductionReportsPopup.stream.unsubscribe();
    };
  }, []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!!dirtyFields[e.target.name as keyof typeof dirtyFields]) {
      configurationTabState.pub.saveData();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <Stack direction='column'>
          <FormCheckbox
            name='isDoughInLitersInProductionReports'
            label={t('settings.show_liters_in_production_print_lists')}
          />
          <FormCheckbox
            name='canForbidIncomingOrdersCAdirect'
            label={t('settings.can_close_orders_from_cashAssist')}
          />
          <FormCheckbox
            name='canWorkWithVersionsInProduction'
            label={t('settings.working_with_versions')}
          />
          <FormCheckbox
            name='isUseProcurementLogicInProductionReports'
            label={t(
              'settings.take_into_consideration_own_produced_articles_from_procurement_in_backing_list_reports',
            )}
          />
          <Stack mt={1} alignItems='start' spacing={1} width='fit-content'>
            <Button
              size='medium'
              variant='outlined'
              color='secondary'
              fullWidth
              onClick={() => orderTypesAccountedInProductionReportsPopup.stream.emit('open')}
            >
              {t('settings.order_types_accounted_in_production_reports')}
            </Button>
          </Stack>
        </Stack>
        <orderTypesAccountedInProductionReportsPopup.Component />
      </form>
    </FormProvider>
  );
};

interface IProductionTab {
  params: TCompanyConfigs;
}
