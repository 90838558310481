import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import {
  BehaviorSubject,
  filter,
  map,
  merge,
  mergeMap,
  Observable,
  share,
  switchMap,
  tap,
} from 'rxjs';
import { t } from 'i18next';

import { dataHelper } from '../../../../shared/helpers/data/data.helper';
import { storageHelper } from '../../../../shared/helpers/storage';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract';
import {
  DeleteEditPrintListRes,
  EditPrintListRes,
  editPrintListService,
} from '../../services/editPrintList.service';
import { C_Report_Group } from '../../../../graphql/generatedModel';
import { snackbarService } from '../../../../shared/components/snackbar/service/snackbar.service';

export const initEditPrintListState: IEditPrintListState = {
  action: undefined,
  editPrintList: [],
  selectedPrintList: undefined,
  search: '',
  sortModel: [{ field: 'name', sort: 'asc' }],
  filter: {
    printListsActiveState: null,
    reportGroupIds: [
      C_Report_Group.RG1_PRODUCTION_PRODUCTION,
      C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS,
      C_Report_Group.RG9_PRODUCTION_CROSSTABLE,
      C_Report_Group.RG3_ARTICLE,
      C_Report_Group.RG11_PRODUCTION_SMARTSCALE,
    ],
  },
  allEditPrintListLength: 0,
  editPrintListTitle: '',
  params: {},
};

class PubImpl extends Pub<IEditPrintListState> {
  init(params: Partial<IEditPrintListState>) {
    this.emit('init', params);
  }
  search(search: string) {
    this.emit('search', { search });
  }
  sort(sortModel: GridSortModel) {
    this.emit('sort', { sortModel });
    storageHelper.local.setItem('editPrintList.editPrintListSortModel', sortModel);
  }
  filter(filter: IEditPrintListState['filter']) {
    this.emit('filter', { filter });
  }
  selectEditPrintList(selectedPrintList: IEditPrintListState['selectedPrintList']) {
    this.emit('selectEditPrintList', { selectedPrintList });
    storageHelper.session.setItem('editPrintList.selectedPrintList', selectedPrintList);
  }
  editPrintList() {
    const { filter } = this.stream$.getValue();
    this.emit('editPrintList', { filter, params: { cache: false } });
  }
  unselectEditPrintList() {
    this.emit('unselectEditPrintList', { selectedPrintList: undefined });
  }
  deleteEditPrintList(deletePrintListItem: IEditPrintListState['editPrintList'][number]) {
    this.emit('deleteEditPrintList', { params: deletePrintListItem });
  }
  addEditPrintList(record: NonNullable<IEditPrintListState['selectedPrintList']>) {
    const streamV = this.stream$.getValue();
    this.stream$.next({ ...streamV, editPrintList: [...streamV.editPrintList, record] });
    this.selectEditPrintList(record);
    storageHelper.memory.removeItem('editPrintList.list');
  }
  updateEditPrintListItem(updatedPrintListItem: IEditPrintListState['editPrintList'][number]) {
    this.emit('updateEditPrintListItem', { params: updatedPrintListItem });
  }
}

class SubImpl extends Sub<IEditPrintListState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());

  shareDataForTabLoader(): IEditPrintListState {
    return this.stream$.getValue();
  }

  protected actionHandlers(): Observable<IEditPrintListState> {
    return merge(
      this.updateState(),
      this.editPrintList(),
      this.deleteEditPrintList(),
      this.updateEditPrintListItem(),
    ).pipe(
      map(({ search, editPrintList, allEditPrintListLength, sortModel, ...rest }) => {
        let sortedList = [...(editPrintList || [])];
        let selectedPrintList = rest.selectedPrintList;
        allEditPrintListLength = editPrintList?.length || 0;

        editPrintList = dataHelper
          .data(editPrintList as [])
          .sort({
            sortModel,
            callback: (sorted) => (sortedList = sorted as []),
          })
          .search({
            search,
            fields: ['name'],
          })
          .result() as EditPrintListRes;
        if (rest.action === 'editPrintList' || rest.action === 'filter') {
          const foundSelectedRecord =
            editPrintList.find((item) => item?.id === selectedPrintList?.id) || editPrintList[0];
          selectedPrintList = foundSelectedRecord || null;
          storageHelper.session.setItem('editPrintList.selectedPrintList', selectedPrintList);
        }

        const newState = {
          ...rest,
          search,
          editPrintList,
          sortModel,
          allEditPrintListLength,
          selectedPrintList,
        };
        this.stream$.next({
          ...newState,
          editPrintList: sortedList,
          action: 'internalUpdate',
        });
        return newState;
      }),
      tap(() => this.loading$.next(false)),
      this.editPrintListTitlePipe,
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  private updateState(): Observable<IEditPrintListState> {
    return this.actionListener([
      'init',
      'search',
      'sort',
      'unselectEditPrintList',
      'selectEditPrintList',
    ]);
  }

  private editPrintList(): Observable<IEditPrintListState> {
    return this.actionListener(['editPrintList', 'filter']).pipe(
      tap(() => this.loading$.next(true)),
      tap(({ filter, params: { cache } }) =>
        editPrintListService.pub.editPrintList({ filter, cache }),
      ),
      switchMap((state) => {
        return editPrintListService.sub.editPrintList().pipe(
          responseHandler<EditPrintListRes>({
            customErrorHandler() {
              return 'production.print_list_not_loaded';
            },
            errorReturnType: [],
          }),
          map((editPrintList) => {
            this.stream$.getValue().editPrintList = [...(editPrintList as EditPrintListRes)];
            return { ...state, editPrintList };
          }),
        );
      }),
    );
  }

  private deleteEditPrintList(): Observable<IEditPrintListState> {
    return this.actionListener('deleteEditPrintList').pipe(
      tap(({ params }) => {
        editPrintListService.pub.deleteEditPrintList({ id: params!.id });
        snackbarService.pub.show({
          content: t('production.deleting_print_list'),
          type: 'loading',
          id: `deleting_edit_print_list_${params!.id}`,
          noAutoHide: true,
        });
      }),
      mergeMap(() => {
        return editPrintListService.sub.deleteEditPrintList().pipe(
          responseHandler<DeleteEditPrintListRes>({
            errorReturnType: {
              status: false,
              id: 'errorId',
            },
          }),
          tap((response) => {
            const { id, status } = response;
            snackbarService.pub.hide(`deleting_edit_print_list_${id}`);
            if (status)
              snackbarService.pub.show({
                content: t('production.print_list_successfully_deleted'),
                type: 'success',
              });
          }),
          filter(({ status }) => !!status),
          map(({ id }) => {
            const state = structuredClone(this.stream$.getValue());
            return this.deleteSelected(state, id);
          }),
        );
      }),
    );
  }

  private updateEditPrintListItem(): Observable<IEditPrintListState> {
    return this.actionListener('updateEditPrintListItem').pipe(
      map((state) => {
        const { params } = state;
        if (params) {
          const itemIdx = state.editPrintList.findIndex((item) => item.id === params.id);
          if (itemIdx !== -1) {
            const temp = [...state.editPrintList];
            temp.splice(itemIdx, 1, params);
            storageHelper.memory.setItem('editPrintList.list', temp);
            state.editPrintList = temp;
          }
        }
        return state;
      }),
    );
  }

  private editPrintListTitlePipe(src: Observable<IEditPrintListState>) {
    return src.pipe(
      map<IEditPrintListState, IEditPrintListState>((state) => {
        const { action, params, selectedPrintList } = state;
        const { name: paramName, id: paramId } = params || {};
        const { name: selectedName, id: selectedId } = selectedPrintList || {};

        const name =
          action === 'updateEditPrintListItem' &&
          paramName !== selectedName &&
          paramId === selectedId
            ? paramName
            : selectedName;

        state.editPrintListTitle = name ? `${name}` : '';
        return state;
      }),
    );
  }

  private deleteSelected(state: IEditPrintListState, id: string): IEditPrintListState {
    const editPrintListCopy = [...state.editPrintList];
    const deletedIndex = editPrintListCopy.findIndex((item) => item.id === id);

    if (state.selectedPrintList?.id === editPrintListCopy[deletedIndex]?.id) {
      if (editPrintListCopy.length === 1) {
        state.selectedPrintList = undefined;
      } else {
        state.selectedPrintList =
          deletedIndex === editPrintListCopy.length - 1
            ? editPrintListCopy[deletedIndex - 1]
            : editPrintListCopy[deletedIndex + 1];
      }
    }
    editPrintListCopy.splice(deletedIndex, 1);
    storageHelper.memory.setItem('editPrintList.list', editPrintListCopy);
    state.editPrintList = editPrintListCopy;
    state.params = {};
    storageHelper.session.setItem(
      'editPrintList.selectedPrintList',
      state.selectedPrintList || null,
    );
    return state;
  }

  /* UTILITY end */
}

class EditPrintListState extends State<IEditPrintListState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const editPrintListState = new EditPrintListState(initEditPrintListState);

export enum EditPrintListFilterType {
  fullList = 'fullList',
  activeLists = 'activeLists',
  inactiveLists = 'inactiveLists',
}

export interface IEditPrintListState {
  action:
    | 'init'
    | 'search'
    | 'sort'
    | 'selectEditPrintList'
    | 'editPrintList'
    | 'filter'
    | 'loader'
    | 'deleteEditPrintList'
    | 'internalUpdate'
    | 'updateEditPrintListItem'
    | 'unselectEditPrintList'
    | undefined;
  editPrintList: EditPrintListRes;
  selectedPrintList?: EditPrintListRes[number];
  search: string;
  filter: any;
  allEditPrintListLength: number;
  editPrintListTitle: string;
  sortModel: GridSortModel;
  params?: any;
}
