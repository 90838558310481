import { FC, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { ITabState } from '../../../states/tabState.model';
import { useTranslation } from 'react-i18next';
import { GridAggregationFunction, GridSortModel } from '@mui/x-data-grid-premium';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper';
import {
  articleOverviewState,
  getInitSortModel,
  OverviewArticleData,
} from './articlesOverview.state';
import { SideControls } from './components/sideControls.component';
import { Column } from '../../../../../../shared/components/table/components/common.components';
import { storageHelper } from '../../../../../../shared/helpers/storage';
import { GroupTable } from '../../../../../../shared/components/groupTable/groupTable.component';
import {
  customSumAggregation,
  renderCellForGroupTable,
} from '../../../../../../shared/components/groupTable/functions/forStyle';
import { Link } from '@mui/material';
import { navigateService } from '../../../../../../shared/services/navigate/navigate.service';
import { globalSearchBuildUrl } from '../../../../../../shared/components/globalSearch/globalSearchBuildUrl.util';

export const ArticlesOverviewTab: FC = () => {
  const { defaultValues } = useLoaderData() as ITabState;
  const [data, setData] = useState<OverviewArticleData>(defaultValues);
  const [loading, setLoading] = useState(false);
  const initIsActive = storageHelper.local.getItem('overview.articleIsGroupModeActive') ?? true;
  const initSortModel = getInitSortModel();
  const [isActive, setIsActive] = useState<boolean>(initIsActive);
  const [sortModel, setSortModel] = useState<GridSortModel>(initSortModel);
  const { t } = useTranslation();

  const getAllNumberRow: GridAggregationFunction<string, string | null> = {
    apply: () => {
      const count: number = data?.length || 0;
      return `${t('order.article', { count })}: ${localeFormatterHelper.formatNumber(count, {
        precision: 0,
      })}`;
    },
    label: '',
    columnTypes: ['string'],
  };

  const formatValue = (value: number) => {
    return localeFormatterHelper.formatNumber(value, {
      returnZero: false,
    });
  };

  useEffect(() => {
    articleOverviewState.pub.init({
      articleOverview: defaultValues,
      isActiveGroupMode: initIsActive,
      sortModel: initSortModel,
    });
    const unsubData = articleOverviewState.sub
      .state()
      .subscribe(({ articleOverview, isActiveGroupMode, sortModel }) => {
        setData(articleOverview);
        setIsActive(isActiveGroupMode);
        setSortModel(sortModel);
      });
    const unsubLoading = articleOverviewState.sub.loading().subscribe(setLoading);

    return () => {
      unsubData.unsubscribe();
      unsubLoading.unsubscribe();
    };
  }, []);

  return (
    <GroupTable
      data={data as []}
      defaultSorting={sortModel}
      onSort={(sortModel) => articleOverviewState.pub.sort(sortModel)}
      heightOffset={isActive ? 339 : 291}
      isActive={isActive}
      initActiveGroup={['article']}
      loading={loading}
      topControls={() => <SideControls isActive={isActive} />}
      initialState={{
        aggregation: {
          model: {
            article: 'allNumberRow',
            quantity: 'quantitySum',
          },
        },
        columns: storageHelper.local.getItem('overview.articleOverviewColumnModel'),
      }}
      onChangeColumnModel={(v) => {
        storageHelper.local.setItem('overview.articleOverviewColumnModel', v);
      }}
      aggregationFunctions={{
        allNumberRow: getAllNumberRow,
        quantitySum: customSumAggregation({ returnZero: false }),
      }}
      groupLocalStorageKey='overview.articleActiveGroup'
    >
      <Column field='article' headerName={t('order.article')} width={193} />
      <Column
        field='quantity'
        headerName={t('common.quantity')}
        align='right'
        headerAlign='right'
        type='number'
        width={64}
      />
      <Column field='customerNo' headerName={t('common.customer_no')} width={91} />
      <Column field='internalOrFullName' headerName={t('common.name')} width={197} />
      <Column
        field='indTextDeliveryNote'
        headerName={t('order.text_on_delivery_note_invoice')}
        width={206}
        renderCell={(params) =>
          renderCellForGroupTable({
            params,
            valueComponent: (
              <span
                dangerouslySetInnerHTML={{
                  __html: params?.value,
                }}
              />
            ),
          })
        }
      />
      <Column
        field='indTextProduction'
        headerName={t('order.text_on_production_list')}
        width={213}
        renderCell={(params) =>
          renderCellForGroupTable({
            params,
            valueComponent: (
              <span
                dangerouslySetInnerHTML={{
                  __html: params?.value,
                }}
              />
            ),
          })
        }
      />
      <Column
        field='orderDate'
        headerName={t('common.date')}
        valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
        width={94}
      />
      <Column
        field='orderNo'
        headerName={t('common.delivery_no')}
        width={80}
        renderCell={({ value, row }) =>
          row.orderId ? (
            <Link
              component='button'
              onClick={() =>
                navigateService.pub.navigateTo(
                  globalSearchBuildUrl('order', {
                    id: row.orderId,
                    date: row.orderDate,
                    isDeleted: false,
                    orderNo: row.orderNo,
                  }),
                )
              }
            >
              {value}
            </Link>
          ) : (
            value
          )
        }
      />
      <Column
        field='positionPrice'
        headerName={t('common.price')}
        align='right'
        headerAlign='right'
        valueFormatter={({ value }) => (value ? formatValue(value) : '')}
        type='number'
        width={79}
      />
      <Column field='positionDiscount' headerName={t('common.discount')} width={63} />
    </GroupTable>
  );
};
