import { LocalizationHelper } from '../../../shared/helpers/localization/localization.helper';

type Keys =
  | 'option'
  | 'companyTab'
  | 'companySection'
  | 'company.label'
  | 'companyName.label'
  | 'addition.label'
  | 'street.label'
  | 'zip.label'
  | 'city.label'
  | 'phone.label'
  | 'fax.label'
  | 'email.label'
  | 'website.label'
  | 'vat.label'
  | 'uid.label'
  | 'gln.label'
  | 'countrySection'
  | 'country.label'
  | 'bankSection'
  | 'bankName.label'
  | 'bin.label'
  | 'accountNo.label'
  | 'iban.label'
  | 'cin.label'
  | 'bic.label'
  | 'company_tab.saved'
  | 'company_tab.notSaved';

const t = LocalizationHelper<Keys>('company');

export const companyDe = t({
  option: 'Firma',
  companyTab: 'Firma',
  companySection: 'Firma',
  bankSection: 'Bankverbindung',
  countrySection: 'Landeseinstellungen',
  'company.label': 'Firma',
  'companyName.label': 'Name',
  'addition.label': 'Zusatz',
  'street.label': 'Strasse',
  'zip.label': 'PLZ',
  'city.label': 'Ort',
  'phone.label': 'Telefon',
  'fax.label': 'Fax',
  'email.label': 'Email',
  'website.label': 'Website',
  'vat.label': 'MwSt-Nummer',
  'uid.label': 'UID-Nummer',
  'gln.label': 'Lieferanten GLN',
  'country.label': 'Land',
  'bankName.label': 'Bankname',
  'bin.label': 'BLZ',
  'accountNo.label': 'Konto-Nr.',
  'iban.label': 'IBAN',
  'cin.label': 'Gläubiger-Identifikationsnummer',
  'bic.label': 'BIC',
  'company_tab.saved': 'Firmeneinstellungen erfolgreich gespeichert',
  'company_tab.notSaved': 'Fehler: Firmeneinstellungen nicht gespeichert!',
}).company;

export const companyFr = t({
  option: 'Entreprise',
  companyTab: 'Entreprise',
  companySection: 'Entreprise',
  bankSection: 'Connexion bancaire',
  countrySection: 'Paramètres du pays',
  'company.label': 'Entreprise',
  'companyName.label': 'Nom',
  'addition.label': 'Complément',
  'street.label': 'Rue',
  'zip.label': 'Code postal',
  'city.label': 'Ville',
  'phone.label': 'Téléphone',
  'fax.label': 'Fax',
  'email.label': 'Email',
  'website.label': 'Site web',
  'vat.label': 'Numéro de TVA',
  'uid.label': 'Numéro UID',
  'gln.label': 'GLN de l’entreprise',
  'country.label': 'Pays',
  'bankName.label': 'Nom de la banque',
  'bin.label': 'BIN',
  'accountNo.label': 'Numéro de compte',
  'iban.label': 'IBAN',
  'cin.label': 'Numéro d’identification du créancier',
  'bic.label': 'BIC',
  'company_tab.saved': 'Paramètres de l’entreprise enregistrés avec succès',
  'company_tab.notSaved': 'Erreur : paramètres de l’entreprise non enregistrés !',
}).company;

export const companyIt = t({
  option: 'Azienda',
  companyTab: 'Azienda',
  companySection: 'Azienda',
  bankSection: 'Collegamento bancario',
  countrySection: 'Impostazioni paese',
  'company.label': 'Azienda',
  'companyName.label': 'Nome',
  'addition.label': 'Aggiunta',
  'street.label': 'Via',
  'zip.label': 'CAP',
  'city.label': 'Città',
  'phone.label': 'Telefono',
  'fax.label': 'Fax',
  'email.label': 'Email',
  'website.label': 'Sito web',
  'vat.label': 'Partita IVA',
  'uid.label': 'Codice fiscale',
  'gln.label': 'GLN aziendale',
  'country.label': 'Paese',
  'bankName.label': 'Nome della banca',
  'bin.label': 'BIN',
  'accountNo.label': 'Numero di conto',
  'iban.label': 'IBAN',
  'cin.label': 'Numero identificativo del creditore',
  'bic.label': 'BIC',
  'company_tab.saved': 'Impostazioni aziendali salvate con successo',
  'company_tab.notSaved': 'Errore: impostazioni aziendali non salvate!',
}).company;

export const companyEn = t({
  option: 'Company',
  companyTab: 'Company',
  companySection: 'Company',
  bankSection: 'Bank connection',
  countrySection: 'Country settings',
  'company.label': 'Company',
  'companyName.label': 'Name',
  'addition.label': 'Addition',
  'street.label': 'Street',
  'zip.label': 'ZIP',
  'city.label': 'City',
  'phone.label': 'Phone',
  'fax.label': 'Fax',
  'email.label': 'Email',
  'website.label': 'Website',
  'vat.label': 'VAT-Number',
  'uid.label': 'UID-Number',
  'gln.label': 'Company GLN',
  'country.label': 'Country',
  'bankName.label': 'Bank name',
  'bin.label': 'BIN',
  'accountNo.label': 'Account No.',
  'iban.label': 'IBAN',
  'cin.label': 'Creditor identification number',
  'bic.label': 'BIC',
  'company_tab.saved': 'Company settings successfully saved',
  'company_tab.notSaved': 'Error: company settings not saved!',
}).company;

// Section Company / Firma.

// 1. Label: Company / Firma
// Fieldname: companySalutation
// Type: String input

// 2. Label: Name / Name
// Fieldname: companyName
// Type: String input

// 3. Label: Addition / Zusatz
// Fieldname: companyAddition
// Type: String input

// 4. Label: Street / Strasse
// Fieldname: companyStreet
// Type: String input

// 5. Label: ZIP / PLZ
// Fieldname: companyZipCode
// Type: String input

// 6. Label: City / Ort
// Fieldname: companyCity
// Type: String input

// 7. Label: Phone / Telefon
// Fieldname: companyPhone
// Type: String input

// 8. Label: Fax / Fax
// Fieldname: companyFax
// Type: String input

// 9. Label: Email / Email
// Fieldname: companyEmail
// Type: String input

// 10. Label: Website / Website
// Fieldname: companyWebsite
// Type: String input

// 11. Label: For country CO1_SWITZERLAND : VAT-Number / MwSt-Nummer, for other countries: UID-Number / UID-Nummer
// Fieldname: companyVatNo
// Type: String input
// Visibility condition: for countries CO1_SWITZERLAND, CO2_GERMANY, CO3_AUSTRIA.

// 12. Label: Company GLN / Lieferanten GLN
// Fieldname: companyGLN
// Type: String input

// Section Country settings / Landeseinstellungen

// 13. Label: Country / Land
// Fieldname: countryId
// Type: Dropdown, without “new item possibility”, without “X button”, without “footer-link to Options”. Items are taken from enum C_COUNTRY. You will need to add translations for this enum on frontend wawi-mui/src/shared/i18n/enums.i18n.ts.
// CO1_SWITZERLAND =  Switzerland / Schweiz
// CO2_GERMANY =  Germany / Deutschland
// CO3_AUSTRIA = Austria / Osterreich
// CO4_MALDIVES = Maldives / Malediven
// CO5_LUXEMBOUG = Luxembourg / Luxemburg

// Section Bank connection / Bankverbindung. It is visible for countries CO2_GERMANY, CO3_AUSTRIA, CO5_LUXEMBOURG.

// 14. Label: Bank name / Bankname
// Fieldname: bankName
// Type: String input

// 15. Label: BIN / BLZ
// Fieldname: bankBIN
// Type: String input

// 16. Label: Account No. / Konto-Nr.
// Fieldname: bankAccountNo
// Type: String input

// 17. Label: IBAN / IBAN
// Fieldname: bankIBAN
// Type: String input

// 18. Label: Creditor identification number / Gläubiger-Identifikationsnummer
// Fieldname: creditorIdNumber
// Type: String input

// 19. Label: BIC / BIC
// Fieldname: bankBIC
// Type: String input
