import { BehaviorSubject } from 'rxjs';
import { IPrintJobsDetailsState, IProductionTabDataRes } from './printJobsDetails.state';
import { C_Report } from '../../../../graphql/generatedModel';
import { ProductionListRes } from '../../services/printJobs.service';

export class StateHelpers {
  stream$: BehaviorSubject<IPrintJobsDetailsState>;
  constructor(stream$: BehaviorSubject<IPrintJobsDetailsState>) {
    this.stream$ = stream$;
  }

  isReportForPreview(reportId: C_Report | null | undefined): boolean {
    return (
      reportId !== C_Report.R12_LABEL && reportId !== C_Report.R49_BAKING_LIST_2_COLUMNS_SMART_SCALE
    );
  }

  isReportPreviewEnabled(report: ProductionListRes[number]): boolean {
    const { addDaysForPreProduction } = report;
    const { datePeriod, dataTabs } = this.stream$.getValue();
    const productionData = dataTabs.production as IProductionTabDataRes | undefined;
    if (productionData === undefined) return false;
    const { countOrders, countOrderPreProduction } = productionData;
    const isEnabledByPreProductionDays = (daysToProduce?: number | null): boolean => {
      if (!daysToProduce) return true;
      const daysDiffOrderDate: number = Math.ceil(
        (new Date(datePeriod.fromDate).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24,
      );
      return daysToProduce <= daysDiffOrderDate;
    };

    return datePeriod.fromDate !== datePeriod.toDate || countOrderPreProduction === undefined
      ? false
      : isEnabledByPreProductionDays(addDaysForPreProduction) && countOrders > 0;
  }
}
