import { forwardRef } from 'react';
import { TextField as MuiField, TextFieldProps } from '@mui/material';

export const TextField = forwardRef<HTMLInputElement, TTextFieldProps>(
  ({ shrink, width, ...props }, ref) => {
    return (
      <MuiField
        variant='outlined'
        size='small'
        fullWidth
        sx={{
          maxWidth: (theme) =>
            width || (shrink ? theme.form.fieldShrinkWidth : theme.form.fieldWidth),
          '& .MuiFormHelperText-root': {
            marginLeft: 0,
          },
          '& input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 30px white inset',
          },
          '& .Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => `${theme.palette.error.main}!important`,
          },
        }}
        InputLabelProps={{ shrink: true }}
        ref={ref}
        {...props}
      />
    );
  },
);

export type TTextFieldProps = TextFieldProps & {
  shrink?: boolean;
  width?: string;
};
