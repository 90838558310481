import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { FC, useEffect, useMemo } from 'react';
import { styled } from '@mui/material';
import { TPopupDefaultActions } from '../../../../../../../../shared/components/popup/services/popup.service.ts';
import { FormCheckboxList } from '../../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  C_Order_Type,
  Wa_OrderTypesFilterInProductionReportsCheckedStateInput,
} from '../../../../../../../../graphql/generatedModel.ts';
import { configurationTabState } from '../../../../../states/configuration.state.ts';

export class OrderTypesAccountedInProductionReportsPopup extends Popup<IOrderTypesAccountedInProductionReportsPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();
      const { getValues, resetField } = useFormContext();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 800 },
          '& > div > div > div': { padding: 20 },
        }));
      }, []);

      useEffect(() => {
        stream.actionListener('open').subscribe(() => {
          const orderTypesFilterInProductionReports = getValues(
            'orderTypesFilterInProductionReportsCheckedState',
          );
          const checkListData: ICheckListData = orderTypesFilterInProductionReports?.map(
            (item: Wa_OrderTypesFilterInProductionReportsCheckedStateInput) => ({
              ...item,
              label: t(`enums.${C_Order_Type[item.id as keyof typeof C_Order_Type]}`),
            }),
          );
          resetField('orderTypesFilterInProductionReportsCheckedState', {
            defaultValue: checkListData,
          });
        });
      });

      return (
        <SWrapper popupTitle={t('settings.order_types')} keepMounted {...{ stream }}>
          <FormCheckboxList
            onChange={() => configurationTabState.pub.saveData()}
            name='orderTypesFilterInProductionReportsCheckedState'
            height='fit-content'
            minHeight='400px'
            maxHeight='70vh'
            minWidth='350px'
          />
        </SWrapper>
      );
    };
  }
}

interface IOrderTypesAccountedInProductionReportsPopup {
  action: TPopupDefaultActions;
}

export interface ICheckListData extends Wa_OrderTypesFilterInProductionReportsCheckedStateInput {
  label: string;
}
