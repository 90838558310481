import InvoiceIcon from '@mui/icons-material/LocalAtm';
import { Button, Stack } from '@mui/material';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeader } from './tableHeader.component';
import { storageHelper } from '../../../shared/helpers/storage';
import { localeFormatterHelper } from '../../../shared/helpers/formatter/localeFormatter.helper';
import { Table } from '../../../shared/components/table/table.component';
import { navigateService } from '../../../shared/services/navigate/navigate.service';
import { PerformGlobalSearchRes } from '../services/globalSearch.service';
import { globalSearchBuildUrl } from '../../../shared/components/globalSearch/globalSearchBuildUrl.util';
import { Column } from '../../../shared/components/table/components/common.components';
import { searchState } from '../../../shared/components/globalSearch/states/search.state';

export const InvoiceTable: FC<IInvoiceTable> = ({ data }) => {
  const { t } = useTranslation();
  const lessThanSixInvoices = data?.length < 6;
  const [isAllDataShown, setIsAllDataShown] = useState(lessThanSixInvoices);

  const handleShowAll = () => {
    setIsAllDataShown(true);
  };

  const icon = <InvoiceIcon color='primary' fontSize='small' />;
  const tableData = useMemo(() => {
    const firstFiveArticles = data?.slice(0, 5);
    const dataForShow = isAllDataShown ? data : firstFiveArticles;
    return dataForShow;
  }, [data, isAllDataShown]);
  let height = isAllDataShown ? 198 + 32 * (data?.length - 5) : 198;
  if (lessThanSixInvoices) {
    height = 198;
  }

  const formatNumberValue = (value: number) => {
    return localeFormatterHelper.formatNumber(value, {
      returnZero: false,
    });
  };

  const formatDate = (value: string) => localeFormatterHelper.formatDate(value);

  useEffect(() => {
    const lessThanSixInvoices = data?.length < 6;
    setIsAllDataShown(lessThanSixInvoices);
  }, [data]);

  return (
    <>
      {tableData?.length ? (
        <Stack>
          <TableHeader
            icon={icon}
            title={t('common.invoice')}
            dataLength={data?.length}
            isAllDataShown={isAllDataShown}
          />
          <Table
            data={(tableData as []) || []}
            initialState={{
              columns: storageHelper.local.getItem('globalSearch.invoiceTable.positionColumnModel'),
            }}
            height={height}
            onChangeColumnModel={(v) =>
              storageHelper.local.setItem('globalSearch.invoiceTable.positionColumnModel', v)
            }
            onRowClick={(e) => {
              if (e?.row?.id) {
                navigateService.pub.navigateTo(globalSearchBuildUrl('invoice', e.row));
                searchState.pub.resetSearch();
              }
            }}
          >
            <Column
              field='date'
              headerName={t('common.date')}
              width={125}
              valueFormatter={({ value }) => (value ? formatDate(value) : '')}
              sortable={false}
            />
            <Column
              field='invoiceNo'
              headerName={t('invoice.invoice_no')}
              width={160}
              sortable={false}
            />
            <Column
              field='internalOrFullName'
              renderCell={(vals) => {
                return (
                  <Fragment
                    children={`${vals.row?.customerNo ?? ''} ${vals.row?.internalOrFullName ?? ''}`}
                  />
                );
              }}
              headerName={t('common.customer')}
              width={210}
              flex={1}
              sortable={false}
            />
            <Column
              field='total'
              headerAlign='right'
              align='right'
              valueFormatter={({ value }) => (value ? formatNumberValue(value) : '')}
              headerName={t('common.total')}
              width={90}
              sortable={false}
            />
          </Table>
          {!isAllDataShown && (
            <Button
              onClick={handleShowAll}
              variant='outlined'
              size='small'
              sx={{ width: '200px', marginTop: '16px', margin: '16px auto 0 auto' }}
            >
              {t('common.show_all')}
            </Button>
          )}
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

interface IInvoiceTable {
  data: PerformGlobalSearchRes['invoices'];
}
