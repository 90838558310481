import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { C_Report } from '../../../../../../graphql/generatedModel';
import {
  FieldsRow,
  FieldsSection,
  FormNumericField,
  FormSelect,
  FormTextField,
} from '../../../../../../shared/components/form';
import { NoteButton } from '../../../../../../shared/components/form/buttons/noteButton.component';
import { valueHelper } from '../../../../../../shared/helpers/valueProcessor/value.helper';
import { CustomerFormsDataRes } from '../../../../services/customer.service';
import { formsTabState } from '../forms.state';
import { NotePopup } from './note.popup';

export const FormsForm: FC<IFormsForm> = ({ formMethods }) => {
  const { getValues } = formMethods;
  const {
    formState: { errors, dirtyFields },
  } = formMethods;
  const noteOrderPopup = useMemo(
    () =>
      new NotePopup({
        type: 'deliveryNote',
      }),
    [],
  );
  const noteInvoicePopup = useMemo(
    () =>
      new NotePopup({
        type: 'invoice',
      }),
    [],
  );
  const checkHtml = useCallback(
    (fieldName: any) => valueHelper.checkValueInHtml(getValues(fieldName)),
    [getValues],
  );
  const [hasNoteOrder, setHasNoteOrder] = useState<boolean>(
    checkHtml('defaultNoteInOrder') || checkHtml('defaultNoteInOrder2'),
  );
  const [hasNoteInvoice, setHasNoteInvoice] = useState<boolean>(
    checkHtml('defaultNoteInInvoice') || checkHtml('defaultNoteInInvoice2'),
  );
  const { t } = useTranslation();

  const noteValues = getValues([
    'defaultNoteInOrder',
    'defaultNoteInOrder2',
    'defaultNoteInInvoice',
    'defaultNoteInInvoice2',
  ]);
  useEffect(() => {
    setHasNoteOrder(checkHtml('defaultNoteInOrder') || checkHtml('defaultNoteInOrder2'));
    setHasNoteInvoice(checkHtml('defaultNoteInInvoice') || checkHtml('defaultNoteInInvoice2'));
  }, [noteValues]);

  useEffect(() => {
    const noteOrderSub = noteOrderPopup.stream.state().subscribe(({ action }) => {
      if (action === 'save') {
        formsTabState.pub.save();
      }
    });
    const noteInvoiceSub = noteInvoicePopup.stream.state().subscribe(({ action }) => {
      if (action === 'save') {
        formsTabState.pub.save();
      }
    });
    return () => {
      noteOrderSub.unsubscribe();
      noteInvoiceSub.unsubscribe();
      noteOrderPopup.stream.unsubscribe();
      noteInvoicePopup.stream.unsubscribe();
    };
  }, []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (
      !errors[e.target.name as keyof CustomerFormsDataRes] &&
      !!dirtyFields[e.target.name as keyof CustomerFormsDataRes]
    ) {
      formsTabState.pub.save();
    }
  };

  const formularReportsOptions = useMemo(
    () => [
      {
        id: C_Report.R_NOT_TO_PRINT,
        label: t(`enums.${C_Report.R_NOT_TO_PRINT}`),
      },
      {
        id: C_Report.R8_DELIVERY_NOTE_A4_WITH_PRICE,
        label: t(`enums.${C_Report.R8_DELIVERY_NOTE_A4_WITH_PRICE}`),
      },
      {
        id: C_Report.R16_DELIVERY_NOTE_A4_WITHOUT_PRICE,
        label: t(`enums.${C_Report.R16_DELIVERY_NOTE_A4_WITHOUT_PRICE}`),
      },
      {
        id: C_Report.R29_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE,
        label: t(`enums.${C_Report.R29_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE}`),
      },
      {
        id: C_Report.R30_DELIVERY_NOTE_A5_LANDSCAPE_WITHOUT_PRICE,
        label: t(`enums.${C_Report.R30_DELIVERY_NOTE_A5_LANDSCAPE_WITHOUT_PRICE}`),
      },
      {
        id: C_Report.R40_DELIVERY_NOTE_A4_OVERVIEW,
        label: t(`enums.${C_Report.R40_DELIVERY_NOTE_A4_OVERVIEW}`),
      },
      {
        id: C_Report.R43_DELIVERY_NOTE_A4_WITH_PRICE_AND_DISCOUNT,
        label: t(`enums.${C_Report.R43_DELIVERY_NOTE_A4_WITH_PRICE_AND_DISCOUNT}`),
      },
      {
        id: C_Report.R44_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE_AND_DISCOUNT,
        label: t(`enums.${C_Report.R44_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE_AND_DISCOUNT}`),
      },
      {
        id: C_Report.R55_DELIVERY_NOTE_A4_WITH_PRICE_AND_ORIGIN,
        label: t(`enums.${C_Report.R55_DELIVERY_NOTE_A4_WITH_PRICE_AND_ORIGIN}`),
      },
      {
        id: C_Report.R62_DELIVERY_NOTE_PARTIAL_DELIVERY_A4_WITH_PRICE,
        label: t(`enums.${C_Report.R62_DELIVERY_NOTE_PARTIAL_DELIVERY_A4_WITH_PRICE}`),
      },
    ],
    [t],
  );

  const invoiceReportOptions = useMemo(
    () => [
      { id: C_Report.R9_INVOICE_A4, label: t(`enums.${C_Report.R9_INVOICE_A4}`) },
      {
        id: C_Report.R19_INVOICE_A4_DETAILED,
        label: t(`enums.${C_Report.R19_INVOICE_A4_DETAILED}`),
      },
      {
        id: C_Report.R31_INVOICE_A4_ARTICLE_GROUPING,
        label: t(`enums.${C_Report.R31_INVOICE_A4_ARTICLE_GROUPING}`),
      },
      {
        id: C_Report.R39_INVOICE_A4_CUSTOMER_GROUPING,
        label: t(`enums.${C_Report.R39_INVOICE_A4_CUSTOMER_GROUPING}`),
      },
    ],
    [t],
  );

  const invoiceSummaryReportOptions = useMemo(
    () => [
      { id: C_Report.R_NOT_TO_PRINT, label: t(`enums.${C_Report.R_NOT_TO_PRINT}`) },
      { id: C_Report.R17_INVOICE_SUMMARY, label: t(`enums.${C_Report.R17_INVOICE_SUMMARY}`) },
      { id: C_Report.R52_DELIVERY_OVERVIEW, label: t(`enums.${C_Report.R52_DELIVERY_OVERVIEW}`) },
    ],
    [t],
  );

  const invoicePaymentReportOptions = useMemo(
    () => [
      { id: C_Report.R_NOT_TO_PRINT, label: t(`enums.${C_Report.R_NOT_TO_PRINT}`) },
      {
        id: C_Report.R18_INVOICE_PAYMENTSLIP,
        label: t(`enums.${C_Report.R18_INVOICE_PAYMENTSLIP}`),
      },
      {
        id: C_Report.R59_INVOICE_PAYMENTSLIP_WITH_QR,
        label: t(`enums.${C_Report.R59_INVOICE_PAYMENTSLIP_WITH_QR}`),
      },
    ],
    [t],
  );

  const offerReportOptions = useMemo(
    () => [
      { id: C_Report.R37_OFFER_A4, label: t(`enums.${C_Report.R37_OFFER_A4}`) },
      {
        id: C_Report.R38_OFFER_A4_ARTICLE_GROUPING,
        label: t(`enums.${C_Report.R38_OFFER_A4_ARTICLE_GROUPING}`),
      },
      {
        id: C_Report.R48_OFFER_A4_WITH_IMAGES,
        label: t(`enums.${C_Report.R48_OFFER_A4_WITH_IMAGES}`),
      },
    ],
    [t],
  );

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <FieldsSection title={t('customer.offer')} titleBoxSx={{ pt: 0 }} nomt>
          <FormSelect
            name='offerReportId'
            label={t('customer.print_form')}
            options={offerReportOptions}
            width='350px'
            disableClearable
          />
        </FieldsSection>
        <FieldsSection title={t('common.delivery_note')}>
          <FieldsRow>
            <FormSelect
              name='orderReportId'
              label={t('customer.print_form')}
              options={formularReportsOptions}
              width='350px'
              disableClearable
            />
            <FormNumericField
              name='deliveryNoteCopies'
              controls
              label={t('customer.copy_other')}
              precision={0}
              shrink
            />
            <NoteButton
              onClick={() =>
                noteOrderPopup.stream.emit('open', {
                  values: {
                    main: getValues('defaultNoteInOrder'),
                    additional: getValues('defaultNoteInOrder2'),
                  },
                })
              }
              badged={hasNoteOrder}
            />
          </FieldsRow>
        </FieldsSection>
        <FieldsSection title={t('common.invoice')}>
          <FieldsRow>
            <FormSelect
              name='invoiceReportId'
              label={t('customer.print_form')}
              options={invoiceReportOptions}
              width='350px'
              disableClearable
            />
            <FormNumericField
              name='invoiceCopies'
              controls
              label={t('customer.copy_other')}
              precision={0}
              shrink
            />
            <NoteButton
              onClick={() =>
                noteInvoicePopup.stream.emit('open', {
                  values: {
                    main: getValues('defaultNoteInInvoice'),
                    additional: getValues('defaultNoteInInvoice2'),
                  },
                })
              }
              badged={hasNoteInvoice}
            />
          </FieldsRow>
          <FieldsRow>
            <FormSelect
              name='invoiceSummaryReportId'
              label={t('customer.summary_form')}
              options={invoiceSummaryReportOptions}
              width='350px'
              disableClearable
            />
          </FieldsRow>
          <FieldsRow>
            <FormSelect
              name='invoicePaymentSlipReportId'
              label={t('customer.payment_slip_form')}
              options={invoicePaymentReportOptions}
              width='350px'
              disableClearable
            />
            <FormTextField name='eBillAccount' label={t('customer.e_bill_account_id')} />
          </FieldsRow>
        </FieldsSection>
      </form>
      <noteOrderPopup.Component />
      <noteInvoicePopup.Component />
    </FormProvider>
  );
};

export const additionalNoteCompanies = [
  'FBB32630-A8A9-E689-0DAB-1C43914A8B76', // Kuebler
  '57CCBFB2-724D-F9CA-5202-ADD5FB4678F9', // Roman Liederman
];

interface IFormsForm {
  formMethods: UseFormReturn<CustomerFormsDataRes>;
}
