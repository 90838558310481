import { CircularProgress } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { SPreviewContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { Subscription } from 'rxjs';
import { PopupService } from '../popup/services/popup.service';
import { IPreviewPopup } from './preview.popup';
import { ISnackbarOptions, snackbarService } from '../snackbar/service/snackbar.service';
import { C_Global_Error_Code, C_Win_Report_Task_Status } from '../../../graphql/generatedModel';

export const Content: FC<IContent> = ({ stream }) => {
  const [link, setPDFLink] = useState<string>('');
  const { t } = useTranslation();

  const errorHandler = (error: Record<string, any>) => {
    const isNotReady =
      error?.response?.errors?.[0]?.extensions?.code === C_Global_Error_Code.GEC20_NO_DATA;
    const noDataForReport = error?.status === C_Win_Report_Task_Status.WRTS15_NO_DATA_FOR_REPORT;

    let snackArgs: ISnackbarOptions = {
      content: t('common.server_error'),
      type: 'error',
    };
    if (noDataForReport) {
      snackArgs = {
        content: t('production.no_data_no_preview'),
        type: 'warning',
      };
    }
    if (isNotReady) {
      snackArgs = {
        content: t('common.report_is_generating'),
        type: 'warning',
      };
    }
    snackbarService.pub.show(snackArgs);
    stream.emit('close');
  };

  useEffect(() => {
    let subId: Subscription | null = null;
    const openSub = stream.actionListener('open').subscribe(({ pub, sub }) => {
      subId = sub().subscribe({
        next: (v) => (v.url ? setPDFLink(v.url) : errorHandler(v)),
        error: errorHandler,
      });
      pub();
    });
    return () => {
      setPDFLink('');
      subId?.unsubscribe?.();
      openSub.unsubscribe();
    };
  }, []);

  return (
    <SPreviewContainer link={link}>
      {!link ? (
        <CircularProgress />
      ) : (
        <div className='iframe-container'>
          <iframe src={link + '#zoom=70'} title='PDF' />
        </div>
      )}
    </SPreviewContainer>
  );
};

export interface ReportRes {
  url: string | null | undefined;
}

export interface IContent {
  stream: PopupService<IPreviewPopup>;
}
