import { forwardRef, ReactNode } from 'react';
import { Switch as MuiSwitch, FormControlLabel, SwitchProps } from '@mui/material';

export const Switch = forwardRef<HTMLButtonElement, ISwitchProps>(
  ({ label, ...switchProps }, ref) => {
    return <FormControlLabel label={label} ref={ref} control={<MuiSwitch {...switchProps} />} />;
  },
);

export interface ISwitchProps extends SwitchProps {
  label?: ReactNode;
}
