import { t } from 'i18next';
import React, { FC, ReactNode, useEffect, useMemo, useRef, useState, useCallback } from 'react';

/* Icons start */
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
/* Icons end */
import { Box, Button, Checkbox, Stack, Typography, ListItem, List } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { useFormContext } from 'react-hook-form';
import { delay } from 'rxjs';
import {
  FieldsRow,
  FieldsSection,
  FormCheckbox,
  FormNumericField,
} from '../../../../../../../../shared/components/form';
import { Column } from '../../../../../../../../shared/components/table/components/common.components.tsx';
import { Table } from '../../../../../../../../shared/components/table/table.component.tsx';
import {
  ProductionTabOptionsRes,
  ArticleProductionTabDataRes,
} from '../../../../../../services/article.service.ts';
import { IProductionTabState, productionTabState } from '../../../production.state.ts';
import { CalculationPopup } from './popups/calculation.popup.tsx';
import { Theme } from '../../../../../../../../shared/styles/theme/theme.ts';

export const CalculationSection: FC<ICalculationSection> = ({ dictArticles }) => {
  const calculationPopup = useMemo(() => new CalculationPopup({ dictArticles }), []);
  const { getValues } = useFormContext();
  const currentArticleId = getValues('id');
  const [articleProductionCalculationData, setArticleProductionCalculationData] = useState(
    getValues('articleProductionCalculation'),
  );
  const [selectedArticleProdCalcPos, setSelectedArticleProdCalcPos] =
    useState<IProductionTabState['selectedArticleProdCalcPos']>(null);

  const saveArticleProductionCalculation = useRef((e: MouseEvent) => {
    if (!e.target) return;
    const target = e.target as HTMLElement;
    if (!target.closest('.articleProductionCalculation')) {
      productionTabState.pub.saveArticleProductionCalculation();
    }
  });

  useEffect(() => {
    productionTabState.pub.initArticleProductionCalculation(
      getValues('articleProductionCalculation'),
    );
    const productionTabStateSub = productionTabState.sub
      .state()
      .subscribe(({ selectedArticleProdCalcPos, articleProductionCalculation }) => {
        setArticleProductionCalculationData(articleProductionCalculation);
        setSelectedArticleProdCalcPos(selectedArticleProdCalcPos);
      });
    calculationPopup.stream
      .state()
      .pipe(delay(0))
      .subscribe(({ action }) => {
        action === 'open' &&
          document.removeEventListener('click', saveArticleProductionCalculation.current);
        action === 'close' &&
          document.addEventListener('click', saveArticleProductionCalculation.current);
      });
    document.addEventListener('click', saveArticleProductionCalculation.current);
    return () => {
      productionTabStateSub.unsubscribe();
      document.removeEventListener('click', saveArticleProductionCalculation.current);
    };
  }, []);

  const articlesLeadingToCircularDependency = getValues(
    'articleProductionCalculationDependencies.articlesLeadingToCircularDependency',
  ) as TCalculationDependencies<'articlesLeadingToCircularDependency'>;

  const isProductionQuantityRounding = getValues('isProductionQuantityRounding');
  const buttonList: IButtonList[] = useMemo(
    () => [
      {
        title: t('common.add'),
        icon: <AddIcon />,
        onClick: () => {
          calculationPopup.stream.emit('open', {
            values: {
              row: {
                isFermentationInterrupt: false,
                addedArticleDescription: '',
                addedArticleNo: '',
                addedArticleId: '',
                multiplier: null,
                id: `new_${Date.now()}`,
              },
              field: 'addedArticleDescription',
              currentArticleId,
              articlesLeadingToCircularDependency,
            },
          });
        },
      },
      {
        title: t('common.remove'),
        icon: <RemoveIcon />,
        onClick: () => productionTabState.pub.deleteArticleProdCalcPos(),
        disabled: !articleProductionCalculationData.length || !selectedArticleProdCalcPos,
      },
    ],
    [t, selectedArticleProdCalcPos, articleProductionCalculationData, currentArticleId],
  );

  const isCircularChain = useMemo(
    () => Boolean(getValues('articleProductionCalculationDependencies.circularChain').length),
    [getValues()],
  );

  const generateMessage = useCallback(
    (type: 'whichArticlesUseThis' | 'whichArticlesThisUses') => {
      const values = getValues(
        'articleProductionCalculationDependencies',
      ) as ArticleProductionTabDataRes['articleProductionCalculationDependencies'];
      const result = [];
      if (values.circularChain?.length) {
        const data =
          type === 'whichArticlesThisUses'
            ? structuredClone(values.circularChain).reverse()
            : values.circularChain;
        result.push(
          <React.Fragment key={Date.now() + 1}>
            <Typography
              sx={{ padding: 0, color: Theme.palette.error.main }}
              key={Date.now() + type}
              variant='subtitle1'
              fontWeight={600}
            >
              {t('article.circular_dependency_identified')}
            </Typography>
            <List key={Date.now() + 2} sx={{ padding: 0, color: Theme.palette.error.main }}>
              {data.map((el, i, arr) => (
                <SListItem key={i} sx={{ pl: `${i * 15}px` }}>
                  <Box>
                    <SArrowLoopWrap sx={{ opacity: arr.length - 1 !== i ? 1 : 0 }}>
                      &#8658;
                    </SArrowLoopWrap>
                    <Typography
                      component='span'
                      sx={{
                        fontStyle: 'italic',
                        fontSize: 'inherit',
                        fontWeight: 500,
                      }}
                    >
                      {el?.articleNo ?? ''}
                    </Typography>{' '}
                    {el?.description ?? ''}
                  </Box>
                </SListItem>
              ))}
            </List>
          </React.Fragment>,
        );
      } else if (values?.[type]) {
        const data = values[type] as TCalculationDependencies<typeof type>;
        const groupedArr = data
          ?.filter((el) => Boolean(el?.level))
          .sort((a, b) => a?.level! - b?.level!)
          .reduce<Record<number, (typeof data)[0][]>>((acc, item) => {
            if (item?.level && !acc[item.level]) {
              acc[item.level] = [];
            }
            acc[item!.level!].push(item);
            return acc;
          }, {});

        for (const level in groupedArr) {
          result.push(
            <STypography key={Date.now() * Number(level)} variant='subtitle1' fontWeight={600}>
              {t('article.level')} {level}
              <List key={Date.now() + Number(level)} sx={{ padding: 0 }}>
                {groupedArr[level].map((el, i) => (
                  <SListItem key={i}>
                    <SBasedArticle>
                      <Typography
                        component='span'
                        sx={{
                          fontStyle: 'italic',
                          fontSize: 'inherit',
                          fontWeight: 500,
                        }}
                      >
                        {el?.[type === 'whichArticlesThisUses' ? 'articleNo' : 'addedArticleNo'] ??
                          ''}
                      </Typography>{' '}
                      {el?.[
                        type === 'whichArticlesThisUses' ? 'description' : 'addedDescription'
                      ] ?? ''}{' '}
                      {(el?.multiplier ?? 1) >= 1 ? ' * ' : ' / '}{' '}
                      {(el?.multiplier ?? 1) >= 1
                        ? el?.multiplier ?? 1
                        : (1 / (el?.multiplier ?? 1)).toFixed(2)}
                    </SBasedArticle>
                    <Box pl={4}>
                      <SArrowWrap reverse={type === 'whichArticlesUseThis'}>&#8658;</SArrowWrap>
                      <Typography
                        component='span'
                        sx={{
                          fontStyle: 'italic',
                          fontSize: 'inherit',
                          fontWeight: 500,
                        }}
                      >
                        {el?.[type === 'whichArticlesThisUses' ? 'addedArticleNo' : 'articleNo'] ??
                          ''}
                      </Typography>{' '}
                      {el?.[
                        type === 'whichArticlesThisUses' ? 'addedDescription' : 'description'
                      ] ?? ''}
                    </Box>
                  </SListItem>
                ))}
              </List>
            </STypography>,
          );
        }
      }
      return result;
    },
    [getValues(), t],
  );

  return (
    <FieldsSection
      marginTop={'31px'}
      title={t('article.calculation_for_production')}
      titleBoxSx={{ pt: 0 }}
      nomt
    >
      <FieldsRow>
        <FormCheckbox
          label={t('article.article_will_be_replaced_with_calculation')}
          name='willReplacedWithProductionCalc'
        />
        <FormCheckbox
          label={t('article.round_to_whole_pieces')}
          name='isProductionQuantityRounding'
        />
        <FormNumericField
          width='170px'
          label={t('article.rounds_to_units')}
          controls
          disabled={!isProductionQuantityRounding}
          precision={0}
          name='productionQuantityRoundTo'
        />
      </FieldsRow>
      <Box className='articleProductionCalculation' mt={2}>
        <Stack direction='row' spacing={2} mb={1}>
          {buttonList.reduce((acc: ReactNode[], { icon, ...props }, i) => {
            acc.push(
              <Button
                aria-hidden={true}
                key={i}
                color='primary'
                size='large'
                startIcon={icon}
                {...props}
              >
                {props.title}
              </Button>,
            );
            return acc;
          }, [])}
        </Stack>
        <STable
          rowSelectionModel={selectedArticleProdCalcPos?.id}
          onRowClick={(v, e) => {
            if (v.id !== selectedArticleProdCalcPos?.id) {
              productionTabState.pub.selectArticleProdCalcPos(v.row);
            }
            const field = (e.target as any).parentElement?.dataset?.field;
            if (field === 'addedArticleDescription' || field === 'multiplier') {
              calculationPopup.stream.emit('open', {
                values: {
                  row: v.row,
                  field,
                  currentArticleId,
                  articlesLeadingToCircularDependency,
                },
              });
            }
          }}
          onCellKeyDown={({ row, field }, { code }) => {
            if (
              code === 'Enter' &&
              (field === 'addedArticleDescription' || field === 'multiplier')
            ) {
              calculationPopup.stream.emit('open', {
                values: { row, field, currentArticleId, articlesLeadingToCircularDependency },
              });
            }
          }}
          focusTabProps={{
            enabled: true,
            enabledFields: ['addedArticleDescription', 'multiplier'],
          }}
          height={200}
          data={articleProductionCalculationData || []}
        >
          <Column
            field='addedArticleNo'
            headerName={t('common.article_no')}
            width={110}
            sortable={false}
          />
          <Column
            field='addedArticleDescription'
            headerName={t('common.description')}
            sortable={false}
            width={350}
          />
          <Column
            field='multiplier'
            headerName={t('article.multiplier_on_amount')}
            width={165}
            sortable={false}
            align='right'
            headerAlign='right'
          />
          <Column
            field='isFermentationInterrupt'
            headerName={t('article.fermentation_interrupt')}
            width={178}
            headerAlign='center'
            align='center'
            renderCell={({ value }) => (
              <SCheckbox sx={{ m: 0 }} readOnly disableRipple checked={value} />
            )}
            sortable={false}
          />
        </STable>
      </Box>
      <FieldsRow>
        <SBox data-title={t('article.used_in_the_calculation_of_items')} {...{ isCircularChain }}>
          <Stack direction={'column'} overflow='scroll' height='133px'>
            {generateMessage('whichArticlesThisUses')}
          </Stack>
        </SBox>
        <SBox data-title={t('article.used_in_the_calculation_of_items')} {...{ isCircularChain }}>
          <Stack direction={'column'} overflow='scroll' height='133px'>
            {generateMessage('whichArticlesUseThis')}
          </Stack>
        </SBox>
      </FieldsRow>
      <calculationPopup.Component />
    </FieldsSection>
  );
};

const SCheckbox = styled(Checkbox)({
  '& label': {
    margin: 0,
  },
});

const STable = styled(Table)({
  '& .MuiDataGrid-cell': {
    padding: 0,
  },
  '& .MuiDataGrid-cellContent': {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'inherit',
  },
});

const SBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCircularChain',
})<{ isCircularChain: boolean }>(({ theme, isCircularChain }) => ({
  width: '100%',
  height: 155,
  borderRadius: 5,
  border: '1px solid',
  borderColor: isCircularChain ? theme.palette.error.main : theme.palette.grey.A400,
  padding: '10px 13px',
  cursor: 'text',
  position: 'relative',
  '&:hover': {
    borderColor: isCircularChain ? theme.palette.error.dark : theme.palette.grey.A700,
  },
  '&::before': {
    content: 'attr(data-title)',
    transform: 'translateY(-50%)',
    position: 'absolute',
    top: -2,
    left: 9,
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '96%',
    background: theme.palette.background.default,
    paddingLeft: 6,
    paddingRight: 6,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
    color: isCircularChain ? theme.palette.error.main : theme.palette.text.secondary,
  },
}));

const STypography = styled(Typography)(({ theme }) => ({
  position: 'relative',
  paddingLeft: 10,
  '&::before': {
    content: "''",
    display: 'block',
    position: 'absolute',
    width: 3,
    top: 15,
    left: 0,
    height: 'calc(100% - 53px)',
    borderRight: `1px solid ${theme.palette.grey['600']}`,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  '&::after': {
    content: "''",
    display: 'block',
    top: 15,
    left: 3,
    position: 'absolute',
    width: 3,
    height: 1,
    backgroundColor: theme.palette.grey['600'],
  },
}));

const SListItem = styled(ListItem)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
  fontWeight: 400,
  fontSize: 14,
  display: 'block',
}));

const SBasedArticle = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::before': {
    content: "''",
    display: 'block',
    top: 14,
    left: -24,
    position: 'absolute',
    width: 20,
    height: 1,
    backgroundColor: theme.palette.grey['600'],
  },
}));

const SArrowWrap = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'reverse',
})<{ reverse: boolean }>(({ reverse }) => ({
  fontSize: 16,
  display: 'inline-block',
  transform: `${
    reverse ? 'rotate(-140deg) translate(7px, 5px)' : 'rotate(40deg) translate(-7px, -5px)'
  } scale(1)`,
}));

const SArrowLoopWrap = styled(Box)({
  fontSize: 16,
  marginLeft: 10,
  display: 'inline-block',
  transform: 'rotate(90deg) scale(1)',
});

interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

interface ICalculationSection {
  dictArticles: ProductionTabOptionsRes['dictArticles'];
}

export type TCalculationDependencies<
  T extends keyof ArticleProductionTabDataRes['articleProductionCalculationDependencies'],
> = NonNullable<ArticleProductionTabDataRes['articleProductionCalculationDependencies'][T]>;
