import { Observable, filter, map, merge, of, switchMap, take, tap } from 'rxjs';
import { Pub, State, Sub } from '../../../../../shared/state/state.abstract';
import {
  C_Report,
  C_Win_Report_Task_Status,
  Wa_MergePdfInput,
  C_S3_Bucket,
} from '../../../../../graphql/generatedModel';
import { workerService } from './workerService.service';
import { printJobsDetailsState } from '../printJobsDetails.state';
import { getProductionWinReportService } from './utils/getDiffServiceByReportId';
import {
  productionWinReportService,
  ReportCreateRes,
} from '../../../services/productionWinReport.service';
import {
  commonRequestsService,
  MergePDFsRes,
} from '../../../../../shared/services/commonRequests/commonRequests.service';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';

class PubImpl extends Pub<IStatusWorkerState> {
  init(list: IStatusWorkerState['list']) {
    workerService.pub.cancelPolling();
    const statusAllDisabled = list.every((item) => item.status === ReportPreviewStatus.disabled);
    this.emit('init', {
      ...initPreviewData,
      list,
      statusAll: statusAllDisabled ? ReportPreviewStatus.disabled : initPreviewData.statusAll,
    });
  }

  itemAction(params: { uuid: StatusItem['uuid']; reportId: StatusItem['reportId'] }) {
    // eslint-disable-next-line prefer-const
    let { list, tasksForGenerate } = this.stream$.getValue();
    const currentItemInd = list.findIndex((item) => item.uuid === params.uuid);
    const currentItem = list[currentItemInd];
    if (currentItem) {
      const newItem = currentItem;
      switch (currentItem.status) {
        case ReportPreviewStatus.enabled:
        case ReportPreviewStatus.error:
          newItem.status = ReportPreviewStatus.loading;
          tasksForGenerate = [
            ...tasksForGenerate,
            { ...params, status: 'notStarted', taskId: undefined },
          ];
          break;
        case ReportPreviewStatus.ready:
          newItem.status = ReportPreviewStatus.viewed;
          break;
      }
      newItem.errorType = undefined;
      list[currentItemInd] = newItem;
      this.emit('itemAction', { list, tasksForGenerate });
    }
  }

  updateItem({ uuid, newItem }: IUpdateItemData) {
    const { list } = this.stream$.getValue();
    const itemInd = list.findIndex((item) => item.uuid === uuid);
    if (itemInd !== -1) {
      list[itemInd] = { ...list[itemInd], ...newItem };
      this.emit('updateItem', { list });
    }
  }

  batchUpdateItems(itemsToUpdate: IUpdateItemData[]) {
    const list = this.stream$.getValue().list;
    itemsToUpdate.forEach(({ uuid, newItem }) => {
      const itemInd = list.findIndex((item) => item.uuid === uuid);
      if (itemInd !== -1) {
        list[itemInd] = { ...list[itemInd], ...newItem };
      }
    });
    this.emit('updateItem', { list });
  }

  batchStart() {
    const { list } = this.stream$.getValue();
    const tasksForGenerate: IStatusWorkerState['tasksForGenerate'] = [];
    const newList = list.map((item) => {
      if (item.status !== ReportPreviewStatus.disabled && item.includeInBatch) {
        tasksForGenerate.push({ ...item, status: 'notStarted', taskId: undefined });
        item.status = ReportPreviewStatus.loading;
      }
      return item;
    });
    this.emit('batchStart', {
      tasksForGenerate,
      list: newList,
      statusAll: ReportPreviewStatus.loading,
    });
  }

  batchPrint() {
    const { list, mergedAllUrl } = this.stream$.getValue();
    if (!mergedAllUrl) {
      const mergeItems: Array<Wa_MergePdfInput> = list.reduce<Wa_MergePdfInput[]>(
        (outArr, item) => {
          if (
            item.s3Key &&
            (item.status === ReportPreviewStatus.ready ||
              item.status === ReportPreviewStatus.viewed)
          ) {
            outArr.push({
              s3Key: item.s3Key,
              s3Bucket: C_S3_Bucket.S3B_MAIN_TEMP,
              isExternalBucket: true,
            });
          }
          return outArr;
        },
        [],
      );
      commonRequestsService.pub.mergePDFs({ mergeItems });
    }
    this.emit('batchPrint', {
      statusAll: mergedAllUrl ? ReportPreviewStatus.ready : ReportPreviewStatus.loading,
    });
  }
}

class SubImpl extends Sub<IStatusWorkerState> {
  protected actionHandlers(): Observable<IStatusWorkerState> {
    return merge(
      this.updateState(),
      this.createReportsListener(),
      this.pollingListener(),
      this.mergePDFsListener(),
    ).pipe(
      switchMap((streamV) => {
        if (streamV.tasksForGenerate.length) {
          return this.queryReport(of(streamV));
        }
        return of(streamV);
      }),
      map((streamV) => {
        if (streamV.statusAll === ReportPreviewStatus.loading) {
          const isStillLoading = streamV.list.some(
            (item) => item.status === ReportPreviewStatus.loading,
          );
          if (!isStillLoading) {
            streamV.statusAll = 'readyForPrint';
          }
        }
        return streamV;
      }),
      tap((streamV) => this.stream$.next({ ...streamV, action: 'internalUpdate' })),
    );
  }

  private createReportsListener(): Observable<IStatusWorkerState> {
    return productionWinReportService.sub.mergedAll().pipe(
      // Disable old requests if they exist
      responseHandler<ReportCreateRes>({
        errorReturnType: {
          data: null,
          uuid: '',
        },
      }),
      filter(() => this.stream$.getValue().action !== 'init'),
      map(({ data, uuid }) => {
        const streamV = this.stream$.getValue();
        if (data?.taskId && data?.url && uuid) {
          statusWorkerState.pub.updateItem({
            uuid,
            newItem: { taskId: data?.taskId, url: data?.url, s3Key: data.s3Key },
          });
          streamV.tasksForGenerate = streamV.tasksForGenerate.map((item) => {
            if (item.uuid === uuid) {
              return { ...item, taskId: data.taskId, status: 'finished' };
            }
            return item;
          });
        } else {
          statusWorkerState.pub.updateItem({
            uuid,
            newItem: { status: ReportPreviewStatus.error },
          });
          streamV.tasksForGenerate = streamV.tasksForGenerate.filter((item) => item.uuid !== uuid);
        }
        return streamV;
      }),
      map((streamV) => {
        const { tasksForGenerate } = streamV;
        if (tasksForGenerate.every((item) => item.status === 'finished')) {
          const tasksId = tasksForGenerate.reduce<number[]>((outArr, item) => {
            if (item.taskId !== undefined) {
              outArr.push(item.taskId);
            }
            return outArr;
          }, []);
          workerService.pub.startPolling({ tasksId });
          streamV.tasksForGenerate = [];
        }
        return streamV;
      }),
    );
  }

  private pollingListener(): Observable<IStatusWorkerState> {
    return workerService.sub.polling().pipe(
      // Ignore old pollings
      filter(() => this.stream$.getValue().action !== 'init'),
      map((pollingRes) => {
        const streamV = this.stream$.getValue();
        const itemsToUpdate: Array<IUpdateItemData> = [];
        pollingRes.forEach((pollingItem) => {
          const updatingItem = streamV.list.find((item) => item.taskId === pollingItem?.taskId);
          if (pollingItem && updatingItem) {
            let statusItem: ReportPreviewStatus = ReportPreviewStatus.error;
            let errorType: C_Win_Report_Task_Status | undefined = undefined;
            switch (pollingItem.status) {
              case C_Win_Report_Task_Status.WRTS2_READY:
                statusItem = ReportPreviewStatus.ready;
                break;
              case C_Win_Report_Task_Status.WRTS1_NOT_STARTED:
              case C_Win_Report_Task_Status.WRTS5_IN_PROGRESS:
                statusItem = ReportPreviewStatus.loading;
                break;
              case C_Win_Report_Task_Status.WRTS15_NO_DATA_FOR_REPORT:
                errorType = pollingItem.status;
                break;
              default:
                statusItem = ReportPreviewStatus.error;
            }
            if (statusItem !== updatingItem.status) {
              itemsToUpdate.push({
                uuid: updatingItem.uuid,
                newItem: {
                  status: statusItem,
                  errorType,
                  generatedAt:
                    statusItem === ReportPreviewStatus.ready ? new Date().getTime() : undefined,
                },
              });
            }
          }
        });
        if (itemsToUpdate.length) {
          statusWorkerState.pub.batchUpdateItems(itemsToUpdate);
        }
        return streamV;
      }),
      // Always filter this stream since stream value will be updated through pub.batchUpdateItems
      filter(() => false),
    );
  }

  private mergePDFsListener(): Observable<IStatusWorkerState> {
    return commonRequestsService.sub.mergePDFs().pipe(
      responseHandler<MergePDFsRes>({
        errorReturnType: {
          url: '',
          s3Key: '',
        },
      }),
      map((res) => {
        const streamV = this.stream$.getValue();
        return {
          ...streamV,
          action: 'batchPrint',
          mergedAllUrl: res.url,
          statusAll: ReportPreviewStatus.ready,
        };
      }),
    );
  }

  private updateState(): Observable<IStatusWorkerState> {
    return this.actionListener(['init', 'itemAction', 'updateItem', 'batchStart', 'batchPrint']);
  }

  private queryReport(stream$: Observable<IStatusWorkerState>): Observable<IStatusWorkerState> {
    return stream$.pipe(
      switchMap((streamV) => {
        return printJobsDetailsState.sub.sharedValues().pipe(
          take(1),
          map(({ datePeriod }) => {
            const { fromDate: periodStart, toDate: periodEnd } = datePeriod;
            streamV.tasksForGenerate = streamV.tasksForGenerate.map((item) => {
              if (item.status === 'notStarted') {
                const pub = getProductionWinReportService(item.reportId, {
                  uuid: item.uuid,
                  variables: {
                    params: {
                      productionPrintListID: Number(item.uuid),
                      periodStart,
                      periodEnd,
                    },
                  },
                });
                pub?.();
                return { ...item, status: 'inProgress' };
              }
              return item;
            });
            return streamV;
          }),
        );
      }),
    );
  }
}

class StatusWorkerState extends State<IStatusWorkerState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export enum ReportPreviewStatus {
  disabled = 'disabled',
  enabled = 'enabled',
  loading = 'loading',
  ready = 'ready',
  viewed = 'viewed',
  error = 'error',
}

export const initPreviewData: IStatusWorkerState = {
  action: undefined,
  list: [],
  tasksForGenerate: [],
  statusAll: ReportPreviewStatus.enabled,
  mergedAllUrl: undefined,
};
export const statusWorkerState = new StatusWorkerState(initPreviewData);

interface IUpdateItemData {
  uuid: StatusItem['uuid'];
  newItem: Partial<StatusItem>;
}

export interface StatusItem {
  uuid: string;
  status: ReportPreviewStatus;
  reportId: C_Report;
  taskId?: number;
  url?: string;
  s3Key?: string;
  errorType?: C_Win_Report_Task_Status;
  generatedAt?: number;
  includeInBatch: boolean;
}

export interface IStatusWorkerState {
  action:
    | 'init'
    | 'itemAction'
    | 'updateItem'
    | 'startReportGenerating'
    | 'batchStart'
    | 'batchPrint'
    | 'internalUpdate'
    | undefined;
  tasksForGenerate: Array<{
    uuid: StatusItem['uuid'];
    reportId: StatusItem['reportId'];
    status: 'notStarted' | 'inProgress' | 'finished';
    taskId: number | undefined;
  }>;
  list: StatusItem[];
  statusAll: ReportPreviewStatus | 'readyForPrint';
  mergedAllUrl: string | undefined;
}
