import { FC } from 'react';
import { styled } from '@mui/material';
import { FactCheck } from '@mui/icons-material';

import { ReportPreviewStatus } from '../../../states/statusWorker/statusWorker.state';
import { ReportVersionStatus } from '../../../states/versionsWorker/versionsWorker.state';

export const VersionStatusIcon: FC<VersionStatusIconProps> = ({ previewStatus, versionStatus }) => (
  <FactCheckIcon
    className='version-status-icon'
    state={
      previewStatus === ReportPreviewStatus.disabled
        ? 'disabled'
        : versionStatus === ReportVersionStatus.loading
        ? 'loading'
        : versionStatus === ReportVersionStatus.viewed
        ? 'active'
        : undefined
    }
  />
);

const FactCheckIcon = styled(FactCheck)<{
  state?: 'disabled' | 'active' | 'loading';
  className?: string;
}>(({ state, theme }) => ({
  width: '20px',
  height: '20px',
  color: theme.palette.grey['600'],
  cursor: 'pointer',
  transition: 'color 0.4s ease, transform 0.4s ease, opacity 0.4s ease',
  ...(state === 'disabled' && {
    opacity: 0.3,
    cursor: 'default',
  }),
  ...(state === 'active' && {
    color: theme.palette.primary.main,
    cursor: 'default',
  }),
  ...(state === 'loading' && {
    animation: 'loadingPulse 0.8s infinite cubic-bezier(0.66, 0, 0.33, 1)',
    color: theme.palette.primary.main,
  }),

  '@keyframes loadingPulse': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '50%': {
      transform: 'scale(1.15)',
      opacity: 0.8,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
}));

interface VersionStatusIconProps {
  id: string | number;
  previewStatus: ReportPreviewStatus;
  versionStatus: ReportVersionStatus;
}
