import { ITabState } from '../states/tabState.model.ts';
import {
  customerService,
  GetCustomerDefaultOrderDataResExtended,
} from '../services/customer.service.ts';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import { take, zipWith } from 'rxjs';
import {
  configsData,
  DictArticlesRes,
  DictCustomersRes,
} from '../../../shared/services/configsData/configsData.service.ts';
import { initDefaultOrderState } from '../components/tabs/defaultOrder/defaultOrder.state.ts';
import { C_Customer_Def_Order_Source } from '../../../graphql/generatedModel.ts';

export const defaultCustomerOrderData: GetCustomerDefaultOrderDataResExtended = {
  id: '',
  customerWeeklyDefaultOrder: [],
  isDeliverySplitting: false,
  defaultOrderSourceId: C_Customer_Def_Order_Source.DO1_DEFAULT_ORDER,
  isAllowedAutoOrder: false,
  isFlexibleOrders: false,
  distributionLowerLimit: 0,
  distributionHigherLimit: 0,
};

export async function resolveDefaultOrderTab(
  data: IResolveDefaultOrderTab,
): Promise<IResolveDefaultOrderTab> {
  if (data?.customerId) {
    const [defaultOrderData, customerList] = await customerService.globHelpers.streamToPromise(
      customerService.sub.getCustomerDefaultOrderData().pipe(
        zipWith(configsData.sub.dictCustomers()),
        responseHandler<DefaultOrderData>({
          errorReturnType: [defaultCustomerOrderData, []],
        }),
        take(1),
      ),
      () => {
        customerService.pub.getCustomerDefaultOrderData({ customerId: data.customerId! });
        configsData.pub.common(['dictCustomers']);
      },
    );
    const {
      customerWeeklyDefaultOrder: defaultOrderGridData,
      id,
      deliverySplittingPartsCount,
      isDeliverySplitting,
      ...formData
    } = defaultOrderData;
    data.defaultOrderTabData = {
      ...initDefaultOrderState,
      defaultOrderGridData,
      formData,
      customerId: id,
      defaultOrderGridDataBackup: structuredClone(defaultOrderGridData),
      splittingFormData: {
        deliverySplittingPartsCount,
        isDeliverySplitting,
        enabledDays: [],
      },
    };
    data.customerList = customerList;
    data.defaultValues = formData;
  }
  return data;
}

export async function resolveDefaultOrderTabOptions(data: ITabState): Promise<ITabState> {
  data.defaultOrderTabOptions.productList = await customerService.globHelpers.streamToPromise(
    configsData.sub
      .dictArticles()
      .pipe(responseHandler<DictArticlesRes>({ errorReturnType: [] }), take(1)),
    () => configsData.pub.common(['dictArticles']),
  );
  return data;
}

export interface IResolveDefaultOrderTab extends ITabState {
  customerList?: DictCustomersRes;
}

type DefaultOrderData = [GetCustomerDefaultOrderDataResExtended, DictCustomersRes];
