import {
  IPosition,
  INewOfferDataRes,
  CustomerInfoRes,
  IFilteredOfferProductDataRes,
  TaxesByDateRes,
  offerService,
  IOfferDetailsParams,
} from '../../services/offer.service.ts';
import { Pub, State } from '../../../../shared/state/state.abstract.ts';
import { SubImpl } from './sub.state.ts';
import { BehaviorSubject, firstValueFrom, zipWith } from 'rxjs';
import { SaveWa_OfferMutationVariables } from '../../../../graphql/generatedModel.ts';
import { IDateSettingsFormData } from '../../components/offerDetails/popups/dateSettings/content.popup.tsx';
import { customerListState } from '../customerList.state.ts';
import { OfferSelectionPopup } from '../../components/offerDetails/popups/offerSelection/offerSelection.popup.tsx';

export const offerSelectionPopup = new OfferSelectionPopup();

export const initOfferDetailsState: IOfferDetailsState = {
  action: undefined,
  customerInfo: {},
  offerData: {
    offerSourceInfo: {},
  },
  offerDataBackup: {
    offerSourceInfo: {},
  },
  dirty: false,
  noData: true,
  selectedPos: null,
  modificationOptions: {
    ignoreQuantityPerLotProblems: false,
  },
};

export const additionalOptions: IAdditionalOptions = {
  productData: {
    productList: [],
    listSpecialAvailabilityStateForArticles: undefined,
    listSpecialAssortmentGroups: undefined,
  },
  taxes: null,
};
export const additionalOptions$ = new BehaviorSubject<IAdditionalOptions>(additionalOptions);

class PubImpl extends Pub<IOfferDetailsState> {
  init(params: IOfferDetailsState) {
    this.emit('init', params);
  }
  initOptions(params: IAdditionalOptions) {
    additionalOptions$.next(params);
  }
  selectPosition(selectedPos: IOfferDetailsState['selectedPos']) {
    this.emit('selectPosition', { selectedPos });
  }
  updateOfferData(offerData: IOfferDetailsState['offerData'], shouldUpdateBackup?: boolean) {
    this.emit('updateOfferData', {
      offerData,
      ...(shouldUpdateBackup && { offerDataBackup: structuredClone(offerData) }),
    });
  }
  updateDateSettings(dateSettings: IDateSettingsFormData) {
    this.emit('updateDateSettings', { params: dateSettings });
  }
  saveOfferData(
    modificationOptions?: SaveWa_OfferMutationVariables['offerProps']['modificationOptions'],
  ) {
    this.emit('saveOfferData', modificationOptions ? { modificationOptions } : {});
  }
  updatePosition(position?: IOfferDetailsState['selectedPos']) {
    this.emit('updatePosition', { selectedPos: position });
  }
  updatePositions(positions: IOfferDetailsState['offerData']['positions']) {
    const { offerData } = this.stream$.getValue();
    this.emit('updateOfferData', { offerData: { ...offerData, positions } });
  }
  updateCell(selectedPos: IOfferDetailsState['selectedPos'], type: TypeCell) {
    this.emit(type, { selectedPos });
  }
  addPosition(args: AddPositionArgs) {
    this.emit(args.type, args.type === 'addPosition' ? { selectedPos: args.position } : {});
  }
  deletePosition(args: DeletePositionArgs) {
    const { selectedPos, offerData } = this.stream$.getValue();
    if (args.type === 'deletePosition' && args.position?.id !== selectedPos?.id) {
      const positions = offerData.positions?.filter((el) => el.id !== args.position?.id);
      this.updatePositions(positions);
    } else {
      this.emit(args.type, args.type === 'deletePosition' ? { selectedPos: args.position } : {});
    }
  }
  replaceOfferData(params: IOfferDetailsParams) {
    offerService.pub.offerData(params);
    this.emit('replaceOfferData', {});
  }
  revertOrderData() {
    this.emit('revertOrderData', {});
  }
  async updateLastEmailDetails(
    datetimeOfLastEmail: IOfferDetailsState['offerData']['datetimeOfLastEmail'],
    dataRecordId: string,
  ) {
    const [{ offerData, offerDataBackup }, { customer }] = await firstValueFrom(
      this.stream$.pipe(zipWith(customerListState.sub.sharedValues())),
    );
    if (customer?.offerId && customer?.offerId === dataRecordId) {
      offerData.datetimeOfLastEmail = datetimeOfLastEmail;
      offerDataBackup.datetimeOfLastEmail = datetimeOfLastEmail;
      this.emit('updateOfferData', { offerData, offerDataBackup });
    }
  }
}

class OfferDetailsState extends State<IOfferDetailsState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const offerDetailsState = new OfferDetailsState(initOfferDetailsState);

export interface IOfferDetailsState {
  action:
    | 'init'
    | 'updateState'
    | 'updateOfferData'
    | 'updateDateSettings'
    | 'saveOfferData'
    | 'selectPosition'
    | 'loader'
    | 'internalUpdate'
    | 'emptyData'
    | 'errorData'
    | 'queryData'
    | 'updatePosition'
    | 'replaceOfferData'
    | 'revertOrderData'
    | 'selectOffer'
    | TypeAddPosition
    | TypeDeletePosition
    | TypeCell
    | undefined;
  customerInfo: ICustomerInfo;
  offerData: INewOfferDataRes;
  offerDataBackup: INewOfferDataRes;
  dirty: boolean;
  noData: boolean;
  selectedPos: IPosition | null;
  modificationOptions: SaveWa_OfferMutationVariables['offerProps']['modificationOptions'];
  params?: any;
}

export interface ICustomerInfo extends Partial<Pick<CustomerInfoRes, TSeparateCustomerInfoFields>> {
  contacts?: Omit<CustomerInfoRes, TSeparateCustomerInfoFields>;
  transportSector?: CustomerInfoRes['transportSector'];
  transportSectorId?: CustomerInfoRes['transportSectorId'];
}
export interface IAdditionalOptions {
  productData: IFilteredOfferProductDataRes;
  taxes: TaxesByDateRes | null;
}

type TSeparateCustomerInfoFields =
  | 'customerNo'
  | 'fullName'
  | 'informationTip'
  | 'deliveryAddress'
  | 'discountKindId'
  | 'isDeliverySplitting'
  | 'deliverySplittingPartsCount';
type TypeCell = 'priceCell' | 'descriptionCell' | 'vatCodeCell' | 'discountCell' | 'quantityCell';

/* TYPES TO ADD POSITION start */
type TypeAddPosition =
  | IAddPosition['type']
  | IAddHeaderPosition['type']
  | IAddPageBreakPosition['type'];
interface IAddPosition {
  type: 'addPosition';
  position?: IOfferDetailsState['selectedPos'];
}
interface IAddHeaderPosition {
  type: 'addHeaderPosition';
  position?: never;
}
interface IAddPageBreakPosition {
  type: 'addPageBreakPosition';
  position?: never;
}
type AddPositionArgs = IAddPosition | IAddHeaderPosition | IAddPageBreakPosition;
/* TYPES TO ADD POSITION end */
/* TYPES TO DELETE POSITION start */
type TypeDeletePosition = IDeletePosition['type'];
interface IDeletePosition {
  type: 'deletePosition';
  position: IOfferDetailsState['selectedPos'];
}
type DeletePositionArgs = IDeletePosition;
/* TYPES TO DELETE POSITION end */

export interface IOfferPrepareArgsToSave {
  state: IOfferDetailsState;
  offerId: string;
  modificationOptions: IOfferDetailsState['modificationOptions'];
}
