import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper';

type Keys =
  | 'new_article'
  | 'article'
  | 'articles'
  | 'general'
  | 'availability'
  | 'availability_setting'
  | 'availability_in_procurement_module'
  | 'lists'
  | 'post'
  | 'production'
  | 'label'
  | 'regular_price'
  | 'special_price'
  | 'addNewRecord'
  | 'deleteRecord'
  | 'net_article'
  | 'article_is_active'
  | 'product_group'
  | 'production_group'
  | 'marketing_group'
  | 'sale_unit'
  | 'custom_week_days'
  | 'period'
  | 'break_off'
  | 'repeat_yearly'
  | 'use_custom_weekdays_settings'
  | 'piece_or_unit'
  | 'description_column'
  | 'number_column'
  | 'drop_file_here'
  | 'active_article'
  | 'copy'
  | 'delivery_periods'
  | 'assortment_groups'
  | 'cashassist_groups'
  | 'cashassist_templates'
  | 'product_groups'
  | 'marketing_groups'
  | 'production_groups'
  | 'shipping_points'
  | 'fraction'
  | 'dough'
  | 'from_date_or_to_date_should_be_selected'
  | 'at_least_one_price_category_should_be_not_empty'
  | 'there_is_a_record_with_the_same_period'
  | 'go_to_the_general_tab_for_copy'
  | 'dough_item'
  | 'dough_weight_before_baking'
  | 'fermentation_interrupt'
  | 'display_on_production_print_lists'
  | 'recipe'
  | 'article_link'
  | 'sheet'
  | 'number_per_sheet'
  | 'sheets_per_production'
  | 'calculation_for_production'
  | 'article_will_be_replaced_with_calculation'
  | 'round_to_whole_pieces'
  | 'rounds_to_units'
  | 'article_no'
  | 'factor_on_quantity'
  | 'used_in_the_calculation'
  | 'sales_group'
  | 'consume_by'
  | 'consumers_storage_info'
  | 'sales_information_for_customer'
  | 'visibility'
  | 'template'
  | 'labeling'
  | 'alternative_designation_on_label'
  | 'origin'
  | 'ean'
  | 'check_digit'
  | 'ean_code'
  | 'ean_code_is_managed_in_cashAssist'
  | 'declaration'
  | 'link_to_recipeAssist'
  | 'sell_by'
  | 'selling_weight_piece'
  | 'alternative_sales_weight'
  | 'shelf_life_in_days'
  | 'item_must_be_kept_cool'
  | 'storage'
  | 'ean_test'
  | 'used_in_the_calculation_of_items'
  | 'create_article'
  | 'copy_article'
  | 'warn_user_with_this_note'
  | 'articleNo_maxLen'
  | 'eanCode_must_digits'
  | 'eanCode_onlyNumb'
  | 'eanCode_not_valid'
  | 'allProductGroups'
  | 'description_maxLen'
  | 'not_unique_number'
  | 'article_list_not_loaded'
  | 'article_saved'
  | 'article_not_saved'
  | 'tab_not_loaded'
  | 'filter_not_loaded'
  | 'basis'
  | 'groups'
  | 'picture'
  | 'cashassist'
  | 'weight'
  | 'days_to_produce'
  | 'fractional_article'
  | 'used_fractional_article'
  | 'number_of_used_fractions'
  | 'charge_splitting'
  | 'kg_pro_charge'
  | 'liters'
  | 'shipping_point'
  | 'weight_of_liter_of_dough'
  | 'round_to'
  | 'number_of_fractions_per_unit'
  | 'round_up'
  | 'article_already_linked'
  | 'multiplier_on_amount'
  | 'level'
  | 'circular_dependency_identified'
  | 'not_available_due_to_circular_dependency'
  | 'copy_special_prices';

const t = LocalizationHelper<Keys>('article');

export const articleDe = t({
  new_article: 'Neuartikel',
  article: 'Artikel',
  articles: 'Artikel',
  cashassist: 'CashAssist',
  general: 'Allgemein',
  availability: 'Verfügbarkeit',
  availability_setting: 'Verfügbarkeit Einstellung',
  availability_in_procurement_module: 'Verfügbarkeit in Beschaffungsmodul',
  lists: 'Listen',
  post: 'Post',
  production: 'Produktion',
  label: 'Etikett',
  special_price: 'Sonderpreise',
  addNewRecord: 'Neuen Eintrag hinzufügen',
  deleteRecord: 'Eintrag löschen',
  consumers_storage_info: 'Verbrauchsinformation für den Kunden',
  sales_information_for_customer: 'Verkaufsinformationen für den Kunden',
  regular_price: 'Standartpreis',
  net_article: 'Nettoartikel (kein Rabatt möglich)',
  article_is_active: 'Artikel ist aktiv',
  product_group: 'Warengruppe',
  production_group: 'Produktionsgruppe',
  marketing_group: 'Marketinggruppe',
  sale_unit: 'Verkaufseinheit',
  piece_or_unit: 'Stück/Einheit',
  description_column: 'Bezeichnung',
  number_column: 'Nummer',
  drop_file_here: 'Datei hier ablegen',
  active_article: 'Aktive Artikel',
  copy: 'Kopieren',
  assortment_groups: 'Sortimentsgruppen',
  cashassist_groups: 'CashAssist Umsatzgruppen',
  cashassist_templates: 'CashAssist Schablonen',
  product_groups: 'Warengruppen',
  marketing_groups: 'Marketinggruppen',
  production_groups: 'Produktionsgruppen',
  shipping_points: 'Versandstellen',
  fraction: 'Bruch',
  dough: 'Teig',
  from_date_or_to_date_should_be_selected: '"Von Datum" oder "Bis Datum" muss gewählt werden',
  at_least_one_price_category_should_be_not_empty:
    'Mindestens eine Preisklasse sollte nicht leer sein',
  there_is_a_record_with_the_same_period: 'Es gibt einen Datensatz mit demselben Zeitraum',
  repeat_yearly: 'Wiederhole  jährlich',
  use_custom_weekdays_settings: 'Verwenden Sie benutzerdefinierte Wochentag-Einstellungen',
  custom_week_days: 'Wochentage',
  period: 'Zeitraum',
  break_off: 'Aussetzen',
  delivery_periods: 'Lieferperioden',
  go_to_the_general_tab_for_copy: 'Gehen Sie zum Kopieren auf die Registerkarte Allgemein',
  dough_item: 'Teigartikel',
  dough_weight_before_baking: 'Teiggewicht vor dem Backen (g)',
  fermentation_interrupt: 'Gärunterbrechung',
  display_on_production_print_lists: 'Auf Produktionslisten anzeigen',
  recipe: 'Rezept',
  article_link: 'Artikelverknüpfung',
  sheet: 'Blech',
  shipping_point: 'Versandstelle',
  consume_by: 'Zu verbrauchen bis',
  number_per_sheet: 'Anzahl pro Blech',
  sheets_per_production: 'Anzahl Blech pro Herstellung',
  calculation_for_production: 'Kalkulation für Produktion',
  article_will_be_replaced_with_calculation: 'Artikel wird mit Berechnung ersetzt',
  round_to_whole_pieces: 'Auf ganze Stück runden',
  rounds_to_units: 'Runden auf Einheiten',
  article_no: 'Artikel-Nr.',
  factor_on_quantity: 'Faktor auf Menge',
  used_in_the_calculation: 'Bei der Kalkulation werden folgende verwendet',
  sales_group: 'Umsatzgruppe',
  visibility: 'Sichtbarkeit',
  template: 'Schablone',
  allProductGroups: 'Alle Sortimentsgruppen',
  labeling: 'Etikettierung',
  alternative_designation_on_label: 'Alternative Bezeichnung auf Etikett',
  origin: 'Herkunft',
  ean: 'EAN',
  ean_code: 'EAN-Code',
  ean_code_is_managed_in_cashAssist: 'EAN-Code wird im CashAssist verwaltet',
  declaration: 'Deklaration',
  link_to_recipeAssist: 'Verknüpfung mit RezeptAssist',
  sell_by: 'Zu verkaufen bis',
  selling_weight_piece: 'Verkaufsgewicht Stück',
  alternative_sales_weight: 'Alternativen Verkaufsgewicht (g)',
  shelf_life_in_days: 'Haltbarkeit in Tagen',
  item_must_be_kept_cool: 'Artikel muss kühl gehalten werden',
  storage: 'Lagerung',
  eanCode_must_digits: 'Die Codes müssen acht- oder dreizehnstellig sein',
  check_digit: 'Prüfziffer',
  ean_test: 'EAN Test',
  used_in_the_calculation_of_items:
    'Dieser Artikel wird bei der Kalkulation folgender Artikel verwendet',
  create_article: 'Artikel erstellen',
  copy_article: 'Artikel kopieren',
  warn_user_with_this_note:
    'Den Benutzer mit diesem Hinweis warnen, wenn der Artikel bestellt wird',
  not_unique_number: 'Nummer existiert bereits',
  articleNo_maxLen: 'Maximale Zeichenanzahl - 10',
  eanCode_onlyNumb: 'EAN darf nur Zahlen enthalten',
  eanCode_not_valid: 'EAN nicht gültig',
  description_maxLen: 'Maximale Zeichenanzahl - 64',
  filter_not_loaded: 'Filterdaten werden nicht geladen!',
  article_list_not_loaded: 'Artikellistendaten nicht geladen!',
  article_saved: 'Artikel wurde erfolgreich gespeichert',
  article_not_saved: 'Fehler: Artikel nicht gespeichert!',
  tab_not_loaded: 'Daten für Tab nicht geladen!',
  basis: 'Basis',
  groups: 'Gruppen',
  picture: 'Bild',
  weight: 'Gewicht (g)',
  days_to_produce: 'Tage zu produzieren',
  fractional_article: 'Bruch-Artkiel',
  used_fractional_article: 'Verwendet Bruch-Artikel',
  number_of_used_fractions: 'Verwendete Teile des Bruchs für diesen Artikel',
  charge_splitting: 'Charge Splitting',
  kg_pro_charge: 'Kg pro Charge',
  liters: 'Liter',
  weight_of_liter_of_dough: 'Literanzahl Teig (g)',
  round_to: 'Runden auf (lt)',
  number_of_fractions_per_unit: 'Anzahl Teile des Bruchs',
  round_up: 'Aufrunden',
  article_already_linked: 'Dieser Rezept ist gerade mit einem anderen Artikel verknüpft',
  multiplier_on_amount: 'Faktor auf Menge',
  level: 'STUFE',
  circular_dependency_identified: 'Zyklische Abhängigkeit entdeckt',
  not_available_due_to_circular_dependency:
    'Nicht verfügbar, da es zu einer zyklischen Abhängigkeit führt!',
  copy_special_prices: 'Sonderpreise kopieren',
});

export const articleEn = t({
  new_article: 'New article',
  article: 'Article',
  cashassist: 'CashAssist',
  articles: 'Articles',
  availability: 'Availability',
  availability_setting: 'Availability Setting',
  availability_in_procurement_module: 'Availability in procurement module',
  general: 'General',
  lists: 'Lists',
  post: 'Post',
  production: 'Production',
  label: 'Label',
  regular_price: 'Regular price',
  net_article: 'No discount possible',
  article_is_active: 'Article is active',
  product_group: 'Product group',
  production_group: 'Production group',
  marketing_group: 'Marketing group',
  sale_unit: 'Sale unit',
  piece_or_unit: 'Quantity/Lot',
  description_column: 'Description',
  number_column: 'Number',
  drop_file_here: 'Drop file here',
  active_article: 'Active article',
  copy: 'Copy',
  special_price: 'Special prices',
  addNewRecord: 'Add new record',
  deleteRecord: 'Delete record',
  repeat_yearly: 'Repeat yearly',
  use_custom_weekdays_settings: 'Use custom weekdays settings',
  custom_week_days: 'Custom week days',
  period: 'Period',
  break_off: 'Break off',
  assortment_groups: 'Assortment groups',
  cashassist_groups: 'CashAssist product groups',
  cashassist_templates: 'CashAssist templates',
  product_groups: 'Product groups',
  marketing_groups: 'Marketing groups',
  production_groups: 'Production groups',
  shipping_points: 'Shipping points',
  fraction: 'Fraction',
  dough: 'Dough',
  from_date_or_to_date_should_be_selected: '"From date" or "To date" should be selected',
  at_least_one_price_category_should_be_not_empty:
    'At least one price category should be not empty',
  there_is_a_record_with_the_same_period: 'There is a record with the same period',
  shipping_point: 'Shipping point',
  go_to_the_general_tab_for_copy: 'Go to the General tab for copy',
  dough_item: 'Dough article',
  dough_weight_before_baking: 'Dough weight before baking (g)',
  fermentation_interrupt: 'Fermentation interrupt',
  display_on_production_print_lists: 'Display on production print lists',
  recipe: 'Recipe',
  article_link: 'Article linking',
  sheet: 'Sheet',
  number_per_sheet: 'Number per sheet',
  sheets_per_production: 'Number of sheets per production',
  calculation_for_production: 'Calculation for production',
  article_will_be_replaced_with_calculation: 'Article will be replaced with calculation',
  round_to_whole_pieces: 'Round to whole pieces',
  rounds_to_units: 'Round to units',
  article_no: 'Article No.',
  factor_on_quantity: 'Factor on quantity',
  used_in_the_calculation: 'The following are used in the calculation',
  sales_group: 'Sales group',
  visibility: 'Visibility',
  template: 'Template',
  labeling: 'Labeling',
  alternative_designation_on_label: 'Alternative description on label',
  origin: 'Origin',
  ean: 'EAN',
  consume_by: 'Consume by',
  ean_code: 'EAN Number',
  ean_code_is_managed_in_cashAssist: 'EAN Code is managed in CashAssist',
  declaration: 'Declaration',
  link_to_recipeAssist: 'Connection to RezeptAssist',
  sell_by: 'To sell by',
  selling_weight_piece: 'Item sale weight',
  alternative_sales_weight: 'Alternative sale weight (g)',
  shelf_life_in_days: 'Shelf life in days',
  item_must_be_kept_cool: 'Article must be kept cool',
  consumers_storage_info: 'Consumption Information for customer',
  sales_information_for_customer: 'Sales information for customer',
  storage: 'Storage',
  ean_test: 'EAN test',
  allProductGroups: 'All assortment groups',
  delivery_periods: 'Delivery periods',
  used_in_the_calculation_of_items: 'This item is used in the calculation of the following items',
  create_article: 'Create article',
  copy_article: 'Copy article',
  warn_user_with_this_note: 'Warn user with this note when ordering article',
  not_unique_number: 'Number already exists',
  articleNo_maxLen: 'Maximum characters number - 10',
  eanCode_must_digits: 'EAN must be eight or thirteen digits long',
  check_digit: 'Check digit',
  eanCode_onlyNumb: 'EAN must contain only numbers',
  eanCode_not_valid: 'EAN not valid',
  description_maxLen: 'Maximum characters number - 64',
  filter_not_loaded: `Filter data don't loaded!`,
  article_list_not_loaded: `Article list data don't loaded!`,
  article_saved: 'Article was successfully saved',
  article_not_saved: 'Error: Article not saved!',
  tab_not_loaded: 'Data for tab not loaded!',
  basis: 'Basis',
  groups: 'Groups',
  picture: 'Picture',
  weight: 'Weight (g)',
  days_to_produce: 'Days to produce',
  fractional_article: 'Fractional article',
  used_fractional_article: 'Used fractional article',
  number_of_used_fractions: 'Number of used fractions',
  charge_splitting: 'Charge splitting',
  kg_pro_charge: 'Kg pro charge',
  liters: 'Liters',
  weight_of_liter_of_dough: 'Weight of liter of dough (g)',
  round_to: 'Round to (lt)',
  number_of_fractions_per_unit: 'Number of fractions per unit',
  round_up: 'Round up',
  article_already_linked: 'The current recipe is already linked to another article',
  multiplier_on_amount: 'Multiplier on amount',
  level: 'STAGE',
  circular_dependency_identified: 'Circular dependency detected',
  not_available_due_to_circular_dependency: 'Not available due to a circular dependency!',
  copy_special_prices: 'Copy special prices',
});
export const articleFr = t({
  new_article: 'Nouvel article',
  article: 'Article',
  cashassist: 'CashAssist',
  articles: 'Articles',
  availability: 'Disponibilité',
  availability_setting: 'Paramètre de disponibilité',
  availability_in_procurement_module: "Disponibilité dans le module d'approvisionnement",
  general: 'Général',
  lists: 'Listes',
  post: 'Publier',
  production: 'Production',
  label: 'Étiquette',
  regular_price: 'Prix régulier',
  net_article: 'Aucun rabais possible',
  article_is_active: "L'article est actif",
  product_group: 'Groupe de produits',
  production_group: 'Groupe de production',
  marketing_group: 'Groupe marketing',
  sale_unit: 'Unité de vente',
  piece_or_unit: 'Quantité/Lot',
  description_column: 'Description',
  number_column: 'Numéro',
  drop_file_here: 'Déposez le fichier ici',
  active_article: 'Article actif',
  copy: 'Copier',
  special_price: 'Prix spéciaux',
  addNewRecord: 'Ajouter un nouvel enregistrement',
  deleteRecord: "Supprimer l'enregistrement",
  repeat_yearly: 'Répéter annuellement',
  use_custom_weekdays_settings: 'Utiliser des paramètres de jours de la semaine personnalisés',
  custom_week_days: 'Jours de la semaine personnalisés',
  period: 'Période',
  break_off: 'Détacher',
  assortment_groups: "Groupes d'assortiment",
  cashassist_groups: 'Groupes de produits CashAssist',
  cashassist_templates: 'Modèles CashAssist',
  product_groups: 'Groupes de produits',
  marketing_groups: 'Groupes marketing',
  production_groups: 'Groupes de production',
  shipping_points: "Points d'expédition",
  fraction: 'Fraction',
  dough: 'Pâte',
  from_date_or_to_date_should_be_selected:
    '"Date de début" ou "Date de fin" doit être sélectionnée',
  at_least_one_price_category_should_be_not_empty:
    'Au moins une catégorie de prix ne doit pas être vide',
  there_is_a_record_with_the_same_period: 'Il existe un enregistrement avec la même période',
  shipping_point: "Point d'expédition",
  go_to_the_general_tab_for_copy: "Allez à l'onglet Général pour copier",
  dough_item: 'Article de pâte',
  dough_weight_before_baking: 'Poids de la pâte avant cuisson (g)',
  fermentation_interrupt: 'Interruption de fermentation',
  display_on_production_print_lists: "Afficher sur les listes d'impression de production",
  recipe: 'Recette',
  article_link: 'Lien d’article',
  sheet: 'Feuille',
  number_per_sheet: 'Nombre par feuille',
  sheets_per_production: 'Nombre de feuilles par production',
  calculation_for_production: 'Calcul pour production',
  article_will_be_replaced_with_calculation: "L'article sera remplacé par un calcul",
  round_to_whole_pieces: 'Arrondir à des pièces entières',
  rounds_to_units: 'Arrondir aux unités',
  article_no: "Numéro d'article",
  factor_on_quantity: 'Facteur sur la quantité',
  used_in_the_calculation: 'Ce qui est utilisé dans le calcul',
  sales_group: 'Groupe de ventes',
  visibility: 'Visibilité',
  template: 'Modèle',
  labeling: 'Étiquetage',
  alternative_designation_on_label: "Désignation alternative sur l'étiquette",
  origin: 'Origine',
  ean: 'EAN',
  consume_by: "À consommer jusqu'au",
  ean_code: 'Numéro EAN',
  ean_code_is_managed_in_cashAssist: 'Le code EAN est géré dans CashAssist',
  declaration: 'Déclaration',
  link_to_recipeAssist: 'Lien vers RezeptAssist',
  sell_by: 'Vendu par',
  selling_weight_piece: "Poids de vente de l'article",
  alternative_sales_weight: 'Poids de vente alternatif (g)',
  shelf_life_in_days: 'Durée de conservation en jours',
  item_must_be_kept_cool: "L'article doit être conservé au frais",
  consumers_storage_info: 'Informations de consommation pour le client',
  sales_information_for_customer: 'Informations de vente pour le client',
  storage: 'Stockage',
  ean_test: 'Test EAN',
  allProductGroups: "Tous les groupes d'assortiment",
  delivery_periods: 'Périodes de livraison',
  used_in_the_calculation_of_items: 'Cet article est utilisé dans le calcul des articles suivants',
  create_article: 'Créer un article',
  copy_article: "Copier l'article",
  warn_user_with_this_note:
    "Avertir l'utilisateur avec cette note lors de la commande de l'article",
  not_unique_number: 'Le nombre existe déjà',
  articleNo_maxLen: 'Nombre maximum de caractères - 10',
  eanCode_must_digits: 'Le code EAN doit contenir huit ou treize chiffres',
  check_digit: 'Chiffre de contrôle',
  eanCode_onlyNumb: 'Le code EAN ne doit contenir que des chiffres',
  eanCode_not_valid: 'EAN non valide',
  description_maxLen: 'Nombre maximum de caractères - 64',
  filter_not_loaded: `Données de filtre non chargées !`,
  article_list_not_loaded: `Les données de la liste des articles ne sont pas chargées !`,
  article_saved: "L'article a été enregistré avec succès",
  article_not_saved: "Erreur : L'article n'a pas été enregistré !",
  tab_not_loaded: "Données de l'onglet non chargées !",
  basis: 'Base',
  groups: 'Groupes',
  picture: 'Image',
  weight: 'Poids (g)',
  days_to_produce: 'Jours de production',
  fractional_article: 'Article fractionné',
  used_fractional_article: 'Article fractionné utilisé',
  number_of_used_fractions: 'Nombre de fractions utilisées',
  charge_splitting: 'Répartition des charges',
  kg_pro_charge: 'Kg par charge',
  liters: 'Litres',
  weight_of_liter_of_dough: 'Poids du litre de pâte (g)',
  round_to: 'Arrondir à (lt)',
  number_of_fractions_per_unit: 'Nombre de fractions par unité',
  round_up: "Arrondir à l'haut",
  article_already_linked: 'La recette actuelle est déjà liée à un autre article',
  multiplier_on_amount: 'Multiplicateur sur le montant',
  level: 'ÉTAPE',
  circular_dependency_identified: 'Dépendance circulaire détectée',
  not_available_due_to_circular_dependency: "Indisponible en raison d'une dépendance circulaire!",
  copy_special_prices: 'Copier les prix spéciaux',
});

export const articleIt = t({
  new_article: 'Nuovo articolo',
  article: 'Articolo',
  cashassist: 'CashAssist',
  articles: 'Articoli',
  availability: 'Disponibilità',
  availability_setting: 'Impostazione disponibilità',
  availability_in_procurement_module: 'Disponibilità nel modulo di approvvigionamento',
  general: 'Generale',
  lists: 'Liste',
  post: 'Pubblica',
  production: 'Produzione',
  label: 'Etichetta',
  regular_price: 'Prezzo normale',
  net_article: 'Nessuno sconto possibile',
  article_is_active: 'Articolo attivo',
  product_group: 'Gruppo di prodotti',
  production_group: 'Gruppo di produzione',
  marketing_group: 'Gruppo di marketing',
  sale_unit: 'Unità di vendita',
  piece_or_unit: 'Quantità/Lotto',
  description_column: 'Descrizione',
  number_column: 'Numero',
  drop_file_here: 'Trascina il file qui',
  active_article: 'Articolo attivo',
  copy: 'Copia',
  special_price: 'Prezzi speciali',
  addNewRecord: 'Aggiungi un nuovo record',
  deleteRecord: 'Elimina record',
  repeat_yearly: 'Ripeti annualmente',
  use_custom_weekdays_settings: 'Usa impostazioni personalizzate dei giorni',
  custom_week_days: 'Giorni della settimana personalizzati',
  period: 'Periodo',
  break_off: 'Interruzione',
  assortment_groups: 'Gruppi di assortimento',
  cashassist_groups: 'Gruppi di prodotti CashAssist',
  cashassist_templates: 'Modelli CashAssist',
  product_groups: 'Gruppi di prodotto',
  marketing_groups: 'Gruppi di marketing',
  production_groups: 'Gruppi di produzione',
  shipping_points: 'Punti di spedizione',
  fraction: 'Frazione',
  dough: 'Impasto',
  from_date_or_to_date_should_be_selected: '"Data da" o "data a" devono essere selezionate',
  at_least_one_price_category_should_be_not_empty:
    'Almeno una categoria di prezzo deve essere non vuota',
  there_is_a_record_with_the_same_period: "C'è un record con lo stesso periodo",
  shipping_point: 'Punto di spedizione',
  go_to_the_general_tab_for_copy: 'Vai alla scheda Generale per la copia',
  dough_item: 'Articolo di impasto',
  dough_weight_before_baking: "Peso dell'impasto prima della cottura (g)",
  fermentation_interrupt: 'Interruzione della fermentazione',
  display_on_production_print_lists: 'Visualizza sulle liste di stampa della produzione',
  recipe: 'Ricetta',
  article_link: 'Collegamento articolo',
  sheet: 'Foglio',
  number_per_sheet: 'Numero per foglio',
  sheets_per_production: 'Numero di fogli per produzione',
  calculation_for_production: 'Calcolo per la produzione',
  article_will_be_replaced_with_calculation: "L'articolo sarà sostituito con il calcolo",
  round_to_whole_pieces: 'Arrotonda a pezzi interi',
  rounds_to_units: 'Arrotonda a unità',
  article_no: 'Numero articolo',
  factor_on_quantity: 'Fattore sulla quantità',
  used_in_the_calculation: 'I seguenti sono utilizzati nel calcolo',
  sales_group: 'Gruppo di vendita',
  visibility: 'Visibilità',
  template: 'Modello',
  labeling: 'Etichettatura',
  alternative_designation_on_label: "Descrizione alternativa sull'etichetta",
  origin: 'Origine',
  ean: 'EAN',
  consume_by: 'Consumare entro',
  ean_code: 'Numero EAN',
  ean_code_is_managed_in_cashAssist: 'Il codice EAN è gestito in CashAssist',
  declaration: 'Dichiarazione',
  link_to_recipeAssist: 'Collegamento a RezeptAssist',
  sell_by: 'Da vendere entro',
  selling_weight_piece: "Peso di vendita dell'articolo",
  alternative_sales_weight: 'Peso di vendita alternativo (g)',
  shelf_life_in_days: 'Scadenza in giorni',
  item_must_be_kept_cool: "L'articolo deve essere conservato in un luogo fresco",
  consumers_storage_info: 'Informazioni di consumo per il cliente',
  sales_information_for_customer: 'Informazioni sulle vendite per il cliente',
  storage: 'Stoccaggio',
  ean_test: 'Test EAN',
  allProductGroups: 'Tutti i gruppi di assortimento',
  delivery_periods: 'Modalità di consegna',
  used_in_the_calculation_of_items:
    'Questo articolo è utilizzato nel calcolo dei seguenti articoli',
  create_article: 'Crea articolo',
  copy_article: 'Copia articolo',
  warn_user_with_this_note: "Avvisa l'utente con questa nota quando ordina l'articolo",
  not_unique_number: 'Numero già esistente',
  articleNo_maxLen: 'Numero massimo di caratteri - 10',
  eanCode_must_digits: "L'EAN deve essere lungo otto o tredici cifre",
  check_digit: 'Cifra di controllo',
  eanCode_onlyNumb: "L'EAN deve contenere solo numeri",
  eanCode_not_valid: 'EAN non valido',
  description_maxLen: 'Numero massimo di caratteri - 64',
  filter_not_loaded: `I dati del filtro non sono stati caricati!`,
  article_list_not_loaded: `I dati della lista degli articoli non sono stati caricati!`,
  article_saved: 'Articolo salvato con successo',
  article_not_saved: 'Errore: articolo non salvato!',
  tab_not_loaded: 'Dati della scheda non caricati!',
  basis: 'Base',
  groups: 'Gruppi',
  picture: 'Immagine',
  weight: 'Peso (g)',
  days_to_produce: 'Giorni per produrre',
  fractional_article: 'Articolo frazionario',
  used_fractional_article: 'Articolo frazionario utilizzato',
  number_of_used_fractions: 'Numero di frazioni utilizzate',
  charge_splitting: 'Divisione dei costi',
  kg_pro_charge: 'Kg per carica',
  liters: 'Litri',
  weight_of_liter_of_dough: 'Peso di un litro di impasto (g)',
  round_to: 'Arrotonda a (lt)',
  number_of_fractions_per_unit: 'Numero di frazioni per unità',
  round_up: 'Arrotonda per eccesso',
  article_already_linked: 'La ricetta attuale è già collegata a un altro articolo',
  multiplier_on_amount: 'Moltiplicatore sulla quantità',
  level: 'FASE',
  circular_dependency_identified: 'Dipendenza circolare rilevata',
  not_available_due_to_circular_dependency: 'Non disponibile a causa di una dipendenza circolare!',
  copy_special_prices: 'Copiare i prezzi speciali',
});
