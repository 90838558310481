import { LoaderFunction } from 'react-router-dom';

import {
  initStockOfFreezerListState,
  IStockOfFreezerListState,
} from '../../stockOfFreezer/states/stockOfFreezerList.state';

import {
  initStockOfFreezerDetailsState,
  IStockOfFreezerDetailsState,
} from '../../stockOfFreezer/states/stockOfFreezerDetails.state';
import {
  ProcurementProductDataAndStorageChangesRes,
  ListProcurementProductsRes,
  stockOfFreezerService,
} from '../../services/stockOfFreezer.service.ts';
import { take } from 'rxjs';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';
import { storageHelper } from '../../../../shared/helpers/storage';
import { dataHelper } from '../../../../shared/helpers/data/data.helper.ts';
import {
  configsData,
  DictStorageLocationsRes,
} from '../../../../shared/services/configsData/configsData.service.ts';
import { addDays, format, subDays } from 'date-fns';

export const stockOfFreezerLoader: LoaderFunction = async (): Promise<IStockOfFreezerLoader> => {
  const initListData = {
    ...(await resolveStockOfFreezerListData(structuredClone(initStockOfFreezerListState))),
  };

  const description = initListData.initData.selectedProduct?.description;
  const productNo = initListData.initData.selectedProduct?.productNo;

  const initDetailsData = await resolveStockOfFreezerDetailsData({
    initData: {
      ...initStockOfFreezerDetailsState,
      stockOfFreezerTitle:
        productNo && description ? `${productNo} ${description}` : productNo || description || '',
    },
    id: initListData.initData.selectedProduct?.id,
  });

  return {
    initListData,
    initDetailsData,
  };
};

async function resolveStockOfFreezerListData(
  data: IStockOfFreezerListState,
): Promise<IStockOfFreezerLoader['initListData']> {
  const storageLocationOptions =
    (await stockOfFreezerService.globHelpers.streamToPromise(
      configsData.sub.dictStorageLocations().pipe(take(1)),
      () => configsData.pub.common(['dictStorageLocations']),
    )) || [];

  data.productsList = await stockOfFreezerService.globHelpers.streamToPromise(
    stockOfFreezerService.sub
      .procurementProductsList()
      .pipe(responseHandler<ListProcurementProductsRes>({ errorReturnType: [] }), take(1)),
    () => {
      stockOfFreezerService.pub.procurementProductsList({ filter: data.filter });
    },
  );
  data.sortModel =
    storageHelper.local.getItem('stockOfFreezer.productsList.sortModel') || data.sortModel;
  if (data.productsList.length) {
    data.productsList = dataHelper
      .data(data.productsList as [])
      .sort({ sortModel: data.sortModel })
      .result() as IStockOfFreezerListState['productsList'];
  }
  const selectedProductStorage = storageHelper.session.getItem('stockOfFreezer.selectedProduct');
  const foundSelectedProduct =
    selectedProductStorage &&
    data.productsList.find((item) => item?.id === selectedProductStorage?.id);
  data.selectedProduct = foundSelectedProduct || data.productsList[0];
  data.allProductsListLength = data.productsList.length;
  return { initData: { ...data, action: 'loader' }, filterOptions: { storageLocationOptions } };
}

const resolveStockOfFreezerDetailsData = async ({
  initData,
  id,
}: resolveDetailsDataProps): Promise<IStockOfFreezerLoader['initDetailsData']> => {
  const storageLocationOptions =
    (await stockOfFreezerService.globHelpers.streamToPromise(
      configsData.sub.dictStorageLocations().pipe(take(1)),
      () => configsData.pub.common(['dictStorageLocations']),
    )) || [];
  const dateFrom = format(subDays(new Date(), 7), 'yyyy-MM-dd');
  const dateTo = format(addDays(new Date(), 7), 'yyyy-MM-dd');
  const datePeriod = {
    dateFrom,
    dateTo,
  };

  if (!id) {
    return { ...initData, isEmptyRightSide: true, storageLocationOptions, datePeriod };
  }

  const { productInfo, changesInStorage } = await stockOfFreezerService.globHelpers.streamToPromise(
    stockOfFreezerService.sub.getProcurementProductDataAndStorageChanges().pipe(
      responseHandler<ProcurementProductDataAndStorageChangesRes>({
        customErrorHandler: () => 'common.server_error',
        errorReturnType: {
          productInfo: { id: 'errorId' },
          changesInStorage: [],
        },
      }),
      take(1),
    ),
    () => {
      stockOfFreezerService.pub.getProcurementProductDataAndStorageChanges({
        dateFrom,
        dateTo,
        procurementProductId: id,
      });
    },
  );

  return {
    ...initData,
    action: 'loader',
    selectedProductInfo: productInfo,
    storageLocationOptions,
    changesInStorage,
    productId: id,
    datePeriod,
  };
};

export interface IStockOfFreezerLoader {
  initListData: {
    initData: IStockOfFreezerListState;
    filterOptions: { storageLocationOptions: DictStorageLocationsRes };
  };
  initDetailsData: IStockOfFreezerDetailsState;
}
interface resolveDetailsDataProps {
  initData: IStockOfFreezerDetailsState;
  id?: NonNullable<IStockOfFreezerListState['selectedProduct']>['id'];
}
