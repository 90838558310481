import { FC, useEffect, useState } from 'react';
import { FieldErrors, UseFormReturn, useFormContext } from 'react-hook-form';

export const ServerValidation: FC<IServerValidation> = ({
  fieldName,
  allowEmpty,
  renderItem,
  handleSubmitValue,
}) => {
  const formMethods = useFormContext();
  const [isFocused, setIsFocused] = useState(false);
  const {
    getValues,
    formState: { errors, isValid, isValidating, dirtyFields, isSubmitting, isSubmitSuccessful },
    resetField,
    setError,
  } = formMethods;
  const fieldValue = getValues(fieldName);

  useEffect(() => {
    if (!isSubmitting && isSubmitSuccessful) {
      resetField(fieldName, { defaultValue: fieldValue, keepDirty: false });
    }
  }, [isSubmitting, isSubmitSuccessful]);

  useEffect(() => {
    if (
      !isValidating &&
      isValid &&
      typeof handleSubmitValue === 'function' &&
      !isFocused &&
      !!dirtyFields[fieldName]
    ) {
      if (!allowEmpty && !fieldValue) {
        setError(fieldName, { message: 'common.not_empty' });
      }
      if (allowEmpty || (!allowEmpty && fieldValue)) {
        handleSubmitValue({ [fieldName]: fieldValue });
      }
    }
  }, [isValid, isValidating, isFocused, dirtyFields]);

  return <>{renderItem({ form: formMethods, fieldName, errors, setIsFocused })}</>;
};
export interface IServerValidation extends Pick<IRenderItem, 'fieldName'> {
  renderItem: (params: IRenderItem) => React.JSX.Element;
  allowEmpty: boolean;
  handleSubmitValue?: (params: Record<string, any>) => void;
  defaultValue?: any;
}
interface IRenderItem {
  form: UseFormReturn;
  fieldName: string;
  errors: FieldErrors;
  setIsFocused: (v: boolean) => void;
}
