import { finalize, map, Observable, switchMap, take, tap, zip } from 'rxjs';
import {
  C_New_Order_Selection_Mode,
  C_Order_Type,
  CreateWa_AutomaticOrdersMutation as CreateAutoOrdersMutation,
  CreateWa_AutomaticOrdersMutationVariables as CreateAutoOrdersVariables,
  CreateWa_DraftOrderMutation as CreateDraftOrderMutation,
  CreateWa_DraftOrderMutationVariables as CreateDraftOrderVariables,
  CreateWa_NoOrderForCustomerMutation as CreateNoOrderForCustomerMutation,
  CreateWa_NoOrderForCustomerMutationVariables as CreateNoOrderVariables,
  CreateWa_OneTimeCustomerMutation as CreateOneTimeCustomerMutation,
  CreateWa_OneTimeCustomerMutationVariables as CreateOneTimeCustomerMutationVariables,
  CreateWa_OrderMutation,
  CreateWa_OrderMutationVariables as CreateOrderVariables,
  DeleteWa_DraftOrderMutation as DeleteDraftOrderMutation,
  DeleteWa_DraftOrderMutationVariables as DeleteDraftOrderVariables,
  DeleteWa_NoOrderForCustomerMutation as DeleteNoOrderForCustomerMutation,
  DeleteWa_NoOrderForCustomerMutationVariables as DeleteNoOrderVariables,
  DictWa_OneTimeCustomersQuery,
  GetCustomerFieldsQuery,
  GetCustomerFieldsQueryVariables,
  GetCustomerInfoQuery as CustomerInfoQuery,
  GetProfileForOneTimeCustomerQuery,
  GetProfileForOneTimeCustomerQueryVariables,
  GetWa_OrderDataForNewOrderQuery,
  GetWa_OrderDataForNewOrderQueryVariables,
  ListWa_CustomersForCreatingOrdersQuery as CustomersListQuery,
  ListWa_CustomersForCreatingOrdersQueryVariables as CustomerListVariables,
  ListWa_ExistingOrdersOfCustomerQuery,
  ListWa_ExistingOrdersOfCustomerQueryVariables as ListExistingsOrdersVariables,
  SaveOneTimeCustomerDataMutation,
  SaveOneTimeCustomerDataMutationVariables,
  Scalars,
} from '../../../graphql/generatedModel.ts';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  createAutoOrders,
  createDraftOrder,
  createNoOrderForCustomer,
  createOneTimeCustomer,
  createOrder,
  deleteDraftOrder,
  deleteNoOrderForCustomer,
  dictOneTimeCustomers,
  getCustomerFields,
  getCustomerInfo,
  getCustomerList,
  getNewOrderData,
  getProfileForOneTimeCustomer,
  listExistingOrders,
  saveOneTimeCustomerData,
} from './gql/createOrder.gql.ts';

type Actions =
  | 'customerList'
  | 'orderDetailsData'
  | 'createDraftOrder'
  | 'deleteDraftOrder'
  | 'orderData'
  | 'listExistingOrders'
  | 'createOrder'
  | 'createAutoOrders'
  | 'createNoOrder'
  | 'deleteNoOrder'
  | 'getProfileForOneTimeCustomer'
  | 'getCustomerFields'
  | 'createOneTimeCustomer'
  | 'saveOneTimeCustomerData'
  | 'oneTimeCustomerList'
  | undefined;
class PubImpl extends Pub<Actions> {
  clearStream() {
    this.emit(undefined, {});
  }
  customerList(params: CustomerListVariables) {
    this.emit('customerList', params);
  }
  orderDetailsData(params: OrderDetailsDataParams) {
    this.emit('orderDetailsData', params);
  }
  orderData(params: OrderDataParams) {
    this.emit('orderData', params);
  }
  createDraftOrder(params: CreateDraftOrderVariables) {
    this.emit('createDraftOrder', params);
  }
  deleteDraftOrder(params: DeleteDraftOrderVariables) {
    this.emit('deleteDraftOrder', params);
  }
  listExistingOrders(params: ListExistingsOrdersVariables) {
    this.emit('listExistingOrders', params);
  }
  createOrder(params: CreateOrderVariables) {
    this.emit('createOrder', params);
  }
  createAutoOrders(params: CreateAutoOrdersVariables) {
    this.emit('createAutoOrders', params);
  }
  createNoOrder(params: CreateNoOrderVariables) {
    this.emit('createNoOrder', params);
  }
  deleteNoOrder(params: DeleteNoOrderVariables) {
    this.emit('deleteNoOrder', params);
  }
  getProfileForOneTimeCustomer(params: GetProfileForOneTimeCustomerQueryVariables) {
    this.emit('getProfileForOneTimeCustomer', params);
  }
  getCustomerFields(params: GetCustomerFieldsQueryVariables) {
    this.emit('getCustomerFields', params);
  }
  createOneTimeCustomer(params: CreateOneTimeCustomerMutationVariables) {
    this.emit('createOneTimeCustomer', params);
  }
  saveOneTimeCustomerData(params: SaveOneTimeCustomerDataMutationVariables) {
    this.emit('saveOneTimeCustomerData', params);
  }
  oneTimeCustomerList(): void {
    this.emit('oneTimeCustomerList');
  }
}
class SubImpl extends Sub<Actions> {
  customerList(): Observable<CustomerListRes> {
    return this.actionListener('customerList').pipe(
      switchMap(({ params }) => {
        return gqlClient(getCustomerList, params) as Observable<CustomersListQuery>;
      }),
      map((data: CustomersListQuery) => {
        const customerList = data.wawiAssist?.listWA_CustomersForCreatingOrders?.map((item) => ({
          ...item,
          id: `${item?.customerId || ''}${item?.draftOrderId || ''}${item?.offerId || ''}`,
        }));
        return (customerList || []) as CustomerListRes;
      }),
    );
  }
  orderData(): Observable<NewOrderDataExtendedRes> {
    return this.actionListener('orderData').pipe(
      switchMap(({ params }) => {
        const { customer, date: onDate, ...args } = params as OrderDataParams;
        const newOrderDataArgs: GetWa_OrderDataForNewOrderQueryVariables['selectionProps'] = {
          mode: C_New_Order_Selection_Mode.DEFAULT_ORDER_OF_CUSTOMER,
          customerId: customer.customerId,
          onDate,
          ...args,
        };
        if (customer.draftOrderId) {
          newOrderDataArgs.mode = C_New_Order_Selection_Mode.FROM_DRAFT_ORDER;
          newOrderDataArgs.draftOrderId = customer.draftOrderId;
        } else if (customer.offerId) {
          newOrderDataArgs.mode = C_New_Order_Selection_Mode.FROM_ACCEPTED_OFFER;
          newOrderDataArgs.acceptedOfferId = customer.offerId;
        }
        return gqlClient(getNewOrderData, {
          selectionProps: newOrderDataArgs,
        }) as Observable<GetWa_OrderDataForNewOrderQuery>;
      }),
      take(1),
      map((data) => {
        const orderData = (data?.wawiAssist?.getWA_OrderDataForNewOrder ||
          []) as OrderDetailsDataRes['orderData'];
        return orderData.map((item) => {
          if (item?.positions && item.positions.length) {
            item.positions = item.positions.map((el, i) => ({
              ...el,
              id: Date.now() + i,
              __reorder__: `${el?.articleNo ? '(' + el?.articleNo + ')' : ''} ${
                el?.description || ''
              }`,
            })) as [];
          }
          return item;
        }) as NewOrderDataExtendedRes;
      }),
    );
  }
  orderDetailsData(): Observable<OrderDetailsDataRes> {
    return this.actionListener('orderDetailsData').pipe(
      tap(({ params }) => {
        createOrderService.pub.orderData(params);
      }),
      switchMap(({ params }) => {
        return zip(
          this.orderData(),
          gqlClient(getCustomerInfo, {
            getCustomerDataId: params.customer?.customerId,
          }) as Observable<CustomerInfoQuery>,
        ).pipe(
          map(([orderData, customerData]) => {
            const customerInfo = (customerData?.wawiAssist?.getCustomerData ||
              {}) as CustomerInfoRes;
            return {
              customerInfo,
              orderData,
            } as OrderDetailsDataRes;
          }),
        );
      }),
    );
  }
  createDraftOrder(): Observable<CreateDraftOrderExtendedRes> {
    return this.actionListener('createDraftOrder').pipe(
      switchMap(({ params }: { params: CreateDraftOrderVariables }) => {
        return (gqlClient(createDraftOrder, params) as Observable<CreateDraftOrderMutation>).pipe(
          map((data: CreateDraftOrderMutation) => {
            return {
              draftOrderId: data?.wawiAssist?.createWA_DraftOrder as CreateDraftOrderRes,
              customerId: params.draftOrderProps.customerId,
            };
          }),
        );
      }),
    );
  }
  deleteDraftOrder(): Observable<DeleteDraftOrderRes> {
    return this.actionListener('deleteDraftOrder').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteDraftOrder, params) as Observable<DeleteDraftOrderMutation>;
      }),
      map((data) => {
        return data?.wawiAssist?.deleteWA_DraftOrder as DeleteDraftOrderRes;
      }),
    );
  }
  listExistingOrders(): Observable<ListExistingOrdersRes> {
    return this.actionListener('listExistingOrders').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          listExistingOrders,
          params,
        ) as Observable<ListWa_ExistingOrdersOfCustomerQuery>;
      }),
      map((data) => {
        return (data?.wawiAssist?.listWA_ExistingOrdersOfCustomer || []) as ListExistingOrdersRes;
      }),
    );
  }
  createOrder(): Observable<CreateOrderRes> {
    return this.actionListener('createOrder').pipe(
      switchMap(({ params }) => {
        return gqlClient(createOrder, params) as Observable<CreateWa_OrderMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.createWA_Order as CreateOrderRes;
      }),
    );
  }
  createAutoOrders(): Observable<CreateAutoOrdersRes> {
    return this.actionListener('createAutoOrders').pipe(
      switchMap(({ params }) => {
        return gqlClient(createAutoOrders, params) as Observable<CreateAutoOrdersMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.createWA_AutomaticOrders as CreateAutoOrdersRes;
      }),
      finalize(() => {
        createOrderService.pub.clearStream();
      }),
    );
  }
  createNoOrder(): Observable<CreateNoOrderForCustomerRes> {
    return this.actionListener('createNoOrder').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          createNoOrderForCustomer,
          params,
        ) as Observable<CreateNoOrderForCustomerMutation>;
      }),
      map((data: CreateNoOrderForCustomerMutation) => {
        return data.wawiAssist?.createWA_NoOrderForCustomer as CreateNoOrderForCustomerRes;
      }),
    );
  }
  deleteNoOrder(): Observable<DeleteNoOrderForCustomerRes> {
    return this.actionListener('deleteNoOrder').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          deleteNoOrderForCustomer,
          params,
        ) as Observable<DeleteNoOrderForCustomerMutation>;
      }),
      map((data: DeleteNoOrderForCustomerMutation) => {
        return data.wawiAssist?.deleteWA_NoOrderForCustomer as DeleteNoOrderForCustomerRes;
      }),
    );
  }
  getProfileForOneTimeCustomer(): Observable<ProfileForOneTimeCustomerRes> {
    return this.actionListener('getProfileForOneTimeCustomer').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getProfileForOneTimeCustomer,
          params,
        ) as Observable<GetProfileForOneTimeCustomerQuery>;
      }),
      map((data: GetProfileForOneTimeCustomerQuery) => {
        return data.wawiAssist?.getDictWA_CustomerProfile as ProfileForOneTimeCustomerRes;
      }),
    );
  }
  getCustomerFields(): Observable<CustomerFieldsRes> {
    return this.actionListener('getCustomerFields').pipe(
      switchMap(({ params }) => {
        return gqlClient(getCustomerFields, params) as Observable<GetCustomerFieldsQuery>;
      }),
      map((data: GetCustomerFieldsQuery) => {
        return data.wawiAssist?.getCustomerData as CustomerFieldsRes;
      }),
    );
  }
  createOneTimeCustomer(): Observable<CreateOneTimeCustomerRes> {
    return this.actionListener('createOneTimeCustomer').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          createOneTimeCustomer,
          params,
        ) as Observable<CreateOneTimeCustomerMutation>;
      }),
      map((data: CreateOneTimeCustomerMutation) => {
        return data.wawiAssist?.createWA_OneTimeCustomer as CreateOneTimeCustomerRes;
      }),
    );
  }
  saveOneTimeCustomerData(): Observable<SaveOneTimeCustomerRes> {
    return this.actionListener('saveOneTimeCustomerData').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          saveOneTimeCustomerData,
          params,
        ) as Observable<SaveOneTimeCustomerDataMutation>;
      }),
      map((data: SaveOneTimeCustomerDataMutation) => {
        return data.wawiAssist?.saveOneTimeCustomerData as SaveOneTimeCustomerRes;
      }),
    );
  }
  oneTimeCustomerList(): Observable<DictOneTimeCustomersQueryRes> {
    return this.actionListener('oneTimeCustomerList').pipe(
      switchMap(() => {
        return gqlClient(dictOneTimeCustomers) as Observable<DictWa_OneTimeCustomersQuery>;
      }),
      map((oneTimeCustomers) => {
        return oneTimeCustomers.wawiAssist?.dictWA_OneTimeCustomers as DictOneTimeCustomersQueryRes;
      }),
    );
  }
}

class CreateOrderService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const createOrderService = new CreateOrderService();

type OrderDetailsDataParams = {
  customer: CustomerListRes[number];
  date: Scalars['Date'];
  orderType: C_Order_Type;
};

export type OrderDataParams = OrderDetailsDataParams &
  Partial<
    Omit<GetWa_OrderDataForNewOrderQueryVariables['selectionProps'], 'onDate' | 'customerId'>
  >;

/*  add id type in a customer list  */
interface ICustomerExtendedRes
  extends NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<CustomersListQuery['wawiAssist']>['listWA_CustomersForCreatingOrders']
      >
    >[number]
  > {
  id: string;
}
export type CustomerListRes = ICustomerExtendedRes[];
/* end */

export type CustomerInfoRes = NonNullable<
  NonNullable<CustomerInfoQuery['wawiAssist']>['getCustomerData']
>;

type NewOrderDataRes = NonNullable<
  NonNullable<GetWa_OrderDataForNewOrderQuery>['wawiAssist']
>['getWA_OrderDataForNewOrder'];

/*  add id type in the order position  */
type TOrderPosition = NonNullable<
  NonNullable<NonNullable<NewOrderDataRes[number]>['positions']>[number]
>;
export interface IPosition extends TOrderPosition {
  id: number;
  __reorder__: string;
}
export type TOrderData = NonNullable<NonNullable<NewOrderDataRes>[number]>;
export interface INewOrderDataRes extends Omit<TOrderData, 'positions'> {
  positions?: IPosition[];
}
export type NewOrderDataExtendedRes = INewOrderDataRes[];
/* end */

export type OrderDetailsDataRes = {
  customerInfo: CustomerInfoRes;
  orderData: NewOrderDataExtendedRes;
};
export type CreateDraftOrderRes = NonNullable<
  NonNullable<CreateDraftOrderMutation['wawiAssist']>['createWA_DraftOrder']
>;
export type DeleteDraftOrderRes = NonNullable<
  NonNullable<DeleteDraftOrderMutation['wawiAssist']>['deleteWA_DraftOrder']
>;
export type ListExistingOrdersRes = NonNullable<
  NonNullable<ListWa_ExistingOrdersOfCustomerQuery['wawiAssist']>['listWA_ExistingOrdersOfCustomer']
>;
export type CreateOrderRes = NonNullable<
  NonNullable<CreateWa_OrderMutation['wawiAssist']>['createWA_Order']
>;
export type CreateAutoOrdersRes = NonNullable<
  NonNullable<CreateAutoOrdersMutation['wawiAssist']>['createWA_AutomaticOrders']
>;
export type CreateNoOrderForCustomerRes = NonNullable<
  NonNullable<CreateNoOrderForCustomerMutation['wawiAssist']>['createWA_NoOrderForCustomer']
>;
export type DeleteNoOrderForCustomerRes = NonNullable<
  NonNullable<DeleteNoOrderForCustomerMutation['wawiAssist']>['deleteWA_NoOrderForCustomer']
>;
export type ProfileForOneTimeCustomerRes = NonNullable<
  NonNullable<GetProfileForOneTimeCustomerQuery['wawiAssist']>['getDictWA_CustomerProfile']
>;
export type CustomerFieldsRes = NonNullable<
  NonNullable<GetCustomerFieldsQuery['wawiAssist']>['getCustomerData']
>;
export type CreateOneTimeCustomerRes = NonNullable<
  NonNullable<CreateOneTimeCustomerMutation['wawiAssist']>['createWA_OneTimeCustomer']
>;
export type SaveOneTimeCustomerRes = NonNullable<
  NonNullable<SaveOneTimeCustomerDataMutation['wawiAssist']>['saveOneTimeCustomerData']
>;
export type DictOneTimeCustomersQueryRes = NonNullable<
  NonNullable<DictWa_OneTimeCustomersQuery['wawiAssist']>['dictWA_OneTimeCustomers']
>;
export type CreateDraftOrderExtendedRes = {
  draftOrderId: CreateDraftOrderRes;
  customerId: CreateDraftOrderVariables['draftOrderProps']['customerId'];
};
