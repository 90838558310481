import {
  TPopupComponent,
  Popup,
} from '../../../../../../shared/components/popup/popup.abstract.tsx';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { INewOfferDataExtendedRes } from '../../../../services/offer.service.ts';
import { IOfferDetailsState } from '../../../../states/offerDetails/offerDetails.state.ts';
import { TPopupDefaultActions } from '../../../../../../shared/components/popup/services/popup.service.ts';
import { Content, IContent } from './content.popup.tsx';

export class OfferSelectionPopup extends Popup<IOfferSelectionPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();
      const [fullTableData, setFullTableData] = useState<IContent['fullTableData']>([]);
      const [tableData, setTableData] = useState<IContent['tableData']>([]);
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 600 },
        }));
      }, []);
      useEffect(() => {
        stream.actionListener('open').subscribe((data) => {
          setFullTableData(data.offers ?? []);
          const updatedData = data?.offers?.map((item) => item?.offerSourceInfo) || [];
          setTableData(updatedData);
        });
      }, []);

      return (
        <SWrapper
          fullWidth
          stream={stream}
          popupTitle={t('offer.select_offer')}
          closureOverlay={false}
          closeBtn={false}
        >
          <Content {...{ tableData, fullTableData, stream }} />
        </SWrapper>
      );
    };
  }
}

export interface IOfferSelectionPopup {
  action: 'confirm' | TPopupDefaultActions;
  offers?: NonNullable<INewOfferDataExtendedRes>;
  confirmedOffer?: IOfferDetailsState['offerData'];
}
