import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper';
import { companyDe, companyEn, companyFr, companyIt } from './company/company.i18n';

type Keys =
  | 'options'
  | 'company'
  | 'configuration'
  | 'print_forms'
  | 'general_tab'
  | 'customer_tab'
  | 'article_tab'
  | 'order_tab'
  | 'internet_orders_tab'
  | 'production_tab'
  | 'invoice_tab'
  | 'vat_tab'
  | 'bank_accounts_tab'
  | 'post_tab'
  | 'financial_accounting_tab'
  | 'special_days_tab'
  | 'writing_tab'
  | 'client'
  | 'vat_normal'
  | 'vat_reduced'
  | 'no_vat'
  | 'activate_commit'
  | 'datev'
  | 'abacus'
  | 'actual_invoice_no'
  | 'rounding_total_prices'
  | 'use_another_countries'
  | 'euro_rate'
  | 'usd_rate'
  | 'enable_datev_export'
  | 'enable_xml_export'
  | 'configuration_saved'
  | 'configuration_not_saved'
  | 'assign_customer_no_automatically'
  | 'part_of_customer_no'
  | 'ongoing_value'
  | 'show_liters_in_production_print_lists'
  | 'can_close_orders_from_cashAssist'
  | 'take_into_consideration_own_produced_articles_from_procurement_in_backing_list_reports'
  | 'the_same_ean_code_may_be_assigned_to_several_articles'
  | 'show_shipping_points_in_articles'
  | 'shipping_function_activated'
  | 'vat_normal_rate'
  | 'vat_reduced_rate'
  | 'vat_special_1'
  | 'vat_special_2'
  | 'vat_zero'
  | 'product_groups_settings'
  | 'price_categories_settings'
  | 'delivery_note_groups_settings'
  | 'actual_order_number'
  | 'decimal_place_count'
  | 'order_position_sorting_kind'
  | 'kilo_amount_representation'
  | 'is_use_price_category_ahead'
  | 'is_shipping_docs_function_activated'
  | 'is_shipping_docs_for_cardboard'
  | 'is_got_next_delivery_day'
  | 'delivery_days'
  | 'related_directories'
  | 'article_list_settings'
  | 'customer_lists_settings'
  | 'post_sectors'
  | 'weight_up_to'
  | 'name'
  | 'profile_name'
  | 'cash_discount_in'
  | 'price_category'
  | 'summary_report'
  | 'payment_slip_report'
  | 'customer_groups_settings'
  | 'company_name_in_reports'
  | 'shipping_points_settings'
  | 'text_blocks_settings'
  | 'standard_order'
  | 'standard_offer'
  | 'standard_invoice'
  | 'text_block'
  | 'standart_besr'
  | 'standart_bank'
  | 'besr_format'
  | 'operation_cost_center'
  | 'tourplans_settings'
  | 'default_settings'
  | 'tourplan'
  | 'production_groups_settings'
  | 'marketing_groups_settings'
  | 'assortment_groups_settings'
  | 'quantity_discounts_settings'
  | 'quantity_discount'
  | 'banks_settings'
  | 'bank_settings'
  | 'internal_bank_description'
  | 'section_customer'
  | 'zip_code'
  | 'subscriber_num'
  | 'qr_iban'
  | 'customer_identification'
  | 'financial_accounting'
  | 'first_name'
  | 'second_name'
  | 'street'
  | 'recipe_book'
  | 'invalid_member_num'
  | 'invalid_qr_iban'
  | 'customer_lists_one'
  | 'customer_lists_other'
  | 'customer_groups_one'
  | 'customer_groups_other'
  | 'delivery_note_groups_one'
  | 'delivery_note_groups_other'
  | 'tourplans_one'
  | 'tourplans_other'
  | 'article_list_one'
  | 'article_list_other'
  | 'product_groups_one'
  | 'product_groups_other'
  | 'production_groups_one'
  | 'production_groups_other'
  | 'assortment_groups_one'
  | 'assortment_groups_other'
  | 'marketing_groups_one'
  | 'marketing_groups_other'
  | 'price_categories_one'
  | 'price_categories_other'
  | 'shipping_points_one'
  | 'shipping_points_other'
  | 'quantity_discounts_one'
  | 'quantity_discounts_other'
  | 'post_sectors_one'
  | 'post_sectors_other'
  | 'post_sectors_settings'
  | 'text_blocks_one'
  | 'text_blocks_other'
  | 'section_bank'
  | 'cannot_delete_this_bank'
  | 'general_vat_system'
  | 'from_invoice_amount'
  | 'reminder_text'
  | 'reminder_texts_settings'
  | 'report_header'
  | 'fixed_shipping_costs'
  | 'shipping_costs_by_weight'
  | 'shipping_cost'
  | 'banks'
  | 'storage_locations_settings'
  | 'storage_locations_one'
  | 'storage_locations_other'
  | 'order_types_accounted_in_production_reports'
  | 'order_types'
  | 'working_with_versions';

const t = LocalizationHelper<Keys>('settings');

export const settingsDe = t({
  options: 'Optionen',
  company: companyDe,
  configuration: 'Konfiguration',
  print_forms: 'Formulare',
  general_tab: 'Allgemein',
  customer_tab: 'Kunde',
  article_tab: 'Artikel',
  order_tab: 'Bestellung',
  internet_orders_tab: 'Internet Bestellung',
  production_tab: 'Produktion',
  invoice_tab: 'Rechnung',
  vat_tab: 'Mwst',
  bank_accounts_tab: 'Bankkonto',
  post_tab: 'Post',
  financial_accounting_tab: 'Fibu',
  special_days_tab: 'Besondere tage',
  writing_tab: 'Schrift',
  client: 'Mandant',
  vat_normal: 'MwSt normaler',
  vat_reduced: 'MwSt reduziert',
  no_vat: 'Keine MwSt',
  activate_commit: 'Festschreibung aktivieren',
  datev: 'Datev',
  profile_name: 'Profilname',
  cash_discount_in: 'Skonto in %',
  price_category: 'Preiskategorie',
  summary_report: 'Zusammenzug Formular',
  payment_slip_report: 'Einzahlungsschein Formular',
  default_settings: 'Standardeinstellungen',
  abacus: 'Abacus',
  production_groups_one: 'Produktionsgruppe',
  production_groups_other: 'Produktionsgruppen',
  production_groups_settings: 'Produktionsgruppen Einstellungen',
  shipping_points_one: 'Versandstellen',
  shipping_points_other: 'Versandstelle',
  shipping_points_settings: 'Versandstellen Einstellungen',
  actual_invoice_no: 'Aktuell Rechnung-Nr.',
  rounding_total_prices: 'Total Preisrundung',
  use_another_countries: 'Eine andere Währung verwenden',
  euro_rate: 'Euro-Kurs',
  usd_rate: 'USD-Kurs',
  enable_datev_export: 'Datev-Export aktivieren',
  enable_xml_export: 'XML-Export aktivieren',
  configuration_saved: 'Konfiguration wurde erfolgreich gespeichert',
  configuration_not_saved: 'Fehler: Konfiguration nicht gespeichert!',
  assign_customer_no_automatically: 'Kunden-Nr. automatisch vergeben',
  part_of_customer_no: 'Teil der Kunden-Nr.',
  ongoing_value: 'Fortlaufender Wert',
  show_liters_in_production_print_lists: 'Produktionslisten mit Liter anzeigen',
  can_close_orders_from_cashAssist: 'Bestellnahme vom CashAssist schließen',
  take_into_consideration_own_produced_articles_from_procurement_in_backing_list_reports:
    'Eigenproduzierte Artikel aus der Beschaffung in Backzettel berücksichtigen',
  the_same_ean_code_may_be_assigned_to_several_articles:
    'Derselbe EAN code darf mehreren Artikeln vergeben werden',
  show_shipping_points_in_articles: 'Versandstellen in Artikeln anzeigen',
  shipping_function_activated: 'Aktivieren Post Function',
  vat_normal_rate: 'MwSt Normalsatz',
  vat_reduced_rate: 'MwSt reduzierter Satz',
  vat_special_1: 'MwSt spezial 1',
  vat_special_2: 'MwSt spezial 2',
  vat_zero: 'MwSt 0%',
  product_groups_settings: 'Warengruppen Einstellungen',
  product_groups_one: 'Warengruppe',
  product_groups_other: 'Warengruppen',
  price_categories_settings: 'Preiskategorien Einstellungen',
  actual_order_number: 'Aktuell Lieferschein-Nr.',
  decimal_place_count: 'Anzahl der Dezimalstellen',
  order_position_sorting_kind: 'Bestellung positionen sortieren nach',
  kilo_amount_representation: 'Darstellung der Kilogrammmenge',
  is_use_price_category_ahead: 'Wenn kein Preis, vorgehende Preiskategorie',
  is_shipping_docs_function_activated: 'Erstellung von Versandunterlagen aktivieren',
  is_shipping_docs_for_cardboard: 'Versandunterlagen für Kartonage erstellen',
  is_got_next_delivery_day:
    'Bei der Erfassung der Bestellung, automatisch auf den nächsten Liefertag positionieren',
  delivery_days: 'Liefertage',
  related_directories: 'Verwandte Verzeichnisse',
  delivery_note_groups_settings: 'Lieferscheingruppen Einstellungen',
  delivery_note_groups_one: 'Lieferscheingruppe',
  delivery_note_groups_other: 'Lieferscheingruppen',
  article_list_settings: 'Artikellisten Einstellungen',
  article_list_one: 'Artikelliste',
  article_list_other: 'Artikellisten',
  customer_lists_settings: 'Kundenlisten Einstellungen',
  customer_lists_one: 'Kundenliste',
  customer_lists_other: 'Kundenlisten',
  post_sectors_settings: 'Transportsektoren Einstellungen',
  post_sectors: 'Transportsektoren',
  post_sectors_one: 'Transportsektor',
  post_sectors_other: 'Transportsektoren',
  weight_up_to: 'Gewicht bis (g)',
  name: 'Name',
  customer_groups_settings: 'Kundengruppen Einstellungen',
  customer_groups_one: 'Kundengruppe',
  customer_groups_other: 'Kundengruppen',
  text_blocks_settings: 'Textbausteine Einstellungen',
  text_blocks_one: 'Textbaustein',
  text_blocks_other: 'Textbausteine',
  standard_order: 'Standard (Bestellen)',
  standard_offer: 'Standard (Angebot)',
  standard_invoice: 'Standard (Rechnung)',
  text_block: 'Textbaustein',
  company_name_in_reports: 'Firmenname auf Drucklisten',
  standart_besr: 'Standart BESR',
  standart_bank: 'Standart Bank',
  besr_format: ' BESR Format',
  operation_cost_center: 'Betrieb Kostenstelle',
  marketing_groups_settings: 'Marketing-Gruppe Einstellungen',
  tourplans_settings: 'Tourenplanen Einstellungen',
  tourplans_one: 'Tourenplane',
  tourplans_other: 'Tourenplanen',
  tourplan: 'Touren',
  assortment_groups_one: 'Sortimentsgruppe',
  assortment_groups_other: 'Sortimentsgruppen',
  assortment_groups_settings: 'Sortimentsgruppen Einstellungen',
  quantity_discounts_settings: 'Mengenrabatt Einstellungen',
  quantity_discounts_one: 'Mengenrabatt',
  quantity_discounts_other: 'Mengenrabatt',
  quantity_discount: 'Mengenrabatt',
  marketing_groups_one: 'Marketing-Gruppe',
  marketing_groups_other: 'Marketing-Gruppe',
  price_categories_one: 'Preiskategorie',
  price_categories_other: 'Preiskategorien',
  banks_settings: 'Banken Einstellungen',
  bank_settings: 'Bank Einstellungen',
  internal_bank_description: 'Interne Bankbezeichnung',
  section_bank: 'Bank',
  section_customer: 'Kunde',
  zip_code: 'PLZ/Ort',
  subscriber_num: 'ESR/BESR Teilnehmer-Nr.',
  qr_iban: 'QR-IBAN',
  customer_identification: 'Kundenidentifikation (max. 6 Stellen)',
  financial_accounting: 'FIBU-Konto',
  first_name: 'Name 1',
  second_name: 'Name 2',
  street: 'Strasse',
  recipe_book: 'Verbuchung der Zahlungseingänge',
  invalid_member_num: 'Es sind nur Zahlen erlaubt',
  invalid_qr_iban: 'Die eingegebene IBAN ist keine QR-IBAN.',
  cannot_delete_this_bank:
    'Sie können diese Bank leider nicht löschen, da sie als Standardbank definiert ist.',
  general_vat_system: 'Algemeines MwSt-System',
  from_invoice_amount: 'Ab Rechnungsbetrag',
  reminder_text: 'Mahnungstext',
  reminder_texts_settings: 'Mahnungstexten Einstellungen',
  fixed_shipping_costs: 'Feste Versandkosten',
  shipping_costs_by_weight: 'Versandkosten nach Gewicht',
  shipping_cost: 'Versandkosten',
  report_header: 'Berichtskopf',
  banks: 'Banken',
  storage_locations_settings: 'Lagerorte Einstellungen',
  storage_locations_one: 'Lagerort',
  storage_locations_other: 'Lagerorte',
  order_types_accounted_in_production_reports:
    'Bestelltypen mit Einfluss auf die Produktionslisten',
  order_types: 'Bestelltypen',
  working_with_versions: 'Mit Versionen arbeiten',
});

export const settingsFr = t({
  options: 'Options',
  company: companyFr,
  configuration: 'Configuration',
  print_forms: 'Imprimer les formulaires',
  general_tab: 'Général',
  customer_tab: 'Client',
  article_tab: 'Article',
  order_tab: 'Commande',
  internet_orders_tab: 'Commandes internet',
  production_tab: 'Production',
  invoice_tab: 'Facture',
  vat_tab: 'TVA',
  default_settings: 'Paramètres par défaut',
  bank_accounts_tab: 'Compte bancaire',
  post_tab: 'Poste',
  financial_accounting_tab: 'Comptabilité financière',
  special_days_tab: 'Jours spéciaux',
  writing_tab: 'Écriture',
  client: 'Client',
  vat_normal: 'TVA normale',
  vat_reduced: 'TVA réduite',
  no_vat: 'Sans TVA',
  activate_commit: 'Activer la validation',
  datev: 'Datev',
  abacus: 'Abacus',
  profile_name: 'Nom du profil',
  cash_discount_in: 'Remise en espèces en %',
  price_category: 'Catégorie de prix',
  summary_report: 'Rapport récapitulatif',
  payment_slip_report: 'Rapport de bulletin de versement',
  actual_invoice_no: 'N° de facture actuel',
  rounding_total_prices: 'Arrondi des prix totaux',
  use_another_countries: 'Utiliser d’autres devises',
  euro_rate: 'Taux de l’euro',
  usd_rate: 'Taux de l’USD',
  production_groups_one: 'Groupe de production',
  production_groups_other: 'Groupes de production',
  production_groups_settings: 'Paramètres des groupes de production',
  shipping_points_one: 'Point de livraison',
  shipping_points_other: 'Points de livraison',
  shipping_points_settings: 'Paramètres des points de livraison',
  enable_datev_export: 'Activer l’export Datev',
  enable_xml_export: 'Activer l’export XML',
  configuration_saved: 'Configuration enregistrée avec succès',
  configuration_not_saved: 'Erreur : Configuration non enregistrée!',
  assign_customer_no_automatically: 'Attribuer le n° client automatiquement',
  part_of_customer_no: 'Partie du n° client',
  ongoing_value: 'Valeur en cours',
  show_liters_in_production_print_lists: 'Afficher les litres dans les listes de production',
  can_close_orders_from_cashAssist: 'Peut clôturer les commandes depuis CashAssist',
  take_into_consideration_own_produced_articles_from_procurement_in_backing_list_reports:
    'Prendre en compte les articles produits en interne dans les rapports de liste de production',
  the_same_ean_code_may_be_assigned_to_several_articles:
    'Le même code EAN peut être attribué à plusieurs articles',
  show_shipping_points_in_articles: 'Afficher les points de livraison dans les articles',
  shipping_function_activated: 'Activer la fonction d’expédition',
  vat_normal_rate: 'Taux normal de TVA',
  vat_reduced_rate: 'Taux réduit de TVA',
  vat_special_1: 'TVA spéciale 1',
  vat_special_2: 'TVA spéciale 2',
  vat_zero: 'TVA 0%',
  product_groups_settings: 'Paramètres des groupes de produits',
  product_groups_one: 'Groupe de produit',
  product_groups_other: 'Groupes de produits',
  price_categories_settings: 'Paramètres des catégories de prix',
  actual_order_number: 'N° de commande actuel',
  decimal_place_count: 'Nombre de décimales',
  order_position_sorting_kind: 'Trier les positions de commande par',
  kilo_amount_representation: 'Représentation de la quantité en kilogrammes',
  is_use_price_category_ahead: 'Si pas de prix, utiliser la catégorie de prix par défaut',
  is_shipping_docs_function_activated: 'Activer la création de documents d’expédition',
  is_shipping_docs_for_cardboard: 'Créer des documents d’expédition pour le carton',
  is_got_next_delivery_day:
    'Passer automatiquement au prochain jour de livraison lors de la saisie de la commande',
  delivery_days: 'Jours de livraison',
  related_directories: 'Répertoires associés',
  delivery_note_groups_settings: 'Paramètres des groupes de bons de livraison',
  delivery_note_groups_one: 'Groupe de bons de livraison',
  delivery_note_groups_other: 'Groupes de bons de livraison',
  article_list_settings: 'Paramètres des listes d’articles',
  article_list_one: 'Liste d’articles',
  article_list_other: 'Listes d’articles',
  customer_lists_settings: 'Paramètres des listes de clients',
  customer_lists_one: 'Liste de clients',
  customer_lists_other: 'Listes de clients',
  post_sectors_settings: 'Paramètres des secteurs postaux',
  post_sectors: 'Secteurs postaux',
  post_sectors_one: 'Secteur postal',
  post_sectors_other: 'Secteurs postaux',
  weight_up_to: 'Poids jusqu’à (g)',
  name: 'Nom',
  customer_groups_settings: 'Paramètres des groupes de clients',
  customer_groups_one: 'Groupe de clients',
  customer_groups_other: 'Groupes de clients',
  text_blocks_settings: 'Paramètres des blocs de texte',
  text_blocks_one: 'Bloc de texte',
  text_blocks_other: 'Blocs de texte',
  standard_order: 'Standard (Commande)',
  standard_offer: 'Standard (Offre)',
  standard_invoice: 'Standard (Facture)',
  text_block: 'Bloc de texte',
  company_name_in_reports: 'Nom de l’entreprise sur les listes d’impression',
  standart_besr: 'BESR standard',
  standart_bank: 'Banque standard',
  besr_format: 'Format BESR',
  operation_cost_center: 'Centre de coûts d’opération',
  tourplans_settings: 'Paramètres des plans de tournée',
  tourplans_one: 'Plan de tournée',
  tourplans_other: 'Plans de tournée',
  tourplan: 'Plan de tournée',
  marketing_groups_settings: 'Paramètres des groupes marketing',
  assortment_groups_one: 'Groupe d’assortiment',
  assortment_groups_other: 'Groupes d’assortiment',
  assortment_groups_settings: 'Paramètres des groupes d’assortiment',
  quantity_discounts_settings: 'Paramètres des remises sur quantité',
  quantity_discounts_one: 'Remise sur quantité',
  quantity_discounts_other: 'Remises sur quantité',
  quantity_discount: 'Remise sur quantité',
  marketing_groups_one: 'Groupe marketing',
  marketing_groups_other: 'Groupes marketing',
  price_categories_one: 'Catégorie de prix',
  price_categories_other: 'Catégories de prix',
  banks_settings: 'Paramètres des banques',
  bank_settings: 'Paramètres bancaires',
  internal_bank_description: 'Description interne de la banque',
  section_bank: 'Banque',
  section_customer: 'Client',
  zip_code: 'Code postal / Localité',
  subscriber_num: 'N° d’abonné ESR/BESR',
  qr_iban: 'QR-IBAN',
  customer_identification: 'Identification du client (max. 6 lettres)',
  financial_accounting: 'Compte bancaire de comptabilité financière',
  first_name: 'Prénom',
  second_name: 'Nom 2',
  street: 'Rue',
  recipe_book: 'Livre des recettes',
  invalid_member_num: 'Seuls les chiffres sont autorisés',
  invalid_qr_iban: 'L’IBAN que vous avez saisi n’est pas un QR IBAN.',
  cannot_delete_this_bank:
    'Malheureusement, vous ne pouvez pas supprimer cette banque car elle est définie comme banque par défaut.',
  general_vat_system: 'Système général de TVA',
  from_invoice_amount: 'À partir du montant (facture)',
  reminder_text: 'Texte de rappel',
  reminder_texts_settings: 'Paramètres des textes de rappel',
  fixed_shipping_costs: 'Frais de port fixes',
  shipping_costs_by_weight: 'Frais de port selon le poids',
  shipping_cost: 'Frais de port',
  report_header: 'En-tête de rapport',
  banks: 'Banques',
  storage_locations_settings: 'Paramètres des emplacements de stockage',
  storage_locations_one: 'Emplacement de stockage',
  storage_locations_other: 'Emplacements de stockage',
  order_types_accounted_in_production_reports:
    'Types de commandes inclus dans les rapports de production',
  order_types: 'Types de commandes',
  working_with_versions: 'Travailler avec les versions',
});

export const settingsIt = t({
  options: 'Opzioni',
  company: companyIt,
  configuration: 'Configurazione',
  print_forms: 'Moduli di stampa',
  general_tab: 'Generale',
  customer_tab: 'Cliente',
  article_tab: 'Articolo',
  order_tab: 'Ordine',
  internet_orders_tab: 'Ordini online',
  production_tab: 'Produzione',
  invoice_tab: 'Fattura',
  vat_tab: 'IVA',
  default_settings: 'Impostazioni predefinite',
  bank_accounts_tab: 'Conto bancario',
  post_tab: 'Posta',
  financial_accounting_tab: 'Contabilità finanziaria',
  special_days_tab: 'Giorni speciali',
  writing_tab: 'Scrittura',
  client: 'Cliente',
  vat_normal: 'IVA normale',
  vat_reduced: 'IVA ridotta',
  no_vat: 'Senza IVA',
  activate_commit: 'Attivare il commit',
  datev: 'Datev',
  abacus: 'Abacus',
  profile_name: 'Nome del profilo',
  cash_discount_in: 'Sconto cassa in %',
  price_category: 'Categoria di prezzo',
  summary_report: 'Rapporto riepilogativo',
  payment_slip_report: 'Rapporto bollettino di pagamento',
  actual_invoice_no: 'N. fattura attuale',
  rounding_total_prices: 'Arrotondamento prezzi totali',
  use_another_countries: 'Usa altre valute',
  euro_rate: 'Tasso euro',
  usd_rate: 'Tasso USD',
  production_groups_one: 'Gruppo di produzione',
  production_groups_other: 'Gruppi di produzione',
  production_groups_settings: 'Impostazioni gruppi di produzione',
  shipping_points_one: 'Punto di spedizione',
  shipping_points_other: 'Punti di spedizione',
  shipping_points_settings: 'Impostazioni punti di spedizione',
  enable_datev_export: 'Abilitare esportazione Datev',
  enable_xml_export: 'Abilitare esportazione XML',
  configuration_saved: 'Configurazione salvata con successo',
  configuration_not_saved: 'Errore: configurazione non salvata!',
  assign_customer_no_automatically: 'Assegna N. cliente automaticamente',
  part_of_customer_no: 'Parte del N. cliente',
  ongoing_value: 'Valore corrente',
  show_liters_in_production_print_lists: 'Mostra litri nelle liste di produzione',
  can_close_orders_from_cashAssist: 'Può chiudere ordini da CashAssist',
  take_into_consideration_own_produced_articles_from_procurement_in_backing_list_reports:
    'Considera articoli prodotti in proprio dalla fornitura nei rapporti di produzione',
  the_same_ean_code_may_be_assigned_to_several_articles:
    'Lo stesso codice EAN può essere assegnato a più articoli',
  show_shipping_points_in_articles: 'Mostra punti di spedizione negli articoli',
  shipping_function_activated: 'Funzione di spedizione attivata',
  vat_normal_rate: 'Aliquota IVA normale',
  vat_reduced_rate: 'Aliquota IVA ridotta',
  vat_special_1: 'IVA speciale 1',
  vat_special_2: 'IVA speciale 2',
  vat_zero: 'IVA 0%',
  product_groups_settings: 'Impostazioni gruppi di prodotti',
  product_groups_one: 'Gruppo di prodotti',
  product_groups_other: 'Gruppi di prodotti',
  price_categories_settings: 'Impostazioni categorie di prezzo',
  actual_order_number: 'N. ordine attuale',
  decimal_place_count: 'Numero di decimali',
  order_position_sorting_kind: 'Ordina le posizioni degli ordini per',
  kilo_amount_representation: 'Rappresentazione in chilogrammi',
  is_use_price_category_ahead: "Se non c'è prezzo, usa categoria di prezzo precedente",
  is_shipping_docs_function_activated: 'Attivare creazione documenti di spedizione',
  is_shipping_docs_for_cardboard: 'Crea documenti di spedizione per cartone',
  is_got_next_delivery_day:
    "All'acquisizione dell'ordine, passa automaticamente al giorno di consegna successivo",
  delivery_days: 'Giorni di consegna',
  related_directories: 'Directory correlate',
  delivery_note_groups_settings: 'Impostazioni gruppi note di consegna',
  delivery_note_groups_one: 'Gruppo note di consegna',
  delivery_note_groups_other: 'Gruppi note di consegna',
  article_list_settings: 'Impostazioni liste articoli',
  article_list_one: 'Lista articoli',
  article_list_other: 'Liste articoli',
  customer_lists_settings: 'Impostazioni liste clienti',
  customer_lists_one: 'Lista clienti',
  customer_lists_other: 'Liste clienti',
  post_sectors_settings: 'Impostazioni settori postali',
  post_sectors: 'Settori postali',
  post_sectors_one: 'Settore postale',
  post_sectors_other: 'Settori postali',
  weight_up_to: 'Peso fino a (g)',
  name: 'Nome',
  customer_groups_settings: 'Impostazioni gruppi clienti',
  customer_groups_one: 'Gruppo clienti',
  customer_groups_other: 'Gruppi clienti',
  text_blocks_settings: 'Impostazioni blocchi di testo',
  text_blocks_one: 'Blocco di testo',
  text_blocks_other: 'Blocchi di testo',
  standard_order: 'Standard (Ordine)',
  standard_offer: 'Standard (Offerta)',
  standard_invoice: 'Standard (Fattura)',
  text_block: 'Blocco di testo',
  company_name_in_reports: 'Nome azienda sulle liste di stampa',
  standart_besr: 'Standard BESR',
  standart_bank: 'Banca standard',
  besr_format: 'Formato BESR',
  operation_cost_center: 'Centro di costo operativo',
  tourplans_settings: 'Impostazioni piani di tour',
  tourplans_one: 'Piano di tour',
  tourplans_other: 'Piani di tour',
  tourplan: 'Piano di tour',
  marketing_groups_settings: 'Impostazioni gruppi di marketing',
  assortment_groups_one: 'Gruppo assortimenti',
  assortment_groups_other: 'Gruppi assortimenti',
  assortment_groups_settings: 'Impostazioni gruppi assortimenti',
  quantity_discounts_settings: 'Impostazioni sconti quantità',
  quantity_discounts_one: 'Sconto quantità',
  quantity_discounts_other: 'Sconti quantità',
  quantity_discount: 'Sconto quantità',
  marketing_groups_one: 'Gruppo marketing',
  marketing_groups_other: 'Gruppi marketing',
  price_categories_one: 'Categoria di prezzo',
  price_categories_other: 'Categorie di prezzo',
  banks_settings: 'Impostazioni banche',
  bank_settings: 'Impostazioni banca',
  internal_bank_description: 'Descrizione banca interna',
  section_bank: 'Banca',
  section_customer: 'Cliente',
  zip_code: 'CAP/Località',
  subscriber_num: 'N. abbonato ESR/BESR',
  qr_iban: 'QR-IBAN',
  customer_identification: 'Identificazione cliente (max. 6 lettere)',
  financial_accounting: 'Conto bancario contabilità finanziaria',
  first_name: 'Nome 1',
  second_name: 'Nome 2',
  street: 'Via',
  recipe_book: 'Libro delle ricevute',
  invalid_member_num: 'Sono ammessi solo numeri',
  invalid_qr_iban: "L'IBAN inserito non è un QR IBAN.",
  cannot_delete_this_bank:
    'Non è possibile eliminare questa banca poiché è definita come banca predefinita.',
  general_vat_system: 'Sistema IVA generale',
  from_invoice_amount: 'Da importo (fattura)',
  reminder_text: 'Testo promemoria',
  reminder_texts_settings: 'Impostazioni testi promemoria',
  fixed_shipping_costs: 'Costi di spedizione fissi',
  shipping_costs_by_weight: 'Costi di spedizione in base al peso',
  shipping_cost: 'Costo di spedizione',
  report_header: 'Intestazione rapporto',
  banks: 'Banche',
  storage_locations_settings: 'Impostazioni ubicazioni di stoccaggio',
  storage_locations_one: 'Ubicazione di stoccaggio',
  storage_locations_other: 'Ubicazioni di stoccaggio',
  order_types_accounted_in_production_reports:
    'Tipi di ordine conteggiati nei rapporti di produzione',
  order_types: 'Tipi di ordine',
  working_with_versions: 'Lavorare con le versioni',
});

export const settingsEn = t({
  options: 'Options',
  company: companyEn,
  configuration: 'Configuration',
  print_forms: 'Print forms',
  general_tab: 'General',
  customer_tab: 'Customer',
  article_tab: 'Article',
  order_tab: 'Order',
  internet_orders_tab: 'Internet ordering',
  production_tab: 'Production',
  invoice_tab: 'Invoice',
  vat_tab: 'VAT',
  default_settings: 'Default settings',
  bank_accounts_tab: 'Bank account',
  post_tab: 'Post',
  financial_accounting_tab: 'Financial accounting',
  special_days_tab: 'Special days',
  writing_tab: 'Writing',
  client: 'Client',
  vat_normal: 'VAT normal',
  vat_reduced: 'VAT reduced',
  no_vat: 'No VAT',
  activate_commit: 'Activate commit',
  datev: 'Datev',
  abacus: 'Abacus',
  profile_name: 'Profile name',
  cash_discount_in: 'Cash discount in %',
  price_category: 'Price category',
  summary_report: 'Summary report',
  payment_slip_report: 'Payment slip report',
  actual_invoice_no: 'Current invoice No.',
  rounding_total_prices: "Total's price rounding",
  use_another_countries: 'Use another currencies',
  euro_rate: 'Euro rate',
  usd_rate: 'USD rate',
  production_groups_one: 'Production group',
  production_groups_other: 'Production groups',
  production_groups_settings: 'Production groups settings',
  shipping_points_one: 'Shipping point',
  shipping_points_other: 'Shipping points',
  shipping_points_settings: 'Shipping points settings',
  enable_datev_export: 'Enable Datev export',
  enable_xml_export: 'Enable XML export',
  configuration_saved: 'Configuration was successfully saved',
  configuration_not_saved: 'Error: Configuration not saved!',
  assign_customer_no_automatically: 'Assign customer No. automatically',
  part_of_customer_no: 'Part of the customer No.',
  ongoing_value: 'Ongoing value',
  show_liters_in_production_print_lists: 'Show liters in production print lists',
  can_close_orders_from_cashAssist: 'Can close orders from CashAssist',
  take_into_consideration_own_produced_articles_from_procurement_in_backing_list_reports:
    'Take into consideration own-produced articles from procurement in backing list reports',
  the_same_ean_code_may_be_assigned_to_several_articles:
    'The same EAN code may be assigned to several articles',
  show_shipping_points_in_articles: 'Show shipping points in articles',
  shipping_function_activated: 'Activate shipping function',
  vat_normal_rate: 'VAT normal rate',
  vat_reduced_rate: 'VAT reduced rate',
  vat_special_1: 'VAT special 1',
  vat_special_2: 'VAT special 2',
  vat_zero: 'VAT 0%',
  product_groups_settings: 'Product groups settings',
  product_groups_one: 'Product group',
  product_groups_other: 'Product groups',
  price_categories_settings: 'Price categories settings',
  actual_order_number: 'Current order No.',
  decimal_place_count: 'Number of decimal places',
  order_position_sorting_kind: 'Sort order positions by',
  kilo_amount_representation: 'Kilogram amount representation',
  is_use_price_category_ahead: 'If there is no price, use price category ahead',
  is_shipping_docs_function_activated: 'Activate shipping documents creation',
  is_shipping_docs_for_cardboard: 'Create shipping documents for cardboard',
  is_got_next_delivery_day: 'When capturing the order, position automatically to next delivery day',
  delivery_days: 'Delivery days',
  related_directories: 'Related directories',
  delivery_note_groups_settings: 'Delivery note groups settings',
  delivery_note_groups_one: 'Delivery note group',
  delivery_note_groups_other: 'Delivery note groups',
  article_list_settings: 'Article lists settings',
  article_list_one: 'Article list',
  article_list_other: 'Article lists',
  customer_lists_settings: 'Customer lists settings',
  customer_lists_one: 'Customer list',
  customer_lists_other: 'Customer lists',
  post_sectors_settings: 'Post sectors settings',
  post_sectors: 'Post sectors',
  post_sectors_one: 'Post sector',
  post_sectors_other: 'Post sectors',
  weight_up_to: 'Weight up to (g)',
  name: 'Name',
  customer_groups_settings: 'Customer groups settings',
  customer_groups_one: 'Customer group',
  customer_groups_other: 'Customer groups',
  text_blocks_settings: 'Text blocks settings',
  text_blocks_one: 'Text block',
  text_blocks_other: 'Text blocks',
  standard_order: 'Standard (Order)',
  standard_offer: 'Standard (Offer)',
  standard_invoice: 'Standard (Invoice)',
  text_block: 'Text block',
  company_name_in_reports: 'Company name on print lists',
  standart_besr: 'Standart BESR',
  standart_bank: 'Standart bank',
  besr_format: ' BESR format',
  operation_cost_center: 'Operation cost center',
  tourplans_settings: 'Tourplans settings',
  tourplans_one: 'Tourplan',
  tourplans_other: 'Tourplans',
  tourplan: 'Tourplan',
  marketing_groups_settings: 'Marketing groups settings',
  assortment_groups_one: 'Assortment group',
  assortment_groups_other: 'Assortment groups',
  assortment_groups_settings: 'Assortment groups settings',
  quantity_discounts_settings: 'Quantity discounts settings',
  quantity_discounts_one: 'Quantity discount',
  quantity_discounts_other: 'Quantity discounts',
  quantity_discount: 'Quantity discount',
  marketing_groups_one: 'Marketing group',
  marketing_groups_other: 'Marketing groups',
  price_categories_one: 'Price category',
  price_categories_other: 'Price categories',
  banks_settings: 'Banks settings',
  bank_settings: 'Bank settings',
  internal_bank_description: 'Internal bank description',
  section_bank: 'Bank',
  section_customer: 'Customer',
  zip_code: 'ZIP/Location',
  subscriber_num: 'ESR/BESR Subscriber-Nr.',
  qr_iban: 'QR-IBAN',
  customer_identification: 'Customer identification (max. 6 letters)',
  financial_accounting: 'Financial accounting bank account',
  first_name: 'Name 1',
  second_name: 'Name 2',
  street: 'Street',
  recipe_book: 'Book of receipts',
  invalid_member_num: 'Only numbers are allowed',
  invalid_qr_iban: 'The IBAN you entered is not a QR IBAN. ',
  cannot_delete_this_bank:
    'Unfortunately, you cannot delete this bank because it is defined as the default bank.',
  general_vat_system: 'General VAT system',
  from_invoice_amount: 'From amount (invoice)',
  reminder_text: 'Reminder text',
  reminder_texts_settings: 'Reminder texts settings',
  fixed_shipping_costs: 'Fixed shipping costs',
  shipping_costs_by_weight: 'Shipping costs by weight',
  shipping_cost: 'Shipping cost',
  report_header: 'Report header',
  banks: 'Banks',
  storage_locations_settings: 'Storage locations settings',
  storage_locations_one: 'Storage location',
  storage_locations_other: 'Storage locations',
  order_types_accounted_in_production_reports: 'Order types accounted in production reports',
  order_types: 'Order types',
  working_with_versions: 'Working with versions',
});
