import { FC, useEffect, useState } from 'react';
import { Popover } from '../../../../../popover/popover.component';
import { Apps as AppsIcon } from '@mui/icons-material';
import { SxProps, Theme as ThemeMUI } from '@mui/material';
import { Content } from './components/content.component';
import { C_Hs_App } from '../../../../../../../graphql/generatedModel';
import CashAssistIcon from '../../../../../../icons/hsApps/cash_assist.svg';
import WawiAssistIcon from '../../../../../../icons/hsApps/wawi_assist.svg';
import RezeptAssistIcon from '../../../../../../icons/hsApps/rezept_assist.svg';
import SmartPickingIcon from '../../../../../../icons/hsApps/smart_picking.svg';
import RestaurantManagerIcon from '../../../../../../icons/hsApps/restaurant_manager.svg';
import RunMyRestoIcon from '../../../../../../icons/hsApps/run_my_resto.svg';
import ConsoleIcon from '../../../../../../icons/hsApps/console.svg';
import { t } from 'i18next';
import { HsAppsListRes, topbarService } from '../../services/topBar.service';
import { responseHandler } from '../../../../../../responseHandler/responseHandler';
import { dataHelper } from '../../../../../../helpers/data/data.helper';

export const AppMenu: FC = () => {
  const [appsInfo, setAppsInfo] = useState<IAppInfo[]>([]);
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    const sub = topbarService.sub
      .hsAppsList()
      .pipe(responseHandler<HsAppsListRes>({ errorReturnType: [] }))
      .subscribe((appsAvailability) => {
        const apps: IAppInfo[] = appsAvailability
          ?.filter(({ isAvailable }) => isAvailable)
          ?.map(({ appType, isAvailable }) => {
            const info = appsIconLabelUrl[appType];
            return {
              ...info,
              isAvailable,
              title: `${info?.label1}${info?.label2}`,
            };
          });

        const onlyWawiAppAvaliable =
          apps?.length === 1 && apps?.[0]?.title === `${t('common.wawi')}${t('common.assist')}`;

        if (onlyWawiAppAvaliable || !apps?.length) {
          setAppsInfo([]);
          setIsShow(false);
        } else {
          apps.push(consoleAppIconLabelUrl);
          const sortedAppsInfo = dataHelper
            .data(apps)
            .sort({ sortModel: [{ field: 'title', sort: 'asc' }] })
            .result() as IAppInfo[];

          setAppsInfo(sortedAppsInfo);
          setIsShow(true);
        }
      });

    topbarService.pub.hsAppsList();
    return () => {
      sub?.unsubscribe();
    };
  }, []);

  if (isShow) {
    return (
      <Popover
        icon={<AppsIcon />}
        content={<Content appsInfo={appsInfo} />}
        popoverProps={popoverProps}
      />
    );
  }

  return <></>;
};

const contentSx: SxProps<ThemeMUI> = {
  '& .MuiPaper-root': {
    right: '24px',
    left: 'unset !important',
    marginTop: '16px',
  },
};

const popoverProps = {
  sx: contentSx,
};

const appsIconLabelUrl: IAppsIconLabelUrl = {
  [C_Hs_App.CASH_ASSIST]: {
    icon: CashAssistIcon,
    label1: t('common.cash'),
    label2: t('common.assist'),
    url: 'https://cashassist.hssoft.com/',
  },
  [C_Hs_App.WAWI_ASSIST]: {
    icon: WawiAssistIcon,
    label1: t('common.wawi'),
    label2: t('common.assist'),
    url: 'https://wawiassist.hssoft.com/',
  },
  [C_Hs_App.REZEPT_ASSIST]: {
    icon: RezeptAssistIcon,
    label1: t('common.rezept'),
    label2: t('common.assist'),
    url: 'https://rezeptassist.web.hssoft.com/',
  },
  [C_Hs_App.SMART_PICKING]: {
    icon: SmartPickingIcon,
    label1: t('common.smart'),
    label2: t('common.picking'),
    url: 'https://smartpicking.hssoft.com/',
  },
  [C_Hs_App.RESTAURANT_MANAGER]: {
    icon: RestaurantManagerIcon,
    label1: t('common.restaurant'),
    label2: t('common.manager'),
    url: 'https://restaurant-manager24.hssoft.com',
  },
  [C_Hs_App.RUN_MY_RESTO]: {
    icon: RunMyRestoIcon,
    label1: t('common.run'),
    label2: t('common.my_resto'),
    url: 'https://app.runmyresto.com',
  },
};

const consoleAppIconLabelUrl: IAppInfo = {
  icon: ConsoleIcon,
  label1: t('common.console'),
  label2: '',
  url: 'https://console.hssoft.com/',
  isAvailable: true,
  title: t('common.console'),
};

export interface IAppInfo {
  icon: string;
  label1: string;
  label2: string;
  title: string;
  url: string;
  isAvailable: boolean;
}

type IAppIconLabelUrl = Pick<IAppInfo, 'icon' | 'label1' | 'label2' | 'url'>;

type IAppsIconLabelUrl = {
  [appType in C_Hs_App]: IAppIconLabelUrl;
};
