import { Box, Button } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { filter, take } from 'rxjs';

import { Wa_CreateCustomerInput } from '../../../../../graphql/generatedModel.ts';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler.ts';
import { modeService } from '../../../../../shared/services/mode/mode.service.ts';
import { CopyCustomerDataRes, customerService } from '../../../services/customer.service.ts';
import { customerTabLoadingService } from '../customerTabLoading.service.ts';
import { GeneralForm } from './components/generalForm.component.tsx';
import { IGeneralTabProps } from './general.tab.tsx';

export const GeneralCopyTab: FC<Pick<IGeneralTabProps, 'mode' | 'loading'>> = ({
  mode,
  loading,
}) => {
  const { t } = useTranslation();

  const {
    getValues,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useFormContext();

  const onSubmitForm = () => {
    const { id, ...data } = getValues() as ICopyCustomerValues;
    if (data.customerNo && dirtyFields['customerNo']) {
      customerTabLoadingService.pub.loading(true);
      customerService.pub.copyCustomer({
        data: {
          ...data,
          customerNo: data.customerNo as string,
        },
        originalId: id,
      });
    }
  };

  const onCancelCopyMode = () => {
    // keep this reset() for server validation handler
    // this keepDirty blocks pub.save() in handleSubmitValue from being called again after copying or creating a new entry
    reset(undefined, { keepDirty: false });
    modeService.pub.mode('edit');
  };

  useEffect(() => {
    const copyCustomerSub = customerService.sub
      .copyCustomer()
      .pipe(
        take(1),
        responseHandler<CopyCustomerDataRes | undefined>({
          success: () => 'customer.customer_saved',
          customErrorHandler: () => {
            customerTabLoadingService.pub.loading(false);
            return 'common.error_chnages_not_saved';
          },
        }),
        filter((v) => v !== undefined),
        take(1),
      )
      .subscribe(() => customerTabLoadingService.pub.loading(false));

    return () => {
      copyCustomerSub.unsubscribe();
    };
  }, []);

  return (
    <>
      <GeneralForm mode={mode} loading={loading} />
      <Box mt={2} display='flex' gap={2}>
        <Button variant='outlined' onClick={onCancelCopyMode}>
          {t('common.cancel')}
        </Button>
        <Button variant='contained' type='submit' onClick={handleSubmit(onSubmitForm)}>
          {t('customer.copy_customer')}
        </Button>
      </Box>
    </>
  );
};

export interface ICopyCustomerValues extends Omit<Wa_CreateCustomerInput, 'customerNo'> {
  customerNo: string | null;
  id: string;
}
