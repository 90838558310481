import { MutableRefObject, useEffect, useState } from 'react';
import { GridStopCellEditModeParams } from '@mui/x-data-grid/models/api/gridEditingApi';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

/* todo Used to prevent the cell being edited from closing when the scroll bar is clicked */
export const useControlScrollClick = ({ apiRef }: IUseControlScrollClick) => {
  const [cellModeParams, setCellModeParams] = useState<GridStopCellEditModeParams | null>(null);
  const click = (e: MouseEvent) => {
    if (cellModeParams && e) {
      const { field, id } = cellModeParams;
      const element = (e.target as HTMLElement).closest(`.${field.replace(/\./g, '')}-${id}`);
      if (!element) {
        const mode = apiRef.current.getCellMode(id, field);
        if (mode === 'edit') apiRef.current.stopCellEditMode(cellModeParams);
      }
      setCellModeParams(null);
    }
  };

  useEffect(() => {
    if (cellModeParams) {
      document.body.addEventListener('click', click);
    } else {
      document.body.removeEventListener('click', click);
    }
    return () => {
      document.body.removeEventListener('click', click);
    };
  }, [cellModeParams]);
  return { onCellModeParams: setCellModeParams };
};

interface IUseControlScrollClick {
  apiRef: MutableRefObject<GridApiPremium>;
}
