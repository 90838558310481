import { gql } from 'graphql-request';

export const getHsAppsList = gql`
  query listHS_Apps {
    wawiAssist {
      listHS_Apps {
        isAvailable
        appType
      }
    }
  }
`;
