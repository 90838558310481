import React, { FC, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';

import { Checkbox, ICheckboxProps } from '../fields/checkbox.component.tsx';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import InboxIcon from '@mui/icons-material/Inbox';

export const CheckboxList: FC<ICheckboxList> = ({
  data,
  controlLabel,
  onChange,
  onBlur,
  minWidth,
  width = 'fit-content',
  height = '100%',
  maxHeight,
  minHeight,
  listLabel,
  withDivider = false,
  headerRightOptions,
  emptyListIndication,
}) => {
  const { t } = useTranslation();
  const [listData, setListData] = useState<ICheckboxList['data']>([]);

  useEffect(() => {
    setListData(data);
  }, [data]);

  const emptyBox = useMemo(
    () => (
      <SEmptyBox {...{ minHeight }}>
        {emptyListIndication?.icon || <InboxIcon />}
        <Typography variant='h5'>{emptyListIndication?.title || t('common.empty')}</Typography>
        <Typography textAlign='center' variant='subtitle2' color={grey['600']}>
          {emptyListIndication?.text || t('common.no_data')}
        </Typography>
      </SEmptyBox>
    ),
    [data, t, emptyListIndication],
  );

  const changeCheckboxes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const copyListData = [...listData];
    const currentIndex = copyListData.findIndex((item) => item.id === e.target.id);
    const el = { ...data[currentIndex] };
    el.checked = e.target.checked;
    copyListData.splice(currentIndex, 1, el);
    setListData(copyListData);
    onChange?.(copyListData);
  };

  const checkValueDifference = (
    data: ICheckboxList['data'],
    key: keyof ICheckboxList['data'][number],
  ) => {
    return !data.every((item) => item[key] === data?.[0]?.[key]);
  };

  const changeAllCheckboxes = (state: boolean) => {
    setListData(
      listData.map((item) => {
        item.checked = state;
        return { ...item };
      }),
    );
  };

  return (
    <Stack
      height='100%'
      position='relative'
      direction='column'
      onBlur={() => onBlur?.(listData)}
      {...{ width }}
    >
      {listLabel && (
        <Typography variant='h5' mb={1}>
          {listLabel}
        </Typography>
      )}
      <SCheckboxList
        {...{ minWidth, height, maxHeight, minHeight }}
        {...(!withDivider && { py: 2, px: 3 })}
      >
        {data.length || Boolean(headerRightOptions) ? (
          <>
            {controlLabel && (
              <>
                <Stack
                  flexWrap='nowrap'
                  flexDirection='row'
                  alignItems='center'
                  {...(withDivider && { py: 1, px: 3 })}
                >
                  {!!data.length && (
                    <Checkbox
                      sx={{ flexGrow: 1 }}
                      label={controlLabel}
                      indeterminate={checkValueDifference(listData, 'checked')}
                      checked={listData.every((el) => el.checked)}
                      onChange={(e) => {
                        if (e.target.checked) changeAllCheckboxes(true);
                        else changeAllCheckboxes(false);
                        onChange?.(listData);
                      }}
                    />
                  )}
                  {headerRightOptions}
                </Stack>
                {withDivider && <Divider />}
              </>
            )}
            <Stack {...(withDivider && { py: 1, px: 3 })} height='100%' direction='column'>
              {listData.length
                ? listData.map(({ checked, ...props }) => (
                    <Checkbox
                      key={props.id}
                      checked={checked}
                      onChange={changeCheckboxes}
                      {...props}
                    />
                  ))
                : emptyBox}
            </Stack>
          </>
        ) : (
          emptyBox
        )}
      </SCheckboxList>
    </Stack>
  );
};

const SCheckboxList = styled(Box)<{
  minWidth?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
}>(({ theme, minWidth, maxHeight, minHeight, height }) => ({
  borderRadius: 4,
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  minWidth: minWidth ? minWidth : 250,
  height: height ? height : 'auto',
  overflowY: 'scroll',
  ...(maxHeight && { maxHeight }),
  ...(minHeight && { minHeight }),
}));

const SEmptyBox = styled(Box)<{ minHeight?: string }>(({ theme, minHeight }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  ...(minHeight && { minHeight }),
  '& svg': {
    height: 60,
    width: 'auto',
    color: theme.palette.info.main,
  },
}));

export interface ICheckboxList {
  data: ICheckboxData[];
  controlLabel?: string;
  onChange?: (v: Partial<ICheckboxProps[]>) => void;
  onBlur?: (v: Partial<ICheckboxProps[]>) => void;
  width?: number;
  minWidth?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
  listLabel?: string;
  withDivider?: boolean;
  headerRightOptions?: ReactNode;
  emptyListIndication?: {
    icon?: ReactNode;
    title?: string;
    text?: string | ReactElement;
  };
}

interface ICheckboxData extends Omit<ICheckboxProps, 'onChange' | 'id'> {
  id: ICheckboxProps['id'];
}
