import { Box, Link, styled } from '@mui/material';
import { FC, MouseEvent } from 'react';
import { IAppInfo } from '../hsAppMenu.component';
import { useTranslation } from 'react-i18next';

export const AppBox: FC<IAppInfo> = ({ icon, title, label1, label2, url }) => {
  const { t } = useTranslation();
  const isWawi = title === `${t('common.wawi')}${t('common.assist')}`;

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (isWawi) {
      e?.preventDefault();
    }
  };

  return (
    <SWrapper href={url} title={title} target='_blank' isWawi={isWawi} onClick={handleClick}>
      <img src={icon} alt={title} />
      <Box className='web-app-menu-title'>
        <b>{label1}</b>
        {label2}
      </Box>
    </SWrapper>
  );
};

const SWrapper = styled(Link, { shouldForwardProp: (prop) => prop !== 'isWawi' })<{
  isWawi: boolean;
}>(({ isWawi, theme }) => ({
  width: '120px',
  height: '100px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  fontSize: '12px',
  fontFamily: theme.typography.fontFamily,
  textDecoration: 'none',
  padding: '24px 16px 24px 16px',
  backgroundColor: isWawi ? theme.palette.primaryFocus.main : 'inherit',
  cursor: isWawi ? 'default' : 'pointer',
  '& img': {
    maxWidth: '35px',
    maxHeight: '30px',
  },
  '& .web-app-menu-title': {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  ':hover': {
    backgroundColor: isWawi ? theme.palette.primaryFocus.main : theme.palette.primaryHover.main,
  },
}));
