import {
  Popup,
  TPopupComponent,
} from '../../../../../../../shared/components/popup/popup.abstract.tsx';
import { FC, useEffect, useMemo, useState } from 'react';
import { TPopupDefaultActions } from '../../../../../../../shared/components/popup/services/popup.service.ts';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { NewOrderDataExtendedRes } from '../../../../../services/createOrder.service.ts';
import { IOrderDetailsState } from '../../../../states/orderDetails/orderDetails.state.ts';
import { Content, IContent } from './content.popup.tsx';

export class OrderSelectionPopup extends Popup<IOrderSelectionPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();
      const [fullTableData, setFullTableData] = useState<IContent['fullTableData']>([]);
      const [tableData, setTableData] = useState<IContent['tableData']>([]);
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 600 },
        }));
      }, []);
      useEffect(() => {
        stream.actionListener('open').subscribe((data) => {
          setFullTableData(data.orders ?? []);
          const updatedData = data?.orders?.map((item) => item?.orderSourceInfo) || [];
          setTableData(updatedData);
        });
      }, []);

      return (
        <SWrapper
          fullWidth
          stream={stream}
          popupTitle={t('order.select_order')}
          closureOverlay={false}
          closeBtn={false}
        >
          <Content {...{ tableData, fullTableData, stream }} />
        </SWrapper>
      );
    };
  }
}

export interface IOrderSelectionPopup {
  action: 'confirm' | TPopupDefaultActions;
  orders: NonNullable<NewOrderDataExtendedRes>;
  saveBackup: boolean;
  confirmedOrder?: IOrderDetailsState['orderData'];
}
