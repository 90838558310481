import { FC, useState, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useLoaderData } from 'react-router-dom';

import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

import { TMode } from '../../../../../../../shared/services/mode/mode.service';
import { EditPrintListGeneralTabRes } from '../../../../../services/editPrintList.service';
import {
  FieldsRow,
  FieldsSection,
  FormCheckbox,
  FormSelect,
  FormTextField,
  FormNumericField,
} from '../../../../../../../shared/components/form';
import {
  C_Report_Group,
  C_Report,
  C_Production_Article_Sorting_Kind,
} from '../../../../../../../graphql/generatedModel';
import {
  generateCrosstableEasyModeSplitNoOptions,
  customerSortingKindsOptions,
  tourplanDayOptions,
} from '../options/generalTab.options';
import { getReportOptionsByGroup } from '../../../../../../../shared/helpers/reportOptionsByGroup/getReportOptionsByGroup';
import { ISelectOption } from '../../../../../../../shared/components/form/fields/select.component';
import {
  getProductionSortingOptions,
  getDefaultProductionSortingOption,
  getReportIdByGroup,
  checkIfAllFiltersChecked,
} from '../utils/generalTab.utils';
import { generalTabState } from '../generalTab.state';
import { NoteButton as FilterButton } from '../../../../../../../shared/components/form/buttons/noteButton.component';
import { FilterPopup } from './filter.popup';
import { CrossTablePopup } from './crossTable/crossTable.popup';
import { TourplansPopup } from './tourplans/tourplans.popup';
import { IGeneralTab } from '../general.tab';

export const GeneralForm: FC<IGeneralForm> = ({ mode }) => {
  const { t } = useTranslation();
  const {
    tourTabOptions,
    articleListsOptions,
    productionPrintListsOptions: printListsOptions,
    customersOptions,
    customerListsOptions,
  } = useLoaderData() as IGeneralTab;

  const filterPopup = useMemo(() => new FilterPopup(), []);
  const crossTablePopup = useMemo(() => new CrossTablePopup(), []);
  const tourplansPopup = useMemo(() => new TourplansPopup(), []);

  const [productionSortingOptions, setProductionSortingOptions] = useState<
    { id: C_Production_Article_Sorting_Kind; label: string }[]
  >([]);
  const {
    watch,
    setValue,
    resetField,
    reset,
    trigger,
    formState: { errors, dirtyFields },
    control: { _formValues },
  } = useFormContext();
  const {
    reportId,
    reportGroupId,
    crosstableEasyModeSplitNoFilter,
    isCrosstableEasyMode,
    articlesSortingKindId,
    articleListsFilter = [],
    customerListsFilter = [],
    orderTypesFilter = [],
    customersSortingKindId,
  } = watch() || {};

  const daysOfTheWeek = useMemo(
    () => [
      { label: t('common.monday'), name: 'isAvailableMon' },
      { label: t('common.tuesday'), name: 'isAvailableTue' },
      { label: t('common.wednesday'), name: 'isAvailableWed' },
      { label: t('common.thursday'), name: 'isAvailableThu' },
      { label: t('common.friday'), name: 'isAvailableFri' },
      { label: t('common.saturday'), name: 'isAvailableSat' },
      { label: t('common.sunday'), name: 'isAvailableSun' },
    ],
    [t],
  );

  const reportGroupOptions = useMemo(
    () => [
      {
        id: C_Report_Group.RG1_PRODUCTION_PRODUCTION,
        label: t(`enums.${C_Report_Group.RG1_PRODUCTION_PRODUCTION}`),
      },
      {
        id: C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS,
        label: t(`enums.${C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS}`),
      },
      {
        id: C_Report_Group.RG9_PRODUCTION_CROSSTABLE,
        label: t(`enums.${C_Report_Group.RG9_PRODUCTION_CROSSTABLE}`),
      },
      {
        id: C_Report_Group.RG3_ARTICLE,
        label: t('production.labels'),
      },
      {
        id: C_Report_Group.RG11_PRODUCTION_SMARTSCALE,
        label: t(`enums.${C_Report_Group.RG11_PRODUCTION_SMARTSCALE}`),
      },
    ],
    [t],
  );

  const isReportIdVisible =
    reportId !== C_Report.R12_LABEL &&
    reportId !== C_Report.R49_BAKING_LIST_2_COLUMNS_SMART_SCALE &&
    reportGroupId !== C_Report_Group.RG3_ARTICLE &&
    reportGroupId !== C_Report_Group.RG11_PRODUCTION_SMARTSCALE;
  const isSplittingAndIndentationCheckboxesVisible =
    reportGroupId === C_Report_Group.RG1_PRODUCTION_PRODUCTION &&
    [
      C_Report.R1_BAKING_LIST_2_COLUMNS,
      C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS,
      C_Report.R25_BAKING_LIST_2_COLUMNS_COMBINED,
      C_Report.R26_BAKING_LIST_WITH_RECIPE_A4,
      C_Report.R27_BAKING_LIST_WITH_RECIPE_A5,
      C_Report.R34_BAKING_LIST_A4,
      C_Report.R41_BAKING_LIST_A4_DETAILED,
    ].includes(reportId as C_Report);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    const fieldName = e.target.name as keyof EditPrintListGeneralTabRes;

    if (!errors[fieldName] && !!dirtyFields[fieldName]) {
      if (mode === 'edit') {
        if (fieldName === 'reportGroupId') {
          const allowedReportGroupIds = [
            C_Report_Group.RG3_ARTICLE,
            C_Report_Group.RG11_PRODUCTION_SMARTSCALE,
          ];
          if (allowedReportGroupIds.includes(reportGroupId as C_Report_Group)) {
            generalTabState.pub.save();
          }
        } else {
          generalTabState.pub.save();
        }
        reset(_formValues, { keepErrors: true });
      }
    }
  };

  const {
    visibleSemiProductsDetails,
    visibleProductionDescription,
    visibleDaysForPreProduction,
    visibleNeedSignatureOnForwardingList,
    visibleCrosstableConfigurationData,
    visibleCrosstableEasyMode,
    isTourListReport,
    visibleArticlesSortingKindId,
    visibleTourplanDay,
  }: IVisibleFields = useMemo(() => {
    const isCrossTableReport = reportGroupId === C_Report_Group.RG9_PRODUCTION_CROSSTABLE;
    const isTourListReport = reportId === C_Report.R32_TOURLIST;
    const isForwardingListCustomerArticlesReport = [
      C_Report.R15_FORWARDING_LIST_CUSTOMER_ARTICLES,
      C_Report.R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE,
    ].includes(reportId as C_Report);
    const isNotSpecialReportGroupOrId =
      reportGroupId !== C_Report_Group.RG11_PRODUCTION_SMARTSCALE &&
      reportId !== C_Report.R51_DELIVERY_NOTES_IN_PRODUCTION &&
      reportId !== C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS;
    return {
      visibleSemiProductsDetails: [
        C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS,
        C_Report.R25_BAKING_LIST_2_COLUMNS_COMBINED,
        C_Report.R26_BAKING_LIST_WITH_RECIPE_A4,
        C_Report.R27_BAKING_LIST_WITH_RECIPE_A5,
        C_Report.R49_BAKING_LIST_2_COLUMNS_SMART_SCALE,
        C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS,
      ].includes(reportId as C_Report),
      visibleProductionDescription: reportId === C_Report.R26_BAKING_LIST_WITH_RECIPE_A4,
      visibleDaysForPreProduction: [
        C_Report_Group.RG1_PRODUCTION_PRODUCTION,
        C_Report_Group.RG11_PRODUCTION_SMARTSCALE,
      ].includes(reportGroupId as C_Report_Group),
      visibleNeedSignatureOnForwardingList:
        reportGroupId === C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS &&
        reportId !== C_Report.R51_DELIVERY_NOTES_IN_PRODUCTION,
      visibleCrosstableConfigurationData: isCrossTableReport && !isCrosstableEasyMode,
      visibleCrosstableEasyMode: isCrossTableReport,
      isTourListReport,
      visibleTourplanDay: isTourListReport || isForwardingListCustomerArticlesReport,
      visibleArticlesSortingKindId: !isTourListReport && isNotSpecialReportGroupOrId,
    };
  }, [reportId, reportGroupId, isCrosstableEasyMode, C_Report]);

  useEffect(() => {
    const options = getProductionSortingOptions(
      reportGroupId as C_Report_Group,
      reportId as C_Report,
    );
    setProductionSortingOptions(options);
    if (
      articlesSortingKindId &&
      !options.some((option) => option.id === articlesSortingKindId) &&
      (reportGroupId !== null || reportId !== null)
    ) {
      setValue(
        'articlesSortingKindId',
        getDefaultProductionSortingOption(reportGroupId as C_Report_Group),
        { shouldDirty: false },
      );
    }
  }, [reportGroupId, reportId]);

  useEffect(() => {
    return () => {
      filterPopup.stream.unsubscribe();
      crossTablePopup.stream.unsubscribe();
      tourplansPopup.stream.unsubscribe();
    };
  }, []);

  return (
    <form {...(mode === 'edit' && { onBlur: onSubmit })} noValidate>
      <FieldsRow alignItems='flex-start'>
        <FormTextField
          name='name'
          label={t('common.description')}
          required
          error={Boolean(errors['name'])}
          helperText={errors['name'] ? t(String(errors['name'].message)) : ''}
        />
        <FormCheckbox name='isActive' label={t('production.activated')} />
      </FieldsRow>

      <FieldsRow alignItems='flex-start'>
        <FormSelect
          name='reportGroupId'
          label={t('production.form_type')}
          options={reportGroupOptions}
          disableClearable
          required
          onChange={(v) => {
            trigger('reportId');
            setValue('reportId', getReportIdByGroup(v as C_Report_Group), {
              shouldDirty: false,
            });
            resetField('tourplanId', { keepDirty: false });
          }}
          error={Boolean(errors['reportGroupId'])}
          helperText={errors['reportGroupId'] ? t(String(errors['reportGroupId'].message)) : ''}
        />
        {isReportIdVisible && (
          <FormSelect
            name='reportId'
            label={t('production.form')}
            options={
              reportGroupId === C_Report_Group.RG1_PRODUCTION_PRODUCTION
                ? getReportOptionsByGroup(reportGroupId)?.filter(
                    ({ id }) =>
                      id !== C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE &&
                      id !== C_Report.R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE,
                  )
                : reportGroupId === C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS
                ? getReportOptionsByGroup(reportGroupId)?.filter(
                    ({ id }) =>
                      id !== C_Report.R68_FORWARDING_LIST_ARTICLE_CUSTOMERS_VERSIONS_DIFFERENCE,
                  )
                : getReportOptionsByGroup(reportGroupId as C_Report_Group)
            }
            disableClearable
            required
            onChange={(v) => {
              v === C_Report.R32_TOURLIST
                ? setValue('tourplanId', null, { shouldValidate: true, shouldDirty: false })
                : resetField('tourplanId', { keepDirty: false });
            }}
            error={Boolean(errors['reportId'])}
            helperText={errors['reportId'] ? t(String(errors['reportId'].message)) : ''}
            sx={{ width: 300 }}
          />
        )}
      </FieldsRow>

      <FieldsSection nomt title={t('production.print_list_settings')}>
        {(visibleDaysForPreProduction ||
          visibleSemiProductsDetails ||
          visibleProductionDescription) && (
          <FieldsRow alignItems='flex-start'>
            {visibleDaysForPreProduction && (
              <FormNumericField
                name='addDaysForPreProduction'
                label={t('production.pre_production_in_days')}
                noTrailingZeros
                shrink
                step={1}
                min={0}
                returnZero
                precision={0}
              />
            )}
            {visibleSemiProductsDetails && (
              <FormCheckbox
                name='isShowSemiProductsDetails'
                label={t('production.show_semi_products_details')}
              />
            )}
            {visibleProductionDescription && (
              <FormCheckbox
                name='isShowProductionDescription'
                label={t('production.show_production_description')}
              />
            )}
          </FieldsRow>
        )}

        {(isTourListReport || visibleTourplanDay || visibleNeedSignatureOnForwardingList) && (
          <FieldsRow alignItems='flex-start'>
            {isTourListReport && (
              <FormSelect
                name='tourplanId'
                label={t('production.tour')}
                options={tourTabOptions as ISelectOption[]}
                disableClearable
                required
                error={Boolean(errors['tourplanId'])}
                helperText={errors['tourplanId'] ? t(String(errors['tourplanId'].message)) : ''}
              />
            )}
            {visibleTourplanDay && (
              <FormSelect
                name='tourplanDay'
                label={t('production.tour_type')}
                options={tourplanDayOptions}
                disableClearable
              />
            )}
            {visibleNeedSignatureOnForwardingList && (
              <FormCheckbox
                name='isNeedSignatureOnForwardingList'
                label={t('production.need_signature')}
              />
            )}
          </FieldsRow>
        )}

        {isTourListReport && (
          <FieldsRow alignItems='flex-start'>
            {isTourListReport && (
              <FormCheckbox
                name='isPrintDeliveryNotesForTourplan'
                label={t('production.print_all_delivery_notes_of_this_tour')}
                sx={{ marginLeft: -1.4 }}
              />
            )}
            {isTourListReport && (
              <Button
                onClick={() =>
                  tourplansPopup.stream.emit('open', {
                    values: {
                      articleListsOptions,
                    },
                    onApply: () => {
                      generalTabState.pub.save();
                      reset(_formValues, { keepErrors: true });
                    },
                  })
                }
                disabled={mode !== 'edit'}
                variant='contained'
              >
                {t('production.tour_list_setup')}
              </Button>
            )}
          </FieldsRow>
        )}

        {(visibleCrosstableEasyMode || crosstableEasyModeSplitNoFilter !== undefined) && (
          <FieldsRow alignItems='flex-start' ml={-1.4}>
            {visibleCrosstableEasyMode && (
              <FormCheckbox
                name='isCrosstableEasyMode'
                label={t('production.automatic_mode_of_cross_table_report')}
              />
            )}
            {crosstableEasyModeSplitNoFilter !== undefined && (
              <FormSelect
                name='crosstableEasyModeSplitNoFilter'
                label={t('production.partial_delivery')}
                shrink
                disableClearable
                options={generateCrosstableEasyModeSplitNoOptions()}
              />
            )}
            {visibleCrosstableConfigurationData && (
              <Button
                onClick={() =>
                  crossTablePopup.stream.emit('open', {
                    values: {
                      printListsOptions,
                      customersOptions,
                      customerListsOptions,
                    },
                    onApply: () => {
                      generalTabState.pub.save();
                      reset(_formValues, { keepErrors: true });
                    },
                  })
                }
                disabled={mode !== 'edit'}
                variant='contained'
              >
                {t('production.cross_table_setup')}
              </Button>
            )}
          </FieldsRow>
        )}

        <FieldsRow alignItems='flex-start'>
          {visibleArticlesSortingKindId && (
            <FormSelect
              name='articlesSortingKindId'
              label={t('production.article_sorting')}
              options={productionSortingOptions}
              disableClearable
            />
          )}
          {customersSortingKindId !== undefined && (
            <FormSelect
              name='customersSortingKindId'
              label={t('production.customer_sorting')}
              options={customerSortingKindsOptions}
              disableClearable
            />
          )}
          <FilterButton
            badged={checkIfAllFiltersChecked(
              articleListsFilter,
              customerListsFilter,
              orderTypesFilter,
            )}
            buttonIcon={LibraryAddCheckIcon}
            disabled={mode !== 'edit'}
            onClick={() => {
              filterPopup.stream.emit('open', {
                onApply: () => generalTabState.pub.save(),
              });
            }}
          >
            {t('production.filters_setup')}
          </FilterButton>
        </FieldsRow>
      </FieldsSection>

      <FieldsSection nomt title={t('production.additional_settings')}>
        <FieldsRow alignItems='flex-start' mt={1.5} ml={-1.4}>
          <FormCheckbox
            name='isShowIndividualTexts'
            label={t('production.show_individual_texts')}
          />
          <FormCheckbox
            name='isOnlyPositionsWithIndividualText'
            label={t('production.consider_only_order_positions_with_individual_text')}
          />
        </FieldsRow>
        {isSplittingAndIndentationCheckboxesVisible && (
          <FieldsRow alignItems='flex-start' mt={0} ml={-1.4}>
            <FormCheckbox
              name='isHighlightArticlesFromProductionCalc'
              label={t('production.display_linked_articles_with_indentation')}
            />
            <FormCheckbox
              name='isSplitDoughBeforePrint'
              label={t('production.do_default_dough_splitting')}
            />
            <FormCheckbox
              name='isShowDeliverySplitting'
              label={t('production.display_delivery_splitting_parts')}
            />
          </FieldsRow>
        )}
      </FieldsSection>

      <FieldsSection nomt title={t('production.print_list_availability')}>
        <FieldsRow alignItems='flex-start' direction='row' ml={-1.4}>
          {daysOfTheWeek.map((props) => (
            <FormCheckbox key={props.name} {...props} />
          ))}
        </FieldsRow>
      </FieldsSection>

      <tourplansPopup.Component />
      <filterPopup.Component />
      <crossTablePopup.Component />
    </form>
  );
};

interface IGeneralForm {
  mode: TMode;
}
type IVisibleFields = {
  [key in
    | 'visibleSemiProductsDetails'
    | 'visibleProductionDescription'
    | 'visibleDaysForPreProduction'
    | 'visibleNeedSignatureOnForwardingList'
    | 'visibleCrosstableConfigurationData'
    | 'visibleCrosstableEasyMode'
    | 'isTourListReport'
    | 'visibleTourplanDay'
    | 'visibleArticlesSortingKindId']: boolean;
};
