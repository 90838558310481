import { BehaviorSubject, Observable, filter, map, merge, share, switchMap, tap } from 'rxjs';
import { Pub, State, Sub } from '../../../../../../shared/state/state.abstract';
import { OverviewArticlesRes, overviewService } from '../../../../services/overview.service';
import { overviewState } from '../../../states/overview.state';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler';
import { dataHelper } from '../../../../../../shared/helpers/data/data.helper';
import { storageHelper } from '../../../../../../shared/helpers/storage';
import { GridSortModel } from '@mui/x-data-grid-premium';

export const initArticlesOverviewState: IArticlesOverviewState = {
  action: undefined,
  articleOverview: [],
  isActiveGroupMode: true,
  search: '',
  sortModel: [{ field: 'orderNo', sort: 'asc' }],
};
class PubImpl extends Pub<IArticlesOverviewState> {
  init(params: Partial<IArticlesOverviewState>) {
    this.emit('init', params);
  }
  toggleGroupMode() {
    this.emit('toggleGroupMode', {});
  }
  search(search: string) {
    this.emit('search', { search });
  }
  sort(sortModel: IArticlesOverviewState['sortModel']) {
    storageHelper.local.setItem('overview.articleSort', sortModel);
    this.emit('sort', { sortModel });
  }
}
class SubImpl extends Sub<IArticlesOverviewState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  protected actionHandlers(): Observable<IArticlesOverviewState> {
    return merge(this.getData(), this.toggleGroupMode(), this.updateArticlesOverview()).pipe(
      map((state) => {
        const { search, articleOverview, sortModel } = state;
        let updatedArticlesOverview = [...(articleOverview || [])] as OverviewArticleData;

        updatedArticlesOverview = dataHelper
          .data(articleOverview as [])
          .sort({ sortModel })
          .search({
            search,
            fields: [
              'article',
              'customerNo',
              'internalOrFullName',
              'indTextDeliveryNote',
              'indTextProduction',
              'orderNo',
              ['orderDate'],
            ],
          })
          .result() as OverviewArticleData;

        this.stream$.next({ ...state, action: 'internalUpdate' });
        return { ...state, articleOverview: updatedArticlesOverview };
      }),
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  private updateArticlesOverview(): Observable<IArticlesOverviewState> {
    return this.actionListener(['search', 'sort']);
  }

  private getData(): Observable<IArticlesOverviewState> {
    return overviewState.sub.state().pipe(
      filter(
        ({ action }) => action === 'updateDatePeriod' || action === 'updateDeliverynoteGroupId',
      ),
      tap(() => this.loading$.next(true)),
      switchMap(({ datePeriod, deliveryNoteGroupId }) => {
        overviewService.pub.getOverviewArticles({
          filter: {
            periodStart: datePeriod?.fromDate,
            periodEnd: datePeriod?.toDate,
            deliveryNoteGroupId,
          },
        });
        return overviewService.sub.getOverviewArticles().pipe(
          responseHandler<OverviewArticlesRes>({
            customErrorHandler: () => 'order.tab_not_loaded',
            errorReturnType: [],
          }),
          map((data) => {
            const state = this.stream$.getValue();
            this.loading$.next(false);
            return {
              ...state,
              action: 'getData',
              articleOverview: concatArticleNameAndNo(data),
            } as IArticlesOverviewState;
          }),
        );
      }),
    );
  }

  private toggleGroupMode(): Observable<IArticlesOverviewState> {
    return this.actionListener('toggleGroupMode').pipe(
      map((oldState) => {
        const state = structuredClone(oldState);
        const oldStatus = state?.isActiveGroupMode;
        const newStatus = !oldStatus;
        storageHelper.local.setItem('overview.articleIsGroupModeActive', newStatus);
        state.isActiveGroupMode = newStatus;
        return state;
      }),
    );
  }
}
class ArticleOverviewState extends State<IArticlesOverviewState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const articleOverviewState = new ArticleOverviewState(initArticlesOverviewState);

export const concatArticleNameAndNo = (data: OverviewArticlesRes): OverviewArticleData => {
  if (!data) {
    return [];
  }
  return data.map((props, i) => {
    const { articleNo, articleName } = props || {};
    return {
      ...props,
      article: `${articleNo} ${articleName}`,
      id: String(i),
    };
  }) as OverviewArticleData;
};

export const getInitSortModel = (): GridSortModel => {
  const initSortModel =
    storageHelper.local.getItem('overview.articleSort') || initArticlesOverviewState?.sortModel;
  return initSortModel;
};

type ArticleItem = NonNullable<NonNullable<OverviewArticlesRes>[number]>;
interface ArticleData extends ArticleItem {
  article: string;
  id: string;
}
export type OverviewArticleData = ArticleData[];
export interface IArticlesOverviewState {
  action: 'init' | 'internalUpdate' | 'getData' | 'toggleGroupMode' | 'search' | 'sort' | undefined;
  articleOverview: OverviewArticleData;
  isActiveGroupMode: boolean;
  search: string;
  sortModel: GridSortModel;
}
