import {
  Observable,
  delay,
  filter,
  finalize,
  map,
  merge,
  of,
  repeat,
  switchMap,
  take,
  takeWhile,
} from 'rxjs';
import {
  C_Report,
  Create_R1_BakingList2ColumnsReportFromVersionMutationVariables,
  Create_R1_BakingList2ColumnsReportFromVersionMutation,
  Create_R14_BakingListWithRecipe2ColumnsReportFromVersionMutationVariables,
  Create_R14_BakingListWithRecipe2ColumnsReportFromVersionMutation,
  Create_R26_BakingListWithRecipeA4ReportFromVersionMutationVariables,
  Create_R26_BakingListWithRecipeA4ReportFromVersionMutation,
  Create_R27_BakingListWithRecipeA4ReportFromVersionMutationVariables,
  Create_R27_BakingListWithRecipeA4ReportFromVersionMutation,
  Create_R34_BakingListA4ReportFromVersionMutationVariables,
  Create_R34_BakingListA4ReportFromVersionMutation,
  Create_R41_BakingListA4DetailedReportFromVersionMutationVariables,
  Create_R41_BakingListA4DetailedReportFromVersionMutation,
  Create_R57_BakingListRawmaterials2ColumnsReportFromVersionMutationVariables,
  Create_R57_BakingListRawmaterials2ColumnsReportFromVersionMutation,
  Create_R58_BakingListVersionsDifferenceWithRecipeMutationVariables,
  Create_R58_BakingListVersionsDifferenceWithRecipeMutation,
  Create_R68_ForwardingListArticleCustomersVersionsDifferenceMutationVariables,
  Create_R68_ForwardingListArticleCustomersVersionsDifferenceMutation,
  Create_R56_BakingListVersionsDifferenceMutationVariables,
  Create_R56_BakingListVersionsDifferenceMutation,
  Create_R56_BakingListVersionsDifferenceFromLiveDataMutationVariables,
  Create_R56_BakingListVersionsDifferenceFromLiveDataMutation,
  GetTasksStatusQuery,
  C_Win_Report_Task_Status,
} from '../../../graphql/generatedModel';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract';
import { gqlClient } from '../../../graphql/graphqlRequest';

import {
  r1FromVersion,
  r14FromVersion,
  r26FromVersion,
  r27FromVersion,
  r34FromVersion,
  r41FromVersion,
  r57FromVersion,
  r58VersionsDifferenceWithRecipe,
  r68VersionsDifference,
  r56VersionsDifference,
  r56VersionsDifferenceFromLiveData,
} from './gql/printJobs.gql';
import { getTasksStatus } from './gql/winReports.gql';

class PubImpl extends Pub<Action> {
  r1FromVersion(args: {
    variables: Create_R1_BakingList2ColumnsReportFromVersionMutationVariables;
  }) {
    this.emit(C_Report.R1_BAKING_LIST_2_COLUMNS, args);
  }
  r14FromVersion(args: {
    variables: Create_R14_BakingListWithRecipe2ColumnsReportFromVersionMutationVariables;
  }) {
    this.emit(C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS, args);
  }
  r26FromVersion(args: {
    variables: Create_R26_BakingListWithRecipeA4ReportFromVersionMutationVariables;
  }) {
    this.emit(C_Report.R26_BAKING_LIST_WITH_RECIPE_A4, args);
  }
  r27FromVersion(args: {
    variables: Create_R27_BakingListWithRecipeA4ReportFromVersionMutationVariables;
  }) {
    this.emit(C_Report.R27_BAKING_LIST_WITH_RECIPE_A5, args);
  }
  r34FromVersion(args: { variables: Create_R34_BakingListA4ReportFromVersionMutationVariables }) {
    this.emit(C_Report.R34_BAKING_LIST_A4, args);
  }
  r41FromVersion(args: {
    variables: Create_R41_BakingListA4DetailedReportFromVersionMutationVariables;
  }) {
    this.emit(C_Report.R41_BAKING_LIST_A4_DETAILED, args);
  }
  r57FromVersion(args: {
    variables: Create_R57_BakingListRawmaterials2ColumnsReportFromVersionMutationVariables;
  }) {
    this.emit(C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS, args);
  }

  r58VersionsDifferenceWithRecipe(args: {
    variables: Create_R58_BakingListVersionsDifferenceWithRecipeMutationVariables;
  }) {
    this.emit(C_Report.R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE, args);
  }

  r68VersionsDifference(args: {
    variables: Create_R68_ForwardingListArticleCustomersVersionsDifferenceMutationVariables;
  }) {
    this.emit(C_Report.R68_FORWARDING_LIST_ARTICLE_CUSTOMERS_VERSIONS_DIFFERENCE, args);
  }

  r56VersionsDifference(args: {
    variables: Create_R56_BakingListVersionsDifferenceMutationVariables;
    type: 'difference';
  }) {
    this.emit(C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE, args);
  }

  r56VersionsDifferenceFromLiveData(args: {
    variables: Create_R56_BakingListVersionsDifferenceFromLiveDataMutationVariables;
    type: 'liveData';
  }) {
    this.emit(C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE, args);
  }

  clear() {
    this.emit(undefined);
  }
}

class SubImpl extends Sub<Action> {
  mergedAll(): Observable<CommonWinReportTaskRes> {
    return merge(
      this.r1FromVersion(),
      this.r14FromVersion(),
      this.r26FromVersion(),
      this.r27FromVersion(),
      this.r34FromVersion(),
      this.r41FromVersion(),
      this.r57FromVersion(),
      this.r58VersionsDifferenceWithRecipe(),
      this.r68VersionsDifference(),
      this.r56VersionsDifference(),
      this.r56VersionsDifferenceFromLiveData(),
    ).pipe(finalize(() => productionReportFromVersionService.pub.clear()));
  }
  r1FromVersion(): Observable<CommonWinReportTaskRes> {
    return this.actionListener(C_Report.R1_BAKING_LIST_2_COLUMNS).pipe(
      this.commonRequest(r1FromVersion, 'create_r1_BakingList2ColumnsReportFromVersion'),
    );
  }
  r14FromVersion(): Observable<CommonWinReportTaskRes> {
    return this.actionListener(C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS).pipe(
      this.commonRequest(
        r14FromVersion,
        'create_r14_BakingListWithRecipe2ColumnsReportFromVersion',
      ),
    );
  }
  r26FromVersion(): Observable<CommonWinReportTaskRes> {
    return this.actionListener(C_Report.R26_BAKING_LIST_WITH_RECIPE_A4).pipe(
      this.commonRequest(r26FromVersion, 'create_r26_BakingListWithRecipeA4ReportFromVersion'),
    );
  }
  r27FromVersion(): Observable<CommonWinReportTaskRes> {
    return this.actionListener(C_Report.R27_BAKING_LIST_WITH_RECIPE_A5).pipe(
      this.commonRequest(r27FromVersion, 'create_r27_BakingListWithRecipeA4ReportFromVersion'),
    );
  }
  r34FromVersion(): Observable<CommonWinReportTaskRes> {
    return this.actionListener(C_Report.R34_BAKING_LIST_A4).pipe(
      this.commonRequest(r34FromVersion, 'create_r34_BakingListA4ReportFromVersion'),
    );
  }
  r41FromVersion(): Observable<CommonWinReportTaskRes> {
    return this.actionListener(C_Report.R41_BAKING_LIST_A4_DETAILED).pipe(
      this.commonRequest(r41FromVersion, 'create_r41_BakingListA4DetailedReportFromVersion'),
    );
  }
  r57FromVersion(): Observable<CommonWinReportTaskRes> {
    return this.actionListener(C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS).pipe(
      this.commonRequest(
        r57FromVersion,
        'create_r57_BakingListRawmaterials2ColumnsReportFromVersion',
      ),
    );
  }

  r58VersionsDifferenceWithRecipe(): Observable<CommonWinReportTaskRes> {
    return this.actionListener(C_Report.R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE).pipe(
      this.commonRequest(
        r58VersionsDifferenceWithRecipe,
        'create_r58_BakingListVersionsDifferenceWithRecipe',
      ),
    );
  }

  r68VersionsDifference(): Observable<CommonWinReportTaskRes> {
    return this.actionListener(
      C_Report.R68_FORWARDING_LIST_ARTICLE_CUSTOMERS_VERSIONS_DIFFERENCE,
    ).pipe(
      this.commonRequest(
        r68VersionsDifference,
        'create_r68_ForwardingListArticleCustomersVersionsDifference',
      ),
    );
  }

  r56VersionsDifference(): Observable<CommonWinReportTaskRes> {
    return this.actionListener(C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE).pipe(
      filter(({ params }) => params.type === 'difference'), // Filter by type
      this.commonRequest(r56VersionsDifference, 'create_r56_BakingListVersionsDifference'),
    );
  }

  r56VersionsDifferenceFromLiveData(): Observable<CommonWinReportTaskRes> {
    return this.actionListener(C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE).pipe(
      filter(({ params }) => params.type === 'liveData'),
      this.commonRequest(
        r56VersionsDifferenceFromLiveData,
        'create_r56_BakingListVersionsDifferenceFromLiveData',
      ),
    );
  }

  private commonRequest(query: string, queryPath: CommonQueryPathKey) {
    return (stream$: Observable<any>) =>
      stream$.pipe(
        switchMap(({ params }) => gqlClient(query, params.variables)),
        delay(2000),
        switchMap((res) => {
          const { taskId } = res.wawiAssist[queryPath] || {};
          const nonSuccessRes: CommonWinReportTaskRes = {
            taskId: (taskId as number) || 0,
            url: '',
            s3Key: '',
          };
          if (!taskId) {
            return of(nonSuccessRes);
          }
          return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
            map((v) => {
              if (v?.[0].status !== C_Win_Report_Task_Status.WRTS2_READY) {
                return { ...nonSuccessRes, status: v?.[0].status };
              }
              return res.wawiAssist?.[queryPath] as CommonWinReportTaskRes;
            }),
          );
        }),
      );
  }
  private _taskStatusPolling({
    tasksId,
    maxPollingAttempts = 10,
  }: {
    tasksId: Array<number>;
    maxPollingAttempts?: number;
  }): Observable<GetWinReportsStatusRes> {
    return (gqlClient(getTasksStatus, { tasksId }) as Observable<GetTasksStatusQuery>).pipe(
      repeat({ delay: 2000 }),
      map((v) => v.wawiAssist?.getWinReportsStatus as GetWinReportsStatusRes),
      takeWhile((v) => {
        return (
          v?.every(
            (item) =>
              item.status === C_Win_Report_Task_Status.WRTS5_IN_PROGRESS ||
              item.status === C_Win_Report_Task_Status.WRTS1_NOT_STARTED,
          ),
          true
        );
      }),
      filter((v, ind) => {
        const isInProcess = v?.some(
          (item) =>
            item.status === C_Win_Report_Task_Status.WRTS5_IN_PROGRESS ||
            item.status === C_Win_Report_Task_Status.WRTS1_NOT_STARTED,
        );
        const maxAttemptsReached = ind + 1 > maxPollingAttempts;
        if (!isInProcess) {
          return true;
        }
        // Limit polling by maximum number of attempts
        return maxAttemptsReached;
      }),
      take(1),
    );
  }
}

class ProductionReportFromVersionService extends Service<Action> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const productionReportFromVersionService = new ProductionReportFromVersionService();

type Action =
  | C_Report.R1_BAKING_LIST_2_COLUMNS
  | C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS
  | C_Report.R26_BAKING_LIST_WITH_RECIPE_A4
  | C_Report.R27_BAKING_LIST_WITH_RECIPE_A5
  | C_Report.R34_BAKING_LIST_A4
  | C_Report.R41_BAKING_LIST_A4_DETAILED
  | C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS
  | C_Report.R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE
  | C_Report.R68_FORWARDING_LIST_ARTICLE_CUSTOMERS_VERSIONS_DIFFERENCE
  | C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE
  | undefined;

export type CommonPubArgsForReportByVersion = {
  variables: CreateReportByVersionMutationVariables;
};

export type CommonPubArgsForReportByVersionDifference = {
  variables:
    | Create_R58_BakingListVersionsDifferenceWithRecipeMutationVariables
    | Create_R68_ForwardingListArticleCustomersVersionsDifferenceMutationVariables;
};

type GetWinReportsStatusRes = NonNullable<
  NonNullable<GetTasksStatusQuery['wawiAssist']>['getWinReportsStatus']
>;

type ExtractQueryPathKey<T> = Exclude<keyof NonNullable<T>, '__typename'>;

export type CommonWinReportTaskRes = {
  __typename?: 'WinReportTask';
  taskId: number;
  url: string;
  s3Key: string;
} & { status?: C_Win_Report_Task_Status };

type CommonQueryPathKey =
  | ExtractQueryPathKey<Create_R41_BakingListA4DetailedReportFromVersionMutation['wawiAssist']>
  | ExtractQueryPathKey<
      Create_R57_BakingListRawmaterials2ColumnsReportFromVersionMutation['wawiAssist']
    >
  | ExtractQueryPathKey<Create_R34_BakingListA4ReportFromVersionMutation['wawiAssist']>
  | ExtractQueryPathKey<Create_R27_BakingListWithRecipeA4ReportFromVersionMutation['wawiAssist']>
  | ExtractQueryPathKey<Create_R26_BakingListWithRecipeA4ReportFromVersionMutation['wawiAssist']>
  | ExtractQueryPathKey<
      Create_R14_BakingListWithRecipe2ColumnsReportFromVersionMutation['wawiAssist']
    >
  | ExtractQueryPathKey<Create_R1_BakingList2ColumnsReportFromVersionMutation['wawiAssist']>
  | ExtractQueryPathKey<Create_R58_BakingListVersionsDifferenceWithRecipeMutation['wawiAssist']>
  | ExtractQueryPathKey<
      Create_R68_ForwardingListArticleCustomersVersionsDifferenceMutation['wawiAssist']
    >
  | ExtractQueryPathKey<Create_R56_BakingListVersionsDifferenceMutation['wawiAssist']>
  | ExtractQueryPathKey<Create_R56_BakingListVersionsDifferenceFromLiveDataMutation['wawiAssist']>;

export type CreateReportByVersionMutationVariables =
  | Create_R1_BakingList2ColumnsReportFromVersionMutationVariables
  | Create_R14_BakingListWithRecipe2ColumnsReportFromVersionMutationVariables
  | Create_R26_BakingListWithRecipeA4ReportFromVersionMutationVariables
  | Create_R27_BakingListWithRecipeA4ReportFromVersionMutationVariables
  | Create_R34_BakingListA4ReportFromVersionMutationVariables
  | Create_R41_BakingListA4DetailedReportFromVersionMutationVariables
  | Create_R57_BakingListRawmaterials2ColumnsReportFromVersionMutationVariables;
