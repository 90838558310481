import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  StackProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import styled from '@mui/material/styles/styled';
/* Icons start */
import InfoIcon from '@mui/icons-material/Info';
import PrintIcon from '@mui/icons-material/Print';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import HistoryIcon from '@mui/icons-material/History';
/* Icons end */
import { AddressField } from './components/addressField.component.tsx';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { IOrderTypeDescription } from '../../../pages/order/edit/states/orderDetails/orderDetails.state.ts';
import { Theme as theme } from '../../styles/theme/theme.ts';
import { TypeColors } from '@mui/material/styles/createPalette';
import { EmailTrackingByTypeQueryVariables } from '../../../graphql/generatedModel.ts';
import { EmailTrackingPopup } from '../emailTracking/components/emailTracking.popup.tsx';

export const InfoBlock: FC<TInfoBlock> = ({
  register,
  addressNameField,
  rightColumn,
  customerName,
  leftColumnProps,
  rightColumnProps,
  infoTip,
  addressValue,
  isEmailServiceForInvoice,
  readOnlyAddress,
  orderType,
  emailTip,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const emailTrackingPopup = useMemo(() => new EmailTrackingPopup(), []);
  const { t } = useTranslation();
  useEffect(() => {
    return () => emailTrackingPopup.stream.unsubscribe();
  }, []);
  const handleMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setOpen(true);
  };
  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setOpen(false);
    }, 500);
    setTimeoutId(id);
  };
  const isEmailPropExist = typeof isEmailServiceForInvoice === 'boolean';
  const isTypeDescription = orderType?.description && orderType?.color;
  return (
    <SWrapper minHeight={124} direction='row' justifyContent='space-between' {...props}>
      <Box {...leftColumnProps}>
        <Stack direction='row' alignItems='center' spacing='6px'>
          <STitle variant='h5'>{customerName}</STitle>
          {infoTip && (
            <STooltipWrapper title={<SInfoContent>{infoTip}</SInfoContent>} placement='right'>
              <SIconButton>
                <InfoIcon />
              </SIconButton>
            </STooltipWrapper>
          )}
          {emailTip && (
            <STooltipWrapper
              title={
                <SInfoContent
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pt: '5px',
                    pb: '5px',
                  }}
                >
                  <Typography fontSize={14} noWrap>
                    {emailTip.message}
                  </Typography>
                  {emailTip.params && (
                    <Button
                      onClick={() =>
                        emailTrackingPopup.stream.emit('open', {
                          params: { queryParams: emailTip.params },
                        })
                      }
                      sx={{ ml: '6px' }}
                      startIcon={<HistoryIcon />}
                    >
                      {t('common.track')}
                    </Button>
                  )}
                </SInfoContent>
              }
              placement='right'
            >
              <SIconButton colorBtn='darkGreen'>
                <MarkEmailReadIcon />
              </SIconButton>
            </STooltipWrapper>
          )}
        </Stack>
        <Stack alignItems='flex-start'>
          {isTypeDescription && (
            <Chip label={orderType?.description} sx={{ backgroundColor: orderType?.color }} />
          )}
          {isEmailPropExist && (
            <>
              {isEmailServiceForInvoice ? (
                <Chip
                  avatar={<MailOutlineIcon />}
                  label={t('invoice.email_service')}
                  sx={{ backgroundColor: theme.palette.grey[300] }}
                />
              ) : (
                <Chip
                  avatar={<PrintIcon />}
                  label={t('invoice.send_letter')}
                  sx={{ backgroundColor: theme.palette.grey[300] }}
                />
              )}
            </>
          )}
          <STooltipWrapper
            title={
              <AddressField
                {...{
                  register,
                  addressNameField,
                  addressValue,
                  readOnly: readOnlyAddress,
                  handleMouseEnter,
                  handleMouseLeave,
                }}
              />
            }
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            open={open}
            placement='bottom-start'
          >
            <Button sx={{ marginTop: 1 }} startIcon={<LocationCityIcon />}>
              {t('common.address')}
            </Button>
          </STooltipWrapper>
        </Stack>
      </Box>
      {rightColumn && <Box {...rightColumnProps}>{rightColumn}</Box>}
      {emailTip && <emailTrackingPopup.Component />}
    </SWrapper>
  );
};

const SWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: `${theme.palette.lightBlue.main}10`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: 16,
}));

const SIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'colorBtn',
})<{ colorBtn?: TypeColors }>(({ theme, colorBtn }) => ({
  padding: 4,
  color: `${theme.palette[colorBtn || 'lightBlue'].main}90`,
  '&:hover': {
    backgroundColor: `${theme.palette[colorBtn || 'lightBlue'].main}10`,
  },
}));

const SInfoContent = styled(Paper)(() => ({
  padding: 10,
  fontSize: 14,
}));

const STooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
    maxWidth: '500px',
  },
}));

const STitle = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export interface IInfoBlockCommon extends StackProps {
  customerName: string;
  rightColumn?: ReactNode;
  leftColumnProps?: BoxProps;
  rightColumnProps?: BoxProps;
  infoTip?: null | string;
  emailTip?: {
    params: EmailTrackingByTypeQueryVariables;
    message: string;
  };
}

type TInfoBlockForm = IInfoBlockCommon & {
  register?: UseFormReturn<any>['register'];
  addressNameField?: string;
  addressValue?: never;
  isEmailServiceForInvoice?: boolean;
  readOnlyAddress?: boolean;
  orderType?: IOrderTypeDescription;
};
type TInfoBlockNoForm = IInfoBlockCommon & {
  register?: never;
  addressNameField?: never;
  addressValue?: string;
  isEmailServiceForInvoice?: boolean;
  readOnlyAddress?: boolean;
  orderType?: IOrderTypeDescription;
};

export type TInfoBlock = TInfoBlockForm | TInfoBlockNoForm;
