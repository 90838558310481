import { Observable, Subject } from 'rxjs';
import { IState, Sub } from '../../../../shared/state/state.abstract';

export function customerTabsSub<T extends IState<T>>() {
  abstract class Common extends Sub<T> {
    protected customerIdNotExist$ = new Subject<boolean>();

    customerIdNotExist(): Observable<boolean> {
      return this.customerIdNotExist$.asObservable();
    }
  }
  return Common;
}
